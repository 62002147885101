import FormNavigation from 'components/CommonKit/FormNavigation/FormNavigation';
import 'components/ProfileType/profileTypeForm.scss';
import { useTranslation } from 'react-i18next';
import { EcogestureStepForm, EquipmentType, ProfileTypeStepForm } from 'enums';
import { ProfileEcogesture, ProfileType } from 'models';
import React, { useCallback, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import EquipmentIcon from '../EquipmentIcon/EquipmentIcon';
import './ecogestureFormEquipment.scss';
import FormProgress from 'components/CommonKit/FormProgress/FormProgress';

interface EcogestureFormEquipmentProps {
  currentProfileEcogesture?: ProfileEcogesture;
  currentProfileType?: ProfileType;
  setPreviousStep: () => void;
  setNextStepEcogestureForm?: (_profileEcogesture: ProfileEcogesture) => void;
  setNextStepProfileForm?: (_profileType: ProfileType) => void;
  step: ProfileTypeStepForm | EcogestureStepForm;
}

const EcogestureFormEquipment = ({
  currentProfileEcogesture,
  currentProfileType,
  setPreviousStep,
  setNextStepEcogestureForm,
  setNextStepProfileForm,
  step,
}: EcogestureFormEquipmentProps) => {
  const { t } = useTranslation();
  const { value: isProfileEcogestureCompleted } = useAppSelector(
    (state) => state.profileStellioUser.isProfileEcogestureCompleted
  );
  const previousEquipments =
    currentProfileType?.equipments ||
    currentProfileEcogesture?.equipments ||
    [];

  const initAnswer = isProfileEcogestureCompleted ? previousEquipments : [];
  const [answer, setAnswer] = useState<EquipmentType[]>(initAnswer);

  const handlePrevious = useCallback(() => {
    setPreviousStep();
  }, [setPreviousStep]);

  const handleNext = useCallback(() => {
    if (setNextStepProfileForm && currentProfileType) {
      setNextStepProfileForm({
        ...currentProfileType,
        equipments: answer,
      });
    } else if (setNextStepEcogestureForm && currentProfileEcogesture) {
      setNextStepEcogestureForm({
        ...currentProfileEcogesture,
        equipments: answer,
      });
    }
  }, [
    setNextStepProfileForm,
    currentProfileType,
    setNextStepEcogestureForm,
    currentProfileEcogesture,
    answer,
  ]);

  const isChecked = (value: EquipmentType): boolean => {
    return answer.includes(value);
  };

  const handleChange = useCallback(
    (value: EquipmentType) => {
      const tempAnswer = [...answer];
      if (tempAnswer.includes(value)) {
        const index = tempAnswer.indexOf(value);
        if (index > -1) tempAnswer.splice(index, 1);
      } else {
        tempAnswer.push(value);
      }
      setAnswer(tempAnswer);
    },
    [answer]
  );

  return (
    <>
      <div className="ecogesture-profile-container">
        <div className="progress-wrapper">
          <FormProgress step={step as ProfileTypeStepForm} formType="profile" />
        </div>
        <div className="equipment-form-container">
          <div className="equipment-label text-22-normal">
            {t(
              `ecogesture_profile.${EcogestureStepForm[
                EcogestureStepForm.EQUIPMENTS
              ].toLowerCase()}.question`
            )}
          </div>
          <div className="equipment-hint text-16-normal">
            {t(
              `ecogesture_profile.${EcogestureStepForm[
                EcogestureStepForm.EQUIPMENTS
              ].toLowerCase()}.hint`
            )}
          </div>
          <div className="icons-container">
            {Object.values(EquipmentType).map((equipment) => (
              <label key={equipment} className="checkbox-equipment">
                <input
                  type="checkbox"
                  value={equipment}
                  name={equipment.toString()}
                  onChange={() => handleChange(equipment)}
                  checked={isChecked(equipment)}
                  className={
                    isChecked(equipment) ? 'item-eq checked' : 'item-eq'
                  }
                />
                <EquipmentIcon
                  equipment={equipment}
                  isChecked={answer.includes(equipment)}
                />
              </label>
            ))}
          </div>
        </div>
      </div>
      <FormNavigation
        step={step}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        disableNextButton={answer.length == 0}
        isEcogesture={true}
      />
    </>
  );
};

export default EcogestureFormEquipment;
