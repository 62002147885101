export enum UserChallengeUpdateFlag {
  CHALLENGE = 0,
  DUEL_UNLOCK = 10,
  DUEL_UPDATE_THRESHOLD = 11,
  DUEL_START = 12,
  DUEL_CONSUMPTION = 13,
  DUEL_WIN = 14,
  DUEL_LOSS = 15,
  DUEL_RESET = 16,
  QUIZ = 20,
  QUIZ_START = 21,
  QUIZ_DONE = 22,
  QUIZ_RESET = 23,
  QUIZ_UPDATE = 24,
  EXPLORATION_START = 30,
  EXPLORATION_UPDATE = 31,
  EXPLORATION_NOTIFICATION = 32,
  EXPLORATION_DONE = 33,
  ACTION_START = 40,
  ACTION_NOTIFICATION = 41,
  ACTION_DONE = 42,
}

export enum UserChallengeState {
  LOCKED = 0,
  UNLOCKED = 1,
  ONGOING = 2,
  DUEL = 3,
  DONE = 4,
}

export enum UserChallengeSuccess {
  ONGOING = 0,
  LOST = 1,
  WIN = 2,
}
