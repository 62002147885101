import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TimeStep } from 'enums';
import { DateTime } from 'luxon';
import { ChartState, Datachart } from 'models';

const initialState: ChartState = {
  selectedDate: DateTime.local().endOf('minute').setZone('utc', {
    keepLocalTime: true,
  }),
  currentTimeStep: TimeStep.WEEK,
  currentIndex: 0,
  currentDatachart: { actualData: [], comparisonData: null },
  currentDatachartIndex: 0,
  loading: true,
  showCompare: false,
  showOfflineData: false,
};

export const chartSlice = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    setCurrentDataChart: (state, action: PayloadAction<Datachart>) => {
      state.currentDatachart = action.payload;
    },
    setCurrentDataChartIndex: (state, action: PayloadAction<number>) => {
      state.currentDatachartIndex = action.payload;
    },
    setCurrentIndex: (state, action: PayloadAction<number>) => {
      state.currentIndex = Math.round(action.payload);
    },
    setCurrentTimeStep: (state, action: PayloadAction<TimeStep>) => {
      state.currentTimeStep = action.payload;
      if (state.currentTimeStep === TimeStep.YEAR) {
        state.showCompare = false;
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSelectedDate: (state, action: PayloadAction<DateTime>) => {
      state.selectedDate = action.payload;
    },
    setShowCompare: (state, action: PayloadAction<boolean>) => {
      state.showCompare = action.payload;
    },
    setShowOfflineData: (state, action: PayloadAction<boolean>) => {
      state.showOfflineData = action.payload;
    },
  },
});

export const {
  setCurrentDataChart,
  setCurrentDataChartIndex,
  setCurrentIndex,
  setCurrentTimeStep,
  setLoading,
  setSelectedDate,
  setShowCompare,
  setShowOfflineData,
} = chartSlice.actions;
