import { ScaleBand, ScaleLinear } from 'd3-scale';
import { detect } from 'detect-browser';
import { DataloadState, FluidType, TimeStep } from 'enums';
import { DateTime } from 'luxon';
import { Dataload } from 'models';
import React, { useEffect, useState } from 'react';
import DateChartService from 'services/dateChart.service';
import {
  setCurrentDataChartIndex,
  setSelectedDate,
} from 'store/chart/chart.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface BarProps {
  index: number;
  dataload: Dataload;
  compareDataload: Dataload | null;
  fluidType: FluidType;
  timeStep: TimeStep;
  compare: boolean;
  xScale: ScaleBand<string>;
  yScale: ScaleLinear<number, number>;
  height: number;
  isSwitching: boolean;
  isDuel?: boolean;
  isMultiMissingFluid?: boolean;
  weekdays?: 'week' | 'weekend';
  clickable?: boolean;
  average?: number;
}

const Bar = ({
  index,
  dataload,
  compareDataload,
  fluidType,
  timeStep,
  compare,
  xScale,
  yScale,
  height,
  isSwitching,
  isDuel,
  isMultiMissingFluid,
  weekdays,
  clickable = true,
  average,
}: BarProps) => {
  const dispatch = useAppDispatch();
  const { selectedDate } = useAppSelector((state) => state.chart);
  const [clicked, setClicked] = useState(false);
  const [animationEnded, setAnimationEnded] = useState(false);
  const [compareAnimationEnded, setCompareAnimationEnded] = useState(false);
  const browser = detect();

  const fluidStyle =
    fluidType === FluidType.MULTIFLUID ? 'MULTIFLUID' : FluidType[fluidType];

  const handleClick = () => {
    if (!isSwitching && !isDuel && clickable) {
      setClicked(true);
      dispatch(setSelectedDate(dataload.date));
    }
  };

  const onAnimationEnd = () => {
    setClicked(false);
    setAnimationEnded(true);
  };

  const onCompareAnimationEnd = () => {
    setClicked(false);
    setCompareAnimationEnded(true);
  };

  let value = dataload.value;
  /**
   * Use 10% of average as the placeholder if average is above 1,
   * otherwise use a minimum of 1 as the placeholder.
   */
  if (value === -1 && average) {
    value = average > 1 ? average * 0.1 : 1;
  }
  const yScaleValue = yScale(value) ?? 0;
  const yScaleCompareValue = yScale(compareDataload?.value ?? 0) ?? 0;

  const xScaleValue =
    xScale(dataload.date.toLocaleString(DateTime.DATETIME_SHORT)) ?? 0;

  const isSelectedDate = isDuel
    ? false
    : new DateChartService().compareStepDate(
        timeStep,
        selectedDate,
        dataload.date
      );

  const clickedAnim = clicked ? 'bounce-2 delay' : '';
  const disabled = clickable ? '' : 'disabled';
  const selected = isSelectedDate ? 'selected' : '';

  const getBarClass = () => {
    const upcoming = dataload.value === -1 ? 'bar-UNCOMING' : '';
    const edgeBrowser = browser && browser.name !== 'edge';
    const bounce = edgeBrowser ? '1' : '3';

    const baseStyles = `bar-${fluidStyle} ${upcoming} ${weekdays} ${selected} ${disabled}`;
    if (clicked) {
      return `${baseStyles} ${clickedAnim}`;
    }
    if (animationEnded) {
      return baseStyles;
    }
    return `${baseStyles} bounce-${bounce} delay--${index}`;
  };

  const getCompareBarClass = () => {
    const animate = `bounce-2 delay--${clicked ? 0 : index}`;
    const animationClass = compareAnimationEnded ? '' : animate;

    return `bar-compare-${fluidStyle} ${selected} ${animationClass} ${clickedAnim}`;
  };

  const barBackgroundClass = isSelectedDate;

  const getBandWidth = (): number => {
    return compare ? xScale.bandwidth() / 2 : xScale.bandwidth();
  };

  const topRoundedRect = (
    x: number,
    y: number,
    width: number,
    height: number
  ): string => {
    const radius = height > 4 ? 4 : height / 4;

    return `
      M${x},${y + radius}
      a${radius},${radius} 0 0 1 ${radius},${-radius}
      h${width - 2 * radius}
      a${radius},${radius} 0 0 1 ${radius},${radius}
      v${height - radius}
      h${-width}
      z`;
  };

  useEffect(() => {
    if (isSelectedDate && !isDuel) {
      setClicked(true);
      dispatch(setCurrentDataChartIndex(index));
    }
  }, [dispatch, isSelectedDate, isDuel, index]);

  return (
    <g>
      {/* selected background bar */}
      {height > 0 && (
        <g
          transform={`translate(${xScaleValue}, -40)`}
          className={`barContainer ${disabled}`}
        >
          <rect
            onClick={!weekdays ? handleClick : () => undefined}
            x="0"
            y="0"
            width={compare ? getBandWidth() * 2 : getBandWidth()}
            height={height + 40}
            className={`background-${barBackgroundClass}`}
            fill="#E0E0E0"
          />
        </g>
      )}
      {/* placeholder bar for upcoming values */}
      {height > 0 &&
        dataload.state === DataloadState.COMING &&
        dataload.date < DateTime.local() && (
          <g
            transform={`translate(${xScaleValue}, ${yScaleValue})`}
            className="barFill"
          >
            <defs>
              <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
                <stop id="stop-color-1" offset="0%" />
                <stop id="stop-color-2" offset="100%" />
              </linearGradient>
            </defs>
            <path
              d={topRoundedRect(
                compare ? getBandWidth() : 0,
                0,
                weekdays ? 3 : getBandWidth(),
                height - yScaleValue
              )}
              className={getBarClass()}
              onClick={!weekdays ? handleClick : () => undefined}
              onAnimationEnd={onAnimationEnd}
            />
          </g>
        )}
      {height > 0 && dataload.value >= 0 && isMultiMissingFluid ? (
        <g
          transform={`translate(${xScaleValue}, ${yScaleValue})`}
          fill="#00000"
          className="barFill"
        >
          <defs>
            <linearGradient
              id="gradient"
              className={getBarClass()}
              x1="0"
              x2="0"
              y1="0"
              y2="1"
            >
              <stop id="stop-color-1" offset="0%" />
              <stop id="stop-color-2" offset="100%" />
            </linearGradient>
            <pattern
              id="diagonalHatch"
              patternUnits="userSpaceOnUse"
              width="8"
              height="8"
              patternTransform="scale(1) rotate(-45)"
            >
              <rect x="0" y="0" width="100%" height="100%" fill="#121212" />
              <path d="M0 8h20z" strokeWidth="3" stroke="#71612E" fill="none" />
            </pattern>
            <pattern
              id="diagonalHatchSelected"
              patternUnits="userSpaceOnUse"
              width="8"
              height="8"
              patternTransform="scale(1) rotate(-45)"
            >
              <rect x="0" y="0" width="100%" height="100%" fill="#121212" />
              <path d="M0 8h20z" strokeWidth="3" stroke="#E3B82A" fill="none" />
            </pattern>
          </defs>
          <path
            d={topRoundedRect(
              compare ? getBandWidth() : 0,
              0,
              getBandWidth(),
              height - yScaleValue
            )}
            stroke={!isSelectedDate ? '#71612E' : '#e3b82a'}
            fill={
              isSelectedDate
                ? 'url(#diagonalHatchSelected)'
                : 'url(#diagonalHatch)'
            }
            onClick={!weekdays ? handleClick : () => undefined}
            onAnimationEnd={onAnimationEnd}
          />
        </g>
      ) : (
        height > 0 &&
        dataload.value &&
        dataload.value >= 0 && (
          <g transform={`translate(${xScaleValue}, ${yScaleValue})`}>
            <defs>
              <linearGradient
                id="gradient"
                className={getBarClass()}
                x1="0"
                x2="0"
                y1="0"
                y2="1"
              >
                <stop id="stop-color-1" offset="0%" />
                <stop id="stop-color-2" offset="100%" />
              </linearGradient>
            </defs>
            <path
              d={topRoundedRect(
                compare ? getBandWidth() : 0,
                0,
                weekdays ? 3 : getBandWidth(),
                height - yScaleValue
              )}
              className={isDuel ? 'bar-duel' : getBarClass()}
              onClick={!weekdays ? handleClick : () => undefined}
              onAnimationEnd={onAnimationEnd}
            />
          </g>
        )
      )}

      {compare && compareDataload && compareDataload.value >= 0 && (
        <g transform={`translate(${xScaleValue}, ${yScaleCompareValue})`}>
          <defs>
            <linearGradient
              id="gradient-compare"
              className={getCompareBarClass()}
              x1="0"
              x2="0"
              y1="0"
              y2="1"
            >
              <stop id="stop-compare-color-1" offset="0%" />
              <stop id="stop-compare-color-2" offset="100%" />
            </linearGradient>
          </defs>
          <path
            d={topRoundedRect(
              0,
              0,
              getBandWidth(),
              height - yScaleCompareValue
            )}
            className={getCompareBarClass()}
            onClick={handleClick}
            onAnimationEnd={onCompareAnimationEnd}
          />
        </g>
      )}
    </g>
  );
};

export default Bar;
