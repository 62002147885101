export enum InitSteps {
  MIGRATION,
  CONSENT,
  PROFILE,
  CHALLENGES,
  PRICES, // never used
  CONSOS,
}
export enum InitStepsErrors {
  MIGRATION_ERROR = 'migration_error',
  CONSENT_ERROR = 'consent_error',
  PROFILE_ERROR = 'profile_error',
  PROFILETYPE_ERROR = 'profileType_error',
  CHALLENGES_ERROR = 'challenges_error',
  ANALYSIS_ERROR = 'analysis_error',
  CONSOS_ERROR = 'consos_error',
  PARTNERS_ERROR = 'partners_error', // never used
  NETWORK_ERROR = 'network_error', // never used
  UNKNOWN_ERROR = 'unknown_error',
}
