import SgeModalHint from 'components/Connection/SGEConnect/SgeModalHint';
import { useTranslation } from 'react-i18next';
import { SgeStore } from 'models';
import React, { useCallback, useState } from 'react';
import { SGEKeysForm } from './SgeConnectView';

interface StepIdentityAndPdlProps {
  sgeState: SgeStore;
  onChange: (
    key: SGEKeysForm,
    value: string | number,
    maxLength?: number
  ) => void;
}

const StepIdentityAndPdl = ({
  sgeState,
  onChange,
}: StepIdentityAndPdlProps) => {
  const { t } = useTranslation();
  const [openHintModal, setOpenHintModal] = useState<boolean>(false);
  const toggleModal = useCallback(() => {
    setOpenHintModal((prev) => !prev);
  }, []);

  return (
    <div className="sge-step-container stepIdentity">
      <div className="title text-22-bold">
        {t('auth.enedissgegrandlyon.identityTitle')}
      </div>
      <label htmlFor="firstName" className="text-16-normal">
        {t('auth.enedissgegrandlyon.firstName')}
      </label>
      <input
        type="text"
        id="firstName"
        name="firstName"
        value={sgeState.firstName}
        onChange={(e) => onChange('firstName', e.target.value)}
        required
      />
      <label htmlFor="lastName" className="text-16-normal">
        {t('auth.enedissgegrandlyon.lastName')}
      </label>
      <input
        type="text"
        id="lastName"
        name="lastName"
        value={sgeState.lastName}
        onChange={(e) => onChange('lastName', e.target.value)}
        required
      />
      <div className="title text-22-bold">
        {t('auth.enedissgegrandlyon.pdlTitle')}
      </div>
      <label htmlFor="pdl" className="text-16-normal">
        {t('auth.enedissgegrandlyon.pdlLabel')}
      </label>
      <input
        id="pdl"
        name="pdl"
        type="number"
        min={0}
        value={sgeState.pdl ? sgeState.pdl : undefined}
        onChange={(e) => onChange('pdl', e.target.value, 14)}
        inputMode="numeric"
        required
      />
      <br />
      <div onClick={toggleModal} className="pdl-hint text-16-normal">
        {t('auth.enedissgegrandlyon.pdlHint')}
      </div>
      <SgeModalHint open={openHintModal} handleCloseClick={toggleModal} />
    </div>
  );
};

export default StepIdentityAndPdl;
