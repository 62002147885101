import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

// enums
import { FluidType } from 'enums';
// components
import { useLastConsumption } from 'components/NotificationsView/hooks';
import './consumptionAverageHelper.scss';

interface Props {
  fluidType: FluidType;
}

const ConsumptionMaxHelper = ({ fluidType }: Props) => {
  const { isLoading, lastMonth, lastWeek, unit } =
    useLastConsumption(fluidType);
  const { t } = useTranslation('');

  return (
    <div className="average-helper">
      <div className="average-paragraph">
        {t('notifications.consoHelper.lastMonth')} ~
        {isLoading ? <CircularProgress size={20} /> : lastMonth?.toFixed(1)}{' '}
        <span className="unit">{unit}</span>
      </div>
      <div className="average-paragraph">
        {t('notifications.consoHelper.lastWeek')} ~
        {isLoading ? <CircularProgress size={20} /> : lastWeek?.toFixed(1)}{' '}
        <span className="unit">{unit}</span>
      </div>
    </div>
  );
};

export default ConsumptionMaxHelper;
