import { ThunkAction, configureStore } from '@reduxjs/toolkit';
import { AnyAction, combineReducers } from 'redux';

// Models
import {
  AnalysisState,
  ChallengeState,
  ChartState,
  GlobalState,
  ModalState,
  Profile,
  ProfileEcogesture,
  ProfileType,
} from 'models';
import { StellioUserProfile } from 'models/stellio.model';
// Store
import { analysisSlice } from 'store/analysis/analysis.slice';
import { challengeSlice } from 'store/challenge/challenge.slice';
import { chartSlice } from 'store/chart/chart.slice';
import { globalSlice } from 'store/global/global.slice';
import { modalSlice } from 'store/modal/modal.slice';
import { profileSlice } from 'store/profile/profile.slice';
import { profileEcogestureSlice } from 'store/profileEcogesture/profileEcogesture.slice';
import { profileTypeSlice } from 'store/profileType/profileType.slice';
import { profileStellioUserSlice } from 'store/profileStellioUser/profileStellioUser.slice';
import {
  ProfileKeycloakUser,
  profileKeycloakUserSlice,
} from 'store/profileKeycloakUserSlice/profileKeycloakUserSlice.slice';

export interface AppState {
  analysis: AnalysisState;
  challenge: ChallengeState;
  chart: ChartState;
  global: GlobalState;
  modal: ModalState;
  profile: Profile;
  profileEcogesture: ProfileEcogesture;
  profileType: ProfileType;
  profileStellioUser: StellioUserProfile;
  profileKeycloakUser: ProfileKeycloakUser;
}

export const rootReducer = combineReducers({
  analysis: analysisSlice.reducer,
  challenge: challengeSlice.reducer,
  chart: chartSlice.reducer,
  global: globalSlice.reducer,
  modal: modalSlice.reducer,
  profile: profileSlice.reducer,
  profileEcogesture: profileEcogestureSlice.reducer,
  profileType: profileTypeSlice.reducer,
  profileStellioUser: profileStellioUserSlice.reducer,
  profileKeycloakUser: profileKeycloakUserSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const getStore = () => store;

export type AppDispatch = ReturnType<any>;
export type MyThunkAction<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  AnyAction
>;
