import Button from '@mui/material/Button';
import finishIcon from 'assets/icons/visu/profileType/finish.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import useExploration from 'hooks/useExploration';
import _cloneDeepWith from 'lodash/cloneDeepWith';
import {
  HousingType,
  UsageEventType,
  UserActionState,
  UserChallengeState,
  UserChallengeSuccess,
  UserDuelState,
  UserExplorationID,
} from 'enums';
import { DateTime, Duration } from 'luxon';
import {
  ProfileType,
  TimePeriod,
  UserChallenge,
  UserChallengeEntity,
  UserEcogesture,
} from 'models';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import ProfileTypeService from 'services/profileType.service'
// import ProfileTypeEntityService from 'services/profileTypeEntity.service'
// import UsageEventService from 'services/usageEvent.service'
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import './profileTypeFinished.scss';
import AppAxiosService from 'services/appAxios.service';
import {
  StellioChallenge,
  StellioChallengeProgress,
  StellioEntityType,
  StellioUserProfile,
} from 'models/stellio.model';
import { HttpStatusCode } from 'axios';
import {
  spreadStellioUserProfile,
  updateStellioUserProfile,
} from 'store/profileStellioUser/profileStellioUser.slice';
import {
  setCurrentUserChallenge,
  setUserChallengeList,
  setUserChallengeProgress,
} from 'store/challenge/challenge.slice';
import ChallengeService from 'services/challenge.service';

const ProfileTypeFinished = ({ profileType }: { profileType: ProfileType }) => {
  const stellioClient = useMemo(() => new AppAxiosService(), []);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    currentChallenge,
    challengesList,
    duelsList,
    explorationsList,
    quizList,
    questionsList,
    customQuestionsList,
    ecogesturesList,
  } = useAppSelector((state) => state.challenge);
  const {
    email,
    sub: userSub,
    given_name: givenName,
    family_name: familyName,
  } = useAppSelector((state) => state.profileKeycloakUser);
  const userId = `urn:ngsi-ld:${StellioEntityType.USER_PROFILE}:${userSub}`;
  const isSavedRef = useRef(false);
  const renders = useRef(0);

  const handleClick = () => {
    if (location?.pathname === '/ecogesture-form') {
      navigate('/ecogesture-selection');
    } else {
      navigate('/consumption');
    }
  };
  const [, setValidExploration] = useExploration();

  const initiateUserProgress = async () => {
    if (!userSub) return;

    const getInitUserProgress = (
      challenge: StellioChallenge,
      i: number
    ): UserChallengeEntity | undefined => {
      const refDuel = duelsList.find(
        (duel) => duel.id === challenge.hasDuel.object
      );
      if (!refDuel) return;

      const refQuiz = quizList.find(
        (quiz) => quiz.id === challenge.hasQuiz.object
      );
      if (!refQuiz) return;
      const refQuestions = questionsList.filter((question) =>
        refQuiz.hasQuestions.find((relation) => relation.object === question.id)
      );
      if (refQuestions.length === 0) return;
      const refCustomQuestion = customQuestionsList.find(
        (customQuestion) =>
          customQuestion.id === refQuiz.hasCustomQuestion.object
      );
      if (!refCustomQuestion) return;
      const refExploration = explorationsList.find(
        (exploration) => exploration.id === challenge.hasExploration.object
      );
      if (!refExploration) return;

      const initUserProgress: UserChallengeEntity = {
        id: challenge.id,
        title: challenge.title.value,
        description: challenge.description.value,
        state: i === 0 ? UserChallengeState.ONGOING : UserChallengeState.LOCKED, // Unlock first challenge
        success: UserChallengeSuccess.ONGOING,
        startDate: null,
        endingDate: null,
        target: challenge.target.value,
        action: {
          ecogesture: null,
          startDate: null,
          state: UserActionState.UNSTARTED,
        },
        progress: {
          quizProgress: 0,
          explorationProgress: 0,
          actionProgress: 0,
        },
        duel: {
          id: refDuel.id,
          description: refDuel.description.value,
          duration: Duration.fromISO(
            refDuel.duration.value
          ).toObject() as Duration,
          fluidTypes: [],
          startDate: null,
          state: UserDuelState.LOCKED,
          threshold: refDuel.threshold?.value ?? 0,
          title: refDuel.title.value,
          userConsumption: 0,
        },
        quiz: {
          id: refQuiz.id,
          customQuestion: {
            interval: refCustomQuestion.interval.value,
            period: refCustomQuestion.period.value,
            questionLabel: refCustomQuestion.title.value,
            timeStep: refCustomQuestion.timeStep.value,
            singleFluid: refCustomQuestion.singleFluid.value,
            type: refCustomQuestion.customQuestionType?.value ?? 0,
            result: 0,
          },
          questions: refQuestions.map((question) => ({
            explanation: question.answerExplanation.value,
            questionLabel: question.title.value,
            result: 0,
            source: 'string',
            answers: question.answers.map((answer) => ({
              answerLabel: answer.value,
              isTrue: answer.isCorrect.value,
            })),
          })),
          startDate: null,
          result: 0,
          state: 0,
        },
        exploration: {
          id: refExploration.id,
          description: refExploration.description?.value ?? '',
          complementary_description:
            refExploration.complementaryDescription?.value ?? '',
          ecogesture_id: refExploration.refEcogesture?.object ?? '',
          fluid_condition: refExploration.fluidCondition?.value ?? [],
          message_success: refExploration.successMessage.value,
          type: refExploration.explorationType.value,
          date: null,
          state: 0,
          progress: 0,
          target: 1,
        },
      };

      return initUserProgress;
    };

    const initialisedChallengeProgress: StellioChallengeProgress = {
      id: `urn:ngsi-ld:${StellioEntityType.CHALLENGE_PROGRESS}:${userSub}`,
      type: StellioEntityType.CHALLENGE_PROGRESS,
      globalState: {
        type: 'Property',
        value: `urn:ngsi-ld:${StellioEntityType.CHALLENGE}:CHALLENGE0001`,
        description: {
          type: 'Property',
          value:
            "Keep tracks of user progress accross challenges. The value reference the current active challenge's id",
        },
      },
      refChallenge: challengesList.map((challenge, i) => {
        const userProgressValue = getInitUserProgress(challenge, i);
        if (!userProgressValue)
          throw new Error(
            `Could not init challenge user progress for: ${challenge.id}`
          );

        return {
          type: 'Relationship',
          object: challenge.id,
          datasetId: `urn:ngsi-ld:Dataset:${i}`,
          challengeTitle: {
            type: 'Property',
            value: challenge.title.value,
          },
          userProgress: {
            type: 'JsonProperty',
            json: userProgressValue,
          },
        };
      }),
      refEcogesture: ecogesturesList.map((ecogesture, i) => {
        const userProgressValue: UserEcogesture = {
          doing: false,
          objective: false,
          viewedInSelection: false,
        };

        return {
          type: 'Relationship',
          object: ecogesture.id,
          datasetId: `urn:ngsi-ld:Dataset:${i}`,
          ecogestureName: {
            type: 'Property',
            value: ecogesture.name.value,
          },
          userProgress: {
            type: 'JsonProperty',
            json: userProgressValue,
          },
        };
      }),
    };

    console.log(initialisedChallengeProgress);

    // Init user ChallengeProgress
    const response = await stellioClient.createEntity(
      initialisedChallengeProgress
    );

    if (response.status === HttpStatusCode.Created) {
      dispatch(setUserChallengeProgress(initialisedChallengeProgress));

      const challengeService = new ChallengeService(
        stellioClient,
        userSub,
        initialisedChallengeProgress,
        ecogesturesList,
        explorationsList
      );

      const { currentUserChallenge, userChallengeList } =
        challengeService.getParsedAndTransformedUserChallenges();
      if (!currentUserChallenge || !userChallengeList) return;
      dispatch(setUserChallengeList(userChallengeList));
      dispatch(setCurrentUserChallenge(currentUserChallenge));
    }
  };

  useEffect(() => {
    if (!userSub) return;
    renders.current++;

    async function createNewUserProfile() {
      if (!givenName || !familyName || !email) return;

      const initialisedUserProfile: StellioUserProfile = {
        id: userId,
        type: StellioEntityType.USER_PROFILE,
        userInfos: {
          type: 'JsonProperty',
          json: {
            givenName,
            familyName,
            email,
          },
        },
        customPopupDate: {
          type: 'Property',
          value: '0000-01-01T00:00:00.000Z',
        },
        partnersIssueSeenDate: {
          type: 'JsonProperty',
          json: {
            enedis: '0000-01-01T00:00:00.000Z',
            grdf: '0000-01-01T00:00:00.000Z',
            suez: '0000-01-01T00:00:00.000Z',
          },
        },
        challengeProgress: {
          type: 'Relationship',
          object: `urn:ngsi-ld:${StellioEntityType.CHALLENGE_PROGRESS}:${userSub}`,
        },
        isFirstConnection: {
          type: 'Property',
          value: true,
        },
        accountInactivityFirstAlert: {
          type: 'Property',
          value: false,
        },
        accountInactivitySecondAlert: {
          type: 'Property',
          value: false,
        },
        lastConnectionDate: {
          type: 'Property',
          value: DateTime.now().toISO(),
        },
        haveSeenLastAnalysis: {
          type: 'Property',
          value: false,
        },
        sendAnalysisNotification: {
          type: 'Property',
          value: true,
        },
        sendConsumptionAlert: {
          type: 'Property',
          value: false,
        },
        waterDailyConsumptionLimit: {
          type: 'Property',
          value: 0,
        },
        isProfileTypeCompleted: {
          type: 'Property',
          value: true,
        },
        isProfileEcogestureCompleted: {
          type: 'Property',
          value: true,
        },
        haveSeenWelcomeModal: {
          type: 'Property',
          value: false,
        },
        haveSeenEcogestureModal: {
          type: 'Property',
          value: false,
        },
        housingType: {
          type: 'Property',
          value: profileType.housingType,
        },
        constructionYear: {
          type: 'Property',
          value: profileType.constructionYear,
        },
        area: {
          type: 'Property',
          value: profileType.area,
        },
        occupantsNumber: {
          type: 'Property',
          value: profileType.occupantsNumber,
        },
        outsideFacingWalls: {
          type: 'Property',
          value: profileType.outsideFacingWalls,
        },
        floor: {
          type: 'Property',
          value: profileType.floor,
        },
        heating: {
          type: 'Property',
          value: profileType.heating,
        },
        coldWater: {
          type: 'Property',
          value: profileType.coldWater,
        },
        hotWater: {
          type: 'Property',
          value: profileType.hotWater,
        },
        individualInsulationWork: {
          type: 'Property',
          value: profileType.individualInsulationWork,
        },
        hasInstalledVentilation: {
          type: 'Property',
          value: profileType.hasInstalledVentilation,
        },
        hasReplacedHeater: {
          type: 'Property',
          value: profileType.hasReplacedHeater,
        },
        hotWaterEquipment: {
          type: 'Property',
          value: profileType.hotWaterEquipment,
        },
        warmingFluid: {
          type: 'Property',
          value: profileType.warmingFluid,
        },
        hotWaterFluid: {
          type: 'Property',
          value: profileType.hotWaterFluid,
        },
        cookingFluid: {
          type: 'Property',
          value: profileType.cookingFluid,
        },
        updateDate: {
          type: 'Property',
          value: DateTime.now().toISO() ?? '',
        },
        equipments: {
          type: 'Property',
          value: profileType.equipments,
        },
      };
      const response = await stellioClient.createEntity(initialisedUserProfile);

      if (response.status === HttpStatusCode.Created) {
        dispatch(spreadStellioUserProfile(initialisedUserProfile));

        initiateUserProgress();
      }
    }

    async function updateOrCreateUserProfile() {
      if (!userSub) return;

      const { data: userProfiles } =
        await stellioClient.getEntityByType<StellioUserProfile>(
          StellioEntityType.USER_PROFILE
        );

      const userProfile = userProfiles.find((userProfile) =>
        userProfile.id.includes(userSub)
      );

      if (userProfile) {
        const attrsToUpdate: Partial<StellioUserProfile> = {};
        Object.keys(profileType).forEach((key) => {
          attrsToUpdate[key] = {
            type: 'Property',
            value: profileType[key],
          };
        });

        delete attrsToUpdate.id;
        delete attrsToUpdate.type;

        await dispatch(updateStellioUserProfile(attrsToUpdate));
      } else {
        await createNewUserProfile();
      }

      isSavedRef.current = true;
    }

    if (!isSavedRef.current && renders.current < 2) {
      updateOrCreateUserProfile();
      if (
        currentChallenge &&
        currentChallenge.exploration.id === UserExplorationID.EXPLORATION001
      ) {
        setValidExploration(currentChallenge.exploration.id);
      }
    }
  }, [userSub]);

  return (
    <div className="profile-type-finished-card">
      <StyledIcon className="profile-type-icon" icon={finishIcon} width={120} />
      <div className="profile-type-finished-label text-28-normal-uppercase">
        {t('profile_type.finished.title')}
      </div>
      <div className="profile-type-finished-description text-18-normal">
        <div>{t('profile_type.finished.label1')}</div>
        <div>{t('profile_type.finished.label2')}</div>
      </div>
      <Button
        aria-label={t('profile_type.accessibility.button_validate')}
        onClick={handleClick}
        className="profile-type-finished-button btn-primary-positive text-16-normal"
      >
        <span>{t('profile_type.finished.button_validate')}</span>
      </Button>
    </div>
  );
};

export default ProfileTypeFinished;
