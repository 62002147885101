import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { SgeStore } from 'models';
import React from 'react';
import { SGEKeysForm } from './SgeConnectView';

interface StepConsentProps {
  sgeState: SgeStore;
  onChange: (key: SGEKeysForm, value: boolean) => void;
}
const StepConsent = ({ sgeState, onChange }: StepConsentProps) => {
  const { t } = useTranslation();

  return (
    <div className="sge-step-container stepConsent">
      <div className="head text-16-normal">
        {t('auth.enedissgegrandlyon.headConsent')}
      </div>
      <div className="title text-22-bold">
        {t('auth.enedissgegrandlyon.textConsent')}
      </div>
      <ul className="text-16-normal">
        <li>{t('auth.enedissgegrandlyon.consentLi1')}</li>
        <li>{t('auth.enedissgegrandlyon.consentLi2')}</li>
        <li>{t('auth.enedissgegrandlyon.consentLi3')}</li>
        <li>{t('auth.enedissgegrandlyon.consentLi4')}</li>
      </ul>
      <label
        className={classNames('checkbox', {
          ['answer-checked']: sgeState.dataConsent,
        })}
      >
        <input
          id="dataConsent"
          type="checkbox"
          name="Data-consent-validation"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange('dataConsent', e.target.checked)
          }
          checked={sgeState.dataConsent}
        />
        <span
          dangerouslySetInnerHTML={{
            __html: t('auth.enedissgegrandlyon.consentCheck1'),
          }}
        />
      </label>
      <label
        className={classNames('checkbox', {
          ['answer-checked']: sgeState.pdlConfirm,
        })}
      >
        <input
          id="pdlConfirm"
          type="checkbox"
          name="Data-consent-validation"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange('pdlConfirm', e.target.checked)
          }
          checked={sgeState.pdlConfirm}
        />
        <span
          dangerouslySetInnerHTML={{
            __html: t('auth.enedissgegrandlyon.consentCheck2'),
          }}
        />
      </label>
    </div>
  );
};

export default StepConsent;
