const _ = require('lodash')

export function getRoundFloat(data: number) {
  return Math.round((data + Number.EPSILON) * 100) / 100
}

export function getPercentage(dataA: Array<number>, dataB: Array<number>) {
  return Math.round(
    Number.parseFloat(((1 - _.mean(dataA) / _.mean(dataB)) * 100).toFixed(2))
  )
}

export function sum(dataA: Array<number>) {
  return Number.parseInt(_.sum(dataA))
}
