import { DateTime } from 'luxon';
import { Profile, ProfileEntity } from 'models';

export default class ProfileService {
  constructor() {}

  private getDate(date: string | undefined): DateTime {
    if (!date) {
      return DateTime.local().minus({ day: 1 }).startOf('day');
    }
    return DateTime.fromISO(date, {
      zone: 'utc',
    });
  }

  /**
   * Retrieve Profile from the ProfileEntity
   * @param {ProfileEntity} profileEntity
   * @returns {Profile}
   */
  public parseProfileEntityToProfile(profileEntity: ProfileEntity): Profile {
    const profile: Profile = {
      ...profileEntity,
      monthlyAnalysisDate:
        typeof profileEntity.monthlyAnalysisDate === 'string'
          ? this.getDate(profileEntity.monthlyAnalysisDate)
          : profileEntity.monthlyAnalysisDate,
      lastConnectionDate: this.getDate(profileEntity.lastConnectionDate),
      partnersIssueSeenDate: {
        enedis: this.getDate(profileEntity.partnersIssueSeenDate?.enedis),
        egl: this.getDate(profileEntity.partnersIssueSeenDate?.egl),
        grdf: this.getDate(profileEntity.partnersIssueSeenDate?.grdf),
      },
      activateHalfHourDate: this.getDate(profileEntity.activateHalfHourDate),
      customPopupDate: this.getDate(profileEntity.customPopupDate),
    };
    return profile;
  }

  public async getProfile(
    profileEntity: ProfileEntity
  ): Promise<Profile | null> {
    return this.parseProfileEntityToProfile(profileEntity);
  }

  public async updateProfile(
    attributes: Partial<Profile>
  ): Promise<Profile | null> {
    // const query: QueryDefinition = Q(PROFILE_DOCTYPE)
    // const {
    //   data: [doc],
    // }: QueryResult<ProfileEntity[]> = await this._client.query(query.limitBy(1))
    // const { data: profileEntity }: QueryResult<ProfileEntity | null> =
    //   await this._client.save({
    //     ...doc,
    //     ...attributes,
    //   })
    // if (profileEntity) {
    //   return this.parseProfileEntityToProfile(profileEntity)
    // }
    return null;
  }
}
