import { Button } from '@mui/material';
import icon from 'assets/icons/visu/profileType/finish.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ecogestureSelectionEnd.scss';

const EcogestureSelectionEnd = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="eg-selection-end-container">
      <div className="content">
        <div className="title text-28-bold">
          {t('ecogesture_selection.title_final')}
        </div>
        <StyledIcon icon={icon} width={120} />
        <div className="text text-16-normal">
          {t('ecogesture_selection.text_final_1')}
        </div>
        <div className="text text-16-normal">
          {t('ecogesture_selection.text_final_2')}
        </div>
      </div>
      <div className="buttons">
        <Button
          aria-label={t('ecogesture_selection.accessibility.button_ok')}
          className="btn-highlight text-16-bold"
          onClick={() => navigate('/ecogestures?tab=0')}
        >
          <span>{t('ecogesture_selection.button_ok')}</span>
        </Button>
      </div>
    </div>
  );
};

export default EcogestureSelectionEnd;
