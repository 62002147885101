import { useState } from 'react';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useTranslation } from 'react-i18next';

import './helperText.scss';
import { ProfileTypeStepForm } from 'enums';

interface Props {
  step: ProfileTypeStepForm;
}

const HelperText = ({ step }: Props) => {
  const { t } = useTranslation();
  const [isHelperTextDisplayed, setIsHelperTextDisplayed] = useState(false);

  const toggle = () => {
    setIsHelperTextDisplayed(!isHelperTextDisplayed);
  };

  let content: string = t(
    `profile_type.${ProfileTypeStepForm[step].toLowerCase()}.helperText`
  );

  content = content.includes('helperText') ? '' : content;

  return content ? (
    <div className="helper-text">
      <div className="toggler" onClick={toggle}>
        {isHelperTextDisplayed ? (
          <>
            {t('profile_type.helperToggler.less')} <ExpandLessOutlinedIcon />
          </>
        ) : (
          <>
            {t('profile_type.helperToggler.more')} <ExpandMoreOutlinedIcon />
          </>
        )}
      </div>
      {isHelperTextDisplayed && (
        <div className="content" onClick={toggle}>
          {content}
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default HelperText;
