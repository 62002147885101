import {
  UserChallengeUpdateFlag,
  UserExplorationID,
  UserExplorationState,
  UserExplorationType,
} from 'enums';
import { DateTime } from 'luxon';
import { ExplorationEntity, UserChallenge, UserExploration } from 'models';
import ChallengeService from './challenge.service';
import StellioAxiosClient from './appAxios.service';
import {
  StellioChallengeProgress,
  StellioEcogesture,
  StellioExploration,
} from 'models/stellio.model';

export default class ExplorationService {
  private readonly _client: StellioAxiosClient;
  private readonly _userSub: string;
  private readonly _userChallengeProgress: StellioChallengeProgress;
  private readonly _allStellioEcogestures: StellioEcogesture[];
  private readonly _allStellioExplorations: StellioExploration[];

  constructor(
    _client: StellioAxiosClient,
    userSub: string,
    allStellioExplorations: StellioExploration[],
    userChallengeProgress: StellioChallengeProgress,
    allStellioEcogestures: StellioEcogesture[]
  ) {
    this._client = _client;
    this._userSub = userSub;
    this._userChallengeProgress = userChallengeProgress;
    this._allStellioEcogestures = allStellioEcogestures;
    this._allStellioExplorations = allStellioExplorations;
  }

  /**
   * Retrieve all exploration entities from db
   * @returns {ExplorationEntity[]}
   */
  public async getAllExplorationEntities(): Promise<ExplorationEntity[]> {
    // const query: QueryDefinition = Q(EXPLORATION_DOCTYPE);
    // const { data: explorations }: QueryResult<ExplorationEntity[]> =
    //   await this._client.query(query);
    // return explorations;
    return [];
  }

  /**
   * Retrieve exploration entities from db given the id
   *
   * @param {string} explorationId - ID of the searched exploration
   * @returns {ExplorationEntity}
   */
  public async getExplorationEntityById(
    explorationId: string
  ): Promise<ExplorationEntity> {
    // const query: QueryDefinition = Q(EXPLORATION_DOCTYPE)
    //   .where({ _id: explorationId })
    //   .limitBy(1);
    // const {
    //   data: [exploration],
    // }: QueryResult<ExplorationEntity[]> = await this._client.query(query);
    // return exploration;
    return {} as ExplorationEntity;
  }

  /**
   * Delete all exploration entities from the db
   * @returns {boolean} - true when deleted with success
   * @throws {Error}
   */
  public async deleteAllExplorationEntities(): Promise<boolean> {
    // const explorations = await this.getAllExplorationEntities();
    // if (explorations) {
    //   for (const exploration of explorations) {
    //     // await this._client.destroy(exploration);
    //   }
    // }
    return true;
  }

  /**
   * Return exploration created from exploration entity
   * @param {ExplorationEntity[]} explorationEntityList - userExploration to update
   * @param {string} searchId - userExploration to update
   * @returns {UserExploration}
   */
  public getUserExplorationfromExplorationEntities(
    explorationEntityList: ExplorationEntity[],
    searchId: string
  ): UserExploration {
    /* eslint-disable camelcase */
    let exploration: UserExploration = {
      id: '',
      state: UserExplorationState.UNLOCKED,
      description: '',
      complementary_description: '',
      target: 0,
      type: UserExplorationType.DECLARATIVE,
      date: null,
      ecogesture_id: '',
      fluid_condition: [],
      progress: 0,
      message_success: '',
    };
    /* eslint-enable camelcase */
    if (explorationEntityList.length > 0) {
      const explorationEntityIndex = explorationEntityList.findIndex(
        (entity) => entity.id === searchId
      );
      if (explorationEntityIndex >= 0) {
        const explorationEntity: ExplorationEntity =
          explorationEntityList[explorationEntityIndex];
        exploration =
          this.parseExplorationEntityToUserExploration(explorationEntity);
      }
    }
    return exploration;
  }

  /**
   * Return UserExploration created from ExplorationEntity
   * @param {ExplorationEntity}
   * @returns {UserExploration}
   */
  public parseExplorationEntityToUserExploration(
    exploration: ExplorationEntity
  ): UserExploration {
    const userExploration: UserExploration = {
      ...exploration,
      progress: 0,
      date: DateTime.local().setZone('utc', {
        keepLocalTime: true,
      }),
    };
    return userExploration;
  }

  /**
   * Return exploration with updated state to UserExplorationState.ONGOING
   * @param {UserExploration} userExploration - userExploration to update
   * @returns {UserExploration}
   */
  public async startUserExploration(
    userExploration: UserExploration
  ): Promise<UserExploration> {
    const updatedUserExploration: UserExploration = {
      ...userExploration,
      state: UserExplorationState.ONGOING,
    };
    return updatedUserExploration;
  }

  /**
   * Return exploration with updated state to UserExplorationState.NOTIFICATION
   * @param {UserExploration} userExploration - userExploration to update
   * @returns {UserExploration}
   */
  public async awaitNotificationUserExploration(
    userExploration: UserExploration
  ): Promise<UserExploration> {
    const updatedUserExploration: UserExploration = {
      ...userExploration,
      state: UserExplorationState.NOTIFICATION,
      progress: 5, // userExploration.type === UserExplorationType.ACTION ? 3 : 1,
    };
    return updatedUserExploration;
  }
  /**
   * Return exploration with updated state to UserExplorationState.DONE
   * @param {UserExploration} userExploration - userExploration to update
   * @returns {UserExploration}
   */
  public endUserExploration(userExploration: UserExploration): UserExploration {
    const updatedUserExploration: UserExploration = {
      ...userExploration,
      state: UserExplorationState.DONE,
      progress: 5, // userExploration.type === UserExplorationType.ACTION ? 3 : 1,
    };
    return updatedUserExploration;
  }

  /**
   * Return updated exploration
   * @param {UserExploration} userExploration - userExploration to update
   * @returns {UserExploration}
   * @returns {questionIndex}
   * @returns {questionResult}
   */
  public async updateUserExploration(
    userExploration: UserExploration
  ): Promise<UserExploration> {
    const updatedProgress = userExploration.progress + 1;

    const updatedUserExploration: UserExploration = {
      ...userExploration,
      progress: updatedProgress,
    };
    return updatedUserExploration;
  }

  /**
   * Return updated UserChallenge with updated UserExploration
   * @param {UserChallenge} currentChallenge - currentUserChallenge ongoing
   * @param {string} explorationID - Id of the exploration
   * @returns {UserChallenge}
   */
  public async checkExploration(
    currentChallenge: UserChallenge,
    explorationID: string
  ): Promise<UserChallenge> {
    const challengeService = new ChallengeService(
      this._client,
      this._userSub,
      this._userChallengeProgress,
      this._allStellioEcogestures,
      this._allStellioExplorations
    );
    switch (explorationID) {
      case UserExplorationID.EXPLORATION007:
        // ECOLYO CONNEXION EXPLORATION
        if (
          currentChallenge.exploration.progress + 1 <
          currentChallenge.exploration.target
        ) {
          return challengeService.updateUserChallenge(
            currentChallenge,
            UserChallengeUpdateFlag.EXPLORATION_UPDATE
          );
        } else {
          return challengeService.updateUserChallenge(
            currentChallenge,
            UserChallengeUpdateFlag.EXPLORATION_NOTIFICATION
          );
        }
      case UserExplorationID.EXPLORATION002:
      case UserExplorationID.EXPLORATION004_0:
      case UserExplorationID.EXPLORATION005:
      case UserExplorationID.EXPLORATION004:
      case UserExplorationID.EXPLORATION003:
      case UserExplorationID.EXPLORATION008:
      case UserExplorationID.EXPLORATION001:
        // ACTION EXPLORATIONS
        return challengeService.updateUserChallenge(
          currentChallenge,
          UserChallengeUpdateFlag.EXPLORATION_NOTIFICATION
        );
      default:
        return currentChallenge;
    }
  }
}
