import ecolyoIcon from 'assets/icons/ico/ecolyo.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import { useTranslation } from 'react-i18next';
import './challengeCardLast.scss';

const ChallengeCardLast = () => {
  const { t } = useTranslation();

  return (
    <div className="cardLast">
      <StyledIcon icon={ecolyoIcon} width={62} />
      <div className="content">
        <div className="text-22-bold title-last">
          {t('challenge.card_last.title')}
        </div>
        <div className="text-18-normal message">
          {t('challenge.card_last.message1')}
        </div>
      </div>
    </div>
  );
};

export default ChallengeCardLast;
