import { CustomPopup } from 'models';
import axios from 'axios';

export default class CustomPopupService {
  constructor() {}

  /**
   * Get information from backoffice about the status of custom popup
   */
  public async getCustomPopup(): Promise<CustomPopup | undefined> {
    const remoteUrl = `${process.env.REACT_APP_BACKOFFICE_BASE_URL}/api/common/customPopup`;

    try {
      const result = await axios.get<CustomPopup>(remoteUrl);

      if (!result.data.description || !result.data.title)
        throw new Error('title or description missing');

      return result.data;
    } catch (error) {
      const errorMessage = `getCustomPopup: Failed to get custom popup:${JSON.stringify(
        error
      )}`;
      console.error(errorMessage);
    }
  }
}
