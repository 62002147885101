import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ConstructionYear,
  Floor,
  FluidType,
  HotWaterEquipment,
  HousingType,
  IndividualInsulationWork,
  IndividualOrCollective,
  OutsideFacingWalls,
  ThreeChoicesAnswer,
} from 'enums';
import { DateTime } from 'luxon';
import { Profile } from 'models';
import { StellioEntityType, StellioUserProfile } from 'models/stellio.model';
import AppAxiosService from 'services/appAxios.service';
import { setProfileFromStellioUser } from 'store/profile/profile.slice';
import { setProfileEcogestureFromStellioUser } from 'store/profileEcogesture/profileEcogesture.slice';
import { setProfileTypeFromStellioUser } from 'store/profileType/profileType.slice';
import { AppDispatch, AppState } from 'store/store';

const initialState: StellioUserProfile = {
  id: '',
  type: StellioEntityType.USER_PROFILE,
  userInfos: {
    type: 'JsonProperty',
    json: {
      email: '',
      familyName: '',
      givenName: '',
    },
  },
  customPopupDate: {
    type: 'Property',
    value: '0000-01-01T00:00:00.000Z',
  },
  lastConnectionDate: {
    type: 'Property',
    value: '0000-01-01T00:00:00.000Z',
  },
  partnersIssueSeenDate: {
    type: 'JsonProperty',
    json: {
      enedis: '0000-01-01T00:00:00.000Z',
      grdf: '0000-01-01T00:00:00.000Z',
      suez: '0000-01-01T00:00:00.000Z',
    },
  },
  challengeProgress: {
    type: 'Relationship',
    object: '',
  },
  isFirstConnection: {
    type: 'Property',
    value: true,
  },
  accountInactivityFirstAlert: {
    type: 'Property',
    value: false,
  },
  accountInactivitySecondAlert: {
    type: 'Property',
    value: false,
  },
  haveSeenLastAnalysis: {
    type: 'Property',
    value: false,
  },
  sendAnalysisNotification: {
    type: 'Property',
    value: false,
  },
  sendConsumptionAlert: {
    type: 'Property',
    value: false,
  },
  waterDailyConsumptionLimit: {
    type: 'Property',
    value: 0,
  },
  isProfileTypeCompleted: {
    type: 'Property',
    value: false,
  },
  isProfileEcogestureCompleted: {
    type: 'Property',
    value: false,
  },
  haveSeenWelcomeModal: {
    type: 'Property',
    value: false,
  },
  haveSeenEcogestureModal: {
    type: 'Property',
    value: false,
  },
  housingType: {
    type: 'Property',
    value: HousingType.APARTMENT,
  },
  constructionYear: {
    type: 'Property',
    value: ConstructionYear.AFTER_1998,
  },
  area: {
    type: 'Property',
    value: '70',
  },
  occupantsNumber: {
    type: 'Property',
    value: 3,
  },
  outsideFacingWalls: {
    type: 'Property',
    value: OutsideFacingWalls.TWO,
  },
  floor: {
    type: 'Property',
    value: Floor.INTERMEDIATE_FLOOR,
  },
  heating: {
    type: 'Property',
    value: IndividualOrCollective.INDIVIDUAL,
  },
  coldWater: {
    type: 'Property',
    value: IndividualOrCollective.INDIVIDUAL,
  },
  hotWater: {
    type: 'Property',
    value: IndividualOrCollective.INDIVIDUAL,
  },
  individualInsulationWork: {
    type: 'Property',
    value: [IndividualInsulationWork.NONE],
  },
  hasInstalledVentilation: {
    type: 'Property',
    value: ThreeChoicesAnswer.UNKNOWN,
  },
  hasReplacedHeater: {
    type: 'Property',
    value: ThreeChoicesAnswer.UNKNOWN,
  },
  hotWaterEquipment: {
    type: 'Property',
    value: HotWaterEquipment.OTHER,
  },
  warmingFluid: {
    type: 'Property',
    value: null,
  },
  hotWaterFluid: {
    type: 'Property',
    value: null,
  },
  cookingFluid: {
    type: 'Property',
    value: FluidType.ELECTRICITY,
  },
  updateDate: {
    type: 'Property',
    value: DateTime.now().toISO() ?? '',
  },
  equipments: {
    type: 'Property',
    value: [],
  },
};

export const spreadStellioUserProfile = createAsyncThunk<
  Profile | void,
  Partial<StellioUserProfile>,
  { dispatch: AppDispatch; state: AppState }
>('profile/updateProfile', async (profileUpdates, { dispatch, getState }) => {
  /**
   * There are 4 states where to spread the profile updates:
   * - Stellio UserProfile entity state
   * - Ecolyo Profile state
   * - Ecolyo ProfileType state
   * - Ecolyo ProfileEcogesture state
   */
  dispatch(setProfileStellioUser(profileUpdates));
  dispatch(setProfileTypeFromStellioUser(profileUpdates));
  dispatch(setProfileFromStellioUser(profileUpdates));
  dispatch(setProfileEcogestureFromStellioUser(profileUpdates));
});

export const updateStellioUserProfile = createAsyncThunk<
  Profile | void,
  Partial<StellioUserProfile>,
  { dispatch: AppDispatch; state: AppState }
>('profile/updateProfile', async (profileUpdates, { dispatch, getState }) => {
  const axiosClient = new AppAxiosService();
  const response = await axiosClient.updateEntityById(
    getState().profileStellioUser.id,
    profileUpdates
  );

  if (response.status === 204) {
    dispatch(spreadStellioUserProfile(profileUpdates));
  }
  return;
});

export const profileStellioUserSlice = createSlice({
  name: 'profileStellioUser',
  initialState,
  reducers: {
    setProfileStellioUser: (
      state,
      action: PayloadAction<Partial<StellioUserProfile>>
    ) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setProfileStellioUser } = profileStellioUserSlice.actions;
