import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { Client } from 'cozy-client'
import { PROFILEECOGESTURE_DOCTYPE } from 'doctypes';
import { IndividualOrCollective, WarmingType } from 'enums';
import { ProfileEcogesture } from 'models';
import { StellioUserProfile } from 'models/stellio.model';
import { AppDispatch, AppState } from 'store/store';

const initialState: ProfileEcogesture = {
  heating: IndividualOrCollective.INDIVIDUAL,
  warmingFluid: WarmingType.ELECTRICITY,
  hotWater: IndividualOrCollective.INDIVIDUAL,
  equipments: [],
};

const profileEcogestureKeys = Object.keys(initialState);

export const profileEcogestureSlice = createSlice({
  name: 'profileEcogesture',
  initialState,
  reducers: {
    setProfileEcogesture: (state, action: PayloadAction<ProfileEcogesture>) => {
      Object.assign(state, action.payload);
    },
    setProfileEcogestureFromStellioUser: (
      state,
      action: PayloadAction<Partial<StellioUserProfile>>
    ) => {
      const profileEcogestureToUpdate = { ...state };

      Object.keys(action.payload).forEach((key) => {
        if (
          key !== 'id' &&
          key !== 'type' &&
          profileEcogestureKeys.includes(key)
        ) {
          profileEcogestureToUpdate[key] = action.payload[key].value;
        }
      });

      Object.assign(state, profileEcogestureToUpdate);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(newProfileEcogestureEntry.fulfilled, (state, action) => {
      Object.assign(state, action.payload);
    });
  },
});

export const { setProfileEcogesture, setProfileEcogestureFromStellioUser } =
  profileEcogestureSlice.actions;

/**
 * Thunk creating a new profile ecogesture in couch DB.
 * Then dispatch it to the app
 */
export const newProfileEcogestureEntry = createAsyncThunk<
  ProfileEcogesture | void,
  Partial<ProfileEcogesture>,
  { dispatch: AppDispatch; state: AppState; extra: { client: any } }
>('profileEcogesture/newProfileEcogesture', async (updates, thunkAPI) => {
  const client = thunkAPI.extra.client;
  const { data: newProfileEcogesture } = await client.create(
    PROFILEECOGESTURE_DOCTYPE,
    updates
  );
  if (newProfileEcogesture) {
    return newProfileEcogesture;
  }
});
