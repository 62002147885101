import { useTranslation } from 'react-i18next';
import { Duration } from 'luxon';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// utils
import { getFluidTypeWithFluidMonitoringId } from 'utils/utils';
import { getPicto } from 'utils/picto';
// models
import { StellioEvent } from 'models/stellio.model';
// components
import { useNotifDescription } from 'components/NotificationsView/hooks';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import {
  getDurationType,
  getDurationValue,
} from 'components/NotificationsView/utils';
import './notificationCard.scss';

interface Props {
  event: StellioEvent;
  isDeleting: boolean;
  onDelete: (eventId: string) => void;
  onEdit: (eventId: string) => void;
}

const NotificationCard = ({ event, isDeleting, onDelete, onEdit }: Props) => {
  const { t } = useTranslation();

  const handleOnDelete = () => {
    onDelete(event.id);
  };

  const handleOnEdit = () => {
    onEdit(event.id);
  };

  const fluidType = getFluidTypeWithFluidMonitoringId(event.hasSubject.object);
  const duration = Duration.fromISO(
    event.thresholdAggrPeriod.value as unknown as string
  );
  const durationType = getDurationType(duration);
  const durationValue = getDurationValue(duration, durationType);
  const threshold = event.thresholdValue.value;
  const { fluidSlug, descriptionRest } = useNotifDescription(
    fluidType,
    durationType,
    durationValue ?? 0,
    threshold
  );

  if (!durationValue) return <></>;

  const fluidIcon = getPicto(fluidType, true);

  return (
    <div className="notif-card-wrapper">
      <Card variant="elevation">
        <CardContent>
          <div className="card-title-wrapper">
            <Typography variant="h6" component="div">
              {t('notifications.notifDescription.myNotif')} {fluidSlug}
            </Typography>
            <StyledIcon className="fluid-icon" icon={fluidIcon} width={28} />
          </div>
          <Typography variant="body2">
            {t('notifications.notifDescription.baseDescription')}{' '}
            {descriptionRest}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={handleOnDelete} disabled={isDeleting}>
            {t('notifications.delete')}
          </Button>
          <Button size="small" onClick={handleOnEdit} disabled={isDeleting}>
            {t('notifications.update')}
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default NotificationCard;
