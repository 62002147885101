import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { Duration } from 'luxon';
import { HttpStatusCode, isAxiosError } from 'axios';

// components
import FluidSelector from 'components/NotificationsView/FluidSelector/FluidSelector';
import 'components/NotificationsView/NewNotificationTab/newNotificationTab.scss';
import {
  getDurationType,
  getDurationValue,
  getFluidMonitoringId,
  getStellioAlert,
  getStellioEvent,
  getSubscription,
} from 'components/NotificationsView/utils';
import {
  FluidNotification,
  NotificationsTab,
} from 'components/NotificationsView/NotificationsView';
import { useNotifDescription } from 'components/NotificationsView/hooks';
import ConsumptionMaxHelper from 'components/NotificationsView/ConsumptionAverageHelper/ConsumptionAverageHelper';
// enums
import { FluidType } from 'enums';
// store
import { useAppSelector } from 'store/hooks';
// services
import AppAxiosService from 'services/appAxios.service';
// models
import { StellioEntityType, StellioEvent } from 'models/stellio.model';

interface DurationValues {
  months?: number;
  weeks?: number;
  days?: number;
}
export type NotifDurationType = 'months' | 'weeks' | 'days';

interface Props {
  setTab: React.Dispatch<React.SetStateAction<NotificationsTab>>;
  onClearNotificationToEdit: () => void;
  notificationToEdit?: FluidNotification;
}

const NewNotificationTab = ({
  setTab,
  onClearNotificationToEdit,
  notificationToEdit,
}: Props) => {
  const { t } = useTranslation();
  const [isCreating, setIsCreating] = useState(false);
  const [durationType, setDurationType] = useState<NotifDurationType>('days');
  const [durationValue, setDurationValue] = useState<number>(1);
  const connectedFluids = useAppSelector((store) => store.global.fluidTypes);
  const [fluidType, setFluidType] = useState<FluidType>(connectedFluids[0]);
  const [threshold, setThreshold] = useState<number>(100);
  const [error, setError] = useState('');
  const { email, sub } = useAppSelector((store) => store.profileKeycloakUser);
  const userFluidMonitorings = useAppSelector(
    (store) => store.global.fluidMonitoring
  );
  const { descriptionRest, notifDescriptionFinalSentence, unit } =
    useNotifDescription(fluidType, durationType, durationValue, threshold);
  const stellioAxios = useMemo(() => new AppAxiosService(), []);

  const updateNotificationEvent = async (
    fluidMonitoringId: string,
    duration: Duration
  ) => {
    if (!notificationToEdit) return;

    const eventToUpdate: Partial<StellioEvent> = {
      hasSubject: {
        type: 'Relationship',
        watchedProperties: {
          type: 'Property',
          value: 'consumption',
        },
        object: fluidMonitoringId,
      },
      thresholdValue: {
        type: 'Property',
        value: threshold,
      },
      thresholdAggrPeriod: {
        type: 'Property',
        value: duration,
      },
    };

    try {
      const response = await stellioAxios.updateEntityById(
        notificationToEdit.eventId,
        eventToUpdate
      );

      if (response.status === 204) {
        setTab(0);
        onClearNotificationToEdit();
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const handleOnSubmit = async () => {
    if (!email || !sub) return;

    const duration: Duration = Duration.fromObject({
      [durationType]: durationValue,
    });

    const alertId = `urn:ngsi-ld:${StellioEntityType.ALERT}:${fluidType}:${sub}`;
    const eventId = `urn:ngsi-ld:${StellioEntityType.EVENT}:${fluidType}:${sub}`;
    const subscriptionId = `urn:ngsi-ld:${StellioEntityType.SUBSCRIPTION}:${fluidType}:${sub}`;
    const fluidMonitoringId = getFluidMonitoringId(
      userFluidMonitorings,
      fluidType
    );
    if (!fluidMonitoringId) return;

    // Just update event params
    if (notificationToEdit) {
      updateNotificationEvent(fluidMonitoringId, duration);
      return;
    }

    // Or create everything
    const subscription = getSubscription({ eventId, subscriptionId });
    const alert = getStellioAlert({
      alertId,
      email,
      eventId,
      subscriptionId,
      descriptionRest,
    });
    const event = getStellioEvent({
      eventId,
      fluidMonitoringId,
      alertId,
      duration,
      threshold,
    });

    setIsCreating(true);

    const createAlertPromise = stellioAxios.createEntity(alert);
    const createEventPromise = stellioAxios.createEntity(event);
    const createSubscriptionPromise =
      stellioAxios.createSubscription(subscription);

    try {
      const [createAlert, createEvent, createSubscription] = await Promise.all([
        createAlertPromise,
        createEventPromise,
        createSubscriptionPromise,
      ]);
      const isAlertCreated = createAlert.status === HttpStatusCode.Created;
      const isEventCreated = createEvent.status === HttpStatusCode.Created;
      const isSubscriptionCreated =
        createSubscription.status === HttpStatusCode.Created;

      if (isAlertCreated && isEventCreated && isSubscriptionCreated) {
        // Go back to first tab
        setTab(0);
      }
    } catch (error) {
      if (isAxiosError(error)) {
        switch (error.response?.status) {
          case HttpStatusCode.Conflict:
            setError(t('notifications.onlyOnePerFluid'));
            break;

          default:
            setError(t('notifications.createError'));
            break;
        }
      } else {
        setError(t('notifications.createError'));
      }
    }

    setIsCreating(false);
  };

  useEffect(() => {
    if (notificationToEdit) {
      const { fluidType, thresholdAggrPeriod, thresholdValue } =
        notificationToEdit;
      const duration = Duration.fromISO(
        thresholdAggrPeriod as unknown as string
      );
      const durationType = getDurationType(duration);
      const durationValue = getDurationValue(duration, durationType);
      if (!durationValue) return;

      setDurationType(durationType);
      setDurationValue(durationValue);
      setFluidType(fluidType);
      setThreshold(thresholdValue);
    }
  }, [notificationToEdit]);

  return (
    <div id="new-notif-container">
      {connectedFluids.length === 0 ? (
        <p id="please-connect-message">{t('notifications.pleaseConnect')}</p>
      ) : (
        <form
          id="new-notif-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleOnSubmit();
          }}
        >
          <div className="step">
            <label>{t('notifications.newNotifLabel.whichFluid')}</label>
            <FluidSelector
              onSelect={setFluidType}
              defaultValue={connectedFluids[0]}
              disabledFluids={[
                FluidType.ELECTRICITY,
                FluidType.GAS,
                FluidType.WATER,
              ].filter((fluid) => !connectedFluids.includes(fluid))}
            />
          </div>
          <div className="step">
            <label htmlFor="threshold">
              {t('notifications.newNotifLabel.whichThreshold')}
            </label>
            <TextField
              id="threshold"
              name="threshold"
              type="number"
              required
              fullWidth
              error={false}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{unit}</InputAdornment>
                ),
              }}
              onChange={(e) => {
                const maxChar = 6;
                if (e.target.value.toString().length <= maxChar) {
                  return setThreshold(parseInt(e.target.value));
                }
              }}
              value={threshold}
            />
          </div>
          <div className="conso-helper">
            <ConsumptionMaxHelper fluidType={fluidType} />
          </div>
          <div className="step duration">
            <label htmlFor="threshold">
              {t('notifications.newNotifLabel.whichDuration')}
            </label>
            <TextField
              required
              fullWidth
              select
              error={false}
              value={durationValue.toString()}
              onChange={(event) =>
                setDurationValue(parseInt(event.target.value))
              }
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
            </TextField>
            <TextField
              required
              fullWidth
              select
              error={false}
              value={durationType}
              onChange={(event) =>
                setDurationType(event.target.value as keyof DurationValues)
              }
            >
              <MenuItem value="days">
                {t('notifications.notifDescription.days')}
              </MenuItem>
              <MenuItem value="weeks">
                {t('notifications.notifDescription.weeks')}
              </MenuItem>
              <MenuItem value="months">
                {t('notifications.notifDescription.months')}
              </MenuItem>
            </TextField>
          </div>
          <div className="step button">
            <label>{notifDescriptionFinalSentence}</label>
            <Button
              variant="contained"
              type="submit"
              disabled={isCreating || !threshold}
            >
              {isCreating ? (
                <CircularProgress size={30} />
              ) : (
                t(`notifications.${notificationToEdit ? 'update' : 'create'}`)
              )}
            </Button>
          </div>
          <div className="error">{error}</div>
        </form>
      )}
    </div>
  );
};

export default NewNotificationTab;
