import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

// enums
import { FluidType, TimeStep } from 'enums';
// services
import ConsumptionService from 'services/consumption.service';
import AppAxiosService from 'services/appAxios.service';
// components
import { NotifDurationType } from 'components/NotificationsView/NewNotificationTab/NewNotificationTab';
import { getFluidUnit } from 'components/NotificationsView/utils';
// models
import { TimePeriod } from 'models';
// store
import { useAppSelector } from 'store/hooks';

const useNotifDescription = (
  fluidType: FluidType,
  durationType: NotifDurationType,
  durationValue: number,
  threshold: number
) => {
  const { t } = useTranslation();
  const unit = getFluidUnit(fluidType);
  const fluidSlug = t(`notifications.notifDescription.${fluidType}`);
  const durationTypeSlug = t(`notifications.notifDescription.${durationType}`);
  const descriptionRest = t(
    `notifications.notifDescription.finalSentenceRest`,
    {
      durationType: durationTypeSlug,
      unit,
      threshold,
      count: durationValue,
      context: durationType === 'weeks' ? 'female' : 'male',
    }
  );
  const notifDescriptionFinalSentence = t(
    'notifications.notifDescription.finalSentence',
    {
      fluidType: fluidSlug,
      rest: descriptionRest,
    }
  );

  return { notifDescriptionFinalSentence, descriptionRest, fluidSlug, unit };
};

const useLastConsumption = (fluidType: FluidType) => {
  const stellioAxios = useMemo(() => new AppAxiosService(), []);
  const userSub = useAppSelector((store) => store.profileKeycloakUser.sub);
  const [lastMonth, setLastMonth] = useState<number>();
  const [lastWeek, setLastWeek] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);

  const initConsumption = async () => {
    if (!userSub) return;
    setIsLoading(true);

    const consumptionService = new ConsumptionService(stellioAxios, userSub);

    const lastMonthTimePeriod: TimePeriod = {
      startDate: DateTime.now().minus({ month: 1 }).startOf('month'),
      endDate: DateTime.now().minus({ month: 1 }).endOf('month'),
    };

    const lastMonthPromise = consumptionService.getStellioTemporalData(
      lastMonthTimePeriod,
      TimeStep.MONTH,
      fluidType
    );

    const lastWeekTimePeriod: TimePeriod = {
      startDate: DateTime.now().minus({ week: 1 }).startOf('week'),
      endDate: DateTime.now().minus({ week: 1 }).endOf('week'),
    };

    const lastWeekPromise = consumptionService.getStellioTemporalData(
      lastWeekTimePeriod,
      'P1W',
      fluidType
    );

    try {
      const [lastMonthResponse, lastWeekResponse] = await Promise.all([
        lastMonthPromise,
        lastWeekPromise,
      ]);

      const lastMonthResult =
        lastMonthResponse && lastMonthResponse?.[0]?.[0]
          ? lastMonthResponse?.[0]?.[0]
          : undefined;

      const lastWeekResult =
        lastWeekResponse && lastWeekResponse?.[0]?.[0]
          ? lastWeekResponse?.[0]?.[0]
          : undefined;

      setLastMonth(lastMonthResult);
      setLastWeek(lastWeekResult);
    } catch (error) {
      console.warn(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    initConsumption();
  }, [fluidType]);

  const unit = getFluidUnit(fluidType);

  return { lastMonth, lastWeek, unit, isLoading };
};

export { useNotifDescription, useLastConsumption };
