import { SgeStore } from 'models';
import React from 'react';
import { SGEKeysForm } from './SgeConnectView';
import { useTranslation } from 'react-i18next';

interface StepAddressProps {
  sgeState: SgeStore;
  onChange: (key: SGEKeysForm, value: string, maxLength?: number) => void;
}

const StepAddress = ({ sgeState, onChange }: StepAddressProps) => {
  const { t } = useTranslation();
  return (
    <div className="sge-step-container stepAddress">
      <div className="title text-22-bold">
        {t('auth.enedissgegrandlyon.addressTitle')}
      </div>
      <label htmlFor="address" className="text-16-normal">
        {t('auth.enedissgegrandlyon.address')}
      </label>
      <input
        type="text"
        id="address"
        name="address"
        value={sgeState.address}
        onChange={(e) => onChange('address', e.target.value)}
      />
      <label htmlFor="zipCode" className="text-16-normal">
        {t('auth.enedissgegrandlyon.zipCode')}
      </label>
      <input
        type="number"
        min={0}
        id="zipCode"
        name="zipCode"
        value={sgeState.zipCode !== null ? sgeState.zipCode : undefined}
        onChange={(e) => onChange('zipCode', e.target.value, 5)}
      />

      <label htmlFor="city" className="text-16-normal">
        {t('auth.enedissgegrandlyon.city')}
      </label>
      <input
        type="text"
        id="city"
        name="city"
        value={sgeState.city}
        onChange={(e) => onChange('city', e.target.value)}
      />
    </div>
  );
};

export default StepAddress;
