import { GeometryObject, GeometryCollection, Point } from 'geojson';
import {
  CustomQuestionType,
  EquipmentType,
  FluidRetrievalStatus,
  FluidType,
  Room,
  Season,
  TimeStep,
  Usage,
  UserExplorationType,
} from 'enums';
import {
  ConstructionYear,
  Floor,
  HotWaterEquipment,
  HotWaterFluid,
  HousingType,
  IndividualInsulationWork,
  IndividualOrCollective,
  OutsideFacingWalls,
  ThreeChoicesAnswer,
  WarmingType,
} from 'enums/profileType.enum';
import { CustomPeriod, UserChallengeEntity, UserEcogesture } from 'models';
import { Duration } from 'luxon';

/////////////////////////////////////////////////////////////////////////////////
//
// Stellio entities types
//
/////////////////////////////////////////////////////////////////////////////////
export enum StellioEntityType {
  USER_PROFILE = 'UserProfile',
  FLUID_MONITORING = 'FluidMonitoring',
  CHALLENGE = 'Challenge',
  CHALLENGE_PROGRESS = 'ChallengeProgress',
  CUSTOM_QUESTION = 'CustomQuestion',
  DUEL = 'Duel',
  ECOGESTURE = 'Ecogesture',
  EXPLORATION = 'Exploration',
  QUIZ = 'Quiz',
  QUESTION = 'Question',
  ALERT = 'Alert',
  EVENT = 'Event',
  SUBSCRIPTION = 'Subscription',
}

export type FirstSetupEntities =
  | StellioChallengeProgress
  | StellioFluidMonitoring;

export type InitChallengeEntitiesList =
  | StellioChallenge
  | StellioDuel
  | StellioExploration
  | StellioQuiz
  | StellioQuestion
  | StellioCustomQuestion
  | StellioEcogesture;

export interface InitChallengeConfig {
  entityType: StellioEntityType;
  setDataAction: Function;
  data: InitChallengeEntitiesList[];
}



export interface StellioUserProfile extends EntityBaseProps {
  // Interface
  userInfos: StellioJsonProp<{
    givenName: string;
    familyName: string;
    email: string;
  }>;
  challengeProgress: StellioRelationship;
  isFirstConnection: StellioProp<boolean>;
  lastConnectionDate: StellioProp<string>;
  customPopupDate: StellioProp<string>;
  partnersIssueSeenDate: StellioJsonProp<{
    enedis: string;
    suez: string;
    grdf: string;
  }>;
  accountInactivityFirstAlert: StellioProp<boolean>;
  accountInactivitySecondAlert: StellioProp<boolean>;
  haveSeenLastAnalysis: StellioProp<boolean>;
  sendAnalysisNotification: StellioProp<boolean>;
  sendConsumptionAlert: StellioProp<boolean>;
  isProfileTypeCompleted: StellioProp<boolean>;
  isProfileEcogestureCompleted: StellioProp<boolean>;
  haveSeenWelcomeModal: StellioProp<boolean>;
  haveSeenEcogestureModal: StellioProp<boolean>;
  waterDailyConsumptionLimit: StellioProp<number>;
  // Habitat type
  housingType: StellioProp<HousingType>;
  constructionYear: StellioProp<ConstructionYear>;
  area: StellioProp<string>;
  occupantsNumber: StellioProp<number>;
  outsideFacingWalls: StellioProp<OutsideFacingWalls>;
  floor: StellioProp<Floor>;
  heating: StellioProp<IndividualOrCollective>;
  coldWater: StellioProp<IndividualOrCollective>;
  hotWater: StellioProp<IndividualOrCollective>;
  individualInsulationWork: StellioProp<IndividualInsulationWork[]>;
  hasInstalledVentilation: StellioProp<ThreeChoicesAnswer>;
  hasReplacedHeater: StellioProp<ThreeChoicesAnswer>;
  hotWaterEquipment: StellioProp<HotWaterEquipment>;
  warmingFluid: StellioProp<WarmingType | null>;
  hotWaterFluid: StellioProp<HotWaterFluid | null>;
  cookingFluid: StellioProp<FluidType>;
  updateDate: StellioProp<string>;
  equipments: StellioProp<EquipmentType[]>;
  location?: StellioGeoProp<Point>;
  hasConsent?: StellioRelationship | StellioRelationship[];
  hasFluidMonitoring?: StellioRelationship | StellioRelationship[];
}

export type StellioFluidTypeSlug = 'Electricity' | 'Water' | 'Gas';
export interface StellioFluidMonitoring extends EntityBaseProps {
  consumption: StellioMultiAttribute<number>;
  isConnected: StellioProp<boolean>;
  retrievalStatus: StellioProp<FluidRetrievalStatus>;
  fluidType: StellioProp<StellioFluidTypeSlug>;
  meterReference: StellioProp<string>;
  refDevice?: StellioRelationship;
}

type SumItem = [number, string, string][]; // value, from, to

export type Consumption = {
  sum: SumItem;
} & StellioMultiAttribute;

export type EstimatedPrice = {
  sum: SumItem;
} & StellioMultiAttribute;
export interface StellioTemporalFluidMonitoring extends EntityBaseProps {
  consumption: Consumption[];
  estimatedPrice: EstimatedPrice[];
}

export type StellioGraphAggregated = [number, string, string, number] // consumption, from, to, estimatedPrice

interface RefChallengeRelationship extends StellioRelationship {
  userProgress: StellioJsonProp<UserChallengeEntity>;
  challengeTitle: StellioProp<string>;
}

interface RefEcogestureRelationship extends StellioRelationship {
  userProgress: StellioJsonProp<UserEcogesture>;
  ecogestureName: StellioProp<string>;
}

export interface StellioChallengeProgress extends EntityBaseProps {
  globalState: StellioProp<string>; // challenge id of current challenge
  refChallenge: RefChallengeRelationship[];
  refEcogesture: RefEcogestureRelationship[];
}

export interface StellioChallenge extends EntityBaseProps {
  title: StellioProp<string>;
  description: StellioProp<string>;
  target: StellioProp<number>;
  hasQuiz: StellioRelationship;
  hasDuel: StellioRelationship;
  hasExploration: StellioRelationship;
}

export interface StellioDuel extends EntityBaseProps {
  title: StellioProp<string>;
  description: StellioProp<string>;
  duration: StellioProp<string>;
  threshold: StellioProp<number>;
}

export interface StellioExploration extends EntityBaseProps {
  description: StellioProp<string>;
  complementaryDescription?: StellioProp<string>;
  explorationType: StellioProp<UserExplorationType>;
  target: StellioProp<number>;
  refEcogesture: StellioRelationship;
  successMessage: StellioProp<string>;
  fluidCondition: StellioProp<FluidType[]>;
}

export interface StellioQuiz extends EntityBaseProps {
  hasQuestions: StellioRelationship[];
  hasCustomQuestion: StellioRelationship;
}

interface Answer extends StellioProp<string> {
  datasetId: string;
  isCorrect: StellioProp<boolean>;
}

export interface StellioQuestion extends EntityBaseProps {
  title: StellioProp<string>;
  answerExplanation: StellioProp<string>;
  answers: Answer[];
}

export interface StellioCustomQuestion extends EntityBaseProps {
  title: StellioProp<string>;
  timeStep: StellioProp<TimeStep>;
  interval: StellioProp<TimeStep>;
  period: StellioProp<CustomPeriod>;
  singleFluid: StellioProp<boolean>;
  customQuestionType: StellioProp<CustomQuestionType>;
}

export interface StellioEcogesture extends EntityBaseProps {
  title: StellioProp<string>;
  name: StellioProp<string>;
  description: StellioProp<string>;
  usage: StellioProp<Usage>;
  fluidTypes: StellioProp<FluidType[]>;
  difficulty: StellioProp<number>;
  impactLevel: StellioProp<number>;
  efficiency: StellioProp<number>;
  rooms: StellioProp<Room[]>;
  season: StellioProp<Season>;
  isEquipment: StellioProp<boolean>;
  equipmentTypes: StellioProp<EquipmentType[]>;
  investment?: StellioProp<string | null>;
  isAction: StellioProp<boolean>;
  actionName?: StellioProp<string | null>;
  timeRequired: StellioProp<number>; // actionDuration
}

export interface StellioEvent {
  id: string;
  type: ['Event', 'AggregatedThresholdBreach'];
  hasSubject: StellioRelationship & {
    watchedProperties: {
      type: 'Property';
      value: 'consumption';
    };
  };
  occurrence: StellioProp<number>;
  status: StellioProp<string>;
  thresholdType: StellioProp<'Upper'>;
  thresholdValue: StellioProp<number>;
  thresholdAggrPeriod: StellioProp<Duration>;
  hasAlert: StellioRelationship;
}

export interface StellioAlert {
  id: string;
  type: 'Alert';
  hasSubject: StellioRelationship;
  mailingList: StellioProp<{ email: string[] }>;
  message: StellioJsonProp<{
    body: string;
    subject: string;
    limitDescription?: string;
  }>;
  occurrence: StellioProp<boolean>;
  triggeredBy: StellioProp<string>;
}

export interface StellioSubscription {
  id: string;
  type: 'Subscription';
  entities: { id: string; type: 'AggregatedThresholdBreach' }[];
  watchedAttributes: ['occurrence'];
  notification: {
    format: 'keyValues';
    endpoint: {
      uri: string;
      accept: 'application/json';
    };
  };
}

const narrowType = (_data: unknown): boolean =>
  typeof _data === 'object' &&
  _data !== null &&
  'id' in _data &&
  typeof _data.id === 'string';

export const isDefinedSource = <T>(data: unknown): data is T => {
  if (Array.isArray(data)) {
    if (data.length === 0) return true;
    return narrowType(data[0]);
  } else {
    return narrowType(data);
  }
};

/////////////////////////////////////////////////////////////////////////////////
//
// NGSI-LD helper types
//
/////////////////////////////////////////////////////////////////////////////////
export interface StellioUpdateMultiStatusResponse {
  notUpdated: { attributeName: string; reason: string }[];
  updated: string[];
}

export interface StellioCreateMultiStatusResponse {
  errors: { entityId: string; error: string[] }[];
  success: string[]; // array of entity id
}

export interface EntityBaseProps {
  id: string;
  type: string;
  '@context'?: string[];
  createdAt?: string;
  modifiedAt?: string;
}

export type StellioProp<T = any> = {
  [key: string]: StellioProp | string | T | undefined | StellioRelationship;
  type: 'Property';
  value: T;
  unitCode?: string;
  observedAt?: string;
  modifiedAt?: string;
  createdAt?: string;
  observedBy?: StellioRelationship;
};

export type StellioJsonProp<T = any> = {
  [key: string]: StellioProp | string | T | undefined | StellioRelationship;
  type: 'JsonProperty';
  json: T;
  unitCode?: string;
  observedAt?: string;
  modifiedAt?: string;
  createdAt?: string;
  observedBy?: StellioRelationship;
  value?: 'ERROR';
};

export interface StellioRelationship {
  type: 'Relationship';
  object: string;
  datasetId?: string;
  [key: string]: StellioProp | StellioJsonProp | string | undefined;
}

export type SupportedGeometry = Exclude<GeometryObject, GeometryCollection>;

export type StellioGeoProp<SupportedGeometry> = {
  [key: string]:
    | StellioProp
    | string
    | undefined
    | SupportedGeometry
    | StellioRelationship;
  type: 'GeoProperty';
  value: SupportedGeometry;
  unitCode?: string;
  observedAt?: string;
  modifiedAt?: string;
  createdAt?: string;
  observedBy?: StellioRelationship;
};

export type StellioMultiAttribute<T = any> = {
  [key: string]: StellioProp | string | T | undefined | StellioRelationship;
  type: 'Property';
  value: T;
  datasetId: string;
};

export type NgsildPossibleValue =
  | StellioProp<any>
  | StellioJsonProp<any>
  | StellioGeoProp<SupportedGeometry>
  | StellioRelationship
  | StellioRelationship[]
  | StellioMultiAttribute<any>[]
  | string[]
  | string
  | number
  | undefined;

export interface PatternNgsildFull {
  [key: string]: NgsildPossibleValue;
}

export type AnyEntity = EntityBaseProps & PatternNgsildFull;

type TemporalValue = [number, string];

type TemporalProp = {
  type: string;
  values: TemporalValue[];
  datasetId?: string;
};

export type AnyTemporalValuesEntity = EntityBaseProps & {
  [key: string]: TemporalProp;
};
