export enum Usage {
  ALL = 0,
  HEATING = 1,
  AIR_CONDITIONING = 2,
  ECS = 3,
  COLD_WATER = 4,
  ELECTRICITY_SPECIFIC = 5,
  COOKING = 6,
}

export enum Room {
  ALL = 0,
  BATHROOM = 1,
  KITCHEN = 2,
  LAUNDRY = 3,
  TOILET = 4,
}

export enum Season {
  NONE = 'Sans saison',
  WINTER = 'Hiver',
  SUMMER = 'Eté',
}

export enum EquipmentType {
  AIR_CONDITIONING = 'AIR_CONDITIONING',
  COMPUTER = 'COMPUTER',
  MICROWAVE = 'MICROWAVE',
  WASHING_MACHINE = 'WASHING_MACHINE',
  DISHWASHER = 'DISHWASHER',
  COOKING_PLATES = 'COOKING_PLATES',
  DRYER = 'DRYER',
  REFREGIRATOR = 'REFREGIRATOR',
  FAN = 'FAN',
  CURTAIN = 'CURTAIN',
  INTERNET_BOX = 'INTERNET_BOX',
  VENTILATION = 'VENTILATION',
  FREEZER = 'FREEZER',
  BOILER = 'BOILER',
  HYDRAULIC_HEATING = 'HYDRAULIC_HEATING',
}

export enum EcogestureTab {
  OBJECTIVE = 0,
  DOING = 1,
  ALL = 2,
}
