import { useEffect, useRef } from 'react';
import { DateTime } from 'luxon';

// services
import CustomPopupService from 'services/customPopup.service';
import PartnersInfoService from 'services/partnersInfoService';
import FluidService from 'services/fluid.service';
// store
import { setFluidStatus } from 'store/global/global.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setCustomPopup, openPartnersModal } from 'store/modal/modal.slice';
// utils
import { getTodayDate } from 'utils/utils';
// models
import { PartnersInfo, CustomPopup, ModalState } from 'models';

const useBackendPopups = () => {
  const today = getTodayDate().toISO();
  const dispatch = useAppDispatch();
  const profileStellioUser = useAppSelector(
    (store) => store.profileStellioUser
  );
  const fluidStatus = useAppSelector((store) => store.global.fluidStatus);
  const isPartnersStatusSet = useRef(false);

  /** Process customPopup and enable it if activated */
  const processCustomPopup = (customPopup: CustomPopup) => {
    try {
      if (
        today !== profileStellioUser.customPopupDate.value &&
        !profileStellioUser.isFirstConnection.value
      ) {
        dispatch(setCustomPopup(customPopup));
      }
    } catch (error) {
      console.error('Error while checking customPopup informations');
    }
  };

  /** Return current status of partner if modal has not been seen today */
  const getPartnerStatus = (currentStatus: boolean, lastSeenDate: DateTime) => {
    if (today !== lastSeenDate.toISO()) {
      return currentStatus;
    }
    return false;
  };

  /** For each fluid, if notification is activated, set FluidStatus.maintenance to true */
  const processFluidsStatus = (partnersInfo: PartnersInfo) => {
    if (
      partnersInfo.notification_activated &&
      !profileStellioUser?.isFirstConnection.value
    ) {
      const _updatedFluidStatus = new FluidService().setFluidStatusMaintenance(
        partnersInfo,
        fluidStatus
      );
      dispatch(setFluidStatus(_updatedFluidStatus));
    }
  };

  /**
   * For each fluid, set partnersIssue to true if notification is activated and seenDate < today
   */
  const processPartnersStatus = (partnersInfo: PartnersInfo) => {
    try {
      if (
        partnersInfo.notification_activated &&
        !profileStellioUser?.isFirstConnection.value
      ) {
        const partnersIssue: ModalState['partnersIssueModal'] = {
          enedis: getPartnerStatus(
            partnersInfo.enedis_failure,
            DateTime.fromISO(
              profileStellioUser.partnersIssueSeenDate.json.enedis
            ).setZone('utc')
          ),
          suez: getPartnerStatus(
            partnersInfo.egl_failure,
            DateTime.fromISO(
              profileStellioUser.partnersIssueSeenDate.json.suez
            ).setZone('utc')
          ),
          grdf: getPartnerStatus(
            partnersInfo.grdf_failure,
            DateTime.fromISO(
              profileStellioUser.partnersIssueSeenDate.json.grdf
            ).setZone('utc')
          ),
        };

        if (Object.values(partnersIssue).some((issue) => issue)) {
          dispatch(openPartnersModal(partnersIssue));
        }
      }
    } catch (error) {
      console.error('Error while fetching partners informations');
    }
  };

  const handleCustomPopup = async () => {
    const customPopupService = new CustomPopupService();
    const customPopup = await customPopupService.getCustomPopup();
    if (customPopup) {
      processCustomPopup(customPopup);
    }
  };

  const handlePartnersInfo = async () => {
    const partnersInfoService = new PartnersInfoService();
    const partnersInfo = await partnersInfoService.getPartnersInfo();
    if (partnersInfo) {
      processFluidsStatus(partnersInfo);
      processPartnersStatus(partnersInfo);
    }
  };

  useEffect(() => {
    // Wait for profile to be filled
    if (!profileStellioUser.id) return;

    /**
     * Load custom popup and partners info synchronously so these treatments don't block the loading
     */
    handleCustomPopup();

    if (!isPartnersStatusSet.current) {
      handlePartnersInfo();
      isPartnersStatusSet.current = true;
    }
  }, [fluidStatus, profileStellioUser]);
};

export default useBackendPopups;
