export enum DaccEvent {
  CONNECTION_COUNT_DAILY = 'connection-count-daily',
  KONNECTOR_EVENT_DAILY = 'konnector-event-daily',
  KONNECTOR_CONNECTED_PER_DAY = 'konnector-connected-daily',
  NAVIGATION_COUNT_DAILY = 'navigation-count-daily',
  CHALLENGE_LAUNCH_EVENT_DAILY = 'challenge-launch-daily',
  CONSUMPTION_VARIATION_MONTHLY = 'consumption-variation-monthly',
  SESSION_DURATION = 'session-duration',
  NAVIGATION_ACTION_DAILY = 'navigation-action-daily',
  EVENT_DURATION = 'event-duration',
  QUIZ_STARS = 'quiz-stars',
  SUMMARY_SUBSCRIPTION_MONTHLY = 'summary-subscription-monthly',
  FLUID_DATA_GRANULARITY = 'fluid-data-granularity-monthly',
  PARTNER_SUCCESS_MONTHLY = 'konnector-attempts-before-success',
  UNINITIALIZED_KONNECTOR_ATTEMPTS_MONTHLY = 'uninitialized-konnector-attempts-monthly',
  CONNECTION_COUNT_MONTHLY = 'connection-count-monthly',
  PROFILE_COUNT_MONTHLY = 'profile-count',
}
