import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { User } from 'oidc-client-ts';

// models
import {
  AnyTemporalValuesEntity,
  isDefinedSource,
  PatternNgsildFull,
  StellioAlert,
  StellioChallengeProgress,
  StellioEntityType,
  StellioEvent,
  StellioSubscription,
  StellioUserProfile,
} from 'models/stellio.model';
// utils
import { appLogger } from 'utils/appLogger';

const stellioEndpoints = {
  entities: 'ngsi-ld/v1/entities',
  subscriptions: 'ngsi-ld/v1/subscriptions',
  temporalEntities: 'ngsi-ld/v1/temporal/entities',
} as const;

type CreateEntity =
  | StellioUserProfile
  | StellioChallengeProgress
  | StellioAlert
  | StellioEvent;

interface GetTemporalEntityParams {
  entityId?: string;
  entityType?: string;
  timerel: string;
  timeAt?: string;
  endTimeAt?: string;
  appendParams?: string;
  attrs?: string;
}

export default class AppAxiosService {
  private readonly headers: Record<string, string>;
  public stellioClient: AxiosInstance;

  constructor() {
    this.headers = {
      Link: `<${process.env.REACT_APP_CONTEXT_LINK}>; rel="http://www.w3.org/ns/json-ld#context"; type="application/ld+json"`,
      'NGSILD-Tenant': `urn:ngsi-ld:tenant:default`,
      'Content-Type': 'application/json',
    };

    this.stellioClient = axios.create({
      baseURL: process.env.REACT_APP_BROKER_API_BASE_URL,
      headers: this.headers,
    });

    appLogger('new axios instance');

    // Request interceptor
    this.stellioClient.interceptors.request.use(
      (config) => {
        // Do something before request is sent
        config.headers['Authorization'] = `Bearer ${this.getToken()}`;

        return config;
      },
      (error) => {
        // Do something with request error
        appLogger('in intercept request error');

        return Promise.reject(error);
      }
    );

    // Response interceptor
    this.stellioClient.interceptors.response.use(
      (response) => {
        // Do something before response is sent
        appLogger(response);
        return response;
      },
      (error) => {
        // Do something with response error
        // Info: get original request in error.config: const originalRequest = error.config
        appLogger('in intercept response error', error);

        if (error.response.status === 401) {
          const loginPageUrl = `${process.env.REACT_APP_BASE_URL}/login`;
          window.location.href = loginPageUrl;
        }

        return Promise.reject(error);
      }
    );
  }

  private getUser(): User | null {
    const oidcStorage = sessionStorage.getItem(
      `oidc.user:${process.env.REACT_APP_KEYCLOAK_BASE_URL}:${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`
    );
    if (!oidcStorage) {
      return null;
    }

    return User.fromStorageString(oidcStorage);
  }

  private getToken(): string {
    const user = this.getUser();
    const token = user?.access_token;
    if (!token) console.warn('Could not find user token');
    return token ?? 'undefined';
  }

  public async getEntityById<T>(id: string): Promise<AxiosResponse<T>> {
    appLogger(`${new Date().toISOString()} - getEntityById: ${id}`);

    const endpoint = `${stellioEndpoints.entities}/${id}`;
    const response = await this.stellioClient.get(endpoint);

    return response;
  }

  public async getEntityByType<T>(
    type: StellioEntityType
  ): Promise<AxiosResponse<T[]>> {
    appLogger(`${new Date().toISOString()} - getEntityByType: ${type}`);

    const endpoint = `${stellioEndpoints.entities}?type=${type}&limit=100`;
    const response = await this.stellioClient.get(endpoint);

    return response;
  }

  private getNextOffsetFromLinkHeader(link: string): string | undefined {
    if (!link) return;
    if (!link.includes('rel="next"')) return;
    const nextOffset = link.match(/offset=(\d+)>;rel="next";/)?.[1];
    return nextOffset;
  }

  public async getEntitiesByTypes<T>(types: StellioEntityType[]): Promise<T[]> {
    const joinedTypes = types.join(',');

    appLogger(
      `${new Date().toISOString()} - getEntitiesByTypes: ${joinedTypes}`
    );

    let retrievedEntities: T[] = [];

    const fetch = async (offset?: string) => {
      let endpoint = `${process.env.REACT_APP_BROKER_API_BASE_URL}/${stellioEndpoints.entities}?type=${joinedTypes}&limit=100`;
      if (offset) {
        endpoint += `&offset=${offset}`;
      }

      const response = await this.stellioClient.get<T[]>(endpoint);
      const source = response.data;

      if (isDefinedSource<T[]>(source)) {
        retrievedEntities = [...retrievedEntities, ...source];

        const nextOffset = this.getNextOffsetFromLinkHeader(
          response.headers.link
        );

        if (nextOffset) {
          console.log('next offset: ', nextOffset);
          await fetch(nextOffset);
        }
      }
    };

    await fetch();

    return retrievedEntities;
  }

  public async createEntity(entity: CreateEntity): Promise<AxiosResponse> {
    appLogger(`${new Date().toISOString()} - createEntity`, entity);

    const response = await this.stellioClient.post(
      stellioEndpoints.entities,
      entity
    );
    return response;
  }

  public async createSubscription(
    subscription: StellioSubscription
  ): Promise<AxiosResponse> {
    appLogger(`${new Date().toISOString()} - createSubscription`, subscription);

    const response = await this.stellioClient.post(
      stellioEndpoints.subscriptions,
      subscription
    );

    return response;
  }

  public async updateEntityById(
    id: string,
    attrsToUdpate: PatternNgsildFull
  ): Promise<AxiosResponse> {
    appLogger(
      `${new Date().toISOString()} - updateEntityById: ${id}`,
      attrsToUdpate
    );

    const endpoint = `${stellioEndpoints.entities}/${id}`;
    const response = await this.stellioClient.patch(endpoint, attrsToUdpate);

    return response;
  }

  public async deleteEntityById(id: string): Promise<AxiosResponse> {
    appLogger(`${new Date().toISOString()} - deleteEntityById: ${id}`);

    const endpoint = `${stellioEndpoints.entities}/${id}`;
    const response = await this.stellioClient.delete(endpoint);

    return response;
  }

  public async deleteSubscriptionById(id: string): Promise<AxiosResponse> {
    appLogger(`${new Date().toISOString()} - deleteSubscriptionById: ${id}`);

    const endpoint = `${stellioEndpoints.subscriptions}/${id}`;
    const response = await this.stellioClient.delete(endpoint);

    return response;
  }

  public async getTemporalEntity<T = AnyTemporalValuesEntity>({
    entityId,
    entityType,
    timeAt,
    timerel,
    appendParams,
    attrs,
    endTimeAt,
  }: GetTemporalEntityParams): Promise<AxiosResponse<T>> {
    appLogger(
      `${new Date().toISOString()} - getTemporalEntity: ${entityType} ${entityId}`
    );

    const endpoint = `${stellioEndpoints.temporalEntities}?type=${entityType}&timeAt=${timeAt}&timerel=${timerel}&endTimeAt=${endTimeAt}&attrs=${attrs}${appendParams}`;
    const response = await this.stellioClient.get(endpoint);

    return response;
  }
}
