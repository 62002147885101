import thunderEmpty from 'assets/icons/visu/thunderEmpty.svg'
import thunderFilled from 'assets/icons/visu/thunderFilled.svg'
import StyledIcon from 'components/CommonKit/Icon/StyledIcon'
import React from 'react'

const EfficiencyRating = ({ result }: { result: number }) => {
  const maxStars = [1, 2, 3, 4, 5]

  return (
    <div className="thunder">
      {maxStars.map(star => (
        <StyledIcon
          key={star}
          className="star"
          icon={result >= star ? thunderFilled : thunderEmpty}
          size={15}
        />
      ))}
    </div>
  )
}

export default EfficiencyRating
