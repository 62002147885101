import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import chronoMini from 'assets/icons/visu/action/chrono-mini.svg';
import { UserChallengeUpdateFlag } from 'enums';
import { Ecogesture, UserChallenge } from 'models';
import React, { useCallback, useMemo } from 'react';
import { updateUserChallengeList } from 'store/challenge/challenge.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import './actionModal.scss';
import { useTranslation } from 'react-i18next';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import ChallengeService from 'services/challenge.service';
import AppAxiosService from 'services/appAxios.service';

interface ActionModalProps {
  open: boolean;
  action: Ecogesture;
  handleCloseClick: () => void;
  userChallenge: UserChallenge;
}

const ActionModal = ({
  open,
  action,
  handleCloseClick,
  userChallenge,
}: ActionModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userChallengeProgress, ecogesturesList, explorationsList } =
    useAppSelector((state) => state.challenge);
  const userSub = useAppSelector((store) => store.profileKeycloakUser.sub);
  const client = useMemo(() => new AppAxiosService(), []);

  const launchAction = useCallback(async () => {
    if (!userChallengeProgress) return;
    if (!userSub) return;
    const challengeService = new ChallengeService(
      client,
      userSub,
      userChallengeProgress,
      ecogesturesList,
      explorationsList
    );
    const updatedChallenge: UserChallenge =
      await challengeService.updateUserChallenge(
        userChallenge,
        UserChallengeUpdateFlag.ACTION_START,
        undefined,
        undefined,
        action
      );
    dispatch(updateUserChallengeList(updatedChallenge));
  }, [
    action,
    client,
    dispatch,
    ecogesturesList,
    explorationsList,
    userChallenge,
    userChallengeProgress,
    userSub,
  ]);

  return (
    <Dialog
      open={open}
      onClose={handleCloseClick}
      aria-labelledby="accessibility-title"
      classes={{
        root: 'modal-root',
        paper: 'modal-paper',
      }}
    >
      <div id="accessibility-title">
        {t('action_modal.accessibility.window_title')}
      </div>
      <div className="action-modal">
        <StyledIcon icon={chronoMini} width={75} />
        <div className="action-title text-16-normal">
          {t('action.duration', {
            smartCount: action.actionDuration,
          })}
        </div>
        <div className="action-text text-16-normal">{action.longName}</div>
        <div className="buttons">
          <Button
            aria-label={t('action_modal.accessibility.button_accept')}
            className="btn-secondary-negative text-16-normal"
            onClick={launchAction}
          >
            <span>{t('action_modal.accept')}</span>
          </Button>
          <Button
            aria-label={t('action_modal.accessibility.button_refuse')}
            className="btn-secondary-negative text-16-normal"
            onClick={handleCloseClick}
          >
            <span>{t('action_modal.refuse')}</span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ActionModal;
