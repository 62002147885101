import BackArrowIcon from 'assets/icons/ico/back-arrow.svg';
import FeedbackIcon from 'assets/icons/ico/feedback.svg';
import StyledIconButton from 'components/CommonKit/IconButton/StyledIconButton';

import { ScreenType } from 'enums';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openFeedbackModal } from 'store/modal/modal.slice';
// import cozyBar from 'utils/cozyBar'
import { useTranslation } from 'react-i18next';
interface CozyBarProps {
  titleKey?: string;
  displayBackArrow?: boolean;
  backFunction?: () => void;
}

/** Header with CozyBar for mobile view */
const CozyBar = ({
  titleKey = 'common.title_app',
  displayBackArrow = false,
  backFunction,
}: CozyBarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const { BarLeft, BarCenter, BarRight } = cozyBar
  const dispatch = useAppDispatch();
  const { screenType } = useAppSelector((state) => state.global);

  const handleClickBack = useCallback(() => {
    if (backFunction) {
      backFunction();
    } else {
      navigate(-1);
    }
  }, [backFunction, navigate]);

  const handleClickFeedbacks = () => {
    dispatch(openFeedbackModal(true));
  };

  if (screenType === ScreenType.MOBILE) {
    return (
      <>
        {/* {displayBackArrow && (
          <BarLeft>
            <StyledIconButton
              aria-label={t('header.accessibility.button_back')}
              className="cv-button"
              icon={BackArrowIcon}
              onClick={handleClickBack}
            />
          </BarLeft>
        )}
        <BarCenter>
          <div className="cozy-bar">
            <span className="app-title">{t(titleKey)}</span>
          </div>
        </BarCenter>
        <BarRight>
          <StyledIconButton
            aria-label={t('header.accessibility.button_open_feedbacks')}
            className="cv-button"
            icon={FeedbackIcon}
            sized={22}
            onClick={handleClickFeedbacks}
          />
        </BarRight> */}
      </>
    );
  } else {
    return null;
  }
};

export default CozyBar;
