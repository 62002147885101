import Config from 'constants/config.json'
import { FluidConfig } from 'models'

export default class ConfigService {
  public getFluidConfig(): FluidConfig[] {
    return Config.fluidConfig as FluidConfig[]
  }

  /**
   *
   * @param min Minimum hour for cron
   * @param max Maximum hour for cron
   */
  public getCronArgs(min = 8, max = 9): string {
    const randomHour = Math.floor(Math.random() * (max - min + 1) + min) // NOSONAR
    const randomMinutes = Math.floor(Math.random() * 59) // NOSONAR
    return `0 ${randomMinutes} ${randomHour} * * *`
  }
}
