import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User, UserProfile } from 'oidc-client-ts';
import { jwtDecode } from 'jwt-decode';

export type ProfileKeycloakFullUser = Partial<User>;
export type ProfileKeycloakUser = Partial<UserProfile & { roles: string[] }>;

const initialState: ProfileKeycloakUser = {};

export const profileKeycloakUserSlice = createSlice({
  name: 'profileKeycloak',
  initialState,
  reducers: {
    setProfileKeycloak: (
      state,
      action: PayloadAction<ProfileKeycloakFullUser>
    ) => {
      const kcFullUser = { ...action.payload };
      const decodedAccessToken = jwtDecode<{
        realm_access: { roles: string[] };
      }>(kcFullUser.access_token ?? '');

      const enhancedUserProfile: ProfileKeycloakUser = {
        ...kcFullUser.profile,
        roles: decodedAccessToken.realm_access.roles,
      };

      Object.assign(state, enhancedUserProfile);
    },
  },
});

export const { setProfileKeycloak } = profileKeycloakUserSlice.actions;
