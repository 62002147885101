import starIcon from 'assets/icons/visu/challenge/star.svg'
import starFilled from 'assets/icons/visu/challenge/starFilled.svg'
import StyledIcon from 'components/CommonKit/Icon/StyledIcon'
import React from 'react'

interface StarsContainerProps {
  result: number
  isQuizBegin?: boolean
}

const StarsContainer = ({ result, isQuizBegin }: StarsContainerProps) => {
  const maxStars = [1, 2, 3, 4, 5]

  return (
    <div className="stars">
      {maxStars.map(star => {
        return (
          <StyledIcon
            key={star}
            className="star"
            icon={result >= star ? starFilled : starIcon}
            size={isQuizBegin ? 25 : 15}
          />
        )
      })}
    </div>
  )
}

export default StarsContainer
