import Button from '@mui/material/Button';
import defaultIcon from 'assets/icons/visu/ecogesture/default.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import EcogestureModal from 'components/Ecogesture/EcogestureModal/EcogestureModal';
import { UsageEventType } from 'enums';
import { Ecogesture } from 'models';
import React, { useCallback, useEffect, useState } from 'react';

import { useAppSelector } from 'store/hooks';
import { importIconById } from 'utils/utils';
import './actionCard.scss';
import { getEcogestureIconId } from 'utils/ecoGagnant';

interface ActionCardProps {
  action: Ecogesture;
  setSelectedAction: React.Dispatch<React.SetStateAction<Ecogesture | null>>;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
}

const ActionCard = ({
  action,
  setSelectedAction,
  setShowList,
}: ActionCardProps) => {
  const [actionIcon, setActionIcon] = useState<string>('');
  const [openEcogestureModal, setOpenEcogestureModal] =
    useState<boolean>(false);
  const { currentChallenge } = useAppSelector((state) => state.challenge);
  const toggleModal = useCallback(() => {
    setOpenEcogestureModal((prev) => !prev);
  }, []);

  const selectEcogesture = useCallback(() => {
    setSelectedAction(action);
    setShowList(false);
    // UsageEventService.addEvent(client, {
    //   type: UsageEventType.ACTION_CHANGE_EVENT,
    //   target: action.id,
    //   context: currentChallenge ? currentChallenge.id : '',
    // })
    toggleModal();
  }, [setSelectedAction, setShowList, action, toggleModal]);

  useEffect(() => {
    async function handleEcogestureIcon() {
      const icon = await importIconById(
        getEcogestureIconId(action),
        'ecogesture'
      );
      setActionIcon(icon || defaultIcon);
    }
    handleEcogestureIcon();
  }, [action]);

  return (
    <>
      {action && (
        <Button key={action.id} className="action-card" onClick={toggleModal}>
          <StyledIcon className="action-icon" icon={actionIcon} width={80} />
          <div className="action-title text-18-bold">{action.shortName}</div>
        </Button>
      )}
      {action && (
        <EcogestureModal
          open={openEcogestureModal}
          ecogesture={action}
          isAction={true}
          handleCloseClick={toggleModal}
          selectEcogesture={selectEcogesture}
        />
      )}
    </>
  );
};

export default ActionCard;
