import { QUIZ_DOCTYPE } from 'doctypes';
import {
  CustomQuestionType,
  FluidType,
  TimeStep,
  UserQuestionState,
  UserQuizState,
} from 'enums';
import { shuffle } from 'lodash';
import { DateTime } from 'luxon';
import {
  Answer,
  CustomPeriod,
  CustomQuestionEntity,
  Datachart,
  IntervalAnswer,
  QuestionEntity,
  QuizEntity,
  TimePeriod,
  UserCustomQuestion,
  UserQuestion,
  UserQuiz,
} from 'models';
import { formatNumberValues } from 'utils/utils';
import ConsumptionDataManager from './consumption.service';
import StellioAxiosClient from './appAxios.service';

export default class QuizService {
  private readonly _client: StellioAxiosClient;
  private readonly _userSub: string;

  constructor(_client: StellioAxiosClient, userSub: string) {
    this._client = _client;
    this._userSub = userSub;
  }

  /**
   * Retrieve all quiz entities from db
   * @returns {QuizEntity[]}
   */
  public async getAllQuizEntities(): Promise<QuizEntity[]> {
    // const query: QueryDefinition = Q(QUIZ_DOCTYPE)
    // const { data: quizs }: QueryResult<QuizEntity[]> = await this._client.query(
    //   query
    // )
    // return quizs
    return [];
  }

  /**
   * Retrieve quiz entities from db given the id
   *
   * @param {string} quizId - ID of the searched quiz
   * @returns {QuizEntity}
   */
  public async getQuizEntityById(quizId: string): Promise<QuizEntity> {
    // const query: QueryDefinition = Q(QUIZ_DOCTYPE)
    //   .where({ _id: quizId })
    //   .limitBy(1)
    // const { data }: QueryResult<QuizEntity[]> = await this._client.query(query)
    // return data?.[0]
    return {} as QuizEntity;
  }

  /**
   * Delete all quiz entities from the db
   * @returns {boolean} - true when deleted with success
   * @throws {Error}
   */
  public async deleteAllQuizEntities(): Promise<boolean> {
    const quizzes = await this.getAllQuizEntities();
    if (quizzes) {
      // for (let index = 0; index < quizzes.length; index++) {
      //   await this._client.destroy(quizzes[index])
      // }
    }
    return true;
  }

  /**
   * Return quiz created from quiz entity
   * @param {QuizEntity[]} quizEntityList - userQuiz to update
   * @param {string} searchId - userQuiz to update
   * @returns {UserQuiz}
   */
  public getUserQuizfromQuizEntities(
    quizEntityList: QuizEntity[],
    searchId: string
  ): UserQuiz {
    let quiz: UserQuiz = {
      id: '',
      questions: [],
      customQuestion: {
        questionLabel: '',
        type: CustomQuestionType.DATE,
        timeStep: TimeStep.DAY,
        interval: TimeStep.DAY,
        period: {},
        result: UserQuestionState.UNLOCKED,
        singleFluid: false,
      },
      state: UserQuizState.UNLOCKED,
      startDate: null,
      result: 0,
    };
    if (quizEntityList.length > 0) {
      const quizEntityIndex = quizEntityList.findIndex(
        (entity) => entity.id === searchId
      );
      if (quizEntityIndex >= 0) {
        const quizEntity: QuizEntity = quizEntityList[quizEntityIndex];
        quiz = this.parseQuizEntityToUserQuiz(quizEntity);
      }
    }
    return quiz;
  }

  /**
   * Return UserQuestion created from QuestionEntity
   * @param {QuestionEntity}
   * @returns {UserQuestion}
   */
  public parseQuestionEntityToQuestion(question: QuestionEntity): UserQuestion {
    const userQuestion: UserQuestion = {
      ...question,
      result: UserQuestionState.UNLOCKED,
    };
    return userQuestion;
  }

  /**
   * Return UserCustomQuestion created from CustomQuestionEntity
   * @param {CustomQuestionEntity}
   * @returns {UserCustomQuestion}
   */
  public parseCustomQuestionEntityToCustomQuestion(
    customQuestion: CustomQuestionEntity
  ): UserCustomQuestion {
    const userCustomQuestion: UserCustomQuestion = {
      ...customQuestion,
      result: UserQuestionState.UNLOCKED,
    };
    return userCustomQuestion;
  }

  /**
   * Return UserQuiz created from QuizEntity
   * @param {QuizEntity}
   * @returns {UserQuiz}
   */
  public parseQuizEntityToUserQuiz(quiz: QuizEntity): UserQuiz {
    const userQuestions: UserQuestion[] = [];

    quiz.questions.forEach((question) => {
      const userQuestion: UserQuestion =
        this.parseQuestionEntityToQuestion(question);
      userQuestions.push(userQuestion);
    });
    const userCustomQuestion: UserCustomQuestion =
      this.parseCustomQuestionEntityToCustomQuestion(quiz.customQuestion);

    const userQuiz: UserQuiz = {
      id: quiz.id,
      customQuestion: userCustomQuestion,
      questions: userQuestions,
      state: UserQuizState.UNLOCKED,
      startDate: quiz.startDate
        ? DateTime.fromISO(quiz.startDate, {
            zone: 'utc',
          })
        : null,
      result: 0,
    };
    return userQuiz;
  }

  /**
   * Return quiz with updated state to UserQuizState.ONGOING and randomize question and answers
   * @param {UserQuiz} userQuiz - userQuiz to update
   * @returns {UserQuiz}
   */
  public async startUserQuiz(userQuiz: UserQuiz): Promise<UserQuiz> {
    const questions = userQuiz.questions.map((question) => ({
      ...question,
      answers: shuffle(question.answers),
    }));

    return {
      ...userQuiz,
      questions: shuffle(questions),
      state: UserQuizState.ONGOING,
      startDate: DateTime.local().setZone('utc', {
        keepLocalTime: true,
      }),
    };
  }
  /**
   * Return quiz with updated state to UserQuizState.UNLOCKED and updated questions with false result
   * @param {UserQuiz} userQuiz - userQuiz to update
   * @returns {UserQuiz}
   */
  public async resetUserQuiz(userQuiz: UserQuiz): Promise<UserQuiz> {
    const updatedQuestions: UserQuestion[] = userQuiz.questions.map(
      (question) => ({
        ...question,
        result: UserQuestionState.UNLOCKED,
      })
    );
    const updatedCustomQuestion = {
      ...userQuiz.customQuestion,
      result: UserQuestionState.UNLOCKED,
    };
    return {
      ...userQuiz,
      customQuestion: updatedCustomQuestion,
      questions: updatedQuestions,
      result: 0,
      state: UserQuizState.UNLOCKED,
    };
  }

  /**
   * Return quiz with updated state to UserQuizState.DONE
   * @param {UserQuiz} userQuiz - userQuiz to update
   * @returns {UserQuiz}
   */
  public async endUserQuiz(userQuiz: UserQuiz): Promise<UserQuiz> {
    const updatedUserQuiz: UserQuiz = {
      ...userQuiz,
      state: UserQuizState.DONE,
    };
    return updatedUserQuiz;
  }

  /**
   * Return quiz with result and updated question or customQuestion if no index is passed
   * @param {UserQuiz} userQuiz - userQuiz to update
   * @returns {UserQuiz}
   * @returns {questionIndex}
   * @returns {questionResult}
   */
  public async updateUserQuiz(
    userQuiz: UserQuiz,
    questionResult: boolean,
    questionIndex?: number
  ): Promise<UserQuiz> {
    const result = questionResult
      ? UserQuestionState.CORRECT
      : UserQuestionState.UNCORRECT;

    const updatedQuestions = userQuiz.questions.map((question, index) => {
      if (index === questionIndex) {
        return {
          ...question,
          result: result,
        };
      }
      return question;
    });

    const updatedCustomQuestion = {
      ...userQuiz.customQuestion,
      result: result,
    };

    return {
      ...userQuiz,
      questions: updatedQuestions,
      customQuestion:
        questionIndex === undefined
          ? updatedCustomQuestion
          : userQuiz.customQuestion,
      result: questionResult
        ? Math.min(userQuiz.result + 1, 5)
        : userQuiz.result,
    };
  }

  /**
   * Build a custom question with the customQuestionEntity
   * @param customQuestionEntity
   * @param fluidType
   * @returns {QuestionEntity}
   */
  public async getCustomQuestion(
    customQuestionEntity: CustomQuestionEntity,
    fluidType: FluidType[]
  ): Promise<QuestionEntity> {
    let answers: Answer[];
    const explanation =
      'Vous pouvez vérifier cette information sur l’écran Conso.';
    const finalInterval: TimePeriod = this.getTimePeriodFromInterval(
      customQuestionEntity.interval,
      customQuestionEntity.period.weekday ? {} : customQuestionEntity.period
    );

    let useFluidType: FluidType[] = fluidType;
    let questionLabel: string = customQuestionEntity.questionLabel;
    let unit = '€';

    if (customQuestionEntity.singleFluid === true) {
      let unitLabel = 'kWh';
      let fluidLabel = "d'électricité";
      // Define the right fluidType
      if (fluidType.includes(FluidType.ELECTRICITY)) {
        useFluidType = [FluidType.ELECTRICITY];
        unit = 'kWh';
      } else if (fluidType.includes(FluidType.GAS)) {
        useFluidType = [FluidType.GAS];
        unit = 'kWh';
        fluidLabel = 'de gaz';
      } else {
        useFluidType = [FluidType.WATER];
        unit = 'L';
        unitLabel = 'litre';
        fluidLabel = "d'eau";
      }
      // Adapt the question
      questionLabel = questionLabel.replace('#unit', unitLabel);
      questionLabel = questionLabel.replace('#fluid', fluidLabel);
    }

    if (customQuestionEntity.type === CustomQuestionType.DATE) {
      // Interval
      const intervalAsnwer: IntervalAnswer =
        await this.getMaxLoadOnLastInterval(
          customQuestionEntity.timeStep,
          finalInterval,
          useFluidType
        );

      answers = this.getAnswersForInterval(
        intervalAsnwer.date,
        customQuestionEntity.timeStep,
        finalInterval
      );
    } else if (customQuestionEntity.type === CustomQuestionType.MAXDATA) {
      // Max data
      const consumptionService = new ConsumptionDataManager(
        this._client,
        this._userSub
      );
      let maxLoad = await consumptionService.getMaxLoad(
        finalInterval,
        customQuestionEntity.timeStep,
        useFluidType,
        undefined,
        !customQuestionEntity.singleFluid
      );
      maxLoad = maxLoad === null ? 0 : maxLoad;
      answers = this.getAnswersForNumberValue(maxLoad as number, unit);
    } else {
      // average
      const averageLoad: number = await this.getAverageOnGivenPeriod(
        customQuestionEntity.timeStep,
        finalInterval,
        useFluidType,
        customQuestionEntity.period.weekday
          ? customQuestionEntity.period.weekday
          : undefined,
        customQuestionEntity.singleFluid
      );
      answers = this.getAnswersForNumberValue(averageLoad, unit);
    }
    const finalQuestion = {
      questionLabel: questionLabel,
      answers: answers,
      explanation: explanation,
      source: '',
    };
    return finalQuestion;
  }

  /**
   * Get the interval needed for the further calculs
   * @param interval
   * @param period
   * @returns {TimePeriod}
   */
  private getTimePeriodFromInterval(
    interval: TimeStep,
    period: CustomPeriod
  ): TimePeriod {
    const today = DateTime.local().setZone('utc', {
      keepLocalTime: true,
    });
    let startTime = today;
    let endTime = today;
    const isPeriod = Object.keys(period).length !== 0;
    switch (interval) {
      case TimeStep.WEEK:
        startTime = isPeriod
          ? DateTime.fromObject(period).startOf('week')
          : today.startOf('week').minus({ week: 1 });
        endTime = isPeriod
          ? DateTime.fromObject(period).endOf('week')
          : today.endOf('week').minus({ week: 1 });
        break;
      case TimeStep.MONTH:
        startTime = isPeriod
          ? DateTime.fromObject(period).startOf('month')
          : today.startOf('month').minus({ month: 1 });
        endTime = isPeriod
          ? DateTime.fromObject(period).endOf('month')
          : today.endOf('month').minus({ month: 1 });
        break;
      case TimeStep.YEAR:
        startTime = isPeriod
          ? DateTime.fromObject(period).startOf('year')
          : today.startOf('year').minus({ year: 1 });
        endTime = isPeriod
          ? DateTime.fromObject(period).endOf('year')
          : today.endOf('year').minus({ year: 1 });
        break;
    }
    const timePeriod: TimePeriod = {
      startDate: startTime,
      endDate: endTime,
    };
    return timePeriod;
  }

  /**
   * Finds max load on given past time period
   * when no max load is found looks x month/year back for a max value
   * if nothing is found fall back on most recent data
   * @param timeStep
   * @param interval
   * @param fluidType
   * @returns {Promise<IntervalAnswer>}
   */
  private async getMaxLoadOnLastInterval(
    timeStep: TimeStep,
    interval: TimePeriod,
    fluidType: FluidType[]
  ): Promise<IntervalAnswer> {
    let dateMax: DateTime = DateTime.local().setZone('utc', {
      keepLocalTime: true,
    });
    let max = 0;
    const consumptionService = new ConsumptionDataManager(
      this._client,
      this._userSub
    );
    const limit = { date: interval.startDate, reached: false };
    let graphData = await consumptionService.getGraphData(
      interval,
      timeStep,
      fluidType
    );
    if (graphData?.actualData) {
      max = Math.max(...graphData.actualData.map((d) => d.value));

      if (max == -1) {
        const newInterval: TimePeriod = { ...interval };
        let objectTimeStep: object;
        switch (timeStep) {
          case TimeStep.MONTH:
            objectTimeStep = { month: 1 };
            break;
          case TimeStep.YEAR:
            objectTimeStep = { year: 1 };
            break;
          default:
            objectTimeStep = { week: 1 };
        }
        do {
          newInterval.startDate = newInterval.startDate.minus(objectTimeStep);
          newInterval.endDate = newInterval.endDate.minus(objectTimeStep);
          limit.date = newInterval.startDate;
          graphData = await consumptionService.getGraphData(
            newInterval,
            timeStep,
            fluidType
          );

          if (limit.date < DateTime.now().minus({ year: 5 })) {
            limit.reached = true;
          }

          if (graphData?.actualData) {
            max = Math.max(...graphData.actualData.map((d) => d.value));
          }
        } while (max == -1 && graphData?.actualData && !limit.reached);
      }
      if (limit.reached) {
        const newInterval: TimePeriod = {
          startDate: DateTime.local()
            .setZone('utc', {
              keepLocalTime: true,
            })
            .minus({ year: 1 }),
          endDate: DateTime.local()
            .setZone('utc', {
              keepLocalTime: true,
            })
            .startOf('month'),
        };
        graphData = await consumptionService.getGraphData(
          newInterval,
          timeStep,
          fluidType
        );
      }
      if (graphData?.actualData) {
        max = Math.max(...graphData.actualData.map((d) => d.value));
        graphData.actualData.forEach((d) => {
          if (d.value === max) dateMax = d.date;
        });
      }
    }

    return { date: dateMax, value: max };
  }

  /**
   * Calcul the average value on a given period
   * @param timeStep
   * @param interval
   * @param fluidType
   * @param weekday
   * @returns {Promise<number>}
   */
  private async getAverageOnGivenPeriod(
    timeStep: TimeStep,
    interval: TimePeriod,
    fluidType: FluidType[],
    weekday: number | undefined,
    singleFluid: boolean
  ): Promise<number> {
    const consumptionService = new ConsumptionDataManager(
      this._client,
      this._userSub
    );
    const graphData = await consumptionService.getGraphData(
      interval,
      timeStep,
      fluidType,
      undefined,
      undefined,
      !singleFluid
    );
    let average = 0;
    if (graphData?.actualData) {
      let total = 0;
      let length = 0;
      graphData.actualData.forEach((d) => {
        if (weekday) {
          if (d.date.weekday === weekday) {
            if (d.value >= 0) {
              total += d.value;
              length += 1;
            }
          }
        } else {
          if (d.value >= 0) {
            total += d.value;
          }
          length += 1;
        }
      });
      average = total / length;
    }
    return average;
  }

  /**
   * Generate wrong date answer following the right one
   * @param rightDate
   * @param interval
   * @returns {Answer[]}
   */
  private getAnswersForInterval(
    rightDate: DateTime,
    timeStep: TimeStep,
    interval: TimePeriod
  ): Answer[] {
    let wrongAnswer = [1, -1];
    switch (timeStep) {
      case TimeStep.MONTH:
        if (rightDate.month === interval.startDate.month) {
          wrongAnswer = [1, 2];
        } else if (rightDate.month === interval.endDate.month) {
          wrongAnswer = [-1, -2];
        }
        return [
          {
            answerLabel: rightDate.setLocale('fr-FR').toLocaleString({
              month: 'long',
              year: 'numeric',
            }),
            isTrue: true,
          },
          {
            answerLabel: rightDate
              .plus({ month: wrongAnswer[0] })
              .setLocale('fr-FR')
              .toLocaleString({
                month: 'long',
                year: 'numeric',
              }),
            isTrue: false,
          },
          {
            answerLabel: rightDate
              .plus({ month: wrongAnswer[1] })
              .setLocale('fr-FR')
              .toLocaleString({
                month: 'long',
                year: 'numeric',
              }),
            isTrue: false,
          },
        ];
      case TimeStep.DAY:
      case TimeStep.WEEK:
      default:
        if (rightDate.day === interval.startDate.day) {
          wrongAnswer = [1, 2];
        } else if (rightDate.day === interval.endDate.day) {
          wrongAnswer = [-1, -2];
        }
        return [
          {
            answerLabel: rightDate.setLocale('fr-FR').toLocaleString({
              month: 'long',
              day: 'numeric',
              weekday: 'long',
            }),
            isTrue: true,
          },
          {
            answerLabel: rightDate
              .plus({ day: wrongAnswer[0] })
              .setLocale('fr-FR')
              .toLocaleString({
                month: 'long',
                day: 'numeric',
                weekday: 'long',
              }),
            isTrue: false,
          },
          {
            answerLabel: rightDate
              .plus({ day: wrongAnswer[1] })
              .setLocale('fr-FR')
              .toLocaleString({
                month: 'long',
                day: 'numeric',
                weekday: 'long',
              }),
            isTrue: false,
          },
        ];
    }
  }

  /**
   * Generate wrong value answer following the right one
   * @param maxLoad
   * @param unit
   * @returns {Answer[]}
   */
  private getAnswersForNumberValue(maxLoad: number, unit: string): Answer[] {
    const coefList: number[] = [0.6, 0.7, 0.8, 0.12, 0.13];
    // Pick a random number in the coefList and remove it from array
    const index1: number = Math.floor(Math.random() * coefList.length); // NOSONAR
    const roll: number[] = coefList.splice(index1, 1);
    const coef1: number = roll[0];
    // Pick a second number in the coefList
    const index2: number = Math.floor(Math.random() * coefList.length); // NOSONAR
    const roll2: number[] = coefList.splice(index2, 1);
    const coef2: number = roll2[0];
    // Format answers
    const load = Math.round(maxLoad * 100) / 100;
    const wrongAnswer1 = Math.round(load * coef1 * 100) / 100;
    const wrongAnswer2 = Math.round(load * coef2 * 100) / 100;
    return [
      {
        answerLabel: `${formatNumberValues(load)} ${unit}`,
        isTrue: true,
      },
      {
        answerLabel: `${formatNumberValues(wrongAnswer1)} ${unit}`,
        isTrue: false,
      },
      {
        answerLabel: `${formatNumberValues(wrongAnswer2)} ${unit}`,
        isTrue: false,
      },
    ];
  }
}
