import Button from '@mui/material/Button';
import CloseIcon from 'assets/icons/ico/close.svg';
import StyledIconButton from 'components/CommonKit/IconButton/StyledIconButton';
import Loader from 'components/Loader/Loader';
import QuizExplanationModal from 'components/Quiz/QuizExplanationModal/QuizExplanationModal';
import { useTranslation } from 'react-i18next';
import { UserChallengeUpdateFlag } from 'enums';
import { Answer, QuestionEntity, UserChallenge, UserQuiz } from 'models';
import React, { useMemo, useState } from 'react';
import ChallengeService from 'services/challenge.service';
import QuizService from 'services/quiz.service';
import {
  setCurrentUserChallenge,
  updateUserChallengeList,
} from 'store/challenge/challenge.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import './quizQuestion.scss';
import AppAxiosService from 'services/appAxios.service';

interface QuizQuestionContentCustomProps {
  userChallenge: UserChallenge;
  goBack: () => void;
  question: QuestionEntity | undefined;
  isLoading: boolean;
}

const QuizQuestionContentCustom = ({
  userChallenge,
  goBack,
  question,
  isLoading,
}: QuizQuestionContentCustomProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const clientAxios = new AppAxiosService();
  const { userChallengeProgress, ecogesturesList, explorationsList } =
    useAppSelector((store) => store.challenge);
  const userSub = useAppSelector((state) => state.profileKeycloakUser.sub);
  const [userChoice, setUserChoice] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [answerIndex, setAnswerIndex] = useState<number>(0);

  const challengeService: ChallengeService | undefined = useMemo(() => {
    if (!userChallengeProgress) return;
    if (!userSub) return;
    return new ChallengeService(
      clientAxios,
      userSub,
      userChallengeProgress,
      ecogesturesList,
      explorationsList
    );
  }, [userChallengeProgress, userSub]);

  const validateQuestion = async () => {
    if (!challengeService) return;
    if (!userSub) return;

    if (question) {
      const resultIndex: number = question.answers.findIndex(
        (answer) => answer.answerLabel === userChoice
      );
      setAnswerIndex(resultIndex);
      setOpenModal(true);

      const result: Answer[] = question.answers.filter(
        (answer) => answer.answerLabel === userChoice
      );
      const quizService: QuizService = new QuizService(clientAxios, userSub);
      const quizUpdated: UserQuiz = await quizService.updateUserQuiz(
        userChallenge.quiz,
        result[0].isTrue
      );
      const userChallengeUpdated: UserChallenge =
        await challengeService.updateUserChallenge(
          userChallenge,
          UserChallengeUpdateFlag.QUIZ_UPDATE,
          quizUpdated
        );
      dispatch(updateUserChallengeList(userChallengeUpdated));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserChoice(e.target.value);
  };

  const finishQuiz = async () => {
    if (!challengeService) return;

    setOpenModal(false);

    const userChallengeUpdated: UserChallenge =
      await challengeService.updateUserChallenge(
        userChallenge,
        UserChallengeUpdateFlag.QUIZ_DONE
      );

    dispatch(updateUserChallengeList(userChallengeUpdated));
  };

  return (
    <div className="quiz-container">
      <div className="question-container">
        <StyledIconButton
          className="btn-back"
          icon={CloseIcon}
          onClick={goBack}
        />
        <p className="index-question">5/5</p>
        <p className="question-title text-18-bold">
          {t('quiz.consumption_question')}
        </p>

        {isLoading ? (
          <div className="question-loading">
            <Loader />
          </div>
        ) : (
          question && (
            <>
              <p className="question text-18-bold">{question.questionLabel}</p>
              {question.answers.map((answer, index) => {
                return (
                  <div className="answer" key={index}>
                    <input
                      type="radio"
                      id={`answer${index}`}
                      value={answer.answerLabel}
                      onChange={handleChange}
                      checked={userChoice === answer.answerLabel}
                    />
                    <label htmlFor={`answer${index}`} className="text-16-bold">
                      {answer.answerLabel}
                    </label>
                  </div>
                );
              })}
            </>
          )
        )}
      </div>
      <br />
      <Button
        variant="outlined"
        aria-label={t('quiz.accessibility.button_validate')}
        onClick={validateQuestion}
        disabled={!userChoice}
      >
        {t('quiz.button_validate')}
      </Button>

      <QuizExplanationModal
        open={openModal}
        answerIndex={answerIndex}
        question={question}
        goNext={finishQuiz}
        handleCloseClick={() => setOpenModal(false)}
      />
    </div>
  );
};

export default QuizQuestionContentCustom;
