import { Alert, Button, Stack, Typography } from '@mui/material';
import { useAuth } from 'react-oidc-context';
import { useTranslation } from 'react-i18next';

// components
import 'components/LoginPage/loginPage.scss';
import TermsModalDisplay from 'components/LoginPage/TermsModalDisplay/TermsModalDisplay';
// assets
import logosPartners from 'assets/png/eco-gagnant-ensemble-couleur.png';
import logosTurfs from 'assets/png/eco-gagnant-territoires.png';
import logoInPhone from 'assets/png/eco-gagnant-smartphone.png';

interface Props {
  redirectUri: string | undefined;
}

export const LoginPage = ({ redirectUri }: Props) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const redirect = redirectUri ? { redirect_uri: redirectUri } : undefined;

  return (
    <div className="login-page">
      <TermsModalDisplay />

      <div className="logos-section-top">
        <img src={logosPartners} alt="ensemble de logos partenaires" />
      </div>

      <div className="form-section">
        <img
          style={{ display: 'none' }}
          src={logoInPhone}
          alt="logo dans un smartphone - caché"
        />
        <div className="eco-gagnant-logo" />
        <Stack spacing={2}>
          <Button
            variant="contained"
            size="large"
            onClick={() => void auth.signinRedirect(redirect)}
            disabled={auth.isLoading}
          >
            {t('loginPage.login')}
          </Button>

          {redirectUri && (
            <Typography variant="subtitle1">
              {redirectUri.includes('?cbFrom=')
                ? t('konnector_modal.success_cb_redirect')
                : t('loginPage.forRedirection')}
            </Typography>
          )}

          {auth.error && <Alert severity="error">{auth.error.message}</Alert>}
        </Stack>
      </div>

      <div className="logos-section-bottom">
        <span>{t('loginPage.withSupportOf')}</span>
        <img src={logosTurfs} alt="ensemble de logos territoires france 2030" />
      </div>
    </div>
  );
};

export default LoginPage;
