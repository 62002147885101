import FormNavigation from 'components/CommonKit/FormNavigation/FormNavigation';
import FormProgress from 'components/CommonKit/FormProgress/FormProgress';
import 'components/ProfileType/profileTypeForm.scss';
import { useTranslation } from 'react-i18next';
import { ProfileTypeStepForm } from 'enums';
import { ProfileType, ProfileTypeAnswer, ProfileTypeValues } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';

interface ProfileTypeFormNumberProps {
  step: ProfileTypeStepForm;
  viewedStep: ProfileTypeStepForm;
  currentProfileType: ProfileType;
  answerType: ProfileTypeAnswer;
  setNextStep: (_profileType: ProfileType) => void;
  setPreviousStep: () => void;
}

const ProfileTypeFormNumber = ({
  step,
  viewedStep,
  currentProfileType,
  answerType,
  setNextStep,
  setPreviousStep,
}: ProfileTypeFormNumberProps) => {
  const { t } = useTranslation();
  const { value: isProfileTypeCompleted } = useAppSelector(
    (state) => state.profileStellioUser.isProfileTypeCompleted
  );
  const [answer, setAnswer] = useState<ProfileTypeValues>('');

  const handlePrevious = useCallback(() => {
    setPreviousStep();
  }, [setPreviousStep]);

  const handleNext = useCallback(() => {
    setNextStep({ ...currentProfileType, [answerType.attribute]: answer });
  }, [currentProfileType, setNextStep, answer, answerType.attribute]);

  useEffect(() => {
    if (step < viewedStep || isProfileTypeCompleted) {
      setAnswer(currentProfileType[answerType.attribute]);
    }
  }, [
    step,
    viewedStep,
    currentProfileType,
    answerType,
    isProfileTypeCompleted,
  ]);

  return (
    <>
      <div className="profile-form-container">
        <FormProgress step={step} formType="profile" />
        <div className="profile-question-label">
          {t(
            `profile_type.${ProfileTypeStepForm[step].toLowerCase()}.question`
          )}
        </div>
        {answer != null && (
          <label className="text">
            <input
              type="number"
              value={answer.toString()}
              name={answerType.attribute}
              onChange={(e) => setAnswer(e.target.value)}
              autoFocus
            />
            m²
          </label>
        )}
      </div>
      <FormNavigation
        step={step}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        disableNextButton={answer === ''}
      />
    </>
  );
};

export default ProfileTypeFormNumber;
