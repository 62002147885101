import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// models
import { FluidConsentCallbackParameters } from 'models';
// store
import { useAppSelector } from 'store/hooks';
// utils
import { appLogger } from 'utils/appLogger';

export const FLUID_CONSENT_CB_PARAMS_KEY = 'fluidConsentCallbackParameters';
export enum ConsentProvider {
  ENEDIS = 'enedis',
  GRDF = 'grdf',
  SUEZ = 'suez',
}

const useFluidProvidersCallback = () => {
  const location = useLocation();
  const fluidStatus = useAppSelector((store) => store.global.fluidStatus);
  const oauthStates = fluidStatus.map(
    (fluidStatus) => fluidStatus.connection.konnectorConfig.oauthState
  );
  const [redirectUri, setRedirectUri] = useState<string>();

  const getConsentParametersIfAny = () => {
    const search = location.search;
    const urlParameter = new URLSearchParams(search);
    const state = urlParameter.get('state') ?? undefined;
    const usagePointsId = urlParameter.get('usage_points_id') ?? undefined;
    const usagePointId = urlParameter.get('usage_point_id') ?? undefined;
    const code = urlParameter.get('code') ?? undefined;

    const isProviderCallback = code && state && oauthStates.includes(state);
    if (!isProviderCallback) return false;

    const firstUsagePoint = usagePointsId && usagePointsId.split(';')[0];
    const fluidConsentCallbackParameters: FluidConsentCallbackParameters = {
      state: state,
      usageId: firstUsagePoint ?? usagePointId, // PDL param for Enedis
      code: code, // PDL param for GRDF
    };
    const isEnedis = state.includes('Enedis'); 
    const isGrdf = state.includes('Grdf');
    const consentProvider = isEnedis
      ? ConsentProvider.ENEDIS
      : isGrdf
        ? ConsentProvider.GRDF
        : ConsentProvider.SUEZ;

    return { consentProvider, fluidConsentCallbackParameters };
  };

  const redirectToFluidView = (
    provider: ConsentProvider,
    params: FluidConsentCallbackParameters
  ) => {
    window.sessionStorage.setItem(
      FLUID_CONSENT_CB_PARAMS_KEY,
      JSON.stringify(params)
    );
    appLogger(
      'Set FLUID_CONSENT_CB_PARAMS_KEY in session',
      JSON.stringify(params)
    );

    /**
     * Clean parameters from URL
     * To avoid conflicts with keycloak OAuth2 OIDC states & codes
     */
    window.history.replaceState({}, document.title, window.location.pathname);

    let fluidSlug = 'electricity';
    switch (provider) {
      case ConsentProvider.GRDF: {
        fluidSlug = 'gas';
        break;
      }
      case ConsentProvider.SUEZ: {
        fluidSlug = 'water';
        break;
      }
    }

    setRedirectUri(
      `${process.env.REACT_APP_BASE_URL}/consumption/${fluidSlug}?cbFrom=${provider}`
    );
  };

  useEffect(() => {
    const consentParameters = getConsentParametersIfAny();

    if (!consentParameters) return;

    const { consentProvider, fluidConsentCallbackParameters } =
      consentParameters;

    redirectToFluidView(consentProvider, fluidConsentCallbackParameters);
  }, []);

  return redirectUri;
};

export default useFluidProvidersCallback;
