import React, { useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 * Inspired by: https://stackoverflow.com/a/42234988
 */
function useOutsideAlerter(
  ref: React.MutableRefObject<HTMLElement | null>,
  callback: (text: string) => void
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback('You clicked outside of me!');
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export default useOutsideAlerter;
