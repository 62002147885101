import { axisRight } from 'd3-axis';
import { ScaleLinear } from 'd3-scale';
import { select, selectAll } from 'd3-selection';
import { FluidType } from 'enums';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface AxisRightProps {
  yScale: ScaleLinear<number, number>;
  fluidType: FluidType;
  width: number;
  marginRight: number;
  marginTop: number;
  isAnalysis?: boolean;
}

const AxisRight = ({
  yScale,
  fluidType,
  width,
  marginRight,
  marginTop,
  isAnalysis,
}: AxisRightProps) => {
  const { t } = useTranslation();
  const fluidStyle =
    fluidType === FluidType.MULTIFLUID ? 'MULTIFLUID' : FluidType[fluidType];
  const yAxisRef = useRef<SVGGElement>(null);
  const newMarginRight =
    fluidType === FluidType.MULTIFLUID ? marginRight - 10 : marginRight;

  const drawYAxis = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    select(yAxisRef.current!).call(
      axisRight(yScale)
        .ticks(isAnalysis ? 2 : 4)
        .tickSize(-width)
        .tickSizeOuter(0)
        .tickFormat((d) =>
          Number(d) >= 1000 && fluidStyle !== 'MULTIFLUID'
            ? typeof d === 'number'
              ? `${d / 1000} ${t(`FLUID.${fluidStyle}.MEGAUNIT`)}`
              : `${d.valueOf() / 1000} ${t(`FLUID.${fluidStyle}.MEGAUNIT`)}`
            : d === 0
            ? `${d}`
            : `${d} ${t(`FLUID.${fluidStyle}.UNIT`)}`
        )
    );
    selectAll('.tick text').attr('class', 'chart-ticks-y-text');
    select('.domain').remove();
  };

  useEffect(() => {
    drawYAxis();
  });

  return (
    <g
      className="axis y"
      ref={yAxisRef}
      transform={`translate(${width - newMarginRight}, ${marginTop})`}
    />
  );
};

export default AxisRight;
