import Content from 'components/Content/Content';
import EcogestureFormEquipment from 'components/EcogestureForm/EcogestureFormEquipment/EcogestureFormEquipment';
import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import 'components/ProfileType/profileTypeView.scss';
import {
  ConstructionYear,
  Floor,
  FluidType,
  HotWaterEquipment,
  HotWaterFluid,
  HousingType,
  IndividualInsulationWork,
  IndividualOrCollective,
  OutsideFacingWalls,
  ProfileTypeFormType,
  ProfileTypeStepForm,
  ThreeChoicesAnswer,
  WarmingType,
} from 'enums';
import { DateTime } from 'luxon';
import { ProfileType, ProfileTypeAnswer } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import ProfileTypeFormService from 'services/profileTypeForm.service';
import { useAppSelector } from 'store/hooks';
import ProfileTypeFinished from './ProfileTypeFinished/ProfileTypeFinished';
import ProfileTypeFormDateSelection from './ProfileTypeFormDateSelection/ProfileTypeFormDateSelection';
import ProfileTypeFormMultiChoice from './ProfileTypeFormMultiChoice/ProfileTypeFormMultiChoice';
import ProfileTypeFormNumber from './ProfileTypeFormNumber/ProfileTypeFormNumber';
import ProfileTypeFormNumberSelection from './ProfileTypeFormNumberSelection/ProfileTypeFormNumberSelection';
import ProfileTypeFormSingleChoice from './ProfileTypeFormSingleChoice/ProfileTypeFormSingleChoice';

const ProfileTypeView = () => {
  const profile = useAppSelector((state) => state.profile);
  const { value: isProfileTypeCompleted } = useAppSelector(
    (state) => state.profileStellioUser.isProfileTypeCompleted
  );
  const { value: isProfileEcogestureCompleted } = useAppSelector(
    (state) => state.profileStellioUser.isProfileEcogestureCompleted
  );
  const profileType = useAppSelector((state) => state.profileType);
  const profileEcogesture = useAppSelector((state) => state.profileEcogesture);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [currentProfileType, setCurrentProfileType] = useState<ProfileType>({
    updateDate: DateTime.local()
      .setZone('utc', {
        keepLocalTime: true,
      })
      .startOf('month'),
    housingType: HousingType.INDIVIDUAL_HOUSE,
    constructionYear: ConstructionYear.BETWEEN_1975_AND_1989,
    area: '0',
    occupantsNumber: 1,
    outsideFacingWalls: OutsideFacingWalls.ONE,
    floor: Floor.NOT_APPLICABLE,
    heating: IndividualOrCollective.INDIVIDUAL,
    coldWater: IndividualOrCollective.INDIVIDUAL,
    individualInsulationWork: [IndividualInsulationWork.NONE],
    hasInstalledVentilation: ThreeChoicesAnswer.NO,
    hasReplacedHeater: ThreeChoicesAnswer.NO,
    hotWater: IndividualOrCollective.INDIVIDUAL,
    hotWaterEquipment: HotWaterEquipment.OTHER,
    warmingFluid: WarmingType.ELECTRICITY,
    hotWaterFluid: HotWaterFluid.ELECTRICITY,
    cookingFluid: FluidType.ELECTRICITY,
    equipments: [],
  });
  const [step, setStep] = useState<ProfileTypeStepForm>(
    ProfileTypeStepForm.HOUSING_TYPE
  );
  const [answerType, setAnswerType] = useState<ProfileTypeAnswer>({
    type: ProfileTypeFormType.SINGLE_CHOICE,
    attribute: 'housingType',
    choices: [],
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [viewedStep, setViewedStep] = useState<number>(-1);

  // if ecogesture profile is completed, update default profileType
  useEffect(() => {
    if (profile.isProfileEcogestureCompleted) {
      setCurrentProfileType({
        ...profileType,
        hotWater: profileEcogesture.hotWater,
        heating: profileEcogesture.heating,
        warmingFluid: profileEcogesture.warmingFluid,
        equipments: profileEcogesture.equipments,
      });
    }
  }, [
    isProfileEcogestureCompleted,
    profileEcogesture.equipments,
    profileEcogesture.heating,
    profileEcogesture.hotWater,
    profileEcogesture.warmingFluid,
    profileType,
  ]);

  const setNextStep = useCallback(
    (_profileType: ProfileType) => {
      let profileTypeFormService: ProfileTypeFormService;
      if (_profileType) {
        setCurrentProfileType(_profileType);
        profileTypeFormService = new ProfileTypeFormService(_profileType);
      } else {
        // if equipments are updated, keep currentProfileType as it is
        profileTypeFormService = new ProfileTypeFormService({
          ...currentProfileType,
        });
      }
      const nextStep = profileTypeFormService.getNextFormStep(
        step,
        !isProfileTypeCompleted
      );
      setIsLoading(true);
      if (nextStep > viewedStep) {
        setViewedStep(nextStep);
      }
      setStep(nextStep);
    },
    [isProfileTypeCompleted, currentProfileType, step, viewedStep]
  );

  const setPreviousStep = useCallback(() => {
    const profileTypeFormService = new ProfileTypeFormService(
      currentProfileType
    );
    const previousStep: ProfileTypeStepForm =
      profileTypeFormService.getPreviousFormStep(step);
    setIsLoading(true);
    setStep(previousStep);
  }, [currentProfileType, step]);

  useEffect(() => {
    const _answerType = ProfileTypeFormService.getAnswerForStep(step);
    setAnswerType(_answerType);
    setIsLoading(false);
  }, [step]);

  /** If profileType OR ecogestureProfile is completed, apply it to local state */
  useEffect(() => {
    if (isProfileTypeCompleted) {
      setCurrentProfileType({ ...profileType });
      return;
    }

    if (isProfileEcogestureCompleted) {
      // Default state + ecogestureProfile
      setCurrentProfileType({
        ...currentProfileType,
        hotWater: profileEcogesture.hotWater,
        heating: profileEcogesture.heating,
        warmingFluid: profileEcogesture.warmingFluid,
        equipments: profileEcogesture.equipments,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileType, profile]);

  const selectForm = () => {
    if (answerType.type === ProfileTypeFormType.SINGLE_CHOICE) {
      return (
        <ProfileTypeFormSingleChoice
          step={step}
          viewedStep={viewedStep}
          currentProfileType={currentProfileType}
          answerType={answerType}
          setNextStep={setNextStep}
          setPreviousStep={setPreviousStep}
        />
      );
    } else if (answerType.type === ProfileTypeFormType.MULTI_CHOICE) {
      return (
        <ProfileTypeFormMultiChoice
          step={step}
          viewedStep={viewedStep}
          currentProfileType={currentProfileType}
          answerType={answerType}
          setNextStep={setNextStep}
          setPreviousStep={setPreviousStep}
        />
      );
    } else if (answerType.type === ProfileTypeFormType.NUMBER) {
      return (
        <ProfileTypeFormNumber
          step={step}
          viewedStep={viewedStep}
          currentProfileType={currentProfileType}
          answerType={answerType}
          setNextStep={setNextStep}
          setPreviousStep={setPreviousStep}
        />
      );
    } else if (answerType.type === ProfileTypeFormType.NUMBER_SELECTION) {
      return (
        <ProfileTypeFormNumberSelection
          step={step}
          viewedStep={viewedStep}
          currentProfileType={currentProfileType}
          answerType={answerType}
          setNextStep={setNextStep}
          setPreviousStep={setPreviousStep}
        />
      );
    } else if (answerType.type === ProfileTypeFormType.DATE_SELECTION) {
      return (
        <ProfileTypeFormDateSelection
          step={step}
          profileType={currentProfileType}
          answerType={answerType}
          setNextStep={setNextStep}
          setPreviousStep={setPreviousStep}
        />
      );
    } else if (answerType.type === ProfileTypeFormType.EQUIPMENT_SELECTION) {
      return (
        <EcogestureFormEquipment
          step={step}
          currentProfileType={currentProfileType}
          setNextStepProfileForm={setNextStep}
          setPreviousStep={setPreviousStep}
        />
      );
    }
  };

  return (
    <>
      <Header
        setHeaderHeight={setHeaderHeight}
        desktopTitleKey="common.title_profiletype"
        displayBackArrow={true}
      />
      <Content heightOffset={headerHeight} withOverFlow>
        <div className="profile-type-container">
          {isLoading && <Loader />}
          {!isLoading && (
            <>
              {step !== ProfileTypeStepForm.END && selectForm()}
              {step === ProfileTypeStepForm.END && (
                <ProfileTypeFinished profileType={currentProfileType} />
              )}
            </>
          )}
        </div>
      </Content>
    </>
  );
};

export default ProfileTypeView;
