import { useEffect, useState } from 'react'

/**
 * Update the width and height of the chart when the window is resized or the chart is loaded
 */
export const useChartResize = (
  ref: React.RefObject<HTMLDivElement>,
  loading: boolean,
  maxHeight = 300,
  maxWidth = 940
) => {
  const [height, setHeight] = useState(maxHeight)
  const [width, setWidth] = useState(maxWidth)

  useEffect(() => {
    function handleResize() {
      if (!loading) {
        const chartContainerHeight = ref.current?.offsetHeight || maxHeight
        const chartContainerWidth = ref.current?.offsetWidth || maxWidth
        const height = Math.min(chartContainerHeight, maxHeight)
        const width = Math.min(chartContainerWidth, maxWidth)
        setWidth(width)
        setHeight(height)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [loading, maxHeight, maxWidth, ref])

  return { width, height }
}
