import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import './cookieBanner.scss';

export const COOKIE_STORAGE_KEY = 'eco-gagnant-cookie-accept';

export interface CookieStatusStorage {
  hasAccepted: boolean;
  date: string;
}

const CookieBanner = () => {
  const { t } = useTranslation('');
  const [isBannerDisplayed, setIsBannerDisplayed] = useState(false);
  const cookieStatus: CookieStatusStorage = {
    hasAccepted: true,
    date: new Date().toISOString(),
  };

  useEffect(() => {
    const cookieStorage = localStorage.getItem(COOKIE_STORAGE_KEY);

    if (cookieStorage !== null) {
      const cookieStatusDate: string = JSON.parse(cookieStorage).date;
      const now = DateTime.now();
      const diff = DateTime.fromISO(cookieStatusDate).diff(now, ['days']);
      const diffDays = diff.toObject().days;
      const canShowBannerAgain = diffDays ? diffDays <= -365 : false;
      if (canShowBannerAgain) setIsBannerDisplayed(true);
    }

    // Never accepted nor refused
    if (cookieStorage === null) {
      setIsBannerDisplayed(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem(COOKIE_STORAGE_KEY, JSON.stringify(cookieStatus));
    window._paq.push(['rememberConsentGiven']);
    window._paq.push(['rememberCookieConsentGiven']);
    setIsBannerDisplayed(false);
  };

  const handleRefuse = () => {
    cookieStatus.hasAccepted = false;
    localStorage.setItem(COOKIE_STORAGE_KEY, JSON.stringify(cookieStatus));
    window._paq.push(['forgetConsentGiven']);
    window._paq.push(['forgetCookieConsentGiven']);
    setIsBannerDisplayed(false);
  };

  return isBannerDisplayed ? (
    <div id="cookie-banner">
      <Typography>{t('cookies.legitimateTrackers')}</Typography>
      <div className="buttons">
        <Button size="small" variant="contained" onClick={handleAccept}>
          {t('cookies.accept')}
        </Button>
        <Button size="small" variant="text" onClick={handleRefuse}>
          {t('cookies.refuse')}
        </Button>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CookieBanner;
