import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import 'components/ProfileType/profileTypeForm.scss';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { importIconById } from 'utils/utils';

interface EquipmentIconProps {
  equipment: string;
  isChecked: boolean;
}

const EquipmentIcon = ({ equipment, isChecked }: EquipmentIconProps) => {
  const { t } = useTranslation();
  const [icon, setIcon] = useState<string>('');
  useEffect(() => {
    let subscribed = true;
    async function getIcon() {
      const svg = await importIconById(equipment, 'equipments');
      if (subscribed && svg) {
        setIcon(svg);
      }
    }
    getIcon();
    return () => {
      subscribed = false;
    };
  }, [equipment]);
  return (
    <>
      <div
        className={
          isChecked
            ? 'equipment-icon-container checked'
            : ' equipment-icon-container'
        }
      >
        <StyledIcon icon={icon} width={40} className="equipmentIcon " />
      </div>
      <div className="text text-14-normal">
        {t(`ecogesture_profile.equipments.${equipment.toLocaleLowerCase()}`)}
      </div>
    </>
  );
};

export default EquipmentIcon;
