export enum HousingType {
  INDIVIDUAL_HOUSE = 'individual_house',
  APARTMENT = 'apartment',
}

export enum Floor {
  GROUND_FLOOR = 'ground_floor',
  INTERMEDIATE_FLOOR = 'intermediate_floor',
  LAST_FLOOR = 'last_floor',
  NOT_APPLICABLE = 'not_applicable',
}
export enum OutsideFacingWalls {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
}

export enum ConstructionYear {
  BEFORE_1948 = 'before_1948',
  BETWEEN_1948_AND_1974 = 'between_1948_and_1974',
  BETWEEN_1975_AND_1989 = 'between_1975_and_1989',
  BETWEEN_1990_AND_1998 = 'between_1990_and_1998',
  AFTER_1998 = 'after_1998',
  UNKNOWN = 'unknown',
}

export enum IndividualOrCollective {
  INDIVIDUAL = 'individual',
  COLLECTIVE = 'collective',
}

export enum IndividualInsulationWork {
  NONE = 'none',
  ROOF_INSULATION = 'roof_insulation',
  WINDOW_REPLACEMENT = 'window_replacement',
  WALL_INSULATION = 'wall_insulation',
}

export enum ThreeChoicesAnswer {
  YES = 'yes',
  NO = 'no',
  UNKNOWN = 'unknown',
}

export enum HotWaterEquipment {
  SOLAR = 'solar',
  THERMODYNAMIC = 'thermodynamic',
  OTHER = 'other',
}

export enum WarmingType {
  ELECTRICITY = 0,
  GAS = 2,
  WOOD = 3,
  FUEL = 4,
  OTHER = 5,
}

export enum HotWaterFluid {
  ELECTRICITY = 0,
  GAS = 2,
  OTHER = 3,
}

export enum ProfileTypeStepForm {
  HOUSING_TYPE = 0,
  AREA = 1,
  OCCUPANTS_NUMBER = 2,
  CONSTRUCTION_YEAR = 3,
  OUTSIDE_FACING_WALLS = 4,
  FLOOR = 5,
  HEATING = 6,
  WARMING_FLUID = 7,
  INDIVIDUAL_INSULATION_WORK = 8,
  VENTILATION = 9,
  HEATER_REPLACEMENT = 10,
  COLD_WATER = 11,
  HOT_WATER = 12,
  HOT_WATER_FLUID = 13,
  HOT_WATER_EQUIPMENT = 14,
  COOKING_FLUID = 15,
  EQUIPMENTS = 16,
  UPDATE_DATE = 17,
  END = 18,
}

export enum ProfileTypeFormType {
  SINGLE_CHOICE = 0,
  MULTI_CHOICE = 1,
  NUMBER_SELECTION = 2,
  NUMBER = 3,
  DATE_SELECTION = 4,
  EQUIPMENT_SELECTION = 5,
}
