import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { BrowserRouter } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import localeFr from 'locales/fr.json';
import localeEn from 'locales/en.json';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { User } from 'oidc-client-ts';
import { ThemeProvider, createTheme } from '@mui/material/styles';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: localeEn,
      },
      fr: {
        translation: localeFr,
      },
    },
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

Sentry.init({
  dsn: 'https://afc1a1cbe9830554b1e8a140f5efc927@o1325406.ingest.sentry.io/4506185371090944',
  enabled: process.env.REACT_APP_ENV_NAME !== 'development',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// see doc here: https://github.com/authts/react-oidc-context#:~:text=You%20must%20provide%20an%20implementation%20of%20onSigninCallback
const onSigninCallback = (_user: User | void): void => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const getEnvMissingMessage = (envName: string) => `env ${envName} missing`;

const oidcConfig: AuthProviderProps = {
  authority:
    process.env.REACT_APP_KEYCLOAK_BASE_URL ??
    getEnvMissingMessage('KEYCLOAK_BASE_URL'), // The URL of the OIDC/OAuth2 provider
  client_id:
    process.env.REACT_APP_KEYCLOAK_CLIENT_ID ??
    getEnvMissingMessage('KEYCLOAK_CLIENT_ID'), // Your client application's identifier as registered with the OIDC/OAuth2 provider
  redirect_uri:
    process.env.REACT_APP_BASE_URL ?? getEnvMissingMessage('APP_BASE_URL'), // The redirect URI of your client application to receive a response from the OIDC/OAuth2 provider
  onSigninCallback,
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#e3b82a',
    },
    secondary: {
      main: '#e3e3e3',
    },
    mode: 'dark',
  },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider {...oidcConfig}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);

// Expose store when run in Cypress
// Explanation for window.parent.Cypress workaround:
// https://github.com/cypress-io/cypress/issues/25394#issuecomment-1401100561
// @ts-ignore
if (window.Cypress || window.parent.Cypress) {
  // @ts-ignore
  window.store = store;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
