import ecogestureData from 'db/ecogestureData.json';
import {
  FluidType,
  IndividualOrCollective,
  Season,
  Usage,
  WarmingType,
} from 'enums';
import { orderBy } from 'lodash';
import { Ecogesture, ProfileEcogesture, UserEcogesture } from 'models';
import { hashFile } from 'utils/hash';
import StellioAxiosClient from './appAxios.service';
import {
  StellioChallengeProgress,
  StellioEcogesture,
  StellioEntityType,
} from 'models/stellio.model';
import { AxiosResponse } from 'axios';
import { getAlwaysArray } from 'utils/ecoGagnant';

export default class EcogestureService {
  private readonly _client: StellioAxiosClient;
  private readonly _challengeProgress: StellioChallengeProgress;
  public readonly _allEcolyoEcogestures: Ecogesture[];

  constructor(
    _client: StellioAxiosClient,
    allStellioEcogestures: StellioEcogesture[],
    challengeProgress: StellioChallengeProgress
  ) {
    this._client = _client;
    this._challengeProgress = challengeProgress;
    this._allEcolyoEcogestures = this.mapStellioToEcolyoModel(
      allStellioEcogestures,
      challengeProgress
    );
  }

  private mapStellioToEcolyoModel = (
    stellioEcogestures: StellioEcogesture[],
    challengeProgress: StellioChallengeProgress
  ): Ecogesture[] => {
    return stellioEcogestures.map((stellioEcogesture): Ecogesture => {
      const userEcogesture = challengeProgress.refEcogesture.find(
        (relationship) => relationship.object === stellioEcogesture.id
      )?.userProgress.json;

      const equipmentTypes = stellioEcogesture.equipmentTypes?.value;
      const ecogestureEcolyo: Ecogesture = {
        id: stellioEcogesture.id,
        _id: stellioEcogesture.id,
        shortName: stellioEcogesture.title.value,
        longName: stellioEcogesture.name.value,
        longDescription: stellioEcogesture.description.value,
        usage: stellioEcogesture.usage.value,
        fluidTypes: getAlwaysArray(stellioEcogesture.fluidTypes.value),
        impactLevel: stellioEcogesture.impactLevel.value,
        efficiency: stellioEcogesture.efficiency.value,
        difficulty: stellioEcogesture.difficulty.value,
        room: getAlwaysArray(stellioEcogesture.rooms?.value) ?? 0,
        season: stellioEcogesture.season.value,
        equipmentType: equipmentTypes ? getAlwaysArray(equipmentTypes) : [],
        equipmentInstallation: false,
        investment: stellioEcogesture.investment?.value ?? null,
        action: stellioEcogesture.isAction.value,
        actionName: stellioEcogesture.actionName?.value ?? null,
        actionDuration: stellioEcogesture.timeRequired.value ?? 3,

        // User specifics
        objective: userEcogesture?.objective ?? false,
        doing: userEcogesture?.doing ?? false,
        viewedInSelection: userEcogesture?.viewedInSelection ?? false,
        equipment: false,
      };

      return ecogestureEcolyo;
    });
  };

  public getAllEcolyoGestures = (): Ecogesture[] => {
    return this._allEcolyoEcogestures;
  };

  /**
   * - Load ecogestures if they exists.
   * - If not create them.
   * - If hash mismatch, update ecogestures.
   */
  public async initEcogesture(hash: string): Promise<{
    ecogestureHash: string;
    ecogestureList: Ecogesture[];
  }> {
    const startTime = performance.now();
    const hashEcogestureType = hashFile(ecogestureData);
    const ecogestures = await this.getAllEcogestures(undefined, true);

    if (!ecogestures || ecogestures?.length === 0) {
      // Populate data if none ecogesture exists
      try {
        for (const ecogesture of ecogestureData) {
          // await this._client.create(ECOGESTURE_DOCTYPE, ecogesture)
        }
        // Check of created document based on count
        const ecogestures = await this.getAllEcogestures();
        if (!ecogestures || ecogestures?.length !== ecogestureData.length) {
          throw new Error(
            'initEcogesture: Created ecogesture type entities does not match'
          );
        }
        // logDuration('[Initialization] Ecogesture list created', startTime)
        return {
          ecogestureHash: hashEcogestureType,
          ecogestureList: ecogestures,
        };
      } catch (error) {
        const errorMessage = `Initialization error - initEcogesture: ${JSON.stringify(
          error
        )}`;
        // logStack('error', errorMessage)
        // logApp.error(errorMessage)
        // Sentry.captureException(errorMessage)
        throw error;
      }
    }
    // Update if the hash is not the same as the one from profile
    if (hash !== hashEcogestureType) {
      // Update the doctype
      try {
        // Deletion of all documents
        await this.deleteAllEcogestures();
        // Population with the data
        // @ts-ignore
        for (const [index, ecogesture] of ecogestureData.entries()) {
          const updateEcogesture = ecogestures[index]
            ? {
                ...ecogesture,
                objective: ecogestures[index].objective,
                doing: ecogestures[index].doing,
                viewedInSelection: ecogestures[index].viewedInSelection,
              }
            : ecogesture;
          // await this._client.create(ECOGESTURE_DOCTYPE, updateEcogesture)
        }
        // Check of created document based on count
        const checkCount = await this.getAllEcogestures();
        if (!checkCount || checkCount?.length !== ecogestureData.length) {
          throw new Error(
            'initEcogesture: Created ecogesture type entities does not match'
          );
        }
        // logDuration('[Initialization] Ecogesture updated', startTime)
        return {
          ecogestureHash: hashEcogestureType,
          ecogestureList: checkCount,
        };
      } catch (error) {
        const errorMessage = `Initialization error - initEcogesture: ${JSON.stringify(
          error
        )}`;
        // logStack('error', errorMessage)
        // logApp.error(errorMessage)
        throw error;
      }
    } else {
      // Doctype already up to date
      // logDuration('[Initialization] Ecogesture already up-to-date', startTime)
      return {
        ecogestureHash: hashEcogestureType,
        ecogestureList: ecogestures,
      };
    }
  }

  // TODO add default params
  public async getAllEcogestures(
    seasonFilter?: Season,
    orderByID?: boolean
  ): Promise<Ecogesture[]> {
    // let query: QueryDefinition = Q(ECOGESTURE_DOCTYPE)
    if (seasonFilter && seasonFilter !== Season.NONE) {
      // query = query
      //   .where({ season: { $ne: seasonFilter } })
      //   .indexFields(['season'])
      //   .sortBy([{ season: 'desc' }])
    } else if (orderByID) {
      // query = query
      //   .where({})
      //   .indexFields(['_id'])
      //   .sortBy([{ _id: 'asc' }])
    } else {
      // query = query
      // .where({})
      // .indexFields(['season'])
      // .sortBy([{ season: 'desc' }])
    }

    // const { data: ecogestures }: QueryResult<Ecogesture[]> =
    //   await this._client.query(query)

    // if (seasonFilter && seasonFilter !== Season.NONE) {
    //   const { data: ecogesturesWithSeason }: QueryResult<Ecogesture[]> =
    //     await this._client.query(
    //       Q(ECOGESTURE_DOCTYPE)
    //         .where({ season: { $eq: seasonFilter } })
    //         .indexFields(['season'])
    //         .sortBy([{ season: 'asc' }])
    //     )
    //   return [...ecogesturesWithSeason, ...ecogestures]
    // }
    // return ecogestures
    return [];
  }

  /**
   * @param {string} ids - ecogestures ids
   * @returns {Promise<Ecogesture[]>}
   */
  public async getEcogesturesByIds(ids: string[]): Promise<Ecogesture[]> {
    let _ids = [...ids];

    const idSample = _ids[0];
    if (!idSample) return [];

    if (!idSample.includes('urn:ngsi-ld')) {
      // Add NGSI-LD id pattern
      _ids = _ids.map(
        (baseName) => `urn:ngsi-ld:${StellioEntityType.ECOGESTURE}:${baseName}`
      );
    }

    return this._allEcolyoEcogestures.filter((ecogesture) => {
      return _ids.includes(ecogesture.id);
    });
  }

  public async deleteAllEcogestures(): Promise<boolean> {
    const ecogestures = await this.getAllEcogestures();
    try {
      for (const ecogesture of ecogestures) {
        // await this._client.destroy(ecogesture)
      }
      return true;
    } catch (error) {
      const errorMessage = `Error deleteAllEcogestures: ${JSON.stringify(
        error
      )}`;
      throw error;
    }
  }

  public async reinitAllEcogestures(): Promise<boolean> {
    const reinitializedRefEcogestureProgress: Partial<StellioChallengeProgress> =
      {
        ...this._challengeProgress,
        refEcogesture: this._challengeProgress.refEcogesture.map(
          (relationship) => {
            return {
              ...relationship,
              userProgress: {
                ...relationship.userProgress,
                json: {
                  objective: false,
                  doing: false,
                  viewedInSelection: false,
                },
              },
            };
          }
        ),
      };
    delete reinitializedRefEcogestureProgress.id;
    delete reinitializedRefEcogestureProgress.type;
    delete reinitializedRefEcogestureProgress.refChallenge;
    delete reinitializedRefEcogestureProgress.globalState;

    const response = await this._client.updateEntityById(
      this._challengeProgress.id,
      reinitializedRefEcogestureProgress
    );

    return response.status === 204;
  }

  /**
   * Removes ecogestures from the list that doesn't fit with user's usages
   * @param {Ecogesture[]} ecogestureList
   * @param {ProfileEcogesture} profileEcogesture
   * @returns {Ecogesture[]}
   */
  public filterByUsage(
    ecogestureList: Ecogesture[],
    profileEcogesture: ProfileEcogesture
  ): Ecogesture[] {
    const filteredByUsage: Ecogesture[] = ecogestureList.filter(
      (ecogesture) => {
        switch (ecogesture.usage) {
          case Usage.HEATING:
            if (
              ecogesture.fluidTypes.includes(FluidType.ELECTRICITY) &&
              profileEcogesture.heating === IndividualOrCollective.INDIVIDUAL &&
              profileEcogesture.warmingFluid === WarmingType.ELECTRICITY
            ) {
              return true;
            } else if (
              ecogesture.fluidTypes.includes(FluidType.GAS) &&
              profileEcogesture.heating === IndividualOrCollective.INDIVIDUAL &&
              profileEcogesture.warmingFluid === WarmingType.GAS
            ) {
              return true;
            } else {
              return false;
            }
          case Usage.ECS:
            if (
              profileEcogesture.hotWater === IndividualOrCollective.INDIVIDUAL
            ) {
              return true;
            } else {
              return false;
            }
          default:
            return true;
        }
      }
    );
    return filteredByUsage;
  }

  /**
   * Removes ecogesture from the list that depends on equipment the user hasn't
   * @param {Ecogesture[]} ecogestureList
   * @param {ProfileEcogesture} profileEcogesture
   * @returns {Ecogesture[]}
   */
  public filterByEquipment(
    ecogestureList: Ecogesture[],
    profileEcogesture: ProfileEcogesture
  ): Ecogesture[] {
    const profileEquipments = profileEcogesture.equipments;

    const filteredByEquipment = ecogestureList.filter((ecogesture) => {
      const ecogestureEquipmentTypes = ecogesture.equipmentType;
      if (!ecogestureEquipmentTypes.length) return true;

      return ecogestureEquipmentTypes.some((ecogestureEquipmentType) =>
        profileEquipments.includes(ecogestureEquipmentType)
      );
    });

    return filteredByEquipment;
  }

  /**
   * Return a filtered list according to ecogesture profile, the list is sorted by low difficulty and high efficiency
   * @param {ProfileEcogesture} profileEcogesture
   * @returns {Ecogesture[]}
   */
  public async getEcogestureListByProfile(
    profileEcogesture: ProfileEcogesture
  ): Promise<Ecogesture[]> {
    const ecogestureList: Ecogesture[] = this._allEcolyoEcogestures;
    const filteredByUsage: Ecogesture[] = this.filterByUsage(
      ecogestureList,
      profileEcogesture
    );
    const filteredByEquipment: Ecogesture[] = this.filterByEquipment(
      filteredByUsage,
      profileEcogesture
    );
    const filteredFlaggedEcogesture: Ecogesture[] = filteredByEquipment.filter(
      (ecogesture: Ecogesture) =>
        (ecogesture.objective === false &&
          ecogesture.doing === false &&
          ecogesture.viewedInSelection === false) ||
        ecogesture.viewedInSelection === true
    );
    const sortedByDifficultyAndEfficiency: Ecogesture[] = orderBy(
      filteredFlaggedEcogesture,
      [
        (ecogesture) => {
          return ecogesture.difficulty;
        },
        (ecogesture) => {
          return ecogesture.efficiency;
        },
      ],
      ['asc', 'desc']
    );
    return sortedByDifficultyAndEfficiency;
  }

  /**
   * Update one ecogesture
   * @param {Ecogesture} ecogesture - Ecogesture to save
   * @returns {Ecogesture} Updated Ecogesture
   */
  public async updateUserEcogesture(
    ecogesture: Ecogesture
  ): Promise<AxiosResponse> {
    const userEcogestureToUpdate: UserEcogesture = {
      doing: ecogesture.doing,
      objective: ecogesture.objective,
      viewedInSelection: ecogesture.viewedInSelection,
    };

    const attrsToUpdate: Partial<StellioChallengeProgress> = {
      ...this._challengeProgress,
      refEcogesture: this._challengeProgress.refEcogesture.map(
        (relationship) => {
          if (relationship.object !== ecogesture.id) {
            return relationship;
          }

          return {
            ...relationship,
            userProgress: {
              ...relationship.userProgress,
              json: userEcogestureToUpdate,
            },
          };
        }
      ),
    };
    delete attrsToUpdate.id;
    delete attrsToUpdate.type;
    delete attrsToUpdate.refChallenge;
    delete attrsToUpdate.globalState;

    const response = await this._client.updateEntityById(
      this._challengeProgress.id,
      attrsToUpdate
    );

    return response;
  }
}
