import Content from 'components/Content/Content';
import Header from 'components/Header/Header';
import { UserChallengeState, UserDuelState } from 'enums';
import { UserChallenge } from 'models';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import DuelEmptyValueModal from './DuelEmptyValueModal/DuelEmptyValueModal';
import DuelError from './DuelError/DuelError';
import DuelOngoing from './DuelOngoing/DuelOngoing';
import DuelUnlocked from './DuelUnlocked/DuelUnlocked';

const DuelView = () => {
  const navigate = useNavigate();
  const { userChallengeList } = useAppSelector((state) => state.challenge);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const id = new URLSearchParams(useLocation().search).get('id');
  const challengeToDisplay: UserChallenge | undefined = userChallengeList.find(
    (challenge) => challenge.id === id
  );

  const goBackToChallenge = () => {
    navigate('/challenges');
  };

  const renderDuel = (challenge: UserChallenge) => {
    switch (challenge.duel.state) {
      case UserDuelState.UNLOCKED:
        return <DuelUnlocked userChallenge={challenge} />;
      case UserDuelState.ONGOING:
        return <DuelOngoing userChallenge={challenge} />;
      case UserDuelState.DONE:
        return <DuelOngoing userChallenge={challenge} isFinished={true} />;
      case UserDuelState.NO_REF_PERIOD_VALID:
        return (
          <DuelEmptyValueModal
            open={true}
            handleCloseClick={goBackToChallenge}
          />
        );
      default:
        return <DuelError />;
    }
  };

  return (
    <>
      <Header
        setHeaderHeight={setHeaderHeight}
        desktopTitleKey="common.title_duel"
        displayBackArrow={true}
      />
      <Content heightOffset={headerHeight}>
        <div>
          {challengeToDisplay &&
          (challengeToDisplay.state === UserChallengeState.DUEL ||
            challengeToDisplay.state === UserChallengeState.DONE) ? (
            renderDuel(challengeToDisplay)
          ) : (
            <DuelError />
          )}
        </div>
      </Content>
    </>
  );
};

export default DuelView;
