import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import StyledIconProps from 'components/CommonKit/Icon/StyledIcon';

interface StyledIconButtonProps extends IconButtonProps {
  icon: string;
  sized?: number;
}

const StyledIconButton = ({
  icon,
  sized = 16,
  ...props
}: StyledIconButtonProps) => {
  return (
    <IconButton {...props}>
      <StyledIconProps icon={icon} width={sized} />
    </IconButton>
  );
};

export default StyledIconButton;
