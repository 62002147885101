import { ScaleBand, ScaleLinear } from 'd3-scale'
import { detect } from 'detect-browser'
import { DateTime } from 'luxon'
import { Dataload } from 'models'
import React, { useState } from 'react'

interface BarProps {
  index: number
  dataload: Dataload
  xScale: ScaleBand<string>
  yScale: ScaleLinear<number, number>
  height: number
  average: number
}

const UncomingBar = ({
  index,
  dataload,
  xScale,
  yScale,
  height,
  average,
}: BarProps) => {
  const [animationEnded, setAnimationEnded] = useState(false)
  const browser = detect()
  const onAnimationEnd = () => {
    setAnimationEnded(true)
  }

  const barClass = `bar-UNCOMING ${
    animationEnded
      ? ''
      : `bounce-${browser?.name !== 'edge' ? '1' : '3'} delay--${index % 13}`
  }`

  const getBandWidth = (): number => {
    return xScale.bandwidth()
  }

  const topRoundedRectDashedLine = (
    x: number,
    y: number,
    width: number,
    height: number
  ): string => {
    const radius = height > 4 ? 4 : height / 4
    return (
      'M' +
      x +
      ',' +
      (y + radius + (height - radius)) +
      'v-' +
      (height - radius) +
      ' a' +
      radius +
      ',' +
      radius +
      ' 0 0 1 ' +
      radius +
      ',' +
      -radius +
      'h' +
      (width - 2 * radius) +
      'a' +
      radius +
      ',' +
      radius +
      ' 0 0 1 ' +
      radius +
      ',' +
      radius +
      'v' +
      (height - radius)
    )
  }

  return (
    <g>
      {height > 0 && (
        <g
          transform={`translate(${xScale(
            dataload.date.toLocaleString(DateTime.DATETIME_SHORT)
          )}, -40)`}
        >
          <rect
            x="0"
            y="0"
            width={getBandWidth()}
            height={height + 40}
            className="background-false"
            fill="#E0E0E0"
          />
        </g>
      )}
      {height > 0 && average >= 0 && (
        <g
          transform={`translate(${xScale(
            dataload.date.toLocaleString(DateTime.DATETIME_SHORT)
          )}, ${yScale(average)})`}
        >
          <path
            d={topRoundedRectDashedLine(
              0,
              0,
              getBandWidth(),
              height - yScale(average)
            )}
            fill="url(#gradient)"
            strokeDasharray="5"
            stroke="#61f0f2"
            onAnimationEnd={onAnimationEnd}
            className={barClass}
          />
        </g>
      )}
    </g>
  )
}

export default UncomingBar
