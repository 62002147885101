import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const codeParamName = 'redirectCode';
const redirectCode = '3948';

const useTargetNavigationRedirect = () => {
  const location = useLocation();
  const [targetNavigationRedirectUrl, setTargetNavigationRedirectUrl] =
    useState<string | undefined>();

  useEffect(() => {
    const urlParameter = new URLSearchParams(location.search);
    const codeParam = urlParameter.get(codeParamName) ?? undefined;

    if (codeParam === redirectCode) {
      setTargetNavigationRedirectUrl(
        `${process.env.REACT_APP_BASE_URL}${location.pathname}`
      );

      /** Clear code parameter from url */
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  return targetNavigationRedirectUrl;
};

export default useTargetNavigationRedirect;
