import { useEffect, useState } from 'react';

// components
import CGUModal from 'components/Terms/CGUModal';
import LegalNoticeModal from 'components/Terms/LegalNoticeModal';

const TermsModalDisplay = () => {
  const [isCguModalOpen, setIsCguModalOpen] = useState(false);
  const [isLegalNoticeModalOpen, setIsLegalNoticeModalOpen] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    const cgu = params.get('cgu');
    const legalNotice = params.get('legalNotice');

    setIsCguModalOpen(cgu !== null);
    setIsLegalNoticeModalOpen(legalNotice !== null);
  }, [document.location.search]);

  return (
    <>
      <CGUModal
        open={isCguModalOpen}
        handleCloseClick={() => setIsCguModalOpen(false)}
      />
      <LegalNoticeModal
        open={isLegalNoticeModalOpen}
        handleCloseClick={() => setIsLegalNoticeModalOpen(false)}
      />
    </>
  );
};

export default TermsModalDisplay;
