import { FluidType, TimeStep } from 'enums'
import { DateTime } from 'luxon'
import { Dataload } from 'models'
import { getMonthNameWithPrep } from './utils'

export function compareDates(dateA: DateTime, dateB: DateTime) {
  return dateA < dateB ? -1 : 1
}

// Use to define if the selected date is the last possible for the given timestep
export const isLastDateReached = (
  date: DateTime,
  timeStep: TimeStep
): boolean => {
  const today = DateTime.local().setZone('utc', {
    keepLocalTime: true,
  })

  switch (timeStep) {
    case TimeStep.HALF_AN_HOUR:
      return date.plus({ minute: 30 }) > today.endOf('day') ? true : false
    case TimeStep.WEEK:
    case TimeStep.DAY:
      return date.plus({ day: 1 }) > today ? true : false
    case TimeStep.MONTH:
      return date.plus({ month: 1 }) > today ? true : false
    case TimeStep.YEAR:
      return date.plus({ year: 1 }) > today ? true : false
    default:
      return false
  }
}

// Use to define if the selected date is the last possible
export const isLastPeriodReached = (
  date: DateTime,
  timeStep: TimeStep
): boolean => {
  switch (timeStep) {
    case TimeStep.HALF_AN_HOUR:
      return date.endOf('day').plus({ day: 1 }) >
        DateTime.local()
          .setZone('utc', {
            keepLocalTime: true,
          })
          .endOf('day')
        ? true
        : false
    case TimeStep.WEEK:
      return date.endOf('week').plus({ week: 1 }) >
        DateTime.local()
          .setZone('utc', {
            keepLocalTime: true,
          })
          .endOf('week')
        ? true
        : false
    case TimeStep.DAY:
      return date.endOf('month').plus({ month: 1 }) >
        DateTime.local()
          .setZone('utc', {
            keepLocalTime: true,
          })
          .endOf('month')
        ? true
        : false
    case TimeStep.MONTH:
      return date.endOf('year').plus({ year: 1 }) >
        DateTime.local()
          .setZone('utc', {
            keepLocalTime: true,
          })
          .endOf('year')
        ? true
        : false
    case TimeStep.YEAR:
      return date.year + 5 >
        DateTime.local().setZone('utc', {
          keepLocalTime: true,
        }).year
        ? true
        : false
    default:
      return false
  }
}

/*
 * Return the diff of day which represent
 * the possible calculation of data based on configured fluidTypes
 */
export const getLagDays = (fluidTypes: FluidType[]): number => {
  if (fluidTypes?.length > 0 && fluidTypes?.includes(FluidType.WATER)) {
    return 3
  } else if (fluidTypes?.length > 0 && fluidTypes?.includes(FluidType.GAS)) {
    return 2
  } else {
    return 1
  }
}

export const convertDateToShortDateString = (
  actualData: Dataload[] | null,
  timeStep: TimeStep
): string => {
  if (!actualData || actualData.length === 0) return ''
  const date = actualData[0].date
  switch (timeStep) {
    case TimeStep.HALF_AN_HOUR:
      return `bilan du ${date.setLocale('fr').toFormat('cccc dd LLLL')}`
    case TimeStep.DAY:
      return `bilan ${getMonthNameWithPrep(date)}`
    case TimeStep.WEEK:
      return `bilan du ${date.toFormat('dd/MM')} au ${actualData[
        actualData.length - 1
      ].date.toFormat('dd/MM')}`
    case TimeStep.MONTH:
      return `bilan de l’année ${date.toFormat('y')}`
    case TimeStep.YEAR:
      return `de ${date.toFormat('y')} à ${actualData[
        actualData.length - 1
      ].date.toFormat('y')}`
    default:
      return ''
  }
}

export const convertDateToMonthYearString = (date: DateTime): string => {
  return date.setLocale('fr-FR').toLocaleString({
    month: 'long',
    year: 'numeric',
  })
}

export const getActualAnalysisDate = (): DateTime => {
  const now = DateTime.local()
    .setZone('utc', {
      keepLocalTime: true,
    })
    .startOf('day')
  if (now.day < 3) {
    return now.set({ day: 3, month: now.month - 1 })
  } else {
    return now.set({ day: 3, month: now.month })
  }
}
