export enum FluidType {
  ELECTRICITY = 0,
  WATER = 1,
  GAS = 2,
  MULTIFLUID = 3,
}

export enum FluidState {
  KONNECTOR_NOT_FOUND = 0,
  NOT_CONNECTED = 1,
  DONE = 200,
  ERROR = 300,
  ERROR_LOGIN_FAILED = 301,
}

export enum FluidRetrievalStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  FINISHED = 'FINISHED',
}
