import IconButton from '@mui/material/IconButton';
import BackArrowIcon from 'assets/icons/ico/back-arrow.svg';
import FeedbackIcon from 'assets/icons/ico/feedback.svg';
import { useTranslation } from 'react-i18next';
import { ScreenType } from 'enums';
import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openFeedbackModal } from 'store/modal/modal.slice';
import './header.scss';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';

interface HeaderProps {
  /** translation key used as t('key.value') */
  desktopTitleKey: string;
  displayBackArrow?: boolean;
  /** additional information to put below the main header */
  children?: React.ReactNode;
  setHeaderHeight(height: number): void;
  backFunction?: () => void;
}

/** Header for desktop view */
const Header = ({
  desktopTitleKey,
  displayBackArrow,
  children,
  setHeaderHeight,
  backFunction,
}: HeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const header = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { screenType } = useAppSelector((state) => state.global);

  const handleClickBack = useCallback(() => {
    if (backFunction) {
      backFunction();
    } else {
      navigate(-1);
    }
  }, [backFunction, navigate]);

  const handleClickFeedbacks = () => {
    dispatch(openFeedbackModal(true));
  };

  useEffect(() => {
    const headerHeight = header.current?.clientHeight || 0;

    setHeaderHeight(headerHeight);
  }, [screenType, children, setHeaderHeight, displayBackArrow]);

  return (
    <div className="header" ref={header}>
      <div className="header-top">
        <div className="header-content">
          <div
            className={
              !desktopTitleKey
                ? 'header-content-top header-content-top-right'
                : 'header-content-top'
            }
          >
            {desktopTitleKey && (
              <div
                className={`header-text-desktop ${
                  screenType === ScreenType.MOBILE
                    ? 'text-14-normal-uppercase'
                    : 'text-22-bold'
                }`}
              >
                {displayBackArrow && (
                  <IconButton
                    aria-label={t('header.accessibility.button_back')}
                    className="header-back-button"
                    onClick={handleClickBack}
                  >
                    <StyledIcon icon={BackArrowIcon} width={16} />
                  </IconButton>
                )}
                {/* <span>{t(desktopTitleKey)}</span> */}
              </div>
            )}
          </div>
          {children}
        </div>
      </div>
      <div className="header-bar" />
    </div>
  );
};

export default Header;
