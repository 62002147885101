import Content from 'components/Content/Content';
import Header from 'components/Header/Header';
import { UserExplorationState } from 'enums';
import { UserChallenge } from 'models';
import React, { useState } from 'react';
import { useAppSelector } from 'store/hooks';
import ExplorationError from './ExplorationError';
import ExplorationFinished from './ExplorationFinished';
import ExplorationOngoing from './ExplorationOngoing';

const ExplorationView = () => {
  const { currentChallenge } = useAppSelector((state) => state.challenge);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  const renderExploration = (challenge: UserChallenge) => {
    switch (challenge.exploration.state) {
      case UserExplorationState.UNLOCKED:
      case UserExplorationState.ONGOING:
        return <ExplorationOngoing userChallenge={challenge} />;
      case UserExplorationState.NOTIFICATION:
      case UserExplorationState.DONE:
        return <ExplorationFinished userChallenge={challenge} />;
      default:
        return <ExplorationError />;
    }
  };

  return (
    <>
      <Header
        setHeaderHeight={setHeaderHeight}
        desktopTitleKey="common.title_exploration"
        displayBackArrow={true}
      />
      <Content heightOffset={headerHeight}>
        {currentChallenge && renderExploration(currentChallenge)}
      </Content>
    </>
  );
};

export default ExplorationView;
