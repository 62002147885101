import { PartnersInfo } from 'models';
import axios from 'axios';

export default class PartnersInfoService {
  constructor() {}

  /**
   * Get information from backoffice about the status of custom popup
   */
  public async getPartnersInfo(): Promise<PartnersInfo | undefined> {
    const remoteUrl = `${process.env.REACT_APP_BACKOFFICE_BASE_URL}/api/common/partnersInfo`;

    try {
      const result = await axios.get<PartnersInfo>(remoteUrl);

      return result.data;
    } catch (error) {
      const errorMessage = `getPartnersInfo: Failed to get Partners Info:${JSON.stringify(
        error
      )}`;
      console.error(errorMessage);
    }
  }
}
