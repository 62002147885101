import { FluidType } from 'enums';
import { FluidConfig } from 'models';
import ConfigService from './fluidConfig.service';

export default class ConverterService {
  private readonly _fluidConfig: FluidConfig[];

  constructor() {
    this._fluidConfig = new ConfigService().getFluidConfig();
  }

  public LoadToEuro(
    load: number,
    fluidType: FluidType,
    price?: number | null
  ): number {
    let convertedLoad: number;
    // If Multifluid do not apply coeff because it doesn't exist
    if (fluidType === FluidType.MULTIFLUID) {
      convertedLoad = load;
    } else {
      convertedLoad = this.applyPrice(
        this._fluidConfig[fluidType],
        load,
        price
      );
    }

    // Prevent round 0 case when the actual value is not 0
    if (convertedLoad > 0 && convertedLoad < 0.01) {
      convertedLoad = 0.01;
    }

    // console.log('Load:', load, ' in €', convertedLoad);

    return convertedLoad;
  }

  /**
   * Return calculated price
   * @param {FluidConfig} fluidConfig - Fluid configuration
   * @param {number} load - Load value
   * @param {number} [price] - Price if exist
   * @returns {number}
   */
  private applyPrice(
    fluidConfig: FluidConfig,
    load: number,
    price?: number | null
  ): number {
    if (price) {
      return price;
    } else {
      return load * fluidConfig.coefficient;
    }
  }
}
