import {
  ConstructionYear,
  EquipmentType,
  Floor,
  FluidType,
  HotWaterEquipment,
  HotWaterFluid,
  HousingType,
  IndividualInsulationWork,
  IndividualOrCollective,
  OutsideFacingWalls,
  ProfileTypeFormType,
  ProfileTypeStepForm,
  ThreeChoicesAnswer,
  WarmingType,
} from 'enums'
import { ProfileType, ProfileTypeAnswer } from 'models'

export default class ProfileTypeFormService {
  private readonly profileType: ProfileType

  constructor(profileType: ProfileType) {
    this.profileType = profileType
  }

  /**
   * getNextFormStep
   * @param {ProfileTypeStepForm} step
   * @returns {ProfileTypeStepForm} next step
   */
  public getNextFormStep(
    step: ProfileTypeStepForm,
    firstProfileType: boolean
  ): ProfileTypeStepForm {
    switch (step) {
      case ProfileTypeStepForm.HOUSING_TYPE:
        return ProfileTypeStepForm.AREA
      case ProfileTypeStepForm.AREA:
        return ProfileTypeStepForm.OCCUPANTS_NUMBER
      case ProfileTypeStepForm.OCCUPANTS_NUMBER:
        return ProfileTypeStepForm.CONSTRUCTION_YEAR
      case ProfileTypeStepForm.CONSTRUCTION_YEAR:
        return ProfileTypeStepForm.OUTSIDE_FACING_WALLS
      case ProfileTypeStepForm.OUTSIDE_FACING_WALLS:
        return this.profileType.housingType === HousingType.INDIVIDUAL_HOUSE
          ? ProfileTypeStepForm.WARMING_FLUID
          : ProfileTypeStepForm.FLOOR
      case ProfileTypeStepForm.FLOOR:
        return ProfileTypeStepForm.HEATING
      case ProfileTypeStepForm.HEATING:
        return this.profileType.heating === IndividualOrCollective.INDIVIDUAL
          ? ProfileTypeStepForm.WARMING_FLUID
          : ProfileTypeStepForm.COLD_WATER
      case ProfileTypeStepForm.WARMING_FLUID:
        return this.profileType.constructionYear === ConstructionYear.AFTER_1998
          ? ProfileTypeStepForm.VENTILATION
          : ProfileTypeStepForm.INDIVIDUAL_INSULATION_WORK
      case ProfileTypeStepForm.INDIVIDUAL_INSULATION_WORK:
        return ProfileTypeStepForm.VENTILATION
      case ProfileTypeStepForm.VENTILATION:
        return ProfileTypeStepForm.HEATER_REPLACEMENT
      case ProfileTypeStepForm.HEATER_REPLACEMENT:
        return this.profileType.housingType === HousingType.INDIVIDUAL_HOUSE
          ? ProfileTypeStepForm.HOT_WATER_FLUID
          : ProfileTypeStepForm.COLD_WATER
      case ProfileTypeStepForm.COLD_WATER:
        return ProfileTypeStepForm.HOT_WATER
      case ProfileTypeStepForm.HOT_WATER:
        return this.profileType.hotWater === IndividualOrCollective.INDIVIDUAL
          ? ProfileTypeStepForm.HOT_WATER_FLUID
          : ProfileTypeStepForm.COOKING_FLUID
      case ProfileTypeStepForm.HOT_WATER_FLUID:
        return ProfileTypeStepForm.HOT_WATER_EQUIPMENT
      case ProfileTypeStepForm.HOT_WATER_EQUIPMENT:
        return ProfileTypeStepForm.COOKING_FLUID
      case ProfileTypeStepForm.COOKING_FLUID:
        return ProfileTypeStepForm.EQUIPMENTS
      case ProfileTypeStepForm.EQUIPMENTS:
        return firstProfileType
          ? ProfileTypeStepForm.END
          : ProfileTypeStepForm.UPDATE_DATE
      case ProfileTypeStepForm.UPDATE_DATE:
        return ProfileTypeStepForm.END
      default:
        return ProfileTypeStepForm.HOUSING_TYPE
    }
  }

  /**
   * getPreviousFormStep
   * @param {ProfileTypeStepForm} step
   * @returns {ProfileTypeStepForm} previous step
   */
  public getPreviousFormStep(step: ProfileTypeStepForm): ProfileTypeStepForm {
    switch (step) {
      case ProfileTypeStepForm.AREA:
        return ProfileTypeStepForm.HOUSING_TYPE
      case ProfileTypeStepForm.OCCUPANTS_NUMBER:
        return ProfileTypeStepForm.AREA
      case ProfileTypeStepForm.CONSTRUCTION_YEAR:
        return ProfileTypeStepForm.OCCUPANTS_NUMBER
      case ProfileTypeStepForm.OUTSIDE_FACING_WALLS:
        return ProfileTypeStepForm.CONSTRUCTION_YEAR
      case ProfileTypeStepForm.FLOOR:
        return ProfileTypeStepForm.OUTSIDE_FACING_WALLS
      case ProfileTypeStepForm.HEATING:
        return ProfileTypeStepForm.FLOOR
      case ProfileTypeStepForm.WARMING_FLUID:
        return this.profileType.housingType === HousingType.INDIVIDUAL_HOUSE
          ? ProfileTypeStepForm.OUTSIDE_FACING_WALLS
          : ProfileTypeStepForm.HEATING
      case ProfileTypeStepForm.INDIVIDUAL_INSULATION_WORK:
        return ProfileTypeStepForm.WARMING_FLUID
      case ProfileTypeStepForm.VENTILATION:
        return this.profileType.constructionYear === ConstructionYear.AFTER_1998
          ? ProfileTypeStepForm.WARMING_FLUID
          : ProfileTypeStepForm.INDIVIDUAL_INSULATION_WORK
      case ProfileTypeStepForm.HEATER_REPLACEMENT:
        return ProfileTypeStepForm.VENTILATION
      case ProfileTypeStepForm.COLD_WATER:
        return this.profileType.heating === IndividualOrCollective.INDIVIDUAL
          ? ProfileTypeStepForm.HEATER_REPLACEMENT
          : ProfileTypeStepForm.HEATING
      case ProfileTypeStepForm.HOT_WATER:
        return ProfileTypeStepForm.COLD_WATER
      case ProfileTypeStepForm.HOT_WATER_FLUID:
        return this.profileType.housingType === HousingType.INDIVIDUAL_HOUSE
          ? ProfileTypeStepForm.HEATER_REPLACEMENT
          : ProfileTypeStepForm.HOT_WATER
      case ProfileTypeStepForm.HOT_WATER_EQUIPMENT:
        return ProfileTypeStepForm.HOT_WATER_FLUID
      case ProfileTypeStepForm.COOKING_FLUID:
        return this.profileType.hotWater === IndividualOrCollective.INDIVIDUAL
          ? ProfileTypeStepForm.HOT_WATER_EQUIPMENT
          : ProfileTypeStepForm.HOT_WATER
      case ProfileTypeStepForm.EQUIPMENTS:
        return ProfileTypeStepForm.COOKING_FLUID
      case ProfileTypeStepForm.UPDATE_DATE:
        return ProfileTypeStepForm.EQUIPMENTS
      default:
        return ProfileTypeStepForm.HOUSING_TYPE
    }
  }

  /**
   * getAnswerForStep
   * @param {ProfileTypeStepForm} step
   * @returns {ProfileTypeAnswer}
   */
  static getAnswerForStep(step: ProfileTypeStepForm): ProfileTypeAnswer {
    switch (step) {
      case ProfileTypeStepForm.HOUSING_TYPE:
        return {
          type: ProfileTypeFormType.SINGLE_CHOICE,
          attribute: 'housingType',
          choices: Object.values(HousingType),
        }
      case ProfileTypeStepForm.CONSTRUCTION_YEAR:
        return {
          type: ProfileTypeFormType.SINGLE_CHOICE,
          attribute: 'constructionYear',
          choices: Object.values(ConstructionYear),
        }
      case ProfileTypeStepForm.AREA:
        return {
          type: ProfileTypeFormType.NUMBER,
          attribute: 'area',
          choices: [],
        }
      case ProfileTypeStepForm.OCCUPANTS_NUMBER:
        return {
          type: ProfileTypeFormType.NUMBER_SELECTION,
          attribute: 'occupantsNumber',
          choices: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        }
      case ProfileTypeStepForm.OUTSIDE_FACING_WALLS:
        return {
          type: ProfileTypeFormType.SINGLE_CHOICE,
          attribute: 'outsideFacingWalls',
          choices: Object.values(OutsideFacingWalls),
        }
      case ProfileTypeStepForm.FLOOR:
        return {
          type: ProfileTypeFormType.SINGLE_CHOICE,
          attribute: 'floor',
          choices: Object.values(Floor),
        }
      case ProfileTypeStepForm.HEATING:
        return {
          type: ProfileTypeFormType.SINGLE_CHOICE,
          attribute: 'heating',
          choices: Object.values(IndividualOrCollective),
        }
      case ProfileTypeStepForm.WARMING_FLUID:
        return {
          type: ProfileTypeFormType.SINGLE_CHOICE,
          attribute: 'warmingFluid',
          choices: [
            WarmingType.ELECTRICITY,
            WarmingType.GAS,
            WarmingType.WOOD,
            WarmingType.FUEL,
            WarmingType.OTHER,
          ],
        }
      case ProfileTypeStepForm.INDIVIDUAL_INSULATION_WORK:
        return {
          type: ProfileTypeFormType.MULTI_CHOICE,
          attribute: 'individualInsulationWork',
          choices: Object.values(IndividualInsulationWork),
        }
      case ProfileTypeStepForm.VENTILATION:
        return {
          type: ProfileTypeFormType.SINGLE_CHOICE,
          attribute: 'hasInstalledVentilation',
          choices: Object.values(ThreeChoicesAnswer),
        }
      case ProfileTypeStepForm.HEATER_REPLACEMENT:
        return {
          type: ProfileTypeFormType.SINGLE_CHOICE,
          attribute: 'hasReplacedHeater',
          choices: Object.values(ThreeChoicesAnswer),
        }
      case ProfileTypeStepForm.COLD_WATER:
        return {
          type: ProfileTypeFormType.SINGLE_CHOICE,
          attribute: 'coldWater',
          choices: Object.values(IndividualOrCollective).reverse(),
        }
      case ProfileTypeStepForm.HOT_WATER:
        return {
          type: ProfileTypeFormType.SINGLE_CHOICE,
          attribute: 'hotWater',
          choices: Object.values(IndividualOrCollective),
        }
      case ProfileTypeStepForm.HOT_WATER_EQUIPMENT:
        return {
          type: ProfileTypeFormType.SINGLE_CHOICE,
          attribute: 'hotWaterEquipment',
          choices: Object.values(HotWaterEquipment),
        }
      case ProfileTypeStepForm.HOT_WATER_FLUID:
        return {
          type: ProfileTypeFormType.SINGLE_CHOICE,
          attribute: 'hotWaterFluid',
          choices: [
            HotWaterFluid.ELECTRICITY,
            HotWaterFluid.GAS,
            HotWaterFluid.OTHER,
          ],
        }
      case ProfileTypeStepForm.COOKING_FLUID:
        return {
          type: ProfileTypeFormType.SINGLE_CHOICE,
          attribute: 'cookingFluid',
          choices: [FluidType.ELECTRICITY, FluidType.GAS],
        }
      case ProfileTypeStepForm.EQUIPMENTS:
        return {
          type: ProfileTypeFormType.EQUIPMENT_SELECTION,
          attribute: 'equipments',
          choices: Object.keys(EquipmentType),
        }
      case ProfileTypeStepForm.UPDATE_DATE:
        return {
          type: ProfileTypeFormType.DATE_SELECTION,
          attribute: 'updateDate',
          choices: [],
        }
      default:
        return {
          type: ProfileTypeFormType.SINGLE_CHOICE,
          attribute: 'housingType',
          choices: [],
        }
    }
  }
}
