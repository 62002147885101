import Button from '@mui/material/Button';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { EcogestureStepForm, ProfileTypeStepForm, SgeStep } from 'enums';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './formNavigation.scss';

interface FormNavigationProps {
  step: ProfileTypeStepForm | EcogestureStepForm | SgeStep;
  handlePrevious: () => void;
  handleNext: () => void;
  disableNextButton: boolean;
  disablePrevButton?: boolean;
  isEcogesture?: boolean;
  isLastConnectStep?: boolean;
  isLoading?: boolean;
  navigationRef?: React.RefObject<HTMLDivElement>;
}

const FormNavigation = ({
  step,
  handlePrevious,
  handleNext,
  disableNextButton,
  disablePrevButton,
  isEcogesture,
  isLastConnectStep,
  isLoading,
  navigationRef,
}: FormNavigationProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handlePreviousClick = () => {
    handlePrevious();
  };
  const handleNextClick = () => {
    handleNext();
    // handle go back to connect for SGE
    if (isLastConnectStep) {
      navigate('/consumption/electricity');
    }
  };

  const getSecondButtonLabel = useCallback(() => {
    if (isLoading) {
      return t('profile_type.form.button_loading');
    } else if (
      isLastConnectStep ||
      step === ProfileTypeStepForm.UPDATE_DATE ||
      (step === EcogestureStepForm.EQUIPMENTS && isEcogesture)
    ) {
      return t('profile_type.form.button_end');
    } else {
      return `${t('profile_type.form.button_next')} >`;
    }
  }, [isEcogesture, isLastConnectStep, isLoading, step, t]);

  return (
    <div className="profile-navigation" ref={navigationRef}>
      <Button
        aria-label={t('profile_type.accessibility.button_previous')}
        onClick={handlePreviousClick}
        className={classNames(
          'profile-navigation-button',
          {
            ['disabled']: disablePrevButton,
          },
          'btn-profile-back',
          'text-16-bold'
        )}
        disabled={
          disablePrevButton || step === ProfileTypeStepForm.HOUSING_TYPE
        }
      >
        <span>{`< ${t('profile_type.form.button_previous')}`}</span>
      </Button>
      <Button
        aria-label={
          step === ProfileTypeStepForm.UPDATE_DATE ||
          (step === EcogestureStepForm.EQUIPMENTS && isEcogesture)
            ? t('profile_type.accessibility.button_end')
            : t('profile_type.accessibility.button_next')
        }
        onClick={handleNextClick}
        className={classNames(
          'profile-navigation-button',
          {
            ['disabled']: disableNextButton,
          },
          'btn-profile-next',
          'text-16-bold'
        )}
        disabled={disableNextButton}
      >
        <span>{getSecondButtonLabel()}</span>
      </Button>
    </div>
  );
};

export default FormNavigation;
