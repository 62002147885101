import { useEffect } from 'react';

// models
import { StellioUserProfile } from 'models/stellio.model';
// store
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { updateStellioUserProfile } from 'store/profileStellioUser/profileStellioUser.slice';

/**
 * This hook keeps the UserProfile entity updated with
 * The keycloak user infos
 */
const useUserInfosUpdater = () => {
  const dispatch = useAppDispatch();
  const userProfileEntity = useAppSelector((store) => store.profileStellioUser);
  const {
    email,
    family_name: familyName,
    given_name: givenName,
  } = useAppSelector((state) => state.profileKeycloakUser);

  const checkHasUserInfosChanged = (): boolean => {
    let hasUserInfosChanged = false;
    const userInfosEntity = userProfileEntity.userInfos.json;

    if (
      userInfosEntity.email !== email ||
      userInfosEntity.familyName !== familyName ||
      userInfosEntity.givenName !== givenName
    ) {
      hasUserInfosChanged = true;
    }

    return hasUserInfosChanged;
  };

  useEffect(() => {
    if (
      !userProfileEntity.userInfos.json.email ||
      !email ||
      !familyName ||
      !givenName
    ) {
      // Waiting for data to be filled in the store
      return;
    }

    if (checkHasUserInfosChanged()) {
      const attrsToUpdate: Partial<StellioUserProfile> = {
        userInfos: {
          type: 'JsonProperty',
          json: {
            email,
            familyName,
            givenName,
          },
        },
      };

      dispatch(updateStellioUserProfile(attrsToUpdate));
    }
  }, [userProfileEntity, email, familyName, givenName]);
};

export default useUserInfosUpdater;
