import { UserChallengeState } from 'enums';
import { UserChallenge } from 'models';
import React from 'react';
import ChallengeCardDone from '../ChallengeCardDone/ChallengeCardDone';
import ChallengeCardLast from '../ChallengeCardLast/ChallengeCardLast';
import ChallengeCardLocked from '../ChallengeCardLocked/ChallengeCardLocked';
import ChallengeCardOnGoing from '../ChallengeCardOnGoing/ChallengeCardOnGoing';
import ChallengeCardUnlocked from '../ChallengeCardUnlocked/ChallengeCardUnlocked';
import './challengeCard.scss';

interface ChallengeCardProps {
  challenge?: UserChallenge;
  cardWidth: number;
  cardHeight: number;
  isChallengeCardLast?: boolean;
}

const ChallengeCard = ({
  challenge,
  cardWidth,
  cardHeight,
  isChallengeCardLast = false,
}: ChallengeCardProps) => {
  const renderCard = (userChallenge: UserChallenge | undefined) => {
    if (!userChallenge || isChallengeCardLast) return <ChallengeCardLast />;

    switch (userChallenge.state) {
      case UserChallengeState.LOCKED:
        return <ChallengeCardLocked userChallenge={userChallenge} />;
      case UserChallengeState.UNLOCKED:
        return <ChallengeCardUnlocked userChallenge={userChallenge} />;
      case UserChallengeState.DONE:
        return <ChallengeCardDone userChallenge={userChallenge} />;
      case UserChallengeState.ONGOING:
      case UserChallengeState.DUEL:
        return <ChallengeCardOnGoing userChallenge={userChallenge} />;
      default:
        return <ChallengeCardLocked userChallenge={userChallenge} />;
    }
  };

  return (
    <div
      className={'slide inactive'}
      style={{
        minWidth: `${cardWidth}px`,
        maxWidth: `${cardWidth}px`,
        height: `${cardHeight}px`,
      }}
    >
      {renderCard(challenge)}
    </div>
  );
};

export default ChallengeCard;
