import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import React from 'react';
import './challengeNoFluidModal.scss';

interface ChallengeNoFluidModalProps {
  open: boolean;
  handleCloseClick: () => void;
}

const ChallengeNoFluidModal = ({
  open,
  handleCloseClick,
}: ChallengeNoFluidModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      onClose={(event, reason): void => {
        event && reason !== 'backdropClick' && handleCloseClick();
      }}
      aria-labelledby="accessibility-title"
      classes={{
        root: 'modal-root',
        paper: 'modal-paper',
      }}
    >
      <div id="accessibility-title">
        {t('challenge_no_fluid_modal.accessibility.window_title')}
      </div>
      <div className="noFluidModal">
        <div className="no-fluid-title">
          {t('challenge_no_fluid_modal.title')}
        </div>
        <div className="no-fluid-content">
          {t('challenge_no_fluid_modal.content')}
        </div>
        <Button
          aria-label={t(
            'challenge_no_fluid_modal.accessibility.button_validate'
          )}
          onClick={handleCloseClick}
          className="btn-secondary-negative text-16-normal"
        >
          <span>{t('challenge_no_fluid_modal.button_validate')}</span>
        </Button>
      </div>
    </Dialog>
  );
};

export default ChallengeNoFluidModal;
