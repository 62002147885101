import { Button } from '@mui/material';
import FormNavigation from 'components/CommonKit/FormNavigation/FormNavigation';
import FormProgress from 'components/CommonKit/FormProgress/FormProgress';
import 'components/ProfileType/profileTypeForm.scss';
import { useTranslation } from 'react-i18next';
import { ProfileTypeStepForm } from 'enums';
import { ProfileType, ProfileTypeAnswer, ProfileTypeValues } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';

interface ProfileTypeFormNumberSelectionProps {
  step: ProfileTypeStepForm;
  viewedStep: ProfileTypeStepForm;
  currentProfileType: ProfileType;
  answerType: ProfileTypeAnswer;
  setNextStep: (_profileType: ProfileType) => void;
  setPreviousStep: () => void;
}

const ProfileTypeFormNumberSelection = ({
  step,
  viewedStep,
  currentProfileType,
  answerType,
  setNextStep,
  setPreviousStep,
}: ProfileTypeFormNumberSelectionProps) => {
  const { t } = useTranslation();
  const { value: isProfileTypeCompleted } = useAppSelector(
    (state) => state.profileStellioUser.isProfileTypeCompleted
  );
  const [answer, setAnswer] = useState<ProfileTypeValues>('');
  const [index, setIndex] = useState<number>(0);

  const decrement = () => {
    setAnswer(answerType.choices[index - 1]);
    setIndex((prev) => prev - 1);
  };

  const increment = () => {
    setAnswer(answerType.choices[index + 1]);
    setIndex((prev) => prev + 1);
  };

  const handlePrevious = useCallback(() => {
    setPreviousStep();
  }, [setPreviousStep]);

  const handleNext = useCallback(() => {
    setNextStep({ ...currentProfileType, [answerType.attribute]: answer });
  }, [currentProfileType, setNextStep, answer, answerType.attribute]);

  useEffect(() => {
    if (step < viewedStep || isProfileTypeCompleted) {
      const foundIndex = answerType.choices.findIndex(
        (element) =>
          element && element === currentProfileType[answerType.attribute]
      );
      foundIndex > -1 && setIndex(foundIndex);
      setAnswer(currentProfileType[answerType.attribute]);
    } else {
      setAnswer(answerType.choices[0]);
    }
  }, [
    step,
    viewedStep,
    currentProfileType,
    answerType,
    isProfileTypeCompleted,
  ]);

  return (
    <>
      <div className="profile-form-container">
        <FormProgress step={step} formType="profile" />
        <div className="profile-question-label">
          {t(
            `profile_type.${ProfileTypeStepForm[step].toLowerCase()}.question`
          )}
        </div>
        {answer !== null ? (
          <div className="number-container">
            <Button
              className="btn-profile-number"
              onClick={() => decrement()}
              disabled={index < 1}
            >
              -
            </Button>
            <label className="number">
              <input
                type="text"
                value={answer.toString()}
                name={answerType.attribute}
                disabled={true}
              />
            </label>
            <Button
              className="btn-profile-number"
              onClick={() => increment()}
              disabled={index >= answerType.choices.length - 1}
            >
              +
            </Button>
          </div>
        ) : null}
      </div>
      <FormNavigation
        step={step}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        disableNextButton={answer === ''}
      />
    </>
  );
};

export default ProfileTypeFormNumberSelection;
