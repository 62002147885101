import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { StellioUserProfile } from 'models/stellio.model';
import { Profile, ProfileEntity } from 'models/profile.model';

// import { AppDispatch, AppState } from 'store/store';
import ProfileService from 'services/profile.service';

const initialState: Profile = {
  id: '',
  ecogestureHash: '',
  challengeHash: '',
  duelHash: '',
  quizHash: '',
  explorationHash: '',
  isFirstConnection: false,
  partnersIssueSeenDate: {
    enedis: DateTime.fromISO('0000-01-01T00:00:00.000Z', {
      zone: 'utc',
    }),
    egl: DateTime.fromISO('0000-01-01T00:00:00.000Z', {
      zone: 'utc',
    }),
    grdf: DateTime.fromISO('0000-01-01T00:00:00.000Z', {
      zone: 'utc',
    }),
  },
  lastConnectionDate: DateTime.fromISO('0000-01-01T00:00:00.000Z', {
    zone: 'utc',
  }),
  customPopupDate: DateTime.fromISO('0000-01-01T00:00:00.000Z', {
    zone: 'utc',
  }),
  haveSeenLastAnalysis: true,
  sendAnalysisNotification: true,
  sendConsumptionAlert: false,
  waterDailyConsumptionLimit: 0,
  mailToken: '',
  monthlyAnalysisDate: DateTime.fromISO('0000-01-01T00:00:00.000Z', {
    zone: 'utc',
  }),
  isProfileTypeCompleted: false,
  isProfileEcogestureCompleted: false,
  onboarding: {
    isWelcomeSeen: true,
  },
  haveSeenEcogestureModal: false,
  activateHalfHourDate: DateTime.fromISO('0000-01-01T00:00:00.000Z', {
    zone: 'utc',
  }),
};

const profileStateKeys = Object.keys(initialState);

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfileFromStellioUser: (
      state,
      action: PayloadAction<Partial<StellioUserProfile>>
    ) => {
      const profileToUpdate = { ...state };

      Object.keys(action.payload).forEach((key) => {
        if (key !== 'id' && key !== 'type' && profileStateKeys.includes(key)) {
          profileToUpdate[key] = action.payload[key].value;
        }
      });

      const profileService = new ProfileService();
      const profileWithDateTime = profileService.parseProfileEntityToProfile(
        profileToUpdate as unknown as ProfileEntity
      );

      Object.assign(state, profileWithDateTime);
    },
  },
});

export const { setProfileFromStellioUser } = profileSlice.actions;

/**
 * Thunk updating profile in couch DB.
 * If the profile has an update, dispatch it to the app
 */
// export const updateProfile = createAsyncThunk<
//   Profile | void,
//   Partial<Profile>,
//   { dispatch: AppDispatch; state: AppState; extra: { client: any } }
// >('profile/updateProfile', async (profileUpdates, thunkAPI) => {
// const client = thunkAPI.extra.client;
// const profileService = new ProfileService(client)
// const updatedProfile = await profileService.updateProfile(profileUpdates)
// if (updatedProfile) {
//   return updatedProfile
// }
// });
