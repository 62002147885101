import ActionCard from 'components/Action/ActionCard/ActionCard';
import { Ecogesture } from 'models';
import React, { useEffect, useMemo, useState } from 'react';
import ActionService from 'services/action.service';
import { useAppSelector } from 'store/hooks';
import './actionList.scss';
import AppAxiosService from 'services/appAxios.service';

interface ActionListProps {
  setSelectedAction: React.Dispatch<React.SetStateAction<Ecogesture | null>>;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
}

const ActionList = ({ setSelectedAction, setShowList }: ActionListProps) => {
  const { fluidTypes } = useAppSelector((state) => state.global);
  const { isProfileTypeCompleted } = useAppSelector((state) => state.profile);
  const { userChallengeProgress, ecogesturesList, explorationsList } =
    useAppSelector((state) => state.challenge);
  const userSub = useAppSelector((store) => store.profileKeycloakUser.sub);
  const [actions, setActions] = useState<Ecogesture[]>();
  const client = useMemo(() => new AppAxiosService(), []);

  useEffect(() => {
    let subscribed = true;
    const getActions = async () => {
      if (!userChallengeProgress) return;
      if (!userSub) return;

      const actionService = new ActionService(
        client,
        userSub,
        userChallengeProgress,
        ecogesturesList,
        explorationsList
      );

      let actionList: Ecogesture[] = [];
      if (isProfileTypeCompleted) {
        actionList = await actionService.getCustomActions(fluidTypes);
      } else {
        actionList = await actionService.getDefaultActions();
      }
      if (subscribed) {
        setActions(actionList);
      }
    };
    getActions();
    return () => {
      subscribed = false;
    };
  }, [
    isProfileTypeCompleted,
    fluidTypes,
    userChallengeProgress,
    client,
    ecogesturesList,
    explorationsList,
    userSub,
  ]);

  return (
    <>
      {actions && (
        <div className="action-list-container">
          {actions.map((action) => {
            return (
              <ActionCard
                key={action.id}
                action={action}
                setSelectedAction={setSelectedAction}
                setShowList={setShowList}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default ActionList;
