import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import challengeWon from 'assets/icons/visu/duelResult/challengeWon.svg';
import defaultIcon from 'assets/icons/visu/duelResult/default.svg';
import { useTranslation } from 'react-i18next';

import { UserChallenge } from 'models';
import React, { useEffect, useState } from 'react';
import { formatNumberValues, importIconById } from 'utils/utils';
import './duelResultModal.scss';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import { getChallengeIconId } from 'utils/ecoGagnant';

interface DuelResultModalProps {
  userChallenge: UserChallenge;
  open: boolean;
  win: boolean;
  handleCloseClick: () => void;
}

const DuelResultModal = ({
  open,
  userChallenge,
  win,
  handleCloseClick,
}: DuelResultModalProps) => {
  const { t } = useTranslation();
  const [winIcon, setWinIcon] = useState<string>(defaultIcon);
  const [lossIcon, setLossIcon] = useState<string>(defaultIcon);
  const result = formatNumberValues(
    Math.abs(userChallenge.duel.threshold - userChallenge.duel.userConsumption)
  );

  const statusKey = win ? 'success' : 'lost';

  useEffect(() => {
    async function handleEcogestureIcon() {
      const icon = await importIconById(
        getChallengeIconId(userChallenge) + '-1',
        'duelResult'
      );
      setWinIcon(icon || defaultIcon);
      const icon2 = await importIconById(
        getChallengeIconId(userChallenge) + '-0',
        'duelResult'
      );
      setLossIcon(icon2 || defaultIcon);
    }
    handleEcogestureIcon();
  }, [userChallenge]);

  return (
    <Dialog
      open={open}
      onClose={handleCloseClick}
      aria-labelledby="accessibility-title"
      classes={{
        root: 'modal-root',
        paper: 'modal-paper blue-border',
      }}
    >
      <div id="accessibility-title">
        {t('duel_result_modal.accessibility.window_title')}
      </div>
      <div className="duel-result-modal-root ">
        <div className="imgResultContainer">
          {win && (
            <StyledIcon
              className="challengeWon"
              icon={challengeWon}
              size={300}
            />
          )}
          <StyledIcon
            className="imgResult"
            icon={win ? winIcon : lossIcon}
            size={180}
          />
        </div>
        <div className="text-28-normal-uppercase title">
          {t(`duel_result_modal.${statusKey}.title`)}
        </div>
        <div className="text-18-bold">
          {t(`duel_result_modal.${statusKey}.message1`, { value: result })}
        </div>
        <div className="text-18-bold">
          {t(`duel_result_modal.${statusKey}.message2`, {
            title: userChallenge.title,
          })}
        </div>
        <Button
          aria-label={t('duel_result_modal.accessibility.button_validate')}
          className="buttonCloseModal text-16-normal btn-secondary-negative"
          onClick={handleCloseClick}
        >
          <span>{t(`duel_result_modal.${statusKey}.button_validate`)}</span>
        </Button>
      </div>
    </Dialog>
  );
};

export default DuelResultModal;
