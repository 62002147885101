import { Button } from '@mui/material';
import defaultIcon from 'assets/icons/visu/duelResult/default.svg';
import classNames from 'classnames';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';

import {
  UserChallengeState,
  UserChallengeSuccess,
  UserChallengeUpdateFlag,
} from 'enums';
import { UserChallenge } from 'models';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  unlockNextUserChallenge,
  updateUserChallengeList,
} from 'store/challenge/challenge.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  formatNumberValues,
  getChallengeTitleWithLineReturn,
  importIconById,
} from 'utils/utils';
import './challengeCardDone.scss';
import { useTranslation } from 'react-i18next';
import AppAxiosService from 'services/appAxios.service';
import ChallengeService from 'services/challenge.service';
import { getChallengeIconId } from 'utils/ecoGagnant';

const ChallengeCardDone = ({
  userChallenge,
}: {
  userChallenge: UserChallenge;
}) => {
  const { t } = useTranslation();
  const {
    userChallengeProgress,
    ecogesturesList,
    userChallengeList,
    currentChallenge,
    explorationsList,
  } = useAppSelector((store) => store.challenge);
  const userSub = useAppSelector((store) => store.profileKeycloakUser.sub);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [winIcon, setWinIcon] = useState<string>(defaultIcon);
  const [lossIcon, setLossIcon] = useState<string>(defaultIcon);

  const getUserSaving = (_userChallenge: UserChallenge) => {
    let label;
    if (_userChallenge.success === UserChallengeSuccess.WIN)
      label = t('challenge.card_done.saving');
    else label = t('challenge.card_done.depense');

    return label + ' ';
  };

  const getResultLabel = (_userChallenge: UserChallenge) => {
    switch (_userChallenge.success) {
      case UserChallengeSuccess.WIN:
        return t('challenge.card_done.win');
      case UserChallengeSuccess.LOST:
      default:
        return t('challenge.card_done.lost');
    }
  };

  const getIcon = (_userChallenge: UserChallenge) => {
    if (_userChallenge.success == UserChallengeSuccess.WIN) return winIcon;
    else return lossIcon;
  };

  const goDuel = async () => {
    navigate('/challenges/duel?id=' + userChallenge.id);
  };

  const handleChallengeReset = async () => {
    if (!userChallengeProgress) return;
    if (!userSub) return;

    const clientAxios = new AppAxiosService();
    const challengeService = new ChallengeService(
      clientAxios,
      userSub,
      userChallengeProgress,
      ecogesturesList,
      explorationsList
    );

    const updatedChallenge = await challengeService.updateUserChallenge(
      userChallenge,
      UserChallengeUpdateFlag.DUEL_RESET
    );

    dispatch(updateUserChallengeList(updatedChallenge));
  };

  useEffect(() => {
    async function handleEcogestureIcon() {
      const icon = await importIconById(
        getChallengeIconId(userChallenge) + '-1',
        'duelResult'
      );
      setWinIcon(icon || defaultIcon);
      const icon2 = await importIconById(
        getChallengeIconId(userChallenge) + '-0',
        'duelResult'
      );
      setLossIcon(icon2 || defaultIcon);
    }
    handleEcogestureIcon();
  }, [userChallenge]);

  // Avoid user getting stuck with only finished and/or locked challenges
  // If current challenge is done and next one is locked
  useEffect(() => {
    if (!currentChallenge && userChallenge.state === UserChallengeState.DONE) {
      const currentChallengeIndex = userChallengeList.findIndex(
        (challenge) => challenge.id === userChallenge.id
      );

      const nextChallenge = userChallengeList[currentChallengeIndex + 1];

      if (nextChallenge && nextChallenge.state === UserChallengeState.LOCKED) {
        dispatch(unlockNextUserChallenge(userChallenge));
      }
    }
  }, []);

  return (
    <div className="cardContent cardDone">
      <div className="challengeName text-22-bold">
        {getChallengeTitleWithLineReturn(userChallenge.id)}
      </div>
      <div className="iconResult">
        <StyledIcon
          className="imgResult"
          icon={getIcon(userChallenge)}
          size={180}
        />
      </div>
      <div className="statsResult">
        <div
          className={classNames('labelResult', {
            ['win']: userChallenge.success === UserChallengeSuccess.WIN,
            ['lost']: userChallenge.success === UserChallengeSuccess.LOST,
          })}
        >
          {getResultLabel(userChallenge)}
        </div>
        <span className="text-18">
          {getUserSaving(userChallenge)}
          <span className="text-18-bold">
            {formatNumberValues(
              Math.abs(
                userChallenge.duel.threshold -
                  userChallenge.duel.userConsumption
              )
            ) + ' '}
            €
          </span>
          <br />
          {t('challenge.card_done.final_defi')}
        </span>
      </div>
      <div className="buttons">
        <Button
          aria-label={t('challenge.card_done.final_defi_view')}
          onClick={goDuel}
          className="text-15-bold btn-secondary-negative grey-border"
        >
          <span>{t('challenge.card_done.final_defi_view')}</span>
        </Button>
        <Button
          aria-label={t('challenge.card_done.reset_defi')}
          onClick={handleChallengeReset}
          className={`text-15-bold ${
            userChallenge.success === UserChallengeSuccess.WIN
              ? 'btn-secondary-negative grey-border'
              : 'btn-primary-challenge'
          }`}
          disabled={currentChallenge !== null}
        >
          <span>{t('challenge.card_done.reset_defi')}</span>
        </Button>
      </div>
    </div>
  );
};

export default ChallengeCardDone;
