import Button from '@mui/material/Button';
import explorationIcon from 'assets/icons/visu/exploration/shield.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import StarsContainer from 'components/CommonKit/StarsContainer/StarsContainer';
import { useTranslation } from 'react-i18next';
import {
  UsageEventType,
  UserChallengeUpdateFlag,
  UserExplorationState,
  UserExplorationType,
} from 'enums';
import { UserChallenge } from 'models';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ChallengeService from 'services/challenge.service';
// import UsageEventService from 'services/usageEvent.service'
import { updateUserChallengeList } from 'store/challenge/challenge.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import './explorationOngoing.scss';
import AppAxiosService from 'services/appAxios.service';

interface ExplorationOngoingProps {
  userChallenge: UserChallenge;
}

const ExplorationOngoing = ({ userChallenge }: ExplorationOngoingProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userChallengeProgress, ecogesturesList, explorationsList } =
    useAppSelector((store) => store.challenge);
  const userSub = useAppSelector((store) => store.profileKeycloakUser.sub);
  const client = useMemo(() => new AppAxiosService(), []);

  const startExploration = async () => {
    if (!userSub) return;

    if (
      userChallenge.exploration.state !== UserExplorationState.ONGOING &&
      userChallengeProgress
    ) {
      const challengeService = new ChallengeService(
        client,
        userSub,
        userChallengeProgress,
        ecogesturesList,
        explorationsList
      );
      const updatedChallenge = await challengeService.updateUserChallenge(
        userChallenge,
        UserChallengeUpdateFlag.EXPLORATION_START
      );
      dispatch(updateUserChallengeList(updatedChallenge));
    }
    navigate('/challenges');
  };

  const validExploration = async () => {
    if (!userChallengeProgress) return;
    if (!userSub) return;
    const challengeService = new ChallengeService(
      client,
      userSub,
      userChallengeProgress,
      ecogesturesList,
      explorationsList
    );
    const updatedChallenge = await challengeService.updateUserChallenge(
      userChallenge,
      UserChallengeUpdateFlag.EXPLORATION_DONE
    );

    // await UsageEventService.addEvent(client, {
    //   type: UsageEventType.EXPLORATION_END_EVENT,
    //   target: userChallenge.exploration.id,
    //   context: userChallenge.id,
    //   startDate: userChallenge.exploration.date,
    // })

    dispatch(updateUserChallengeList(updatedChallenge));
    navigate('/challenges');
  };

  const renderButton = () => {
    switch (userChallenge.exploration.type) {
      case UserExplorationType.DECLARATIVE:
        return (
          <div className="button-start">
            <Button
              variant="outlined"
              aria-label={t('exploration.accessibility.button_already_done')}
              onClick={validExploration}
            >
              {t('exploration.button_already_done')}
            </Button>
            <Button
              variant="outlined"
              aria-label={t('exploration.accessibility.button_already_done')}
              onClick={() => navigate(-1)}
            >
              {t('exploration.button_come_back')}
            </Button>
          </div>
        );
      case UserExplorationType.ACTION:
      case UserExplorationType.ECOGESTURE:
      case UserExplorationType.CONSUMPTION:
        return (
          <div className="button-start">
            <Button
              variant="outlined"
              aria-label={t('exploration.accessibility.button_start')}
              onClick={startExploration}
            >
              {t('exploration.button_start')}
            </Button>
          </div>
        );
    }
  };

  return (
    <>
      <div className="exploration-container">
        <div className="exploration-begin-container">
          <StyledIcon
            className="exploration-icon"
            icon={explorationIcon}
            size={180}
          />
          <StarsContainer
            result={userChallenge.progress.explorationProgress}
            isQuizBegin={true}
          />
          <div className="exploration-explanation text-18-bold">
            <div>{userChallenge.exploration.description}</div>
            <div>{userChallenge.exploration.complementary_description}</div>
          </div>
          {renderButton()}
        </div>
      </div>
    </>
  );
};

export default ExplorationOngoing;
