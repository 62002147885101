import FeedbackModal from 'components/Feedback/FeedbackModal';
import { ScreenType } from 'enums';
import React, { useEffect, useState } from 'react';
import { changeScreenType } from 'store/global/global.slice';
import { useAppDispatch } from 'store/hooks';
import './content.scss';

interface ContentProps {
  children: React.ReactNode;
  passContentHeight?: (height: number) => void;
  heightOffset?: number;
  withOverFlow?: boolean;
}

const Content = ({
  children,
  passContentHeight,
  heightOffset = 0,
  withOverFlow,
}: ContentProps) => {
  const dispatch = useAppDispatch();
  const [screenHeight, setScreenHeight] = useState(0);

  // Set listeners for scroll
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        dispatch(changeScreenType(ScreenType.MOBILE));
      } else if (window.innerWidth <= 1024) {
        dispatch(changeScreenType(ScreenType.TABLET));
      } else {
        dispatch(changeScreenType(ScreenType.DESKTOP));
      }

      setScreenHeight(window.innerHeight);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);

  const bottomNavHeight = 65;
  const contentHeight = screenHeight - bottomNavHeight - heightOffset;
  if (passContentHeight) passContentHeight(contentHeight);

  return (
    <>
      <FeedbackModal />
      <div
        className="content-view"
        style={{
          top: heightOffset,
          overflowY: withOverFlow ? 'auto' : 'inherit',
          height: contentHeight,
        }}
      >
        {children}
      </div>
    </>
  );
};

export default Content;
