import {
  EcogestureStepForm,
  EquipmentType,
  IndividualOrCollective,
  ProfileEcogestureAnswerType,
  WarmingType,
} from 'enums'
import {
  ProfileEcogesture,
  ProfileEcogestureAnswer,
  ProfileEcogestureValues,
} from 'models'

export default class ProfileEcogestureFormService {
  private readonly profileEcogesture: ProfileEcogesture

  constructor(profileEcogesture: ProfileEcogesture) {
    this.profileEcogesture = profileEcogesture
  }

  /**
   * getNextFormStep
   * @param {EcogestureStepForm} step
   * @returns {EcogestureStepForm} next step
   */
  public getNextFormStep(step: EcogestureStepForm): EcogestureStepForm {
    switch (step) {
      case EcogestureStepForm.HEATING_TYPE:
        return this.profileEcogesture.heating ===
          IndividualOrCollective.INDIVIDUAL
          ? EcogestureStepForm.WARMING_FLUID
          : EcogestureStepForm.HOT_WATER
      case EcogestureStepForm.WARMING_FLUID:
        return EcogestureStepForm.HOT_WATER
      case EcogestureStepForm.HOT_WATER:
        return EcogestureStepForm.EQUIPMENTS
      case EcogestureStepForm.EQUIPMENTS:
        return EcogestureStepForm.END
      default:
        return EcogestureStepForm.HEATING_TYPE
    }
  }

  /**
   * getPreviousFormStep
   * @param {EcogestureStepForm} step
   * @returns {EcogestureStepForm} previous step
   */
  public getPreviousFormStep(step: EcogestureStepForm): EcogestureStepForm {
    switch (step) {
      case EcogestureStepForm.EQUIPMENTS:
        return EcogestureStepForm.HOT_WATER
      case EcogestureStepForm.HOT_WATER:
        return this.profileEcogesture.heating ===
          IndividualOrCollective.INDIVIDUAL
          ? EcogestureStepForm.WARMING_FLUID
          : EcogestureStepForm.HEATING_TYPE
      case EcogestureStepForm.WARMING_FLUID:
        return EcogestureStepForm.HEATING_TYPE
      default:
        return EcogestureStepForm.HEATING_TYPE
    }
  }

  /**
   * getAnswerForStep
   * @param {EcogestureStepForm} step
   * @returns {EcogestureFormAnswer}
   */
  static getAnswerForStep(step: EcogestureStepForm): ProfileEcogestureAnswer {
    switch (step) {
      case EcogestureStepForm.WARMING_FLUID:
        return {
          type: ProfileEcogestureAnswerType.SINGLE_CHOICE,
          attribute: 'warmingFluid',
          choices: [
            WarmingType.ELECTRICITY,
            WarmingType.GAS,
            WarmingType.WOOD,
            WarmingType.FUEL,
            WarmingType.OTHER,
          ],
        }
      case EcogestureStepForm.HOT_WATER:
        return {
          type: ProfileEcogestureAnswerType.SINGLE_CHOICE,
          attribute: 'hotWater',
          choices: Object.values(IndividualOrCollective),
        }
      case EcogestureStepForm.EQUIPMENTS:
        return {
          type: ProfileEcogestureAnswerType.MULTI_CHOICE,
          attribute: 'equipments',
          choices: Object.keys(EquipmentType) as ProfileEcogestureValues[],
        }
      case EcogestureStepForm.HEATING_TYPE:
      default:
        return {
          type: ProfileEcogestureAnswerType.SINGLE_CHOICE,
          attribute: 'heating',
          choices: Object.values(IndividualOrCollective),
        }
    }
  }
}
