import _isObject from 'lodash/isObject';
import _transform from 'lodash/transform';
import _isArray from 'lodash/isArray';

import {
  StellioChallenge,
  StellioEcogesture,
  StellioEntityType,
} from 'models/stellio.model';
import { Ecogesture, UserChallenge } from 'models';

export const getChallengeIconId = (
  challenge: StellioChallenge | UserChallenge
): string => {
  return challenge.id.split(`urn:ngsi-ld:${StellioEntityType.CHALLENGE}:`)[1];
};

export const getEcogestureIconId = (
  ecogesture: Ecogesture | StellioEcogesture
): string => {
  return ecogesture.id.split(`urn:ngsi-ld:${StellioEntityType.ECOGESTURE}:`)[1];
};

// https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
const uuidv4 = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getUniqueNgsildId = (entityType: StellioEntityType): string => {
  return `urn:ngsi-ld:${entityType}:${uuidv4()}`;
};

export const getAlwaysArray = <T>(object: T | T[]): T[] => {
  if (Array.isArray(object)) {
    return object;
  } else {
    return [object];
  }
};
