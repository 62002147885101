import { MenuItem, Select } from '@mui/material';
import FormNavigation from 'components/CommonKit/FormNavigation/FormNavigation';
import FormProgress from 'components/CommonKit/FormProgress/FormProgress';
import 'components/ProfileType/profileTypeForm.scss';
import { useTranslation } from 'react-i18next';
import { ProfileTypeStepForm } from 'enums';
import { DateTime } from 'luxon';
import { ProfileType, ProfileTypeAnswer, ProfileTypeValues } from 'models';
import React, { useCallback, useState } from 'react';
import { getMonthFullName } from 'utils/utils';

interface ProfileTypeFormDateSelectionProps {
  step: ProfileTypeStepForm;
  profileType: ProfileType;
  answerType: ProfileTypeAnswer;
  setNextStep: (_profileType: ProfileType) => void;
  setPreviousStep: () => void;
}

interface SelectionMonth {
  label?: string;
  value: string;
}

const ProfileTypeFormDateSelection = ({
  step,
  profileType,
  answerType,
  setNextStep,
  setPreviousStep,
}: ProfileTypeFormDateSelectionProps) => {
  const { t } = useTranslation();
  const [selectedYear, setSelectedYear] = useState<number>(DateTime.now().year);
  const [selectedMonth, setSelectedMonth] = useState<SelectionMonth>({
    label: DateTime.now().toLocaleString({ month: 'long' }),
    value: DateTime.now().month.toString().padStart(2, '0'), // Date.getMonth starts at 0
  });
  const buildISODate = (year: string, month: string) =>
    DateTime.fromISO(`${year}-${month}-01`);

  const [answer, setAnswer] = useState<ProfileTypeValues>(
    buildISODate(selectedYear.toString(), selectedMonth.value).toISO()
  );

  const selectMonths: SelectionMonth[] = [
    {
      label: 'Janvier',
      value: '01',
    },
    {
      label: 'Février',
      value: '02',
    },
    {
      label: 'Mars',
      value: '03',
    },
    {
      label: 'Avril',
      value: '04',
    },
    {
      label: 'Mai',
      value: '05',
    },
    {
      label: 'Juin',
      value: '06',
    },
    {
      label: 'Juillet',
      value: '07',
    },
    {
      label: 'Août',
      value: '08',
    },
    {
      label: 'Septembre',
      value: '09',
    },
    {
      label: 'Octobre',
      value: '10',
    },
    {
      label: 'Novembre',
      value: '11',
    },
    {
      label: 'Décembre',
      value: '12',
    },
  ];

  const selectYears = [];
  const curYear = DateTime.now().year;
  const limitYears = curYear - 5;

  for (let i = curYear; i >= limitYears; i--) {
    // @ts-ignore
    selectYears.push(i);
  }

  const handlePrevious = useCallback(() => {
    setPreviousStep();
  }, [setPreviousStep]);

  const handleNext = () => {
    setNextStep({ ...profileType, [answerType.attribute]: answer });
  };

  function handleSelectMonth(event: any) {
    setSelectedMonth({
      value: event.target.value,
      label: getMonthFullName(parseInt(event.target.value)),
    });
    setAnswer(
      buildISODate(selectedYear.toString(), event.target.value).toISO()
    );
  }

  function handleSelectYear(event: any) {
    setSelectedYear(parseInt(event.target.value));
    setAnswer(buildISODate(event.target.value, selectedMonth.value).toISO());
  }

  /** If current year, only show past and present months else show full months */
  const renderMonths =
    selectedYear === DateTime.now().year
      ? selectMonths.slice(0, DateTime.now().month)
      : selectMonths;

  return (
    <>
      <div className="profile-form-container">
        <FormProgress step={step} formType="profile" />
        <div className="profile-question-label">
          {t(
            `profile_type.${ProfileTypeStepForm[step].toLowerCase()}.question`
          )}
        </div>
        {answer !== null && (
          <div className="select-container">
            <div className="date-select">
              <Select
                labelId="selectYearDate"
                className="year"
                defaultValue={selectedYear}
                value={selectedYear}
                onChange={(e) => handleSelectYear(e)}
              >
                {selectYears.map((year) => (
                  <MenuItem value={year} key={year} className="date-option">
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="date-select">
              <Select
                labelId="selectMonthDate"
                className="month"
                defaultValue={selectedMonth.value}
                value={selectedMonth.value}
                onChange={(e) => handleSelectMonth(e)}
              >
                {renderMonths.map((month) => (
                  <MenuItem
                    value={month.value}
                    key={month.value}
                    className="date-option"
                  >
                    {month.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        )}
      </div>
      <FormNavigation
        step={step}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        disableNextButton={answer === ''}
      />
    </>
  );
};

export default ProfileTypeFormDateSelection;
