import { Button, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from 'assets/icons/ico/close.svg';
import { useTranslation } from 'react-i18next';
import React from 'react';
import './ecogestureLaunchFormModal.scss';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';

interface EcogestureLaunchFormModalProps {
  open: boolean;
  handleCloseClick: () => void;
}
const EcogestureLaunchFormModal = ({
  open,
  handleCloseClick,
}: EcogestureLaunchFormModalProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleCloseClick}
      aria-labelledby="accessibility-title"
      classes={{
        root: 'modal-root',
        paper: 'modal-paper',
      }}
    >
      <div id="accessibility-title">
        {t('feedback.accessibility.window_title')}
      </div>
      <IconButton
        aria-label={t('feedback.accessibility.button_close')}
        className="modal-paper-close-button"
        onClick={handleCloseClick}
      >
        <StyledIcon icon={CloseIcon} width={16} />
      </IconButton>
      <div className="eg-init-modal">
        <div className="title text-20-bold">
          {t('ecogesture.initModal.title')}
        </div>
        <div className="text-16-normal text">
          {t('ecogesture.initModal.text3')}
        </div>
        <Button
          aria-label={t('ecogesture.initModal.btn2')}
          onClick={handleCloseClick}
          className="btn-profile-next rounded text-16-bold"
        >
          <span>{t('ecogesture.initModal.btn2')}</span>
        </Button>
      </div>
    </Dialog>
  );
};

export default EcogestureLaunchFormModal;
