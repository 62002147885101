import { Button } from '@mui/material';
import doingIcon from 'assets/icons/ico/doing-enabled.svg';
import objectiveIcon from 'assets/icons/ico/objective-enabled.svg';
import skipIcon from 'assets/icons/ico/skip-enabled.svg';
import defaultIcon from 'assets/icons/visu/ecogesture/default.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import { useTranslation } from 'react-i18next';
import { Ecogesture } from 'models';
import React, { useEffect, useRef, useState } from 'react';
import { importIconById } from 'utils/utils';
import './ecogestureSelectionDetail.scss';
import { getEcogestureIconId } from 'utils/ecoGagnant';

interface EcogestureSelectionDetailProps {
  ecogesture: Ecogesture;
  validate: (objective: boolean, doing: boolean) => void;
  title: string;
}

const EcogestureSelectionDetail = ({
  ecogesture,
  validate,
  title,
}: EcogestureSelectionDetailProps) => {
  const { t } = useTranslation();
  const [ecogestureIcon, setEcogestureIcon] = useState<string>('');
  const [showDetails, setShowDetails] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let subscribed = true;
    async function getIcon() {
      const icon = await importIconById(
        getEcogestureIconId(ecogesture),
        'ecogesture'
      );
      if (subscribed) {
        setEcogestureIcon(icon || defaultIcon);
      }
    }
    getIcon();
    setShowDetails(false);
    return () => {
      subscribed = false;
    };
  }, [ecogesture.id]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!showDetails) return;
      containerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }, 200);

    return () => clearTimeout(timeout);
  }, [showDetails]);

  return (
    <div className="eg-selection-detail-container">
      <div className="content">
        <div className="iconContainer">
          <StyledIcon className="icon" icon={ecogestureIcon} width={240} />
        </div>
        <div className="text-22 title">{title}</div>
        <div className="text text-18-bold">{ecogesture.longName}</div>

        <div
          className="showMore text-15-normal"
          onClick={() => setShowDetails((prev) => !prev)}
          role="button"
        >
          {t(`ecogesture_modal.show_${showDetails ? 'less' : 'more'}`)}
        </div>

        {showDetails && (
          <div
            className="longDescription text-16-normal-150"
            ref={containerRef}
          >
            {ecogesture.longDescription}
          </div>
        )}
      </div>
      <div className="buttons">
        <Button
          aria-label={t('ecogesture_selection.button_objective')}
          className="btn-secondary-negative text-14-bold column-flex"
          onClick={() => validate(true, false)}
        >
          <StyledIcon icon={objectiveIcon} width={60} />
          <span>{t('ecogesture_selection.button_objective')}</span>
        </Button>
        <Button
          aria-label={t('ecogesture_selection.button_doing')}
          focusVisibleClassName="noFocus"
          className="btn-secondary-negative text-14-bold column-flex"
          onClick={() => validate(false, true)}
        >
          <StyledIcon icon={doingIcon} width={60} />
          <span>{t('ecogesture_selection.button_doing')}</span>
        </Button>
        <Button
          aria-label={t('ecogesture_selection.button_skip')}
          focusVisibleClassName="noFocus"
          className="btn-secondary-negative text-14-bold column-flex"
          onClick={() => validate(false, false)}
        >
          <StyledIcon icon={skipIcon} width={60} />
          <span>{t('ecogesture_selection.button_skip')}</span>
        </Button>
      </div>
    </div>
  );
};

export default EcogestureSelectionDetail;
