import Button from '@mui/material/Button';
import quizIcon from 'assets/icons/visu/quiz/questionMark.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import StarsContainer from 'components/CommonKit/StarsContainer/StarsContainer';
import { useTranslation } from 'react-i18next';
import { UserChallenge } from 'models';
import React from 'react';
import ChallengeService from 'services/challenge.service';
import {
  setCurrentUserChallenge,
  updateUserChallengeList,
} from 'store/challenge/challenge.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import './quizBegin.scss';
import AppAxiosService from 'services/appAxios.service';
import { UserChallengeUpdateFlag } from 'enums';

const QuizBegin = ({ userChallenge }: { userChallenge: UserChallenge }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userChallengeProgress, ecogesturesList, explorationsList } =
    useAppSelector((store) => store.challenge);
  const userSub = useAppSelector((store) => store.profileKeycloakUser.sub);

  const launchQuiz = async () => {
    if (!userChallengeProgress) return;
    if (!userSub) return;

    const axiosClient = new AppAxiosService();
    const challengeService: ChallengeService = new ChallengeService(
      axiosClient,
      userSub,
      userChallengeProgress,
      ecogesturesList,
      explorationsList
    );
    const userChallengeUpdated: UserChallenge =
      await challengeService.updateUserChallenge(
        userChallenge,
        UserChallengeUpdateFlag.QUIZ_START
      );

    dispatch(updateUserChallengeList(userChallengeUpdated));
    dispatch(setCurrentUserChallenge(userChallengeUpdated));
  };

  return (
    <div className="quiz-container">
      <div className="quiz-begin-container">
        <StyledIcon className="quiz-icon" icon={quizIcon} width={180} />
        <StarsContainer
          result={userChallenge.progress.quizProgress}
          isQuizBegin={true}
        />

        <div className="quiz-explanation text-18-bold">
          {t('quiz.explanation')}
        </div>
        <div className="button-start">
          <Button
            aria-label={t('duel.accessibility.button_start_quiz')}
            onClick={launchQuiz}
            className="btn-secondary-negative text-16-normal"
          >
            <span>{t('duel.button_start')}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QuizBegin;
