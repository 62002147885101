import { FluidType } from 'enums';
import React from 'react';
import './Loader.scss';
import { useTranslation } from 'react-i18next';
interface LoaderProps {
  color?: 'gold' | 'gaz' | 'elec' | 'water' | 'black';
  fluidType?: FluidType;
  text?: string;
}

/**
 * Loader of Ecolyo, default color is gold
 * @param color {'gold' | 'gaz' | 'elec' | 'water'} Default is gold
 * @param text Optional, text to be placed under the loader
 */
const Loader = ({ color = 'gold', fluidType, text }: LoaderProps) => {
  const { t } = useTranslation();
  let variant = color;

  switch (fluidType) {
    case FluidType.ELECTRICITY:
      variant = 'elec';
      break;
    case FluidType.GAS:
      variant = 'gaz';
      break;
    case FluidType.WATER:
      variant = 'water';
      break;
  }

  return (
    <div className={`loader ${variant}`}>
      <div
        className={`bars `}
        aria-busy="true"
        aria-label={t('common.accessibility.loading')}
        title={t('common.accessibility.loading')}
      >
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
      </div>
      {text}
    </div>
  );
};

export default Loader;
