import { Button } from '@mui/material';
import icon from 'assets/icons/visu/ecogesture/ECOGESTURE0001.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ecogestureSelectionRestart.scss';

interface EcogestureSelectionRestartProps {
  listLength: number;
  restart: () => void;
}

const EcogestureSelectionRestart = ({
  listLength,
  restart,
}: EcogestureSelectionRestartProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="eg-selection-restart-container">
      <div className="content">
        <div className="title text-21-bold">
          {t('ecogesture_selection.title', {
            smartCount: listLength,
          })}
        </div>
        <StyledIcon icon={icon} width={120} />
        <div className="text text-16-normal">
          {t('ecogesture_selection.text')}
        </div>
      </div>
      <div className="buttons">
        <Button
          aria-label={t(
            'ecogesture_selection.accessibility.button_go_to_ecogesture'
          )}
          className="btn-secondary-negative text-16-normal"
          onClick={() => navigate('/ecogestures?tab=0')}
        >
          <span>{t('ecogesture_selection.button_go_to_ecogesture')}</span>
        </Button>
        <Button
          aria-label={t('ecogesture_selection.accessibility.button_continue')}
          className="btn-highlight text-16-bold"
          onClick={restart}
        >
          <span>{t('ecogesture_selection.button_continue')}</span>
        </Button>
      </div>
    </div>
  );
};

export default EcogestureSelectionRestart;
