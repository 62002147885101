/* eslint-disable camelcase */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  FluidRetrievalStatus,
  FluidSlugType,
  FluidState,
  FluidType,
  ScreenType,
  Usage,
} from 'enums';
import {
  FluidConnection,
  FluidStatus,
  GlobalState,
  Notes,
  PartnersInfo,
  SgeStore,
  TermsStatus,
} from 'models';
import { StellioFluidMonitoring } from 'models/stellio.model';

const initialState: GlobalState = {
  screenType: ScreenType.MOBILE,
  releaseNotes: {
    show: false,
    notes: [
      {
        title: '',
        description: '',
      },
    ],
  },
  challengeExplorationNotification: false,
  challengeActionNotification: false,
  challengeDuelNotification: false,
  analysisNotification: false,
  termsStatus: {
    accepted: true,
    versionType: 'init',
  },
  fluidStatus: [
    {
      fluidType: FluidType.ELECTRICITY,
      status: FluidState.NOT_CONNECTED,
      retrievalStatus: FluidRetrievalStatus.NOT_STARTED,
      maintenance: false,
      firstDataDate: null,
      lastDataDate: null,
      connection: {
        shouldLaunchKonnector: false,
        isUpdating: false,
        konnector: null,
        account: null,
        trigger: null,
        triggerState: null,
        konnectorConfig: {
          name: 'Enedis',
          oauth: false,
          slug: FluidSlugType.ELECTRICITY,
          siteLink: 'https://mon-compte-client.enedis.fr/',
          activation: '',
          oauthState: '0001**_CudEcoGagnantEnedis_**1000',
          clientId: 'nmIUWF1A33LwRGRTu6bRKL_pfz0a',
          giveConsentUrl:
            'https://mon-compte-particulier.enedis.fr/dataconnect/v1/oauth2/authorize',
        },
      },
    },
    {
      fluidType: FluidType.WATER,
      status: FluidState.NOT_CONNECTED,
      retrievalStatus: FluidRetrievalStatus.NOT_STARTED,
      maintenance: false,
      firstDataDate: null,
      lastDataDate: null,
      connection: {
        shouldLaunchKonnector: false,
        isUpdating: false,
        konnector: null,
        account: null,
        trigger: null,
        triggerState: null,
        konnectorConfig: {
          name: "L'eau du Dunkerquois",
          oauth: false,
          slug: FluidSlugType.WATER,
          siteLink:
            'https://www.eaux-dunkerque.fr/mon-compte-en-ligne/je-cree-mon-compte',
          activation: '',
          oauthState: '0001**_CudEcoGagnantSuezDunkerquois_**1000',
          clientId: 'eco_gagnant',
          giveConsentUrl:
            'https://eddk-int.toutsurmoneau.fr/mon-compte-en-ligne/partage-conso',
        },
      },
    },
    {
      fluidType: FluidType.GAS,
      status: FluidState.NOT_CONNECTED,
      retrievalStatus: FluidRetrievalStatus.NOT_STARTED,
      maintenance: false,
      firstDataDate: null,
      lastDataDate: null,
      connection: {
        shouldLaunchKonnector: false,
        isUpdating: false,
        konnector: null,
        account: null,
        trigger: null,
        triggerState: null,
        konnectorConfig: {
          name: 'GRDF',
          oauth: false,
          slug: FluidSlugType.GAS,
          siteLink: 'https://monespace.grdf.fr/creation-particulier',
          activation: '',
          oauthState: '0001**_CudEcoGagnantGrdf_**1000',
          clientId: '0oaclr1idjo62AABT417',
          giveConsentUrl:
            'https://adict-connexion.grdf.fr/oauth2/aus5y2ta2uEHjCWIR417/v1/authorize',
        },
      },
    },
  ],
  fluidTypes: [],
  fluidMonitoring: [],
  partnersInfo: {
    egl_failure: false,
    enedis_failure: false,
    grdf_failure: false,
    notification_activated: false,
  },
  shouldRefreshConsent: false,
  sgeConnect: {
    currentStep: 0,
    firstName: '',
    lastName: '',
    pdl: null,
    address: '',
    zipCode: null,
    city: '',
    dataConsent: false,
    pdlConfirm: false,
    shouldLaunchAccount: false,
  },
  ecogestureFilter: Usage.ALL,
  lastEpglLogin: '',
};

type UpdatedFluidConnection = PayloadAction<{
  fluidType: FluidType;
  fluidConnection: FluidConnection;
}>;

type ShowReleaseNote = PayloadAction<{
  show: boolean;
  notes: Notes[];
  redirectLink?: string;
}>;

const getFluidTypesFromStatus = (fluidStatus: FluidStatus[]): FluidType[] => {
  const fluidTypes: FluidType[] = [];
  fluidStatus.forEach((fluid) => {
    if (
      (fluid.status !== FluidState.KONNECTOR_NOT_FOUND &&
        fluid.status !== FluidState.NOT_CONNECTED &&
        fluid.status !== FluidState.ERROR_LOGIN_FAILED) ||
      // Handle Login Error case for oauth konnectors
      (fluid.status === FluidState.ERROR_LOGIN_FAILED &&
        fluid.fluidType !== FluidType.WATER)
    ) {
      fluidTypes.push(fluid.fluidType);
    }
  });
  return fluidTypes.sort();
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    changeScreenType: (state, action: PayloadAction<ScreenType>) => {
      state.screenType = action.payload;
    },
    toggleChallengeExplorationNotification: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.challengeExplorationNotification = action.payload;
    },

    toggleChallengeActionNotification: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.challengeActionNotification = action.payload;
    },
    toggleChallengeDuelNotification: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.challengeDuelNotification = action.payload;
    },
    toggleAnalysisNotification: (state, action: PayloadAction<boolean>) => {
      state.analysisNotification = action.payload;
    },
    setFluidMonitoring: (
      state,
      action: PayloadAction<StellioFluidMonitoring>
    ) => {
      state.fluidMonitoring = [...state.fluidMonitoring, action.payload];
    },
    setFluidStatus: (state, action: PayloadAction<FluidStatus[]>) => {
      state.fluidStatus = action.payload;
      state.fluidTypes = getFluidTypesFromStatus(action.payload);
    },
    updateTermsStatus: (state, action: PayloadAction<TermsStatus>) => {
      state.termsStatus = action.payload;
    },
    showReleaseNotes: (state, action: ShowReleaseNote) => {
      state.releaseNotes = action.payload;
    },
    setPartnersInfo: (state, action: PayloadAction<PartnersInfo>) => {
      state.partnersInfo = action.payload;
    },
    setShouldRefreshConsent: (state, action: PayloadAction<boolean>) => {
      state.shouldRefreshConsent = action.payload;
    },
    updateFluidConnection: (
      state,
      { payload: { fluidType, fluidConnection } }: UpdatedFluidConnection
    ) => {
      state.fluidStatus[fluidType].connection = fluidConnection;
    },
    setLastEpglLogin: (state, action: PayloadAction<string>) => {
      state.lastEpglLogin = action.payload;
    },
    updateSgeStore: (state, action: PayloadAction<SgeStore>) => {
      state.sgeConnect = action.payload;
    },
    updateEcogestureFilter: (state, action: PayloadAction<Usage>) => {
      state.ecogestureFilter = action.payload;
    },
  },
});

export const {
  changeScreenType,
  setFluidStatus,
  setFluidMonitoring,
  setLastEpglLogin,
  setPartnersInfo,
  setShouldRefreshConsent,
  showReleaseNotes,
  toggleAnalysisNotification,
  toggleChallengeActionNotification,
  toggleChallengeDuelNotification,
  toggleChallengeExplorationNotification,
  updateEcogestureFilter,
  updateFluidConnection,
  updateSgeStore,
  updateTermsStatus,
} = globalSlice.actions;
