import * as Sentry from '@sentry/react';
import { CHALLENGE_DOCTYPE, USERCHALLENGE_DOCTYPE } from 'doctypes';
import {
  FluidState,
  FluidType,
  TimeStep,
  UserActionState,
  UserChallengeState,
  UserChallengeSuccess,
  UserChallengeUpdateFlag,
  UserDuelState,
  UserExplorationState,
} from 'enums';
import { DateTime } from 'luxon';
import {
  ChallengeEntity,
  Datachart,
  Dataload,
  DuelEntity,
  Ecogesture,
  ExplorationEntity,
  FluidStatus,
  QuizEntity,
  Relation,
  RelationEntitiesObject,
  TimePeriod,
  UserAction,
  UserChallenge,
  UserChallengeEntity,
  UserDuel,
  UserExploration,
  UserQuiz,
} from 'models';
import { getLagDays } from 'utils/date';
import { getRoundFloat } from 'utils/math';
import { getRelationship, getRelationshipHasMany } from 'utils/utils';
import ActionService from './action.service';
import ExplorationService from './exploration.service';
import AppAxiosService from './appAxios.service';
import DuelService from './duel.service';
import QuizService from './quiz.service';
import ConsumptionDataManager from './consumption.service';
import {
  StellioChallengeProgress,
  StellioEcogesture,
  StellioExploration,
} from 'models/stellio.model';
import { getAlwaysArray } from 'utils/ecoGagnant';
import EcogestureService from './ecogesture.service';

export default class ChallengeService {
  private readonly _client: AppAxiosService;
  private readonly _userSub: string;
  private readonly _allStellioEcogestures: StellioEcogesture[];
  private readonly _allStellioExplorations: StellioExploration[];
  private _userChallengeProgress: StellioChallengeProgress;

  constructor(
    client: AppAxiosService,
    userSub: string,
    userChallengeProgress: StellioChallengeProgress,
    allStellioEcogestures: StellioEcogesture[],
    allStellioExplorations: StellioExploration[]
  ) {
    this._client = client;
    this._userSub = userSub;
    this._allStellioEcogestures = allStellioEcogestures;
    this._allStellioExplorations = allStellioExplorations;
    this._userChallengeProgress = userChallengeProgress;
  }

  /**
   * Retrieve list of Userchallenge and unlock the current challenge if the last one is done
   * If all challenges are locked, then unlock the first one
   * @param {UserChallenge[]} userChallenges - status of fluids
   * @returns {UserChallenge[]}
   */
  public unLockCurrentUserChallenge(
    userChallenges: UserChallenge[]
  ): UserChallenge[] {
    let isAllLocked = true;
    userChallenges.forEach((challenge, i) => {
      if (challenge.state != UserChallengeState.LOCKED) isAllLocked = false;
      if (challenge.state === UserChallengeState.DONE) {
        if (userChallenges[i + 1]?.state === UserChallengeState.LOCKED) {
          userChallenges[i + 1].state = UserChallengeState.UNLOCKED;
        }
      }
    });
    if (isAllLocked) userChallenges[0].state = UserChallengeState.UNLOCKED;
    return userChallenges;
  }

  /**
   * Retrieve UserChallenge from the UserChallengeEntity
   * @param {UserChallengeEntity} userChallenges - status of fluids
   * @returns {UserChallenge}
   */
  public parseUserChallengeEntityToUserChallenge(
    userChallengeEntity: UserChallengeEntity
  ): UserChallenge {
    const userChallenge: UserChallenge = {
      ...userChallengeEntity,
      startDate: userChallengeEntity.startDate
        ? DateTime.fromISO(userChallengeEntity.startDate, {
            zone: 'utc',
          })
        : null,
      endingDate: userChallengeEntity.endingDate
        ? DateTime.fromISO(userChallengeEntity.endingDate, {
            zone: 'utc',
          })
        : null,
      duel: {
        ...userChallengeEntity.duel,
        startDate: userChallengeEntity.duel.startDate
          ? DateTime.fromISO(userChallengeEntity.duel.startDate, {
              zone: 'utc',
            })
          : null,
      },
      action: {
        ...userChallengeEntity.action,
        startDate: userChallengeEntity.action.startDate
          ? DateTime.fromISO(userChallengeEntity.action.startDate, {
              zone: 'utc',
            })
          : null,
      },
    };
    return userChallenge;
  }

  private getUserChallengeEntityFromUserChallenge(
    userChallenge: UserChallenge
  ): UserChallengeEntity {
    const userChallengeEntity: UserChallengeEntity = {
      ...userChallenge,
      startDate: userChallenge.startDate
        ? userChallenge.startDate.toISO()
        : null,
      endingDate: userChallenge.endingDate
        ? userChallenge.endingDate.toISO()
        : null,
      duel: {
        ...userChallenge.duel,
        startDate: userChallenge.duel.startDate
          ? userChallenge.duel.startDate.toISO()
          : null,
      },
      action: {
        ...userChallenge.action,
        startDate: userChallenge.action.startDate
          ? userChallenge.action.startDate.toISO()
          : null,
      },
    };
    return userChallengeEntity;
  }

  /**
   * Retrieve UserChallenge from the UserChallengeEntity
   * @param {UserChallengeEntity} userChallenges - status of fluids
   * @returns {UserChallenge}
   */
  public parseChallengeEntityToUserChallenge(
    challenge: ChallengeEntity,
    duel: UserDuel,
    quiz: UserQuiz,
    exploration: UserExploration
  ): UserChallenge {
    const userChallenge: UserChallenge = {
      id: challenge.id,
      title: challenge.title,
      description: challenge.description,
      target: challenge.target,
      duel: duel,
      state: UserChallengeState.LOCKED,
      progress: {
        quizProgress: 0,
        explorationProgress: 0,
        actionProgress: 0,
      },
      success: UserChallengeSuccess.ONGOING,
      startDate: null,
      endingDate: null,
      quiz: quiz,
      exploration: exploration,
      action: {
        ecogesture: null,
        startDate: null,
        state: UserActionState.UNSTARTED,
      },
    };
    return userChallenge;
  }

  /**
   *
   * @param {UserExploration | ExplorationEntity} exploration - Exploration to be tested
   * @param {FluidStatus[]} fluidStatus
   * @returns {boolean} isValid
   */
  public async isExplorationConditionVerified(
    exploration: UserExploration | ExplorationEntity,
    fluidStatus: FluidStatus[]
  ): Promise<boolean> {
    let isValid = false;
    const fluidCondition: FluidType[] = exploration.fluid_condition;
    // check if the fluid is connected
    if (
      fluidStatus[fluidCondition[0]].status !==
        FluidState.KONNECTOR_NOT_FOUND &&
      fluidStatus[fluidCondition[0]].status !== FluidState.NOT_CONNECTED
    ) {
      isValid = true;
    }
    return isValid;
  }

  /**
   *
   * @param {ChallengeEntity} challenge - get all relations entities of a challenge
   * @returns {RelationEntitiesObject}
   */
  public async getRelationEntities(
    challenge: ChallengeEntity
  ): Promise<RelationEntitiesObject> {
    const duelEntityRelation: Relation = getRelationship(challenge, 'duel');
    const quizEntityRelation: Relation = getRelationship(challenge, 'quiz');
    const explorationEntityRelation: Relation[] = getRelationshipHasMany(
      challenge,
      'exploration'
    );
    const result: RelationEntitiesObject = {
      duelEntityRelation: duelEntityRelation,
      quizEntityRelation: quizEntityRelation,
      explorationEntityRelation: explorationEntityRelation,
    };
    return result;
  }

  /**
   * Get a UserChallenge from its Entity and verify the exploration if there is a condition and if it's verified
   * @param {UserExploration} exploration
   * @param {ChallengeEntity} challenge
   * @param {UserDuel} duel
   * @param {UserQuiz} quiz
   * @param {FluidStatus[]} fluidStatus
   * @returns {UserChallenge}
   */
  public async getUpdatedUserChallengeIfExplorationConditionIsValid(
    exploration: UserExploration,
    challenge: ChallengeEntity,
    duel: UserDuel,
    quiz: UserQuiz,
    fluidStatus: FluidStatus[]
  ): Promise<UserChallenge | undefined> {
    let userChallenge: UserChallenge | null = null;
    // Check if it's a conditional exploration
    if (exploration.fluid_condition.length > 0) {
      const isConditionVerified = await this.isExplorationConditionVerified(
        exploration,
        fluidStatus
      );
      // if condition is verified, add exploration to UserChallenge and return it
      if (isConditionVerified) {
        userChallenge = this.parseChallengeEntityToUserChallenge(
          challenge,
          duel,
          quiz,
          exploration
        );
        return userChallenge;
      }
    } // if there is no condition, add the exploration
    else {
      userChallenge = this.parseChallengeEntityToUserChallenge(
        challenge,
        duel,
        quiz,
        exploration
      );
      return userChallenge;
    }
  }
  /**
   * The whole exploration process, checks if the exploration fluid condition exists and is valid for each existing
   * Exploration related to given challenge
   * @param {ExplorationEntity[] | undefined} explorationEntities
   * @param {Relation[]} explorationEntityRelation
   * @param {ChallengeEntity} challenge
   * @param {UserDuel} duel
   * @param {UserQuiz} quiz
   * @param {UserChallenge[]} buildList
   * @param {FluidStatus[]} fluidStatus
   * @returns {UserChallenge[]} - buildList
   */
  public async processExploration(
    explorationEntities: ExplorationEntity[] | undefined,
    explorationEntityRelation: Relation[],
    challenge: ChallengeEntity,
    duel: UserDuel,
    quiz: UserQuiz,
    buildList: UserChallenge[],
    fluidStatus: FluidStatus[]
  ): Promise<UserChallenge[]> {
    const explorationService = new ExplorationService(
      this._client,
      this._userSub,
      this._allStellioExplorations,
      this._userChallengeProgress,
      this._allStellioEcogestures
    );
    for (const explorationRelation of explorationEntityRelation) {
      const exploration: UserExploration =
        explorationService.getUserExplorationfromExplorationEntities(
          explorationEntities || [],
          explorationRelation._id
        );
      const userChallenge =
        await this.getUpdatedUserChallengeIfExplorationConditionIsValid(
          exploration,
          challenge,
          duel,
          quiz,
          fluidStatus
        );
      if (userChallenge) {
        buildList.push(userChallenge);
        break;
      }
    }
    return buildList;
  }
  /**
   * Checks if the exploration condition exists and is valid when updating the buildList
   * @param {UserChallenge} userChallenge
   * @param {ChallengeEntity[]} challengeEntityList
   * @param {FluidStatus[]} fluidStatus
   * @returns {UserChallenge}
   */
  public async loopVerificationExplorationCondition(
    userChallenge: UserChallenge,
    challengeEntityList: ChallengeEntity[],
    fluidStatus: FluidStatus[]
  ): Promise<UserChallenge> {
    if (userChallenge.exploration.state === UserExplorationState.UNLOCKED) {
      const currentEntity = challengeEntityList.filter(
        (challenge) => challenge.id === userChallenge.id
      );
      const relationsArray = currentEntity[0].relationships.exploration;
      const explorationService = new ExplorationService(
        this._client,
        this._userSub,
        this._allStellioExplorations,
        this._userChallengeProgress,
        this._allStellioEcogestures
      );
      let updatedUserChallenge: UserChallenge = { ...userChallenge };
      for (const relation of relationsArray.data) {
        const newExploEntity =
          await explorationService.getExplorationEntityById(relation._id);
        const newUserExplo: UserExploration =
          explorationService.parseExplorationEntityToUserExploration(
            newExploEntity
          );
        if (newExploEntity.fluid_condition.length > 0) {
          const isConditionValid = await this.isExplorationConditionVerified(
            newExploEntity,
            fluidStatus
          );
          if (isConditionValid) {
            updatedUserChallenge = {
              ...updatedUserChallenge,
              exploration: newUserExplo,
            };
            break;
          }
        } else {
          updatedUserChallenge = {
            ...updatedUserChallenge,
            exploration: newUserExplo,
          };
          break;
        }
      }
      return updatedUserChallenge;
    }
    return userChallenge;
  }

  /**
   * Retrieve UserChallenge list with all challenges
   * @param {FluidStatus[]} fluidStatus
   * @returns {UserChallenge[]}
   */
  public async buildUserChallengeList(
    fluidStatus?: FluidStatus[]
  ): Promise<UserChallenge[]> {
    // const queryChallengeEntity: QueryDefinition = Q(CHALLENGE_DOCTYPE).include([
    //   'duel',
    // ]);
    // const querySeasonEntityIncludeQuiz: QueryDefinition = Q(
    //   CHALLENGE_DOCTYPE
    // ).include(['quiz']);
    // const querySeasonEntityIncludeExploration: QueryDefinition = Q(
    //   CHALLENGE_DOCTYPE
    // ).include(['exploration']);
    // const {
    //   data: challengeEntityList,
    //   included: duelEntities,
    // }: QueryResult<ChallengeEntity[], DuelEntity[]> =
    //   await this._client.query(queryChallengeEntity);
    // const {
    //   included: explorationEntities,
    // }: QueryResult<ChallengeEntity[], ExplorationEntity[]> =
    //   await this._client.query(querySeasonEntityIncludeExploration);
    // const {
    //   included: quizEntities,
    // }: QueryResult<ChallengeEntity[], QuizEntity[]> = await this._client.query(
    //   querySeasonEntityIncludeQuiz
    // );
    const userChallengeList: UserChallenge[] =
      await this.getAllUserChallengeEntities();
    const duelService = new DuelService(this._client, this._userSub);
    const quizService = new QuizService(this._client, this._userSub);
    const explorationService = new ExplorationService(
      this._client,
      this._userSub,
      this._allStellioExplorations,
      this._userChallengeProgress,
      this._allStellioEcogestures
    );
    let buildList: UserChallenge[] = [];
    // Case UserChallengeList is empty
    // if (challengeEntityList.length > 0 && userChallengeList.length === 0) {
    //   for (const challenge of challengeEntityList) {
    //     const relationEntities = await this.getRelationEntities(challenge);
    //     const duel: UserDuel = duelService.getDuelfromDuelEntities(
    //       duelEntities || [],
    //       relationEntities.duelEntityRelation._id
    //     );
    //     const quiz: UserQuiz = quizService.getUserQuizfromQuizEntities(
    //       quizEntities || [],
    //       relationEntities.quizEntityRelation._id
    //     );
    //     // Only one exploration relation
    //     if (relationEntities.explorationEntityRelation.length === 1) {
    //       const exploration: UserExploration =
    //         explorationService.getUserExplorationfromExplorationEntities(
    //           explorationEntities || [],
    //           relationEntities.explorationEntityRelation[0]._id
    //         );
    //       const userChallenge = this.parseChallengeEntityToUserChallenge(
    //         challenge,
    //         duel,
    //         quiz,
    //         exploration
    //       );
    //       buildList.push(userChallenge);
    //     }
    //     // Several explorations with fluid condition
    //     else {
    //       await this.processExploration(
    //         explorationEntities,
    //         relationEntities.explorationEntityRelation,
    //         challenge,
    //         duel,
    //         quiz,
    //         buildList,
    //         fluidStatus
    //       );
    //     }
    //   }
    //   buildList = this.unLockCurrentUserChallenge(buildList);
    // }
    // Case UserChallengeList is existing
    // else if (challengeEntityList.length > 0 && userChallengeList.length > 0) {
    //   for (const challenge of challengeEntityList) {
    //     const userChallengeIndex = userChallengeList.findIndex(
    //       (entity) => entity.id === challenge.id
    //     );
    //     if (userChallengeIndex >= 0) {
    //       let userChallenge: UserChallenge =
    //         userChallengeList[userChallengeIndex];
    //       userChallenge = await this.loopVerificationExplorationCondition(
    //         userChallenge,
    //         challengeEntityList,
    //         fluidStatus
    //       );
    //       buildList.push(userChallenge);
    //     } else {
    //       const relationEntities = await this.getRelationEntities(challenge);
    //       const duel: UserDuel = duelService.getDuelfromDuelEntities(
    //         duelEntities || [],
    //         relationEntities.duelEntityRelation._id
    //       );
    //       const quiz: UserQuiz = quizService.getUserQuizfromQuizEntities(
    //         quizEntities || [],
    //         relationEntities.quizEntityRelation._id
    //       );
    //       await this.processExploration(
    //         explorationEntities,
    //         relationEntities.explorationEntityRelation,
    //         challenge,
    //         duel,
    //         quiz,
    //         buildList,
    //         fluidStatus
    //       );
    //     }
    //   }
    //   buildList = this.unLockCurrentUserChallenge(buildList);
    // }
    return buildList;
  }

  /**
   * Retrieve all ChallengeEntities
   * @returns {ChallengeEntity[]}
   */
  public async getAllChallengeEntities(): Promise<ChallengeEntity[]> {
    // const query: QueryDefinition = Q(CHALLENGE_DOCTYPE);
    // const { data: challenges }: QueryResult<ChallengeEntity[]> =
    //   await this._client.query(query);
    // return challenges;
    return [];
  }

  /**
   * Delete all ChallengeEntities
   * @returns {boolean}
   * @throws {Error}
   */
  public async deleteAllChallengeEntities(): Promise<boolean> {
    try {
      const challengeEntity = await this.getAllChallengeEntities();
      if (!challengeEntity) return true;
      for (const entity of challengeEntity) {
        // await this._client.destroy(entity);
      }
      return true;
    } catch (error) {
      const errorMessage = `deleteAllChallengeEntities :${JSON.stringify(
        error
      )}`;
      throw error;
    }
  }

  /**
   * Retrieve all UserChallengeEntities
   * @returns {UserChallenge[]}
   */
  public async getAllUserChallengeEntities(): Promise<UserChallenge[]> {
    const userChallenges: UserChallenge[] =
      this._userChallengeProgress.refChallenge.map((relationship) => {
        const userChallengeEntity: UserChallengeEntity =
          relationship.userProgress.json;
        return this.parseUserChallengeEntityToUserChallenge(
          userChallengeEntity
        );
      });
    return userChallenges;
  }

  /**
   * Retrieve dataloads for ongoing duel
   * success return: UserChallenge, Dataload[]
   * failure throw error
   */
  public async initChallengeDuelProgress(
    userChallenge: UserChallenge
  ): Promise<{
    updatedUserChallenge: UserChallenge;
    dataloads: Dataload[];
  }> {
    const consumptionService = new ConsumptionDataManager(
      this._client,
      this._userSub
    );
    try {
      const dataloads: Dataload[] =
        await this.getUserChallengeDataload(userChallenge);
      const userConsumption: number = getRoundFloat(
        consumptionService.calculatePerformanceIndicatorValue(dataloads)
      );
      const _userChallenge: UserChallenge = {
        ...userChallenge,
        duel: {
          ...userChallenge.duel,
          userConsumption: userConsumption,
        },
      };
      const updatedUserChallenge: UserChallenge =
        await this.updateUserChallenge(
          _userChallenge,
          UserChallengeUpdateFlag.DUEL_CONSUMPTION
        );
      return { updatedUserChallenge, dataloads };
    } catch (error) {
      const errorMessage = `Challenge service error on initChallengeDuelProgress: ${JSON.stringify(
        error
      )}`;
      throw error;
    }
  }

  /**
   * Start UserChallenge and retrieve updated UserChallenge
   * @returns {UserChallenge}
   * @throws {Error}
   */
  public async startUserChallenge(
    userChallenge: UserChallenge
  ): Promise<UserChallenge> {
    const newChallenge: UserChallenge = {
      ...userChallenge,
      state: UserChallengeState.ONGOING,
      progress: {
        actionProgress: 0,
        explorationProgress: 0,
        quizProgress: 0,
      },
      startDate: DateTime.local()
        .setZone('utc', { keepLocalTime: true })
        .startOf('day'),
      success: UserChallengeSuccess.ONGOING,
    };

    return newChallenge;
  }

  /**
   * Update UserChallenge depending on the flag and retrieve it
   * @param {UserChallenge} userChallenge - userChallenge to update
   * @param {UserChallengeUpdateFlag} flag - update flag
   * @returns {UserChallenge} - updated userChallenge
   * @throws {Error}
   */
  public async updateUserChallenge(
    userChallenge: UserChallenge,
    flag: UserChallengeUpdateFlag,
    quizWithUpdatedQuestions?: UserQuiz,
    fluidStatus?: Array<FluidStatus>,
    action?: Ecogesture
  ): Promise<UserChallenge> {
    let updatedUserChallenge: UserChallenge;
    let updatedDuel = userChallenge.duel;
    let updatedQuiz = userChallenge.quiz;
    let updatedExploration = userChallenge.exploration;
    let updatedAction = userChallenge.action;
    const duelService = new DuelService(this._client, this._userSub);
    const quizService = new QuizService(this._client, this._userSub);
    const explorationService = new ExplorationService(
      this._client,
      this._userSub,
      this._allStellioExplorations,
      this._userChallengeProgress,
      this._allStellioEcogestures
    );
    const actionService = new ActionService(
      this._client,
      this._userSub,
      this._userChallengeProgress,
      this._allStellioEcogestures,
      this._allStellioExplorations
    );
    switch (flag) {
      case UserChallengeUpdateFlag.CHALLENGE:
      case UserChallengeUpdateFlag.DUEL_CONSUMPTION:
        updatedUserChallenge = userChallenge;
        break;
      case UserChallengeUpdateFlag.DUEL_UNLOCK:
        updatedDuel = await duelService.unlockUserDuel(userChallenge.duel);
        updatedUserChallenge = {
          ...userChallenge,
          state: UserChallengeState.DUEL,
          duel: updatedDuel,
        };
        break;
      case UserChallengeUpdateFlag.DUEL_UPDATE_THRESHOLD:
        updatedDuel = await duelService.updateUserDuelThreshold(
          userChallenge.duel,
          fluidStatus
        );
        updatedUserChallenge = {
          ...userChallenge,
          state: UserChallengeState.DUEL,
          duel: updatedDuel,
        };
        break;
      case UserChallengeUpdateFlag.DUEL_START:
        updatedDuel = await duelService.startUserDuel(userChallenge.duel);
        updatedUserChallenge = {
          ...userChallenge,
          state: UserChallengeState.DUEL,
          duel: updatedDuel,
        };
        break;
      case UserChallengeUpdateFlag.DUEL_WIN:
        updatedDuel = await duelService.endUserDuel(userChallenge.duel);
        updatedUserChallenge = {
          ...userChallenge,
          state: UserChallengeState.DONE,
          duel: updatedDuel,
          endingDate: DateTime.local()
            .setZone('utc', {
              keepLocalTime: true,
            })
            .startOf('day'),
          success: UserChallengeSuccess.WIN,
        };
        break;
      case UserChallengeUpdateFlag.DUEL_LOSS:
        updatedDuel = await duelService.endUserDuel(userChallenge.duel);
        updatedUserChallenge = {
          ...userChallenge,
          state: UserChallengeState.DONE,
          duel: updatedDuel,
          endingDate: DateTime.local()
            .setZone('utc', {
              keepLocalTime: true,
            })
            .startOf('day'),
          success: UserChallengeSuccess.LOST,
        };
        break;
      case UserChallengeUpdateFlag.DUEL_RESET:
        updatedDuel = await duelService.resetUserDuel(userChallenge.duel);
        updatedUserChallenge = {
          ...userChallenge,
          state: UserChallengeState.DUEL,
          duel: updatedDuel,
          success: UserChallengeSuccess.ONGOING,
        };
        break;
      case UserChallengeUpdateFlag.QUIZ_START:
        updatedQuiz = await quizService.startUserQuiz(userChallenge.quiz);
        updatedUserChallenge = {
          ...userChallenge,
          quiz: updatedQuiz,
        };
        break;
      case UserChallengeUpdateFlag.QUIZ_DONE: {
        const updateQuizProgress = Math.min(userChallenge.quiz.result, 5);
        updatedQuiz = await quizService.endUserQuiz(userChallenge.quiz);
        updatedUserChallenge = {
          ...userChallenge,
          quiz: updatedQuiz,
          progress: {
            ...userChallenge.progress,
            quizProgress: updateQuizProgress,
          },
        };
        break;
      }
      case UserChallengeUpdateFlag.QUIZ_UPDATE:
        updatedUserChallenge = {
          ...userChallenge,
          quiz: quizWithUpdatedQuestions
            ? quizWithUpdatedQuestions
            : userChallenge.quiz,
        };
        break;
      case UserChallengeUpdateFlag.QUIZ_RESET:
        updatedQuiz = await quizService.resetUserQuiz(userChallenge.quiz);
        updatedUserChallenge = {
          ...userChallenge,
          quiz: updatedQuiz,
        };
        break;
      case UserChallengeUpdateFlag.EXPLORATION_START:
        updatedExploration = await explorationService.startUserExploration(
          userChallenge.exploration
        );
        updatedUserChallenge = {
          ...userChallenge,
          exploration: updatedExploration,
        };
        break;
      case UserChallengeUpdateFlag.EXPLORATION_UPDATE:
        updatedExploration = await explorationService.updateUserExploration(
          userChallenge.exploration
        );
        updatedUserChallenge = {
          ...userChallenge,
          exploration: updatedExploration,
        };
        break;
      case UserChallengeUpdateFlag.EXPLORATION_NOTIFICATION:
        updatedExploration =
          await explorationService.awaitNotificationUserExploration(
            userChallenge.exploration
          );
        updatedUserChallenge = {
          ...userChallenge,
          exploration: updatedExploration,
        };
        break;
      case UserChallengeUpdateFlag.EXPLORATION_DONE:
        updatedExploration = explorationService.endUserExploration(
          userChallenge.exploration
        );
        updatedUserChallenge = {
          ...userChallenge,
          exploration: updatedExploration,
          progress: {
            ...userChallenge.progress,
            explorationProgress: updatedExploration.progress,
          },
        };
        break;
      case UserChallengeUpdateFlag.ACTION_START:
        let userAction: UserAction = userChallenge.action;
        if (action) {
          userAction = actionService.launchAction(action);
        }
        updatedUserChallenge = {
          ...userChallenge,
          action: userAction,
        };
        break;
      case UserChallengeUpdateFlag.ACTION_NOTIFICATION:
        updatedAction = actionService.awaitNotificationAction(
          userChallenge.action
        );
        updatedUserChallenge = {
          ...userChallenge,
          action: updatedAction,
        };
        break;
      case UserChallengeUpdateFlag.ACTION_DONE:
        updatedAction = actionService.endAction(userChallenge.action);
        updatedUserChallenge = {
          ...userChallenge,
          action: updatedAction,
          progress: {
            ...userChallenge.progress,
            actionProgress: 5,
          },
        };

        break;
      default:
        updatedUserChallenge = userChallenge;
        break;
    }

    try {
      const updatedChallengeProgressEntity: Partial<StellioChallengeProgress> =
        {
          ...this._userChallengeProgress,
          refChallenge: this._userChallengeProgress.refChallenge.map(
            (relationship) => {
              if (relationship.object !== updatedUserChallenge.id) {
                return relationship;
              }

              return {
                ...relationship,
                userProgress: {
                  ...relationship.userProgress,
                  json: this.getUserChallengeEntityFromUserChallenge(
                    updatedUserChallenge
                  ),
                },
              };
            }
          ),
        };

      delete updatedChallengeProgressEntity.id;
      delete updatedChallengeProgressEntity.type;
      delete updatedChallengeProgressEntity.refEcogesture;
      delete updatedChallengeProgressEntity.globalState;

      console.log('flag: ', flag, updatedUserChallenge);

      const response = await this._client.updateEntityById(
        this._userChallengeProgress.id,
        updatedChallengeProgressEntity
      );

      console.log(response.status);

      return updatedUserChallenge;
    } catch (error) {
      const errorMessage = `Update user challenge error`;
      console.warn(errorMessage, error);
      throw error;
    }
  }

  /**
   * Retrieve the dataload for a UserChallenge with duel ongoing
   * @param {UserChallenge} userChallenge - userChallenge to update
   * @param {UserChallengeUpdateFlag} flag - update flag
   * @returns {Dataload[]}
   */
  public async getUserChallengeDataload(
    userChallenge: UserChallenge
  ): Promise<Dataload[]> {
    if (userChallenge.duel.startDate) {
      const consumptionService = new ConsumptionDataManager(
        this._client,
        this._userSub
      );
      const timePeriod: TimePeriod = {
        startDate: userChallenge.duel.startDate,
        endDate: userChallenge.duel.startDate.plus({
          day: userChallenge.duel.duration.days - 1,
        }),
      };
      const dataChart: Datachart | null = await consumptionService.getGraphData(
        timePeriod,
        TimeStep.DAY,
        getAlwaysArray(userChallenge.duel.fluidTypes),
        undefined,
        undefined,
        true
      );
      if (dataChart) {
        return dataChart.actualData;
      }
    }
    return [];
  }

  /**
   * Define if challenge is done and if is win or lost
   * @param {UserChallenge} userChallenge - current userChallenge
   * @param {Dataload[]} dataloads - dataloads of current challenge
   * @returns {boolean, boolean}
   */
  public async isChallengeDone(
    userChallenge: UserChallenge,
    dataloads: Dataload[]
  ): Promise<{
    isDone: boolean;
    isWin: boolean;
  }> {
    let isDone = false;
    let isWin = false;
    if (
      userChallenge.state === UserChallengeState.DUEL &&
      userChallenge.duel.state === UserDuelState.ONGOING &&
      userChallenge.duel.duration
    ) {
      const fullDuration =
        userChallenge.duel.duration.days +
        getLagDays(getAlwaysArray(userChallenge.duel.fluidTypes)) +
        1;
      let diffFromNow = userChallenge.duel.startDate
        ? userChallenge.duel.startDate
            .diff(
              DateTime.local().setZone('utc', {
                keepLocalTime: true,
              }),
              'days'
            )
            .toObject().days
        : 0;

      diffFromNow = diffFromNow ? Math.abs(diffFromNow) : 0;

      if (diffFromNow >= fullDuration) {
        isDone = true;
        if (
          isDone &&
          userChallenge.duel.userConsumption < userChallenge.duel.threshold
        ) {
          isWin = true;
        }
      } else {
        const duration = userChallenge.duel.duration.days;
        if (
          dataloads.length === duration &&
          dataloads[duration - 1].value !== -1
        ) {
          isDone = true;
          dataloads.forEach((d: Dataload) => {
            if (
              d.value === -1 ||
              (d.valueDetail &&
                d.valueDetail.filter((data) => data.value === -1).length > 0)
            ) {
              isDone = false;
            }
          });
          if (
            isDone &&
            userChallenge.duel.userConsumption < userChallenge.duel.threshold
          ) {
            isWin = true;
          }
        }
      }
    }

    return { isDone, isWin };
  }

  public getParsedAndTransformedUserChallenges(): {
    currentUserChallenge: UserChallenge | null;
    userChallengeList: UserChallenge[] | null;
  } {
    try {
      let challengeEntities: UserChallengeEntity[] =
        this._userChallengeProgress.refChallenge.map(
          (relationship) => relationship.userProgress.json
        );

      const userChallengeList = challengeEntities.map((challengeEntity) => {
        return this.parseUserChallengeEntityToUserChallenge(challengeEntity);
      });

      const filteredCurrentOngoingChallenge = challengeEntities.filter(
        (challenge) =>
          challenge.state === UserChallengeState.ONGOING ||
          challenge.state === UserChallengeState.DUEL
      );

      let currentUserChallenge: UserChallenge | null = null;

      if (filteredCurrentOngoingChallenge.length !== 0) {
        currentUserChallenge = this.parseUserChallengeEntityToUserChallenge(
          filteredCurrentOngoingChallenge[0]
        );
      }

      if (!currentUserChallenge) {
        return {
          currentUserChallenge,
          userChallengeList,
        };
      }

      const ecogestureService = new EcogestureService(
        this._client,
        this._allStellioEcogestures,
        this._userChallengeProgress
      );

      const transformedEcogesture =
        ecogestureService._allEcolyoEcogestures.find(
          (ecolyoEcogest) =>
            ecolyoEcogest.id === currentUserChallenge?.action.ecogesture?.id
        );

      currentUserChallenge = {
        ...currentUserChallenge,
        action: {
          ...currentUserChallenge.action,
          ecogesture: transformedEcogesture ?? null,
        },
      };

      return {
        currentUserChallenge,
        userChallengeList,
      };
    } catch (error) {
      console.warn(
        'Error while parsing and transforming UserChallengeEntity',
        error
      );
    }
    return {
      currentUserChallenge: null,
      userChallengeList: null,
    };
  }
}
