import challengeLockedIcon from 'assets/icons/visu/challenge/challengeLocked.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import { useTranslation } from 'react-i18next';
import { UserChallenge } from 'models';
import { getChallengeTitleWithLineReturn } from 'utils/utils';
import './challengeCardLocked.scss';
import { getChallengeIconId } from 'utils/ecoGagnant';

const ChallengeCardLocked = ({
  userChallenge,
}: {
  userChallenge: UserChallenge;
}) => {
  const { t } = useTranslation();

  return (
    <div className="cardContent cardLocked">
      <span className="challengeTitle">
        {getChallengeTitleWithLineReturn(getChallengeIconId(userChallenge))}
      </span>
      <StyledIcon className="challengeIcon" icon={challengeLockedIcon} />
      <p className="toUnlock text-16-normal-150">
        {t('challenge.card.locked.desc')}
      </p>
    </div>
  );
};

export default ChallengeCardLocked;
