import { Button } from '@mui/material';
import ClockIcon from 'assets/icons/visu/action/duration-clock.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import EcogestureModal from 'components/Ecogesture/EcogestureModal/EcogestureModal';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { UserAction } from 'models';
import React, { useCallback, useState } from 'react';
import './actionOnGoing.scss';

const ActionOnGoing = ({ userAction }: { userAction: UserAction }) => {
  const { t } = useTranslation();
  const [openEcogestureModal, setOpenEcogestureModal] =
    useState<boolean>(false);

  const toggleEcogestureModal = useCallback(() => {
    setOpenEcogestureModal((prev) => !prev);
  }, [setOpenEcogestureModal]);

  const setGradient = useCallback(() => {
    if (!userAction.startDate || !userAction.ecogesture) return null;

    const circle = 360;
    const durationInDays = userAction.ecogesture.actionDuration;
    const ratio = circle / durationInDays;
    const progressionInDays = -Math.round(
      userAction.startDate.diffNow('days').days
    );
    const progress = ratio * progressionInDays;
    if (progress === 0) {
      return `linear-gradient(90deg, #121212 50%,transparent 50%), linear-gradient(110deg, #58ffff 50%, transparent 50%)`;
    } else if (progress === circle) {
      return `linear-gradient(90deg, #58ffff 50%, #58ffff 50%)`;
    } else if (progress === circle / 2) {
      return `linear-gradient(90deg, #121212 50%, #58ffff 50%)`;
    } else if (progress > circle / 2) {
      if (durationInDays / 3 === 1) {
        return `linear-gradient(${
          progress / 2
        }deg, transparent 50%, #58ffff  50%),
        linear-gradient(90deg, transparent 50%, #58ffff 50%)`;
      } else {
        return `linear-gradient(90deg, transparent 50%, #58ffff  50%),
        linear-gradient(180deg, transparent 50%, #58ffff 50%)`;
      }
    } else if (progress < circle / 2) {
      if (durationInDays / 3 === 1) {
        return `linear-gradient(90deg, #121212 50%,transparent 50%), linear-gradient(240deg, #58ffff 50%, transparent 50%)`;
      } else {
        return `linear-gradient(90deg, #121212 50%,transparent 50%), linear-gradient(${
          progress * 2
        }deg, #58ffff 50%, transparent 50%)`;
      }
    }
  }, [userAction.startDate, userAction.ecogesture]);

  const getResultDate = useCallback(() => {
    if (userAction.startDate && userAction.ecogesture) {
      const startDate: DateTime = userAction.startDate;
      const durationInHours: number = userAction.ecogesture.actionDuration * 24;
      const resultDate: string = startDate
        .plus({ hours: durationInHours })
        .setLocale('fr')
        .toFormat('cccc dd LLLL');
      return resultDate;
    }
  }, [userAction]);

  return (
    <div className="action-ongoing">
      {userAction.ecogesture && (
        <>
          <div className="duration text-18-normal">
            {t('action.duration', {
              smartCount: userAction.ecogesture.actionDuration,
            })}
          </div>
          <div className="description text-18-bold">
            {userAction.ecogesture.longName}
          </div>
          <div className="duration-image">
            <StyledIcon
              icon={ClockIcon}
              className="duration-icon"
              width={230}
            />
            <div
              className="pie"
              style={{
                backgroundImage: `${setGradient()}`,
              }}
            />
          </div>
          <div className="result-title text-18-bold"> {t('action.result')}</div>
          <div className="result-date text-24-bold">{getResultDate()}</div>
          <Button
            className="btn-secondary-negative btn-detail text-16-normal"
            onClick={toggleEcogestureModal}
          >
            <span>{t('action.details')}</span>
          </Button>
          <EcogestureModal
            open={openEcogestureModal}
            ecogesture={userAction.ecogesture}
            isAction={false}
            handleCloseClick={toggleEcogestureModal}
          />
        </>
      )}
    </div>
  );
};

export default ActionOnGoing;
