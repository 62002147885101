// import Icon from 'cozy-ui/transpiled/react/Icon'
import React from 'react';

interface StyledIconProps {
  className?: string;
  icon: string;
  ariaHidden?: boolean;
  size?: number;
  role?: string;
  title?: string;
  width?: number;
  height?: number;
  alt?: string;
}

const StyledIcon = ({
  icon,
  ariaHidden = true,
  size = 16,
  width,
  height,
  alt,
  ...props
}: StyledIconProps) => (
  <img
    src={icon}
    aria-hidden={ariaHidden}
    alt={alt}
    style={{ fontSize: size, width, height }}
    {...props}
  />
);

export default StyledIcon;
