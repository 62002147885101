import Button from '@mui/material/Button';
import starFilled from 'assets/icons/visu/challenge/starFilled.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import { UsageEventType, UserChallengeUpdateFlag } from 'enums';
import { UserChallenge } from 'models';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { updateUserChallengeList } from 'store/challenge/challenge.slice';
import { toggleChallengeActionNotification } from 'store/global/global.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import './actionDone.scss';
import { useTranslation } from 'react-i18next';
import AppAxiosService from 'services/appAxios.service';
import ChallengeService from 'services/challenge.service';

const ActionDone = ({
  currentChallenge,
}: {
  currentChallenge: UserChallenge;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userChallengeProgress, ecogesturesList, explorationsList } =
    useAppSelector((state) => state.challenge);
  const userSub = useAppSelector((state) => state.profileKeycloakUser.sub);
  const client = useMemo(() => new AppAxiosService(), []);

  const handleEndAction = useCallback(async () => {
    if (!userChallengeProgress) return;
    if (!userSub) return;

    const challengeService = new ChallengeService(
      client,
      userSub,
      userChallengeProgress,
      ecogesturesList,
      explorationsList
    );
    const updatedChallenge: UserChallenge =
      await challengeService.updateUserChallenge(
        currentChallenge,
        UserChallengeUpdateFlag.ACTION_DONE
      );
    // await UsageEventService.addEvent(client, {
    //   type: UsageEventType.ACTION_END_EVENT,
    //   target: currentChallenge.action.ecogesture
    //     ? currentChallenge.action.ecogesture.id
    //     : '',
    //   context: currentChallenge.id,
    //   startDate: currentChallenge.action.startDate,
    // })
    dispatch(toggleChallengeActionNotification(false));
    dispatch(updateUserChallengeList(updatedChallenge));
    navigate(-1);
  }, [
    client,
    currentChallenge,
    dispatch,
    ecogesturesList,
    explorationsList,
    navigate,
    userChallengeProgress,
    userSub,
  ]);

  return (
    <div className="action-done-container">
      <div className="action-done">
        <div className="result-title">{t('action.finished')}</div>
        <div className="result-text text-16-normal">
          {t('action.resultText1')}
        </div>
        <div className="result-text text-16-normal">
          {t('action.resultText2')}
        </div>
        <div className="result-text text-16-normal">
          {t('action.resultText3')}
        </div>
        <div className="result-text text-16-normal">
          {t('action.resultText4')}
        </div>
        <div className="stars">
          <span>5</span>
          <StyledIcon icon={starFilled} width={33} />
        </div>
        <Button
          className="btn-secondary-negative btn-detail"
          onClick={handleEndAction}
        >
          <span>{t('action.ok')}</span>
        </Button>
      </div>
    </div>
  );
};

export default ActionDone;
