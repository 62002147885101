import React from 'react';
import './legalNoticeView.scss';
import { useTranslation } from 'react-i18next';
const LegalNoticeContent = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="legal-notice-root">
        <div className="legal-notice-content">
          <p className="version">{t('legal.version')}</p>
          <p dangerouslySetInnerHTML={{ __html: t('legal.site') }} />
          <p>{t('legal.adress')}</p>
          <p>{t('legal.phone')}</p>
          <p
            className="ln-contact"
            dangerouslySetInnerHTML={{ __html: t('legal.mail') }}
          />
          <div className="text-16-normal">
            <div className="legal-notice-oneline">
              <span className="text-14-normal">{t('legal.p1b')}</span>
              {t('legal.p1')}
            </div>
            <div className="legal-notice-oneline">
              <span className="text-14-normal">{t('legal.p2b')}</span>
              {t('legal.p2')}
            </div>
            <div className="legal-notice-oneline">
              <span className="text-14-normal">{t('legal.p3b')}</span>
              {t('legal.p3')}
            </div>
            <div className="legal-notice-oneline">
              <span className="text-14-normal">{t('legal.p4b')}</span>
              {t('legal.p4')}
            </div>
            <div className="legal-notice-oneline">
              <span className="text-14-normal">{t('legal.p5b')}</span>
              {t('legal.p5')}
            </div>
            <div className="legal-notice-oneline">
              <span className="text-14-normal">{t('legal.p6b')}</span>
              {t('legal.p6')}
            </div>
            <div className="legal-notice-part">
              <h3> {t('legal.title1')}</h3>
              <p>{t('legal.part1')}</p>
            </div>
            <div className="legal-notice-part">
              <h3>{t('legal.title2')}</h3>
              <p>{t('legal.part2')}</p>
              <ul>
                <li>{t('legal.part2-1')}</li>
                <li>{t('legal.part2-2')}</li>
                <li>
                  {t('legal.part2-3')}
                  <ul>
                    <li>{t('legal.part2-3-1')}</li>
                    <li>{t('legal.part2-3-2')}</li>
                    <li>{t('legal.part2-3-3')}</li>
                    <li>{t('legal.part2-3-4')}</li>
                    <li
                      dangerouslySetInnerHTML={{ __html: t('legal.part2-3-5') }}
                    />
                  </ul>
                </li>
                <li>{t('legal.part2-4')}</li>
              </ul>
              <p>{t('legal.part2-5')}</p>
              <p>{t('legal.part2-6')}</p>
              <ul>
                <li>{t('legal.part2-6-1')}</li>
                <li>{t('legal.part2-6-2')}</li>
                <li>{t('legal.part2-6-3')}</li>
              </ul>
              <p dangerouslySetInnerHTML={{ __html: t('legal.part2-7') }} />
              <p>{t('legal.part2-8')}</p>
              <p>{t('legal.part2-9')}</p>
              <p dangerouslySetInnerHTML={{ __html: t('legal.part2-10') }} />
              <p>{t('legal.part2-11')}</p>
            </div>
            <div className="legal-notice-part">
              <h3>{t('legal.title3')}</h3>
              <p>{t('legal.part3-1')}</p>
              <p>{t('legal.part3-2')}</p>
            </div>
            <div className="legal-notice-part">
              <h3>{t('legal.title4')}</h3>
              <p>{t('legal.part4-1')}</p>
              <p>{t('legal.part4-2')}</p>
            </div>
            <div className="legal-notice-part">
              <h3>{t('legal.title5')}</h3>
              <p>{t('legal.part5')}</p>
            </div>
            <div className="legal-notice-part">
              <h3>{t('legal.title6')}</h3>
              <p>{t('legal.part6')}</p>
            </div>
            <div className="legal-notice-part">
              <h3>{t('legal.title7')}</h3>
              <p>{t('legal.part7-1')}</p>
              <p dangerouslySetInnerHTML={{ __html: t('legal.part7-2') }} />
              <p>{t('legal.part7-3')}</p>
            </div>
            <div className="legal-notice-part">
              <h3>{t('legal.title8')}</h3>
              <p>{t('legal.part8')}</p>
            </div>
            <div className="legal-notice-part">
              <h3>{t('legal.title9')}</h3>
              <p>{t('legal.part9-1')}</p>
              <p>{t('legal.part9-2')}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LegalNoticeContent;
