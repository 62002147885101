import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './duelError.scss';

const DuelError = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className="duel-error-container">
      <div className="duel-error-message">{t('duel.global_error')}</div>
      <div className="duel-error-button">
        <Button
          aria-label={t('duel.accessibility.button_go_back')}
          onClick={goBack}
          className="btn-secondary-negative text-16-normal"
        >
          <span>{t('duel.button_go_back')}</span>
        </Button>
      </div>
    </div>
  );
};

export default DuelError;
