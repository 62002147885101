import AxisBottom from 'components/Charts/AxisBottom';
import AxisRight from 'components/Charts/AxisRight';
import Bar from 'components/Charts/Bar';
import UncomingBar from 'components/Charts/UncomingBar';
import { scaleBand, ScaleBand, scaleLinear, ScaleLinear } from 'd3-scale';
import { FluidType, TimeStep } from 'enums';
import { DateTime } from 'luxon';
import { Dataload, UserChallenge } from 'models';
import { useAppSelector } from 'store/hooks';

interface BarChartProps {
  userChallenge: UserChallenge;
  finishedDataLoad?: Dataload[];
  average: number;
  timeStep: TimeStep;
  width?: number;
  height?: number;
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  marginBottom?: number;
}

const DuelBar = ({
  userChallenge,
  finishedDataLoad,
  timeStep,
  average,
  width = 600,
  height = 400,
  marginLeft = 10,
  marginRight = 50,
  marginTop = 20,
  marginBottom = 50,
}: BarChartProps) => {
  const { currentDataload } = useAppSelector((state) => state.challenge);
  const dataload: Dataload[] = finishedDataLoad
    ? finishedDataLoad
    : currentDataload;
  const getContentWidth = () => {
    return width - marginLeft - marginRight;
  };

  const getContentHeight = () => {
    return height - marginTop - marginBottom;
  };

  const getMaxLoad = () => {
    return dataload ? Math.max(...dataload.map((d: Dataload) => d.value)) : 0;
  };

  const xScale: ScaleBand<string> = scaleBand()
    .domain(
      dataload.map((d: Dataload) =>
        d.date.toLocaleString(DateTime.DATETIME_SHORT)
      )
    )
    .range([0, getContentWidth()])
    .padding(0.2);

  const yScale: ScaleLinear<number, number> = scaleLinear()
    .domain([0, getMaxLoad() > average ? getMaxLoad() : average * 2])
    .range([getContentHeight(), 0]);

  const isUpcoming = (dataload: Dataload): boolean => {
    let completedValueDetail = true;
    if (dataload.valueDetail) {
      dataload.valueDetail.forEach((value) => {
        if (!value) completedValueDetail = false;
      });
    }
    if (!dataload.valueDetail || !completedValueDetail) {
      const dateDiff = DateTime.local()
        .setZone('utc', { keepLocalTime: true })
        .diff(dataload.date, 'days')
        .toObject();
      if (
        dateDiff.days !== undefined &&
        userChallenge !== undefined &&
        0 < dateDiff.days
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <svg width={width} height={height}>
      <AxisRight
        fluidType={FluidType.MULTIFLUID}
        yScale={yScale}
        width={width}
        marginRight={marginRight - 10}
        marginTop={marginTop}
      />
      <g transform={`translate(${marginLeft},${marginTop})`}>
        {dataload.map((d: Dataload, index: number) => {
          if (!isUpcoming(d)) {
            return (
              <Bar
                key={index}
                index={index}
                dataload={d}
                compareDataload={null}
                fluidType={FluidType.MULTIFLUID}
                timeStep={timeStep}
                compare={false}
                xScale={xScale}
                yScale={yScale}
                height={getContentHeight()}
                isSwitching={false}
                isDuel={true}
              />
            );
          } else {
            return (
              <UncomingBar
                key={index}
                index={index}
                dataload={d}
                xScale={xScale}
                yScale={yScale}
                height={getContentHeight()}
                average={average / 2}
              />
            );
          }
        })}
        <line
          transform={`translate(0, ${yScale(average)})`}
          strokeDasharray="10"
          x1="0"
          y1="0"
          x2={width - marginRight}
          y2="0"
          className="bar-average"
        />
      </g>
      <AxisBottom
        data={dataload}
        timeStep={timeStep}
        xScale={xScale}
        height={height}
        marginLeft={marginLeft}
        marginBottom={marginBottom}
        isDuel={true}
      />
    </svg>
  );
};

export default DuelBar;
