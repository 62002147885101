import Button from '@mui/material/Button';
import ecolyoIcon from 'assets/icons/ico/ecolyo.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import { useTranslation } from 'react-i18next';
import { InitStepsErrors } from 'models';
import React from 'react';
import './splashScreen.scss';

const SplashScreenError = ({ error }: { error: InitStepsErrors }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="splash-content">
        <div className="splash-loader">
          <StyledIcon icon={ecolyoIcon} width={130} className="error" />
          <span>Eco-Gagnant</span>
          <div className="splash-error-text text-20-bold">
            {t('splashscreen.error_loading')}
          </div>
          <div className="splash-error-text text-18-normal">
            {t(`splashscreen.${error}`)}
          </div>
        </div>
      </div>
      <div className="splash-footer">
        <Button
          aria-label={t('splashscreen.accessibility.button_reload')}
          onClick={() => window.location.reload()}
          className="splash-footer-button btn-highlight text-16-bold"
        >
          <span>{t('splashscreen.button_reload')}</span>
        </Button>
      </div>
    </>
  );
};

export default SplashScreenError;
