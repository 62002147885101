import React from 'react';
import './gcuContent.scss';
import { useTranslation } from 'react-i18next';
const GCUContent = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="gcu-content-root">
      <div className="gcu-content-wrapper">
        <p className="text-14-normal version">{t('gcu.version')}</p>

        <div className="gcu-content-part-title text-15-normal">
          {t('gcu.content.title1')}
        </div>
        <p className="text-14-normal">{t('gcu.content.part1_1')}</p>
        <p className="text-14-normal">{t('gcu.content.part1_2')}</p>
        <p className="text-14-normal">{t('gcu.content.part1_3')}</p>
        <div className="gcu-content-part-title text-15-normal">
          {t('gcu.content.title2')}
        </div>
        <p className="text-14-normal">{t('gcu.content.part2_1')}</p>
        <p className="text-14-normal">{t('gcu.content.part2_2')}</p>
        <ul className="text-14-normal">
          <li>{t('gcu.content.part2_2_list1')}</li>
          <li>{t('gcu.content.part2_2_list2')}</li>
          <li>{t('gcu.content.part2_2_list3')}</li>
        </ul>
        <p className="text-14-normal">{t('gcu.content.part2_3')}</p>
        <p className="text-14-normal">{t('gcu.content.part2_4')}</p>
        <div className="gcu-content-part-title text-15-normal">
          {t('gcu.content.title3')}
        </div>
        <p className="text-14-normal">{t('gcu.content.part3_1')}</p>
        <p className="text-14-normal">{t('gcu.content.part3_2')}</p>
        <p className="text-14-normal">{t('gcu.content.part3_3')}</p>
        <p className="text-14-normal">{t('gcu.content.part3_4')}</p>
        <div className="gcu-content-part-title text-15-normal">
          {t('gcu.content.title4')}
        </div>
        <p className="text-14-normal">{t('gcu.content.part4_1')}</p>
        <p
          className="text-14-normal"
          dangerouslySetInnerHTML={{ __html: t('gcu.content.part4_2') }}
        />
        <div className="gcu-content-part-title text-15-normal">
          {t('gcu.content.title5')}
        </div>
        <p className="text-14-normal">{t('gcu.content.part5_1')}</p>
        <p className="text-14-normal">{t('gcu.content.part5_2')}</p>
        <p className="text-14-normal">{t('gcu.content.part5_3')}</p>
        <ul className="text-14-normal">
          <li>{t('gcu.content.part5_3_list1')}</li>
          <li>{t('gcu.content.part5_3_list2')}</li>
        </ul>
        <p className="text-14-normal">{t('gcu.content.part5_4')}</p>
        <p className="text-14-normal">{t('gcu.content.part5_5')}</p>
        <div className="gcu-content-part-title text-15-normal">
          {t('gcu.content.title6')}
        </div>
        <p className="text-14-normal">{t('gcu.content.part6_1')}</p>
        <p className="text-14-normal">{t('gcu.content.part6_2')}</p>
        <p className="text-14-normal">{t('gcu.content.part6_3')}</p>
        <ul className="text-14-normal">
          <li>{t('gcu.content.part6_3_list1')}</li>
          <li>{t('gcu.content.part6_3_list2')}</li>
          <li>{t('gcu.content.part6_3_list3')}</li>
        </ul>
        <p className="text-14-normal">{t('gcu.content.part6_4')}</p>
        <p className="text-14-normal">{t('gcu.content.part6_5')}</p>
        <div className="gcu-content-part-title text-15-normal">
          {t('gcu.content.title8')}
        </div>
        <p className="text-14-normal">{t('gcu.content.part8_1')}</p>
        <p
          className="text-14-normal"
          dangerouslySetInnerHTML={{ __html: t('gcu.content.part8_2') }}
        />
        <div className="gcu-content-part-title text-15-normal">
          {t('gcu.content.title9')}
        </div>
        <p className="text-14-normal">
          <span className="text-14-bold">{t('gcu.content.part9_1_title')}</span>
          <span>{t('gcu.content.part9_1_content')}</span>
        </p>
        <p className="text-14-normal">
          <span className="text-14-bold">{t('gcu.content.part9_2_title')}</span>
          <span>{t('gcu.content.part9_2_content')}</span>
        </p>
        <p className="text-14-normal">
          <span className="text-14-bold">{t('gcu.content.part9_3_title')}</span>
          <span>{t('gcu.content.part9_3_content')}</span>
        </p>
        <p className="text-14-normal">
          <span className="text-14-bold">{t('gcu.content.part9_4_title')}</span>
          <span
            dangerouslySetInnerHTML={{
              __html: t('gcu.content.part9_4_content'),
            }}
          />
        </p>
        <p className="text-14-normal">
          <span className="text-14-bold">{t('gcu.content.part9_5_title')}</span>
          <span>{t('gcu.content.part9_5_content')}</span>
        </p>
      </div>
    </div>
  );
};

export default GCUContent;
