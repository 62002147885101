import CaptionAverageIcon from 'assets/icons/visu/duel/captionAverage.svg';
import CaptionConsumptionIcon from 'assets/icons/visu/duel/captionConsumption.svg';
import CaptionIncomingIcon from 'assets/icons/visu/duel/captionIncoming.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import { useChartResize } from 'hooks/useChartResize';
import { useTranslation } from 'react-i18next';
import {
  UserChallengeSuccess,
  UserChallengeUpdateFlag,
  UserDuelState,
} from 'enums';
import { Dataload, UserChallenge, UserDuel } from 'models';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import ChallengeService from 'services/challenge.service';
import {
  unlockNextUserChallenge,
  updateUserChallengeList,
} from 'store/challenge/challenge.slice';
import { toggleChallengeDuelNotification } from 'store/global/global.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { formatNumberValues } from 'utils/utils';
import DuelChart from '../DuelChart/DuelChart';
import DuelResultModal from '../DuelResultModal/DuelResultModal';
import LastDuelModal from '../LastDuelModal/lastDuelModal';
import './duelOngoing.scss';
import AppAxiosService from 'services/appAxios.service';

interface DuelOngoingProps {
  userChallenge: UserChallenge;
  isFinished?: boolean;
}

const DuelOngoing = ({ userChallenge, isFinished }: DuelOngoingProps) => {
  const { t } = useTranslation();
  const {
    currentDataload,
    challengesList,
    userChallengeProgress,
    ecogesturesList,
    explorationsList,
  } = useAppSelector((state) => state.challenge);
  const userSub = useAppSelector((store) => store.profileKeycloakUser.sub);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [resultModal, setResultModal] = useState<boolean>(false);
  const [winChallenge, setWinChallenge] = useState<boolean>(false);
  const [isLastDuel, setIsLastDuel] = useState<boolean>(false);
  const [finishedDataLoad, setFinishedDataLoad] = useState<Dataload[]>();
  const chartContainer = useRef<HTMLDivElement>(null);
  const { height, width } = useChartResize(chartContainer, false);
  const axiosClient = useMemo(() => new AppAxiosService(), []);
  const challengeService = useMemo(() => {
    if (!userChallengeProgress) return;
    if (!userSub) return;
    return new ChallengeService(
      axiosClient,
      userSub,
      userChallengeProgress,
      ecogesturesList,
      explorationsList
    );
  }, [userChallengeProgress, userSub]);

  const duel: UserDuel = userChallenge.duel;
  const title: string = duel.title;
  const durationInDays: number = duel.duration.days;

  const userConsumption: string = formatNumberValues(
    userChallenge.duel.userConsumption
  ).toString();
  const average: string = formatNumberValues(
    userChallenge.duel.threshold
  ).toString();

  const setResult = useCallback(async () => {
    if (!challengeService) return;

    const updatedChallenge = await challengeService.updateUserChallenge(
      userChallenge,
      winChallenge
        ? UserChallengeUpdateFlag.DUEL_WIN
        : UserChallengeUpdateFlag.DUEL_LOSS
    );

    dispatch(updateUserChallengeList(updatedChallenge));
    dispatch(unlockNextUserChallenge(updatedChallenge));
    dispatch(toggleChallengeDuelNotification(false));

    if (userChallenge.id == challengesList[challengesList.length - 1].id) {
      setIsLastDuel(true);
    } else {
      navigate('/challenges');
    }
  }, [userChallenge, winChallenge, dispatch, challengesList, challengeService]);

  useEffect(() => {
    let subscribed = true;
    async function setChallengeResult() {
      if (!challengeService) return;

      const { isDone, isWin } = await challengeService.isChallengeDone(
        userChallenge,
        currentDataload
      );
      if (subscribed) {
        setResultModal(isDone);
        setWinChallenge(isWin);
      }
    }

    setChallengeResult();

    return () => {
      subscribed = false;
    };
  }, [challengeService, currentDataload, userChallenge]);

  useEffect(() => {
    let subscribed = true;
    async function populateData() {
      if (!challengeService) return;

      if (isFinished) {
        const dataloads: Dataload[] =
          await challengeService.getUserChallengeDataload(userChallenge);
        if (subscribed) {
          setFinishedDataLoad(dataloads);
        }
      }
    }
    populateData();
    return () => {
      subscribed = false;
    };
  }, [challengeService, dispatch, isFinished, userChallenge]);

  return (
    <>
      <div className="duel-ongoing-container">
        <div className="duel-title text-16-normal">{title}</div>
        {duel.state === UserDuelState.DONE ? (
          <div className="duel-goal text-26-bold">
            {userChallenge.success === UserChallengeSuccess.WIN
              ? t('duel.goal_done')
              : t('duel.goal_failed')}
          </div>
        ) : (
          <div className="duel-goal text-18-normal">
            {t('duel.goal1', {
              durationInDays,
              smartCount: durationInDays,
            })}
            <span> </span>
            {t('duel.goal2', {
              title,
              smartCount: title,
            })}
          </div>
        )}
        <div className="duel-consumption text-28-normal">
          <span className="consumption">{userConsumption}</span>
          {` / ${average} €`}
        </div>
        <div className="duel-chart fs-root" ref={chartContainer}>
          <DuelChart
            userChallenge={userChallenge}
            finishedDataLoad={finishedDataLoad}
            width={width}
            height={height}
          />
        </div>
        <div className="duel-chart-caption text-15-normal">
          <div className="duel-caption">
            <StyledIcon
              className="caption-icon"
              icon={CaptionAverageIcon}
              size={25}
            />
            <div className="caption-label">{t('duel.caption_average')}</div>
          </div>
          <div className="duel-caption">
            <StyledIcon
              className="caption-icon"
              icon={CaptionConsumptionIcon}
              size={25}
            />
            <div className="caption-label">{t('duel.caption_consumption')}</div>
          </div>
          <div className="duel-caption">
            <StyledIcon
              className="caption-icon"
              icon={CaptionIncomingIcon}
              size={25}
            />
            <div className="caption-label">{t('duel.caption_incoming')}</div>
          </div>
        </div>
      </div>
      <DuelResultModal
        open={resultModal}
        userChallenge={userChallenge}
        win={winChallenge}
        handleCloseClick={setResult}
      />
      <LastDuelModal
        open={isLastDuel}
        handleCloseClick={() => navigate('/challenges')}
      />
    </>
  );
};

export default DuelOngoing;
