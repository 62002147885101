import Content from 'components/Content/Content';
import Header from 'components/Header/Header';
import { UserQuizState } from 'enums';
import { UserChallenge } from 'models';
import React, { useState } from 'react';
import { useAppSelector } from 'store/hooks';
import QuizBegin from './QuizBegin/QuizBegin';
import QuizFinish from './QuizFinish/QuizFinish';
import QuizQuestion from './QuizQuestion/QuizQuestion';

const QuizView = () => {
  const { currentChallenge } = useAppSelector((state) => state.challenge);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  const renderQuiz = (challenge: UserChallenge) => {
    switch (challenge.quiz.state) {
      case UserQuizState.UNLOCKED:
        return <QuizBegin userChallenge={challenge} />;
      case UserQuizState.ONGOING:
        return <QuizQuestion userChallenge={challenge} />;
      case UserQuizState.DONE:
        return <QuizFinish userChallenge={challenge} />;
      default:
        return <QuizBegin userChallenge={challenge} />;
    }
  };

  return (
    <>
      <Header
        setHeaderHeight={setHeaderHeight}
        desktopTitleKey="common.title_quiz"
        displayBackArrow={true}
      />
      <Content heightOffset={headerHeight}>
        {currentChallenge && renderQuiz(currentChallenge)}
      </Content>
    </>
  );
};

export default QuizView;
