import Button from '@mui/material/Button';
import defaultIcon from 'assets/icons/visu/challenge/challengeLocked.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import { useTranslation } from 'react-i18next';
import { FluidState, UsageEventType } from 'enums';
import { UserChallenge } from 'models';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { updateUserChallengeList } from 'store/challenge/challenge.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getChallengeTitleWithLineReturn, importIconById } from 'utils/utils';
import ChallengeNoFluidModal from '../ChallengeNoFluidModal/ChallengeNoFluidModal';
import './challengeCardUnlocked.scss';
import { getChallengeIconId } from 'utils/ecoGagnant';
import AppAxiosService from 'services/appAxios.service';
import ChallengeService from 'services/challenge.service';

const ChallengeCardUnlocked = ({
  userChallenge,
}: {
  userChallenge: UserChallenge;
}) => {
  const { t } = useTranslation();
  const userSub = useAppSelector((store) => store.profileKeycloakUser.sub);
  const { userChallengeProgress, ecogesturesList, explorationsList } =
    useAppSelector((store) => store.challenge);
  const axiosClient = useMemo(() => new AppAxiosService(), []);
  const challengeService = useMemo(() => {
    if (!userChallengeProgress) return;
    if (!userSub) return;
    return new ChallengeService(
      axiosClient,
      userSub,
      userChallengeProgress,
      ecogesturesList,
      explorationsList
    );
  }, [userChallengeProgress, userSub]);
  const { fluidTypes, fluidStatus } = useAppSelector((state) => state.global);
  const { currentChallenge } = useAppSelector((state) => state.challenge);
  const dispatch = useAppDispatch();
  const [openNoFluidModal, setopenNoFluidModal] = useState(false);
  const [challengeIcon, setChallengeIcon] = useState(defaultIcon);
  let statusRequirementOk = false;

  const toggleNoFluidModal = useCallback(() => {
    setopenNoFluidModal((prev) => !prev);
  }, []);

  fluidStatus.forEach((fluid) => {
    if (
      fluid.status !== FluidState.NOT_CONNECTED &&
      fluid.status !== FluidState.KONNECTOR_NOT_FOUND
    ) {
      statusRequirementOk = true;
    }
  });

  const launchChallenge = async () => {
    if (!challengeService) return;

    if (fluidTypes.length > 0 && statusRequirementOk) {
      const updatedChallenge =
        await challengeService.startUserChallenge(userChallenge);

      dispatch(updateUserChallengeList(updatedChallenge));
    } else {
      return toggleNoFluidModal();
    }
  };

  useEffect(() => {
    async function handleEcogestureIcon() {
      const icon = await importIconById(
        getChallengeIconId(userChallenge),
        'challenge'
      );
      if (icon) {
        setChallengeIcon(icon);
      }
    }
    if (userChallenge) {
      handleEcogestureIcon();
    }
  }, [userChallenge]);

  return (
    <>
      <div className="cardContent cardUnlocked">
        <span className="challengeTitle">
          {getChallengeTitleWithLineReturn(getChallengeIconId(userChallenge))}
        </span>
        <StyledIcon className="challengeIcon" icon={challengeIcon} />
        <Button
          aria-label={t('challenge.accessibility.button_launch')}
          onClick={launchChallenge}
          className="btn-duel-active text-16-bold"
          disabled={currentChallenge !== null}
        >
          <span>{t('challenge.card_unlocked.button_launch')}</span>
        </Button>
      </div>
      <ChallengeNoFluidModal
        open={openNoFluidModal}
        handleCloseClick={toggleNoFluidModal}
      />
    </>
  );
};

export default ChallengeCardUnlocked;
