import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CustomPopup, ModalState } from 'models'

const initialState: ModalState = {
  customPopupModal: {
    popupEnabled: false,
    title: '',
    description: '',
    endDate: '',
  },
  isConnectionModalOpen: false,
  isFeedbacksOpen: false,
  partnersIssueModal: {
    enedis: false,
    suez: false,
    grdf: false,
  },
};

type OpenPartnersModalAction = PayloadAction<{
  suez: boolean;
  enedis: boolean;
  grdf: boolean;
}>;

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openFeedbackModal: (state, action: PayloadAction<boolean>) => {
      state.isFeedbacksOpen = action.payload
    },
    openPartnersModal: (state, action: OpenPartnersModalAction) => {
      state.partnersIssueModal = action.payload
    },
    openConnectionModal: (state, action: PayloadAction<boolean>) => {
      state.isConnectionModalOpen = action.payload
    },
    setCustomPopup: (state, action: PayloadAction<CustomPopup>) => {
      state.customPopupModal = action.payload
    },
  },
})

export const {
  openFeedbackModal,
  openPartnersModal,
  setCustomPopup,
  openConnectionModal,
} = modalSlice.actions
