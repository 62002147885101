import { DUEL_DOCTYPE } from 'doctypes';
import { FluidState, FluidType, TimeStep, UserDuelState } from 'enums';
import { DateTime, Duration } from 'luxon';
import {
  Datachart,
  Dataload,
  DuelEntity,
  FluidStatus,
  PerformanceIndicator,
  TimePeriod,
  UserDuel,
} from 'models';
import { getRoundFloat } from 'utils/math';
import ConsumptionService from './consumption.service';
import PerformanceService from './performanceIndicator.service';
import StellioAxiosClient from './appAxios.service';

export default class DuelService {
  private readonly _client: StellioAxiosClient;
  private readonly _userSub: string;

  constructor(_client: StellioAxiosClient, userSub: string) {
    this._client = _client;
    this._userSub = userSub;
  }

  /**
   * Retrieve period with data based on lastDataDate of the fluids
   * @param {FluidStatus[]} fluidStatus - status of fluids
   * @param {Duel} userDuel
   * @returns {TimePeriod} - true when deleted with success
   */
  private async getValidPeriod(
    fluidStatus: FluidStatus[],
    fluidTypes: FluidType[],
    userDuel: UserDuel
  ): Promise<TimePeriod | false> {
    let lastDate: DateTime = DateTime.local().setZone('utc', {
      keepLocalTime: true,
    });
    fluidStatus.forEach((fluid) => {
      if (
        fluid.status !== FluidState.KONNECTOR_NOT_FOUND &&
        fluid.status !== FluidState.NOT_CONNECTED &&
        fluid.lastDataDate !== null &&
        fluid.lastDataDate < lastDate
      ) {
        lastDate = fluid.lastDataDate;
      }
    });
    lastDate = lastDate.startOf('day');
    let validPeriod: TimePeriod | false = {
      startDate: lastDate.minus({
        day: userDuel.duration.days - 1,
      }),
      endDate: lastDate,
    };
    validPeriod = await this.isPeriodComplete(
      validPeriod,
      fluidTypes,
      userDuel.duration
    );
    return validPeriod;
  }

  /**
   * Check all period until find one with no empty value or return false
   * @param period
   * @param fluidType
   * @param duration
   * @returns {Promise<false | TimePeriod>}
   */
  private async isPeriodComplete(
    period: TimePeriod,
    fluidType: FluidType[],
    duration: Duration
  ): Promise<false | TimePeriod> {
    const maxDiffperiod: Duration = Duration.fromObject({
      months: 6,
    });
    const maxDiffperiodMilli: number = maxDiffperiod.as('milliseconds');
    let isComplete = true;
    const newPeriod: TimePeriod = {
      startDate: period.startDate.minus(duration),
      endDate: period.endDate.minus(duration),
    };
    let diffFromNow = newPeriod.endDate.diffNow().toObject().milliseconds;
    diffFromNow = diffFromNow ? Math.abs(diffFromNow) : 0;
    const consumptionService = new ConsumptionService(
      this._client,
      this._userSub
    );
    const dataLoad: Datachart | null = await consumptionService.getGraphData(
      period,
      TimeStep.DAY,
      fluidType,
      undefined,
      undefined,
      true
    );
    if (dataLoad?.actualData) {
      dataLoad.actualData.forEach((d: Dataload) => {
        if (
          d.value === -1 ||
          (d.valueDetail &&
            d.valueDetail.filter((data) => data.value === -1).length > 0)
        )
          isComplete = false;
      });
    }
    if (diffFromNow && !isComplete && diffFromNow > maxDiffperiodMilli)
      return false;

    return isComplete
      ? period
      : await this.isPeriodComplete(newPeriod, fluidType, duration);
  }
  /**
   * Retrieve all duel entities from db
   * @returns {DuelEntity[]}
   */
  public async getAllDuelEntities(): Promise<DuelEntity[]> {
    // const query: QueryDefinition = Q(DUEL_DOCTYPE)
    // const { data: dueles }: QueryResult<DuelEntity[]> =
    //   await this._client.query(query)
    // return dueles
    return [];
  }

  /**
   * Retrieve duel entities from db given the id
   *
   * @param {string} duelId - ID of the searched duel
   * @returns {DuelEntity}
   */
  public async getDuelEntityById(duelId: string): Promise<DuelEntity> {
    // const query: QueryDefinition = Q(DUEL_DOCTYPE)
    //   .where({ _id: duelId })
    //   .limitBy(1)
    // const { data }: QueryResult<DuelEntity[]> = await this._client.query(query)
    // return data?.[0]
    return {} as DuelEntity;
  }

  /**
   * Delete all duel entities from the db
   * @returns {boolean} - true when deleted with success
   * @throws {Error}
   */
  public async deleteAllDuelEntities(): Promise<boolean> {
    try {
      const duels = await this.getAllDuelEntities();
      if (!duels) return true;
      for (const duel of duels) {
        // await this._client.destroy(duel)
      }
      return true;
    } catch (error) {
      const errorMessage = `deleteAllDuelEntities:${JSON.stringify(error)}`;
      throw error;
    }
  }

  /**
   * Return duel with updated state to UserDuelState.UNLOCKED
   * @param {UserDuel} userDuel - userDuel to unlock
   * @returns {UserDuel}
   */
  public async unlockUserDuel(userDuel: UserDuel): Promise<UserDuel> {
    const updatedUserDuel: UserDuel = {
      ...userDuel,
      state: UserDuelState.UNLOCKED,
    };
    return updatedUserDuel;
  }
  private getFluidTypesFromStatus(fluidStatus: FluidStatus[]): FluidType[] {
    const fluidTypes: FluidType[] = [];
    fluidStatus.forEach((fluid) => {
      if (
        fluid.status !== FluidState.KONNECTOR_NOT_FOUND &&
        fluid.status !== FluidState.NOT_CONNECTED
      ) {
        fluidTypes.push(fluid.fluidType);
      }
    });
    return fluidTypes.sort();
  }

  /**
   * Return duel with updated thrshold and fluidTypes
   * @param {UserDuel} userDuel - userDuel to update
   * @returns {UserDuel}
   */
  public async updateUserDuelThreshold(
    userDuel: UserDuel,
    fluidStatus: Array<FluidStatus> = []
  ): Promise<UserDuel> {
    const consumptionService = new ConsumptionService(
      this._client,
      this._userSub
    );
    const performanceService = new PerformanceService();
    const fluidTypes: Array<FluidType> =
      this.getFluidTypesFromStatus(fluidStatus);
    // Get last period with all days known
    const period: TimePeriod | false = await this.getValidPeriod(
      fluidStatus,
      fluidTypes,
      userDuel
    );
    if (period !== false) {
      // Fetch performance data
      const fetchLastValidData: Array<PerformanceIndicator> =
        await consumptionService.getPerformanceIndicators(
          period,
          TimeStep.DAY,
          fluidTypes
        );
      const maxData: PerformanceIndicator =
        performanceService.aggregatePerformanceIndicators(fetchLastValidData);
      // Set the threshold
      let updatedThreshold: number;
      if (maxData?.value && maxData.value > 0) {
        updatedThreshold = getRoundFloat(maxData.value);
      } else {
        updatedThreshold = -1;
      }
      const updatedUserDuel: UserDuel = {
        ...userDuel,
        state: UserDuelState.UNLOCKED,
        threshold: updatedThreshold,
        fluidTypes: fluidTypes,
      };
      return updatedUserDuel;
    } else {
      const updatedUserDuel: UserDuel = {
        ...userDuel,
        state: UserDuelState.NO_REF_PERIOD_VALID,
      };
      return updatedUserDuel;
    }
  }

  /**
   * Return duel with updated state to UserDuelState.ONGOING and startDate
   * @param {UserDuel} userDuel - userDuel to update
   * @returns {UserDuel}
   */
  public async startUserDuel(userDuel: UserDuel): Promise<UserDuel> {
    const updatedUserDuel: UserDuel = {
      ...userDuel,
      state: UserDuelState.ONGOING,
      startDate: DateTime.local()
        .setZone('utc', { keepLocalTime: true })
        .startOf('day'),
    };
    return updatedUserDuel;
  }

  /**
   * Return duel with updated state to UserDuelState.DONE
   * @param {UserDuel} userDuel - userDuel to update
   * @returns {UserDuel}
   */
  public async endUserDuel(userDuel: UserDuel): Promise<UserDuel> {
    const updatedUserDuel: UserDuel = {
      ...userDuel,
      state: UserDuelState.DONE,
    };
    return updatedUserDuel;
  }

  /**
   * Return duel with updated state to UserDuelState.UNLOCKED
   * @param {UserDuel} userDuel - userDuel to reset
   * @returns {UserDuel}
   */
  public async resetUserDuel(userDuel: UserDuel): Promise<UserDuel> {
    return {
      ...userDuel,
      startDate: null,
      state: UserDuelState.UNLOCKED,
      threshold: 0,
      userConsumption: 0,
    };
  }

  /**
   * Return duel created from duel entity
   * @param {DuelEntity} duel - userDuel to update
   * @returns {Duel}
   */
  public parseDuelEntityToDuel(duel: DuelEntity): UserDuel {
    const userDuel: UserDuel = {
      id: duel.id,
      title: duel.title,
      description: duel.description,
      duration: duel.duration,
      threshold: 0,
      state: UserDuelState.LOCKED,
      startDate: null,
      fluidTypes: [],
      userConsumption: 0,
    };
    return userDuel;
  }

  /**
   * Return duel created from duel entity
   * @param {DuelEntity[]} duelEntityList - userDuel to update
   * @param {string} searchId - userDuel to update
   * @returns {UserDuel}
   */
  public getDuelfromDuelEntities(
    duelEntityList: DuelEntity[],
    searchId: string
  ): UserDuel {
    let duel: UserDuel = {
      id: '',
      title: '',
      description: '',
      duration: Duration.fromObject({ day: 0 }),
      threshold: 0,
      state: UserDuelState.LOCKED,
      startDate: null,
      fluidTypes: [],
      userConsumption: 0,
    };
    if (duelEntityList.length > 0) {
      const duelEntityIndex = duelEntityList.findIndex(
        (entity) => entity.id === searchId
      );
      if (duelEntityIndex >= 0) {
        const duelEntity: DuelEntity = duelEntityList[duelEntityIndex];
        duel = this.parseDuelEntityToDuel(duelEntity);
      }
    }
    return duel;
  }
}
