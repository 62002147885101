import { Button, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from 'assets/icons/ico/close.svg';
import { useTranslation } from 'react-i18next';

import React from 'react';
import './ecogestureSelectionModal.scss';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';

interface EcogestureSelectionModalProps {
  open: boolean;
  handleCloseClick: () => void;
}

const EcogestureSelectionModal = ({
  open,
  handleCloseClick,
}: EcogestureSelectionModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleCloseClick}
      aria-labelledby="accessibility-title"
      classes={{
        root: 'modal-root',
        paper: 'modal-paper',
      }}
    >
      <div id="accessibility-title">
        {t('ecogesture_selection.accessibility.window_title')}
      </div>
      <IconButton
        aria-label={t('ecogesture_selection.accessibility.button_close')}
        className="modal-paper-close-button"
        onClick={handleCloseClick}
      >
        <StyledIcon icon={CloseIcon} width={16} />
      </IconButton>
      <div className="eg-selection-modal">
        <div className="title text-20-bold">
          {t('ecogesture_selection.selectionModal.title')}
        </div>
        <div className="text text-16-normal">
          {t('ecogesture_selection.selectionModal.text')}
        </div>
        <Button
          aria-label={t('ecogesture_selection.selectionModal.button_close')}
          onClick={handleCloseClick}
          className="btn-highlight text-16-bold"
        >
          <span>{t('ecogesture_selection.selectionModal.button_close')}</span>
        </Button>
      </div>
    </Dialog>
  );
};

export default EcogestureSelectionModal;
