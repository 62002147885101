import { UserExplorationState } from 'enums';
import { UserExploration } from 'models';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import AppAxiosService from 'services/appAxios.service';
import ExplorationService from 'services/exploration.service';
import { updateUserChallengeList } from 'store/challenge/challenge.slice';
import { toggleChallengeExplorationNotification } from 'store/global/global.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const useExploration = (): [string, Dispatch<SetStateAction<string>>] => {
  const dispatch = useAppDispatch();
  const {
    currentChallenge,
    explorationsList,
    userChallengeProgress,
    ecogesturesList,
  } = useAppSelector((state) => state.challenge);
  const userSub = useAppSelector((store) => store.profileKeycloakUser.sub);
  const exploration: UserExploration | null = currentChallenge
    ? currentChallenge.exploration
    : null;

  const ecogestureId: string = exploration ? exploration.ecogesture_id : '';

  const [explorationID, setExplorationID] = useState<string>('');

  useEffect(() => {
    let subscribed = true;

    if (
      explorationID &&
      currentChallenge?.exploration.id === explorationID &&
      currentChallenge?.exploration.state === UserExplorationState.ONGOING
    ) {
      const checkExplo = async () => {
        if (!userChallengeProgress) {
          return console.warn('userChallengeProgress not set');
        }
        if (!userSub) {
          return console.warn('userSub not set');
        }

        const client = new AppAxiosService();
        const explorationService = new ExplorationService(
          client,
          userSub,
          explorationsList,
          userChallengeProgress,
          ecogesturesList
        );
        explorationService
          .checkExploration(currentChallenge, explorationID)
          .then((updatedChallenge) => {
            dispatch(updateUserChallengeList(updatedChallenge));
            if (
              updatedChallenge.exploration.state ===
              UserExplorationState.NOTIFICATION
            ) {
              dispatch(toggleChallengeExplorationNotification(true));
            }
          });
        if (subscribed) setExplorationID('');
      };
      checkExplo();
    }

    return () => {
      subscribed = false;
    };
  }, [
    exploration,
    explorationID,
    ecogestureId,
    currentChallenge,
    dispatch,
    userChallengeProgress,
    explorationsList,
    ecogesturesList,
    userSub,
  ]);

  return [explorationID, setExplorationID];
};

export default useExploration;
