import Dialog from '@mui/material/Dialog';
import CloseIcon from 'assets/icons/ico/close.svg';
import star from 'assets/icons/visu/duel/star.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';

import React from 'react';
import './lastDuelModal.scss';
import { useTranslation } from 'react-i18next';

interface LastDuelModalProps {
  open: boolean;
  handleCloseClick: () => void;
}

const LastDuelModal = ({ open, handleCloseClick }: LastDuelModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleCloseClick}
      aria-labelledby="accessibility-title"
      classes={{
        root: 'modal-root',
        paper: 'modal-paper blue-light-border',
      }}
    >
      <div id="accessibility-title">{t('last_duel_modal.title')}</div>
      <div className="duel-last-modal-root">
        <div onClick={handleCloseClick}>
          <StyledIcon className="closeIcon" icon={CloseIcon} width={16} />
        </div>
        <StyledIcon className="icon" icon={star} width={48} />
        <div className="text-28-bold title">{t('last_duel_modal.title')}</div>
        <div className="text-22-bold subtitle">
          {t('last_duel_modal.subtitle')}
        </div>
        <div className="text-18-normal content">
          {t('last_duel_modal.message1')}
        </div>
        <div className="text-18-normal content">
          {t('last_duel_modal.message2')}
        </div>
      </div>
    </Dialog>
  );
};

export default LastDuelModal;
