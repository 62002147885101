import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ConstructionYear,
  Floor,
  FluidType,
  HotWaterEquipment,
  HotWaterFluid,
  HousingType,
  IndividualInsulationWork,
  IndividualOrCollective,
  OutsideFacingWalls,
  ThreeChoicesAnswer,
  WarmingType,
} from 'enums';
import { DateTime } from 'luxon';
import { ProfileType } from 'models';
import { StellioUserProfile } from 'models/stellio.model';

const initialState: ProfileType = {
  housingType: HousingType.INDIVIDUAL_HOUSE,
  constructionYear: ConstructionYear.BETWEEN_1975_AND_1989,
  area: '100',
  occupantsNumber: 4,
  outsideFacingWalls: OutsideFacingWalls.TWO,
  floor: Floor.NOT_APPLICABLE,
  heating: IndividualOrCollective.INDIVIDUAL,
  coldWater: IndividualOrCollective.INDIVIDUAL,
  hotWater: IndividualOrCollective.INDIVIDUAL,
  individualInsulationWork: [
    IndividualInsulationWork.WINDOW_REPLACEMENT,
    IndividualInsulationWork.WALL_INSULATION,
  ],
  hasInstalledVentilation: ThreeChoicesAnswer.UNKNOWN,
  hasReplacedHeater: ThreeChoicesAnswer.UNKNOWN,
  hotWaterEquipment: HotWaterEquipment.SOLAR,
  warmingFluid: WarmingType.ELECTRICITY,
  hotWaterFluid: HotWaterFluid.ELECTRICITY,
  cookingFluid: FluidType.ELECTRICITY,
  updateDate: DateTime.fromISO('0000-01-01T00:00:00.000Z'),
  equipments: [],
};

const profileTypeKeys = Object.keys(initialState);

export const profileTypeSlice = createSlice({
  name: 'profileType',
  initialState,
  reducers: {
    setProfileType: (state, action: PayloadAction<Partial<ProfileType>>) => {
      Object.assign(state, action.payload);
    },
    setProfileTypeFromStellioUser: (
      state,
      action: PayloadAction<Partial<StellioUserProfile>>
    ) => {
      const profileTypeToUpdate = { ...state };

      Object.keys(action.payload).forEach((key) => {
        if (key !== 'id' && key !== 'type' && profileTypeKeys.includes(key)) {
          profileTypeToUpdate[key] = action.payload[key].value;
        }
      });

      Object.assign(state, profileTypeToUpdate);
    },
  },
});

export const { setProfileType, setProfileTypeFromStellioUser } =
  profileTypeSlice.actions;
