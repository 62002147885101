import { useCallback, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Duration } from 'luxon';

// components
import Content from 'components/Content/Content';
import Header from 'components/Header/Header';
import NotificationsList from 'components/NotificationsView/NotificationsList/NotificationsList';
import NewNotificationTab from 'components/NotificationsView/NewNotificationTab/NewNotificationTab';
import 'components/NotificationsView/notificationsView.scss';
// enums
import { FluidType } from 'enums';
// models
import { StellioEvent } from 'models/stellio.model';
// utils
import { getFluidTypeWithFluidMonitoringId } from 'utils/utils';

export enum NotificationsTab {
  MY_NOTIFICATIONS = 0,
  NEW_NOTIFICATION = 1,
}

export interface FluidNotification {
  eventId: string;
  fluidType: FluidType;
  thresholdValue: number;
  thresholdAggrPeriod: Duration;
}

interface TabPanelProps {
  children: React.ReactNode;
  tab: NotificationsTab;
  value: NotificationsTab;
}

const TabPanel = ({ children, tab, value }: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== tab}
      id={`simple-tabpanel-${tab}`}
      aria-labelledby={`simple-tab-${tab}`}
    >
      {children}
    </div>
  );
};

const NotificationsView = () => {
  const { t } = useTranslation();
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const tab = new URLSearchParams(useLocation().search).get('tab');
  const [tabValue, setTabValue] = useState<NotificationsTab>(
    tab ? parseInt(tab) : NotificationsTab.MY_NOTIFICATIONS
  );
  const [notificationToEdit, setNotificationToEdit] = useState<
    FluidNotification | undefined
  >();
  const navigate = useNavigate();

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<object>, newValue: NotificationsTab) => {
      event.preventDefault();
      const params = new URLSearchParams();
      params.append('tab', newValue.toString());
      navigate({ search: params.toString() });
      setTabValue(newValue);
    },
    []
  );

  const handleOnEdit = (event: StellioEvent) => {
    const fluidType = getFluidTypeWithFluidMonitoringId(
      event.hasSubject.object
    );

    if (fluidType === undefined) return;

    setNotificationToEdit({
      eventId: event.id,
      fluidType,
      thresholdAggrPeriod: event.thresholdAggrPeriod.value,
      thresholdValue: event.thresholdValue.value,
    });
  };

  return (
    <>
      <Header setHeaderHeight={setHeaderHeight} desktopTitleKey="common.notifs">
        <Tabs
          value={tabValue}
          className="notifications-tabs"
          aria-label="notifications-tabs"
          onChange={handleTabChange}
          TabIndicatorProps={{ className: 'indicator-tab' }}
          centered={true}
        >
          <Tab
            label={t('notifications.myNotifs')}
            className={classNames('single-tab', {
              active: tabValue === NotificationsTab.MY_NOTIFICATIONS,
            })}
            id={`simple-tab-${NotificationsTab.MY_NOTIFICATIONS}`}
            aria-controls={`simple-tabpanel-${NotificationsTab.MY_NOTIFICATIONS}`}
            tabIndex={0}
          />
          <Tab
            label={
              notificationToEdit
                ? t('notifications.updateNotif')
                : t('notifications.newNotif')
            }
            className={classNames('single-tab', {
              active: tabValue === NotificationsTab.NEW_NOTIFICATION,
            })}
            id={`simple-tab-${NotificationsTab.NEW_NOTIFICATION}`}
            aria-controls={`simple-tabpanel-${NotificationsTab.NEW_NOTIFICATION}`}
            tabIndex={0}
          />
        </Tabs>
      </Header>
      <Content heightOffset={headerHeight} withOverFlow>
        <TabPanel value={tabValue} tab={NotificationsTab.MY_NOTIFICATIONS}>
          <NotificationsList
            setTab={setTabValue}
            tabValue={tabValue}
            onEdit={handleOnEdit}
            onClearNotificationToEdit={() => setNotificationToEdit(undefined)}
          />
        </TabPanel>

        <TabPanel value={tabValue} tab={NotificationsTab.NEW_NOTIFICATION}>
          <NewNotificationTab
            setTab={setTabValue}
            notificationToEdit={notificationToEdit}
            onClearNotificationToEdit={() => setNotificationToEdit(undefined)}
          />
        </TabPanel>
      </Content>
    </>
  );
};

export default NotificationsView;
