import { Ecogesture, UserChallenge } from 'models';
import React, { useState } from 'react';
import ActionBegin from '../ActionBegin/ActionBegin';
import ActionList from '../ActionList/ActionList';

const ActionChoose = ({ userChallenge }: { userChallenge: UserChallenge }) => {
  const [selectedAction, setSelectedAction] = useState<Ecogesture | null>(null);
  const [showList, setShowList] = useState<boolean>(false);

  return (
    <>
      {!showList ? (
        <ActionBegin
          action={selectedAction ?? undefined}
          setShowList={setShowList}
          userChallenge={userChallenge}
        />
      ) : (
        <ActionList
          setSelectedAction={setSelectedAction}
          setShowList={setShowList}
        />
      )}
    </>
  );
};

export default ActionChoose;
