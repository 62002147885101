import { ScaleBand } from 'd3-scale'
import { TimeStep } from 'enums'
import { capitalize } from 'lodash'
import { DateTime } from 'luxon'
import { Dataload } from 'models'
import React from 'react'
import DateChartService from 'services/dateChart.service'
import { useAppSelector } from 'store/hooks'

interface TextTypeProps {
  index: number
  dataload: Dataload
  timeStep: TimeStep
  width: number
  selectedDate: DateTime
  displayAllDays?: boolean
}

function TextAxis({
  index,
  dataload,
  timeStep,
  width,
  selectedDate,
  displayAllDays,
}: TextTypeProps) {
  const dateChartService = new DateChartService()
  const isSelectedDate = dateChartService.compareStepDate(
    timeStep,
    selectedDate,
    dataload.date
  )

  const style = isSelectedDate
    ? 'tick-text tick-text-selected chart-ticks-x-text'
    : 'tick-text chart-ticks-x-text'
  switch (timeStep) {
    case TimeStep.YEAR:
      return (
        <text y="10" dy="0.71em" transform={`translate(${width})`}>
          <tspan className={style} textAnchor="middle">
            {dataload.date.toLocaleString({ year: 'numeric' })}
          </tspan>
        </text>
      )
    case TimeStep.MONTH:
      return (
        <text y="10" dy="0.71em" transform={`translate(${width})`}>
          <tspan className={style} textAnchor="middle">
            {dataload.date.toLocaleString({ month: 'narrow' })}
          </tspan>
        </text>
      )
    case TimeStep.DAY: {
      const renderText = () => {
        if (displayAllDays) {
          return (
            <>
              <tspan className={style} x="0" textAnchor="middle">
                {dataload.date.toLocaleString({ weekday: 'narrow' })}
              </tspan>
              <tspan className={style} x="0" dy="1.2em" textAnchor="middle">
                {dataload.date.toLocaleString({ day: 'numeric' })}
              </tspan>
            </>
          )
        } else if (dataload.date.weekday === 1) {
          return (
            <>
              <tspan className={style} x="0" textAnchor="middle">
                {capitalize(
                  dataload.date
                    .toLocaleString({ weekday: 'short' })
                    .substring(0, 3)
                )}
              </tspan>
              <tspan className={style} x="0" dy="1.2em" textAnchor="middle">
                {dataload.date.toLocaleString({ day: 'numeric' })}
              </tspan>
            </>
          )
        }
        return null
      }
      return (
        <text y="10" dy="0.71em" transform={`translate(${width})`}>
          {renderText()}
        </text>
      )
    }

    case TimeStep.WEEK:
      return (
        <text y="10" dy="0.71em" transform={`translate(${width})`}>
          <tspan className={style} x="0" textAnchor="middle">
            {dataload.date.toLocaleString({ weekday: 'narrow' })}
          </tspan>
          <tspan className={style} x="0" dy="1.2em" textAnchor="middle">
            {dataload.date.toLocaleString({ day: 'numeric' })}
          </tspan>
        </text>
      )
    case TimeStep.HALF_AN_HOUR:
      return !(index % 4) ? (
        <text x={width} y="10" dy="0.71em">
          <tspan className={style} textAnchor="middle">
            {dataload.date.hour}
          </tspan>
        </text>
      ) : null
    default:
      return (
        <text x={width} y="10" dy="0.71em">
          <tspan className={style} textAnchor="middle">
            -
          </tspan>
        </text>
      )
  }
}

interface AxisBottomProps {
  data: Dataload[]
  timeStep: TimeStep
  xScale: ScaleBand<string>
  height: number
  marginLeft: number
  marginBottom: number
  isDuel?: boolean
}

const AxisBottom = ({
  data,
  timeStep,
  xScale,
  height,
  marginLeft,
  marginBottom,
  isDuel = false,
}: AxisBottomProps) => {
  const { selectedDate } = useAppSelector(state => state.chart)
  const dashArray = `${height / 30} ${height / 30}`
  const dateChartService = new DateChartService()
  const displayAllDays: boolean = isDuel && data.length <= 15
  return (
    <g
      className="axis x"
      transform={`translate(${marginLeft}, ${height - marginBottom})`}
    >
      {data.map((d, index) => (
        <g
          key={index}
          className="tick"
          opacity="1"
          transform={`translate(${xScale(
            d.date.toLocaleString(DateTime.DATETIME_SHORT)
          )}, 0)`}
        >
          <TextAxis
            index={index}
            dataload={d}
            timeStep={timeStep}
            width={xScale.bandwidth() / 2}
            selectedDate={selectedDate}
            displayAllDays={displayAllDays}
          />
          {dateChartService.compareStepDate(
            timeStep,
            DateTime.local().setZone('utc', {
              keepLocalTime: true,
            }),
            d.date
          ) && !isDuel ? (
            <line
              stroke="white"
              strokeLinecap="round"
              strokeDasharray={dashArray}
              x1={xScale.bandwidth() / 2}
              x2={xScale.bandwidth() / 2}
              y1="0"
              y2={-(height - marginBottom)}
            />
          ) : null}
        </g>
      ))}
    </g>
  )
}

export default AxisBottom
