import Button from '@mui/material/Button';
import defaultDuelIcon from 'assets/icons/visu/challenge/CHALLENGE0001.svg';
import defaultIcon from 'assets/icons/visu/duel/default.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import { useTranslation } from 'react-i18next';

import { UserChallengeUpdateFlag } from 'enums';
import { UserChallenge } from 'models';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ChallengeService from 'services/challenge.service';
import { setChallengeConsumption } from 'store/challenge/challenge.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { formatNumberValues, importIconById } from 'utils/utils';
import './duelUnlocked.scss';
import AppAxiosService from 'services/appAxios.service';
import { getChallengeIconId } from 'utils/ecoGagnant';

const DuelUnlocked = ({ userChallenge }: { userChallenge: UserChallenge }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userChallengeProgress, ecogesturesList, explorationsList } =
    useAppSelector((store) => store.challenge);
  const userSub = useAppSelector((store) => store.profileKeycloakUser.sub);
  const axiosClient = useMemo(() => new AppAxiosService(), []);
  const challengeService = useMemo(() => {
    if (!userSub) return;
    if (!userChallengeProgress) return;
    return new ChallengeService(
      axiosClient,
      userSub,
      userChallengeProgress,
      ecogesturesList,
      explorationsList
    );
  }, [userChallengeProgress, userSub]);
  const [duelIcon, setDuelIcon] = useState(defaultIcon);
  const average: string = formatNumberValues(
    userChallenge.duel.threshold
  ).toString();
  const duration: string = userChallenge.duel.duration.days.toString();
  const description: string = userChallenge.duel.description
    .replace('#CONSUMPTION', average)
    .replace('#DURATION', duration);

  const launchDuel = useCallback(async () => {
    if (!challengeService) return;

    const updatedChallenge = await challengeService.updateUserChallenge(
      userChallenge,
      UserChallengeUpdateFlag.DUEL_START
    );
    const dataloads =
      await challengeService.getUserChallengeDataload(updatedChallenge);

    dispatch(
      setChallengeConsumption({
        userChallenge: updatedChallenge,
        currentDataload: dataloads,
      })
    );
  }, [challengeService, dispatch, userChallenge]);

  useEffect(() => {
    async function handleEcogestureIcon() {
      const icon = await importIconById(
        getChallengeIconId(userChallenge),
        'challenge'
      );
      setDuelIcon(icon || defaultDuelIcon);
    }
    handleEcogestureIcon();
  }, [userChallenge]);

  return (
    <>
      <div className="duel-unlocked-container">
        <StyledIcon className="duel-icon" icon={duelIcon} width={219} />
        <div className="duel-description text-20-italic">{`"${description}"`}</div>
        <div className="duel-title text-16-normal">
          {userChallenge.duel.title}
        </div>
        <div className="duel-average-info text-18-normal">
          {t('duel.average_info', {
            average,
            smartCount: average,
          })}
        </div>
        <div className="button-start">
          <Button
            aria-label={t('duel.accessibility.button_start_duel')}
            onClick={launchDuel}
            className="btn-secondary-negative text-16-normal"
          >
            <span>{t('duel.button_start')}</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default DuelUnlocked;
