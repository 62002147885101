import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Bill from 'assets/icons/ico/bill.svg';
import CloseIcon from 'assets/icons/ico/close.svg';
import Prm from 'assets/icons/ico/prm.svg';
import { useTranslation } from 'react-i18next';
import React from 'react';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';

interface SgeModalHintProps {
  open: boolean;
  handleCloseClick: () => void;
}

const SgeModalHint = ({ open, handleCloseClick }: SgeModalHintProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      onClose={handleCloseClick}
      aria-labelledby="accessibility-title"
      classes={{
        root: 'modal-root',
        paper: 'modal-paper',
      }}
    >
      <div id="accessibility-title">
        {t('challenge_no_fluid_modal.accessibility.window_title')}
      </div>
      <IconButton
        aria-label={t('feedback.accessibility.button_close')}
        className="modal-paper-close-button"
        onClick={handleCloseClick}
      >
        <StyledIcon icon={CloseIcon} width={16} />
      </IconButton>
      <div className="sgeHintModal">
        <div className="title text-20-bold">
          {t('auth.enedissgegrandlyon.modalHint.title')}
        </div>
        <div className="pdl">
          <p>{t('auth.enedissgegrandlyon.pdlModal.txt1')}</p>
          <p>{t('auth.enedissgegrandlyon.pdlModal.txt2')}</p>
          <div className="bill">
            <StyledIcon icon={Bill} alt="Facture" className="bill" />
            <p>{t('auth.enedissgegrandlyon.pdlModal.txt3')}</p>
          </div>
          <p>{t('auth.enedissgegrandlyon.pdlModal.txt4')}</p>
          <StyledIcon icon={Prm} alt="PDL" className="prm" />
        </div>
        <Button
          aria-label={t(
            'auth.enedissgegrandlyon.modalHint.button-accessibility'
          )}
          onClick={handleCloseClick}
          className="btn-highlight text-16-bold"
          style={{ height: '40px' }}
        >
          <span>{t('auth.enedissgegrandlyon.modalHint.button')}</span>
        </Button>
      </div>
    </Dialog>
  );
};

export default SgeModalHint;
