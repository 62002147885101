import classNames from 'classnames';
import FormNavigation from 'components/CommonKit/FormNavigation/FormNavigation';
import FormProgress from 'components/CommonKit/FormProgress/FormProgress';
import 'components/ProfileType/profileTypeForm.scss';
import { useTranslation } from 'react-i18next';
import { ProfileTypeStepForm } from 'enums';
import { remove } from 'lodash';
import { ProfileType, ProfileTypeAnswer } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import HelperText from '../HelperText/HelperText';

interface ProfileTypeFormMultiChoiceProps {
  step: ProfileTypeStepForm;
  viewedStep: ProfileTypeStepForm;
  currentProfileType: ProfileType;
  answerType: ProfileTypeAnswer;
  setNextStep: (_profileType: ProfileType) => void;
  setPreviousStep: () => void;
}

const ProfileTypeFormMultiChoice = ({
  step,
  viewedStep,
  currentProfileType,
  answerType,
  setNextStep,
  setPreviousStep,
}: ProfileTypeFormMultiChoiceProps) => {
  const { t } = useTranslation();
  const { value: isProfileTypeCompleted } = useAppSelector(
    (state) => state.profileStellioUser.isProfileTypeCompleted
  );
  const [answer, setAnswer] = useState<string[]>([]);

  const handleChange = (value: string) => {
    let tempAnswer = [...answer];
    if (value === 'none' && !tempAnswer.includes(value)) {
      tempAnswer = [value];
    } else if (tempAnswer.includes(value)) {
      remove(tempAnswer, function (n) {
        return n === 'none' || n === value;
      });
    } else {
      remove(tempAnswer, function (n) {
        return n === 'none';
      });
      tempAnswer.push(value);
    }
    setAnswer(tempAnswer);
  };

  const isChecked = (value: string): boolean => {
    return answer.includes(value);
  };

  const handlePrevious = useCallback(() => {
    setPreviousStep();
  }, [setPreviousStep]);

  const handleNext = useCallback(() => {
    setNextStep({
      ...currentProfileType,
      [answerType.attribute]: answer,
    });
  }, [currentProfileType, setNextStep, answer, answerType.attribute]);

  useEffect(() => {
    // Set answer if profileType is completed
    if (step < viewedStep || isProfileTypeCompleted) {
      setAnswer(currentProfileType[answerType.attribute] as string[]);
    }
  }, [
    step,
    viewedStep,
    currentProfileType,
    answerType,
    isProfileTypeCompleted,
  ]);

  return (
    <>
      <div className="profile-form-container">
        <FormProgress step={step} formType="profile" />
        <div className="profile-question-label">
          {t(
            `profile_type.${ProfileTypeStepForm[step].toLowerCase()}.question`
          )}
        </div>
        <span className="profile-question-hint">
          {t('profile_type.multi_choices')}
        </span>
        {answerType.choices.map((value, index) => {
          if (!value) return null;
          const stringValue = value.toString();
          return (
            <label
              key={index}
              className={classNames('checkbox', {
                ['answer-checked']: answer.includes(stringValue),
              })}
            >
              <input
                type="checkbox"
                value={stringValue}
                name={stringValue}
                onChange={() => handleChange(stringValue)}
                checked={isChecked(stringValue)}
              />
              {t(
                `profile_type.${ProfileTypeStepForm[
                  step
                ].toLowerCase()}.${value}`
              )}
            </label>
          );
        })}
        <HelperText step={step} />
      </div>

      <FormNavigation
        step={step}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        disableNextButton={answer.length < 1}
      />
    </>
  );
};

export default ProfileTypeFormMultiChoice;
