import AddElecIcon from 'assets/icons/ico/add-elec.svg'
import AddGasIcon from 'assets/icons/ico/add-gas.svg'
import AddWaterIcon from 'assets/icons/ico/add-water.svg'
import Disconnected from 'assets/icons/visu/disconnected-grey.svg'
import DisconnectedActive from 'assets/icons/visu/disconnected.svg'
import iconEglWhiteLogo from 'assets/icons/visu/egl-logo-white.svg'
import iconEglLogo from 'assets/icons/visu/egl-logo.svg'
import ElecUnactive from 'assets/icons/visu/elec-grey.svg'
import ElecParamIcon from 'assets/icons/visu/elec-param.svg'
import ElecSmallIcon from 'assets/icons/visu/elec-small.svg'
import ElecIcon from 'assets/icons/visu/elec.svg'
import iconEnedisWhiteLogo from 'assets/icons/visu/enedis-logo-white.svg'
import iconEnedisLogo from 'assets/icons/visu/enedis-logo.svg'
import GasUnactive from 'assets/icons/visu/gas-grey.svg'
import GasParamIcon from 'assets/icons/visu/gas-param.svg'
import GasSmallIcon from 'assets/icons/visu/gas-small.svg'
import GasIcon from 'assets/icons/visu/gas.svg'
import iconGrdfWhiteLogo from 'assets/icons/visu/grdf-logo-white.svg'
import iconGrdfLogo from 'assets/icons/visu/grdf-logo.svg'
import MultiUnactive from 'assets/icons/visu/multi-grey.svg'
import Multi from 'assets/icons/visu/multi.svg'
import WaterUnactive from 'assets/icons/visu/water-grey.svg'
import WaterParamIcon from 'assets/icons/visu/water-param.svg'
import WaterSmallIcon from 'assets/icons/visu/water-small.svg'
import WaterIcon from 'assets/icons/visu/water.svg'
import { FluidType } from 'enums'


/**
 * Return an icon corresponding to FluidType enum
 * @param type FluidType
 */
export function getPicto(type: FluidType, small = false) {
  switch (type) {
    case FluidType.ELECTRICITY:
      return small ? ElecSmallIcon : ElecIcon
    case FluidType.WATER:
      return small ? WaterSmallIcon : WaterIcon
    case FluidType.GAS:
      return small ? GasSmallIcon : GasIcon
    case FluidType.MULTIFLUID:
      return Multi
    default:
      return ElecIcon
  }
}
/**
 * Return an icon corresponding to add FluidType enum
 * @param type FluidType
 */
export function getAddPicto(type: FluidType) {
  switch (type) {
    case FluidType.ELECTRICITY:
      return AddElecIcon
    case FluidType.WATER:
      return AddWaterIcon
    case FluidType.GAS:
      return AddGasIcon
    default:
      return ElecIcon
  }
}

export function getParamPicto(type: FluidType) {
  switch (type) {
    case FluidType.ELECTRICITY:
      return ElecParamIcon
    case FluidType.WATER:
      return WaterParamIcon
    case FluidType.GAS:
      return GasParamIcon
    default:
      return ElecParamIcon
  }
}

const getIcon = (
  active: boolean,
  connected: boolean,
  activeIcon: string,
  inactiveIcon: string
) => {
  if (connected) {
    return active ? activeIcon : inactiveIcon
  } else {
    return active ? DisconnectedActive : Disconnected
  }
}

/**
 * Returns the appropriate icon for a given fluid type and connection status
 */
export function getNavPicto(
  fluidType: FluidType,
  active: boolean,
  connected: boolean
) {
  switch (fluidType) {
    case FluidType.ELECTRICITY:
      return getIcon(active, connected, ElecSmallIcon, ElecUnactive)
    case FluidType.WATER:
      return getIcon(active, connected, WaterSmallIcon, WaterUnactive)
    case FluidType.GAS:
      return getIcon(active, connected, GasSmallIcon, GasUnactive)
    case FluidType.MULTIFLUID:
      return getIcon(active, connected, Multi, MultiUnactive)
    default:
      return ElecIcon
  }
}

// /**
//  * Return an partner icon corresponding to FluidType enum
//  * @param slug string
//  * @param blackLogo boolean - define the color of the logo (black or white)
//  */
// export function getPartnerPicto(slug: string, blackLogo = true) {
//   const fluidConfig = new ConfigService().getFluidConfig()
//   switch (slug) {
//     case fluidConfig[FluidType.ELECTRICITY].konnectorConfig.slug:
//       return blackLogo ? iconEnedisLogo : iconEnedisWhiteLogo
//     case fluidConfig[FluidType.WATER].konnectorConfig.slug:
//       return blackLogo ? iconEglLogo : iconEglWhiteLogo
//     case fluidConfig[FluidType.GAS].konnectorConfig.slug:
//       return blackLogo ? iconGrdfLogo : iconGrdfWhiteLogo
//     default:
//       return ''
//   }
// }
