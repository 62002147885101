import Content from 'components/Content/Content';
import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import ProfileTypeView from 'components/ProfileType/ProfileTypeView';
import { EcogestureStepForm, ProfileEcogestureAnswerType } from 'enums';
import { ProfileEcogesture, ProfileEcogestureAnswer } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { newProfileEcogestureEntry } from 'store/profileEcogesture/profileEcogesture.slice';
import EcogestureFormEquipment from './EcogestureFormEquipment/EcogestureFormEquipment';
import EcogestureFormSingleChoice from './EcogestureFormSingleChoice/EcogestureFormSingleChoice';
import EcogestureLaunchFormModal from './EcogestureLaunchFormModal/EcogestureLaunchFormModal';
import { updateStellioUserProfile } from 'store/profileStellioUser/profileStellioUser.slice';
import ProfileEcogestureFormService from 'services/profileEcogestureForm.service';

const EcogestureFormView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isProfileTypeCompleted } = useAppSelector((state) => state.profile);
  const profileEcogesture = useAppSelector((state) => state.profileEcogesture);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  const shouldOpenModal = new URLSearchParams(useLocation().search).get(
    'modal'
  );
  const [step, setStep] = useState<EcogestureStepForm>(
    EcogestureStepForm.HEATING_TYPE
  );
  const [answerType, setAnswerType] = useState<ProfileEcogestureAnswer>({
    type: ProfileEcogestureAnswerType.SINGLE_CHOICE,
    attribute: 'heating',
    choices: [],
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openLaunchModal, setOpenLaunchModal] = useState<boolean>(
    shouldOpenModal !== 'false' ? true : false
  );
  const [viewedStep, setViewedStep] = useState<number>(-1);
  const [currentProfileEcogesture, setCurrentProfileEcogesture] =
    useState<ProfileEcogesture>(profileEcogesture);

  const setNextStep = useCallback(
    (_profileEcogesture: ProfileEcogesture) => {
      setCurrentProfileEcogesture(_profileEcogesture);
      const pefs = new ProfileEcogestureFormService(_profileEcogesture);
      const nextStep = pefs.getNextFormStep(step);
      setIsLoading(true);
      if (nextStep > viewedStep) {
        setViewedStep(nextStep);
      }
      const _answerType =
        ProfileEcogestureFormService.getAnswerForStep(nextStep);
      setAnswerType(_answerType);
      setStep(nextStep);
    },
    [step, viewedStep]
  );

  const setPreviousStep = useCallback(() => {
    const pefs = new ProfileEcogestureFormService(currentProfileEcogesture);
    const previousStep = pefs.getPreviousFormStep(step);
    setIsLoading(true);
    const _answerType =
      ProfileEcogestureFormService.getAnswerForStep(previousStep);
    setAnswerType(_answerType);
    setStep(previousStep);
  }, [currentProfileEcogesture, step]);

  const handleEndForm = useCallback(() => {
    dispatch(newProfileEcogestureEntry(currentProfileEcogesture));
    dispatch(
      updateStellioUserProfile({
        isProfileEcogestureCompleted: { type: 'Property', value: true },
      })
    );
    navigate('/ecogesture-selection');
  }, [currentProfileEcogesture, dispatch, navigate]);

  useEffect(() => {
    const _answerType = ProfileEcogestureFormService.getAnswerForStep(step);
    setAnswerType(_answerType);
    setIsLoading(false);

    // handle end of form
    if (step === EcogestureStepForm.END) {
      handleEndForm();
    }
  }, [handleEndForm, step]);

  if (isProfileTypeCompleted) {
    return <ProfileTypeView />;
  }

  return (
    <>
      <Header
        setHeaderHeight={setHeaderHeight}
        desktopTitleKey="common.title_ecogestures"
      />
      <Content heightOffset={headerHeight}>
        {isLoading && (
          <div className="loaderContainer">
            <Loader />
          </div>
        )}
        {!isLoading && (
          <>
            {step === EcogestureStepForm.EQUIPMENTS && (
              <EcogestureFormEquipment
                step={EcogestureStepForm.EQUIPMENTS}
                currentProfileEcogesture={currentProfileEcogesture}
                setNextStepEcogestureForm={setNextStep}
                setPreviousStep={setPreviousStep}
              />
            )}
            {step !== EcogestureStepForm.EQUIPMENTS && (
              <EcogestureFormSingleChoice
                step={step}
                viewedStep={viewedStep}
                currentProfileEcogesture={currentProfileEcogesture}
                answerType={answerType}
                setNextStep={setNextStep}
                setPreviousStep={setPreviousStep}
              />
            )}
          </>
        )}
      </Content>
      <EcogestureLaunchFormModal
        open={openLaunchModal}
        handleCloseClick={() => setOpenLaunchModal(false)}
      />
    </>
  );
};

export default EcogestureFormView;
