import Button from '@mui/material/Button';
import starResult from 'assets/icons/visu/quiz/starResult.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import { useTranslation } from 'react-i18next';
import { UserChallengeUpdateFlag } from 'enums';
import { UserChallenge } from 'models';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { updateUserChallengeList } from 'store/challenge/challenge.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import './quizFinish.scss';
import AppAxiosService from 'services/appAxios.service';
import ChallengeService from 'services/challenge.service';

const QuizFinish = ({ userChallenge }: { userChallenge: UserChallenge }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userChallengeProgress, ecogesturesList, explorationsList } =
    useAppSelector((store) => store.challenge);
  const userSub = useAppSelector((store) => store.profileKeycloakUser.sub);
  const clientAxios = useMemo(() => new AppAxiosService(), []);
  const challengeService: ChallengeService | undefined = useMemo(() => {
    if (!userChallengeProgress) return;
    if (!userSub) return;
    return new ChallengeService(
      clientAxios,
      userSub,
      userChallengeProgress,
      ecogesturesList,
      explorationsList
    );
  }, [userChallengeProgress, userSub]);

  const retryQuiz = useCallback(async () => {
    if (!challengeService) return;

    const userChallengeUpdated: UserChallenge =
      await challengeService.updateUserChallenge(
        userChallenge,
        UserChallengeUpdateFlag.QUIZ_RESET
      );
    dispatch(updateUserChallengeList(userChallengeUpdated));
  }, [dispatch, userChallenge, challengeService]);

  const goBack = async () => {
    if (!challengeService) return;

    const userChallengeUpdated: UserChallenge =
      await challengeService.updateUserChallenge(
        userChallenge,
        UserChallengeUpdateFlag.QUIZ_UPDATE,
        userChallenge.quiz
      );
    dispatch(updateUserChallengeList(userChallengeUpdated));
    navigate('/challenges');
  };

  return (
    <div className="quiz-container">
      <div className="quiz-finish-container">
        <div>
          <div className="score-final-title">{t('quiz.score_final')}</div>
          <div className="score-final">
            <span>{userChallenge.quiz.result}</span> / 5
          </div>
          <div className="score-label">{t('quiz.earn')}</div>
          <div className="score-stars">
            {userChallenge.quiz.result}
            <StyledIcon className="quiz-icon" icon={starResult} width={30} />
          </div>
        </div>
        {userChallenge.quiz.result === 5 ? (
          <div className="button-start">
            <Button
              variant="outlined"
              aria-label={t('quiz.accessibility.button_end_quiz')}
              onClick={goBack}
              className=""
            >
              {t('quiz.button_end_quiz')}
            </Button>
          </div>
        ) : (
          <div className="button-start">
            <Button
              variant="outlined"
              aria-label={t('quiz.accessibility.button_go_back')}
              onClick={goBack}
              className=""
            >
              {t('quiz.button_go_back')}
            </Button>
            <Button
              variant="text"
              aria-label={t('quiz.accessibility.button_try_again')}
              onClick={retryQuiz}
              className=""
            >
              {t('quiz.button_try_again')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizFinish;
