import DuelBar from 'components/Duel/DuelChart/DuelBar';
import { TimeStep } from 'enums';
import { Dataload, UserChallenge } from 'models';
import React, { useEffect, useState } from 'react';
import './duelChart.scss';

interface DuelChartProps {
  userChallenge: UserChallenge;
  finishedDataLoad?: Dataload[];
  width: number;
  height: number;
}

const DuelChart = ({
  userChallenge,
  finishedDataLoad,
  width,
  height,
}: DuelChartProps) => {
  const [average, setAverage] = useState<number>(0);

  useEffect(() => {
    setAverage(userChallenge.duel.threshold / userChallenge.duel.duration.days);
  }, [userChallenge.duel.duration.days, userChallenge.duel.threshold]);

  return (
    <div className="fs-slide">
      <DuelBar
        userChallenge={userChallenge}
        finishedDataLoad={finishedDataLoad}
        average={average}
        timeStep={TimeStep.DAY}
        height={height}
        width={width}
      />
    </div>
  );
};

export default DuelChart;
