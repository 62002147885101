export enum DataloadState {
  VALID = 'VALID',
  EMPTY = 'EMPTY',
  MISSING = 'MISSING',
  HOLE = 'HOLE',
  UPCOMING = 'UPCOMING',
  COMING = 'COMING',
  AGGREGATED_VALID = 'AGGREGATED_VALID',
  AGGREGATED_EMPTY = 'AGGREGATED_EMPTY',
  AGGREGATED_WITH_EMPTY = 'AGGREGATED_WITH_EMPTY',
  AGGREGATED_HOLE_OR_MISSING = 'AGGREGATED_HOLE_OR_MISSING',
  AGGREGATED_WITH_HOLE_OR_MISSING = 'AGGREGATED_WITH_HOLE_OR_MISSING',
  AGGREGATED_WITH_COMING = 'AGGREGATED_WITH_UPCOMING',
  AGGREGATED_COMING = 'AGGREGATED_COMING',
}

export enum DataloadSectionType {
  NO_COMPARE = 'NO_COMPARE',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
