// import { Client } from 'cozy-client';
import { FluidRetrievalStatus, FluidState, FluidType } from 'enums';
import { FluidStatus, Konnector, PartnersInfo, TriggerState } from 'models';
// import AccountService from 'services/account.service';
// import ConsumptionService from 'services/consumption.service';
// import ConfigService from 'services/fluidConfig.service';
// import KonnectorService from 'services/konnector.service';
// import TriggerService from 'services/triggers.service';
// import { allFluids } from 'utils/utils';

export default class FluidService {
  // private _client: Client;

  //   constructor(_client: Client) {
  //     this._client = _client;
  //   }

  //   private parseFluidStatus = (
  //     konnector: Konnector | null,
  //     state: TriggerState | null
  //   ): FluidState => {
  //     if (!konnector) return FluidState.KONNECTOR_NOT_FOUND;
  //     if (!state) return FluidState.NOT_CONNECTED;
  //     switch (state.status) {
  //       case 'done':
  //         return FluidState.DONE;
  //       case 'errored':
  //         if (state?.last_error === 'LOGIN_FAILED') {
  //           return FluidState.LOGIN_FAILED;
  //         }
  //         if (state?.last_error === 'CHALLENGE_ASKED') {
  //           return FluidState.CHALLENGE_ASKED;
  //         }
  //         return FluidState.ERROR;

  //       default:
  //         return FluidState.NOT_CONNECTED;
  //     }
  //   };

  public parseFluidMaintenance(
    fluidType: FluidType,
    partnersInfo?: PartnersInfo
  ) {
    return Boolean(
      partnersInfo?.notification_activated &&
        ((fluidType === FluidType.GAS && partnersInfo.grdf_failure) ||
          (fluidType === FluidType.ELECTRICITY &&
            partnersInfo.enedis_failure) ||
          (fluidType === FluidType.WATER && partnersInfo.egl_failure))
    );
  }

  public setFluidStatusMaintenance(
    partnersInfo: PartnersInfo,
    fluidStatus: FluidStatus[]
  ) {
    const result: FluidStatus[] = fluidStatus.map((singleFluidStatus) => {
      switch (singleFluidStatus.fluidType) {
        case FluidType.ELECTRICITY:
          return {
            ...singleFluidStatus,
            maintenance: partnersInfo.enedis_failure,
          };
        case FluidType.GAS:
          return {
            ...singleFluidStatus,
            maintenance: partnersInfo.grdf_failure,
          };
        case FluidType.WATER:
          return {
            ...singleFluidStatus,
            maintenance: partnersInfo.egl_failure,
          };

        default:
          return singleFluidStatus;
      }
    });

    return result;
  }

  // public getFluidStatus = async (
  //   partnersInfo?: PartnersInfo
  // ): Promise<FluidStatus[]> => {
  //   const fluidConfig = new ConfigService().getFluidConfig();
  //   const accountService = new AccountService(this._client);
  //   const [elecAccount, waterAccount, gasAccount] = await Promise.all([
  //     accountService.getAccountByType(
  //       fluidConfig[FluidType.ELECTRICITY].konnectorConfig.slug
  //     ),
  //     accountService.getAccountByType(
  //       fluidConfig[FluidType.WATER].konnectorConfig.slug
  //     ),
  //     accountService.getAccountByType(
  //       fluidConfig[FluidType.GAS].konnectorConfig.slug
  //     ),
  //   ]);
  //   const konnectorService = new KonnectorService(this._client);
  //   const [elecKonnector, waterKonnector, gasKonnector] = await Promise.all([
  //     konnectorService.getKonnector(
  //       fluidConfig[FluidType.ELECTRICITY].konnectorConfig.slug
  //     ),
  //     konnectorService.getKonnector(
  //       fluidConfig[FluidType.WATER].konnectorConfig.slug
  //     ),
  //     konnectorService.getKonnector(
  //       fluidConfig[FluidType.GAS].konnectorConfig.slug
  //     ),
  //   ]);
  //   const triggerService = new TriggerService(this._client);
  //   const [elecTrigger, waterTrigger, gasTrigger] = await Promise.all([
  //     elecAccount && elecKonnector
  //       ? triggerService.getTrigger(elecAccount, elecKonnector)
  //       : null,
  //     waterAccount && waterKonnector
  //       ? triggerService.getTrigger(waterAccount, waterKonnector)
  //       : null,
  //     gasAccount && gasKonnector
  //       ? triggerService.getTrigger(gasAccount, gasKonnector)
  //       : null,
  //   ]);
  //   const consumptionService = new ConsumptionService(this._client);
  //   const [elecStatus, waterStatus, gasStatus] = await Promise.all([
  //     elecTrigger ? triggerService.fetchTriggerState(elecTrigger) : null,
  //     waterTrigger ? triggerService.fetchTriggerState(waterTrigger) : null,
  //     gasTrigger ? triggerService.fetchTriggerState(gasTrigger) : null,
  //   ]);
  //   const firstDataDates =
  //     await consumptionService.fetchAllFirstDateData(allFluids);
  //   const lastDataDates =
  //     await consumptionService.fetchAllLastDateData(allFluids);

  //   const result: FluidStatus[] = [
  //     {
  //       fluidType: FluidType.ELECTRICITY,
  //       status: this.parseFluidStatus(elecKonnector, elecStatus),
  //       retrievalStatus: FluidRetrievalStatus.NOT_STARTED,
  //       maintenance: this.parseFluidMaintenance(
  //         FluidType.ELECTRICITY,
  //         partnersInfo
  //       ),
  //       firstDataDate: firstDataDates[FluidType.ELECTRICITY],
  //       lastDataDate: lastDataDates[FluidType.ELECTRICITY],
  //       connection: {
  //         shouldLaunchKonnector: false,
  //         isUpdating: false,
  //         konnector: elecKonnector,
  //         account: elecAccount,
  //         trigger: elecTrigger,
  //         triggerState: elecStatus,
  //         konnectorConfig: fluidConfig[FluidType.ELECTRICITY].konnectorConfig,
  //       },
  //     },
  //     {
  //       fluidType: FluidType.WATER,
  //       status: this.parseFluidStatus(waterKonnector, waterStatus),
  //       retrievalStatus: FluidRetrievalStatus.NOT_STARTED,
  //       maintenance: this.parseFluidMaintenance(FluidType.WATER, partnersInfo),
  //       firstDataDate: firstDataDates[FluidType.WATER],
  //       lastDataDate: lastDataDates[FluidType.WATER],
  //       connection: {
  //         shouldLaunchKonnector: false,
  //         isUpdating: false,
  //         konnector: waterKonnector,
  //         account: waterAccount,
  //         trigger: waterTrigger,
  //         triggerState: waterStatus,
  //         konnectorConfig: fluidConfig[FluidType.WATER].konnectorConfig,
  //       },
  //     },
  //     {
  //       fluidType: FluidType.GAS,
  //       status: this.parseFluidStatus(gasKonnector, gasStatus),
  //       retrievalStatus: FluidRetrievalStatus.NOT_STARTED,
  //       maintenance: this.parseFluidMaintenance(FluidType.GAS, partnersInfo),
  //       firstDataDate: firstDataDates[FluidType.GAS],
  //       lastDataDate: lastDataDates[FluidType.GAS],
  //       connection: {
  //         shouldLaunchKonnector: false,
  //         isUpdating: false,
  //         konnector: gasKonnector,
  //         account: gasAccount,
  //         trigger: gasTrigger,
  //         triggerState: gasStatus,
  //         konnectorConfig: fluidConfig[FluidType.GAS].konnectorConfig,
  //       },
  //     },
  //   ];
  //   return result;
  // };

  //   /**
  //    * Return fluids with data older than 5 days
  //    */
  //   static getOldFluidData = async (
  //     fluidStatus: FluidStatus[]
  //   ): Promise<FluidType[]> => {
  //     const fluidOldData: FluidType[] = [];
  //     if (fluidStatus.length > 0) {
  //       for (const fluid of fluidStatus) {
  //         let diffInDays = 0;
  //         if (fluid?.lastDataDate) {
  //           const dateToCompare = fluid.lastDataDate;
  //           diffInDays = dateToCompare.diffNow('days').toObject().days || 0;
  //           if (
  //             diffInDays < -5 &&
  //             fluid.status !== FluidState.KONNECTOR_NOT_FOUND &&
  //             fluid.status !== FluidState.NOT_CONNECTED
  //           ) {
  //             !fluidOldData.includes(fluid.fluidType) &&
  //               fluidOldData.push(fluid.fluidType);
  //           }
  //         }
  //       }
  //     }
  //     return fluidOldData;
  //   };
}
