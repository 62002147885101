export enum EcogestureStepForm {
  HEATING_TYPE = 0,
  WARMING_FLUID = 1,
  HOT_WATER = 2,
  EQUIPMENTS = 3,
  END,
}
export enum ProfileEcogestureAnswerType {
  SINGLE_CHOICE = 0,
  MULTI_CHOICE = 1,
}
