export enum UserExplorationState {
  UNLOCKED = 0,
  ONGOING = 1,
  NOTIFICATION = 2,
  DONE = 3,
}

export enum UserExplorationType {
  DECLARATIVE = 0,
  ACTION = 1,
  CONSUMPTION = 2,
  ECOGESTURE = 3,
}

export enum UserExplorationID {
  EXPLORATION001 = 'urn:ngsi-ld:Exploration:EXPLORATION001',
  EXPLORATION002 = 'urn:ngsi-ld:Exploration:EXPLORATION002',
  EXPLORATION003 = 'urn:ngsi-ld:Exploration:EXPLORATION003',
  EXPLORATION004 = 'urn:ngsi-ld:Exploration:EXPLORATION004',
  EXPLORATION004_0 = 'urn:ngsi-ld:Exploration:EXPLORATION004_0',
  EXPLORATION005 = 'urn:ngsi-ld:Exploration:EXPLORATION005',
  EXPLORATION006 = 'urn:ngsi-ld:Exploration:EXPLORATION006',
  EXPLORATION007 = 'urn:ngsi-ld:Exploration:EXPLORATION007',
  EXPLORATION008 = 'urn:ngsi-ld:Exploration:EXPLORATION008',
}
