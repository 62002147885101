import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './explorationError.scss';

const ExplorationError = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="exploration-error-container">
      <div className="exploration-error-message">
        {t('exploration.global_error')}
      </div>
      <div className="exploration-error-button">
        <Button
          aria-label={t('exploration.accessibility.button_go_back')}
          onClick={() => navigate(-1)}
          className="btn-secondary-negative text-16-normal"
        >
          <span>{t('exploration.button_go_back')}</span>
        </Button>
      </div>
    </div>
  );
};

export default ExplorationError;
