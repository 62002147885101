import FormNavigation from 'components/CommonKit/FormNavigation/FormNavigation'
import FormProgress from 'components/CommonKit/FormProgress/FormProgress'
import Content from 'components/Content/Content'
import CozyBar from 'components/Header/CozyBar'
import Header from 'components/Header/Header'
import { SgeStep } from 'enums'
import { SgeStore } from 'models'
import React, { useCallback, useState } from 'react'
import { updateSgeStore } from 'store/global/global.slice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import './SgeConnect.scss'
import StepAddress from './StepAddress'
import StepConsent from './StepConsent'
import StepIdentityAndPdl from './StepIdentityAndPdl'

export type SGEKeysForm =
  | 'firstName'
  | 'lastName'
  | 'pdl'
  | 'address'
  | 'zipCode'
  | 'city'
  | 'dataConsent'
  | 'pdlConfirm'

const SgeConnectView = () => {
  const dispatch = useAppDispatch()
  const { sgeConnect } = useAppSelector(state => state.global)
  const [isLoading, setIsLoading] = useState(false)
  const [currentSgeState, setCurrentSgeState] = useState<SgeStore>(sgeConnect)
  const [currentStep, setCurrentStep] = useState<SgeStep>(
    sgeConnect.currentStep
  )
  const [headerHeight, setHeaderHeight] = useState<number>(0)

  const isNextValid = useCallback(() => {
    switch (currentStep) {
      case SgeStep.IdentityAndPDL:
        return (
          currentSgeState.firstName !== '' &&
          currentSgeState.lastName !== '' &&
          currentSgeState.pdl !== null &&
          currentSgeState.pdl.toString().length === 14
        )
      case SgeStep.Address:
        return (
          currentSgeState.address !== '' &&
          currentSgeState.city !== '' &&
          currentSgeState.zipCode !== null &&
          currentSgeState.zipCode.toString().length === 5
        )
      case SgeStep.Consent:
        return currentSgeState.dataConsent && currentSgeState.pdlConfirm
      default:
        return false
    }
  }, [
    currentSgeState.address,
    currentSgeState.city,
    currentSgeState.dataConsent,
    currentSgeState.firstName,
    currentSgeState.lastName,
    currentSgeState.pdl,
    currentSgeState.pdlConfirm,
    currentSgeState.zipCode,
    currentStep,
  ])

  const handleNext = useCallback(() => {
    if (currentStep < SgeStep.Consent && isNextValid()) {
      setCurrentStep(prev => prev + 1)
      dispatch(updateSgeStore(currentSgeState))
    }
    if (currentStep === SgeStep.Consent && isNextValid() && !isLoading) {
      setIsLoading(true)
      const updatedState = {
        ...currentSgeState,
        city: currentSgeState.city.trim(),
        shouldLaunchAccount: true,
      }
      setCurrentSgeState(updatedState)
      dispatch(updateSgeStore(updatedState))
    }
  }, [currentSgeState, currentStep, dispatch, isNextValid, isLoading])

  const handlePrev = useCallback(() => {
    if (currentStep !== SgeStep.IdentityAndPDL) {
      setCurrentStep(prev => prev - 1)
    }
    dispatch(updateSgeStore(currentSgeState))
  }, [currentSgeState, currentStep, dispatch])

  const onChange = useCallback(
    (
      key: SGEKeysForm,
      value: string | boolean | number,
      maxLength?: number
    ) => {
      // TODO duplicate with Form login input
      if (
        !maxLength ||
        value === '' ||
        (/\d/.test(value.toString()) && value.toString().length <= maxLength)
      ) {
        const updatedState = {
          ...currentSgeState,
          [key]: value,
        }
        setCurrentSgeState(updatedState)
      }
    },
    [currentSgeState]
  )

  const renderStep = (step: SgeStep) => {
    switch (step) {
      case SgeStep.Address:
        return <StepAddress sgeState={currentSgeState} onChange={onChange} />
      case SgeStep.Consent:
        return <StepConsent sgeState={currentSgeState} onChange={onChange} />
      case SgeStep.IdentityAndPDL:
      default:
        return (
          <StepIdentityAndPdl sgeState={currentSgeState} onChange={onChange} />
        )
    }
  }

  return (
    <>
      <CozyBar titleKey="common.title_sge_connect" displayBackArrow={true} />
      <Header
        setHeaderHeight={setHeaderHeight}
        desktopTitleKey="common.title_sge_connect"
        displayBackArrow={true}
      />
      <Content heightOffset={headerHeight}>
        <div className="sge-view">
          <div className="sge-container">
            <FormProgress step={currentStep} formType="sge" />
            {renderStep(currentStep)}
          </div>
          <FormNavigation
            step={currentStep}
            handlePrevious={handlePrev}
            handleNext={handleNext}
            isLoading={isLoading}
            disableNextButton={!isNextValid() || isLoading}
            disablePrevButton={currentStep === SgeStep.IdentityAndPDL}
            isLastConnectStep={currentStep === SgeStep.Consent}
            isEcogesture={false}
          />
        </div>
      </Content>
    </>
  )
}

export default SgeConnectView
