import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UserChallengeState } from 'enums';
import {
  ChallengeState,
  Dataload,
  Ecogesture,
  UserChallenge,
  UserEcogesture,
} from 'models';
import {
  StellioChallenge,
  StellioChallengeProgress,
  StellioCustomQuestion,
  StellioDuel,
  StellioEcogesture,
  StellioExploration,
  StellioQuestion,
  StellioQuiz,
} from 'models/stellio.model';

const initialState: ChallengeState = {
  userChallengeList: [],
  challengesList: [],
  duelsList: [],
  explorationsList: [],
  quizList: [],
  questionsList: [],
  customQuestionsList: [],
  ecogesturesList: [],
  userChallengeProgress: null,
  currentChallenge: null,
  currentDataload: [],
};

type SetChallengeConsumption = PayloadAction<{
  userChallenge: UserChallenge;
  currentDataload: Dataload[];
}>;

export const challengeSlice = createSlice({
  name: 'challenge',
  initialState,
  reducers: {
    setChallengesList: (state, action: PayloadAction<StellioChallenge[]>) => {
      state.challengesList = action.payload;
    },
    setDuelsList: (state, action: PayloadAction<StellioDuel[]>) => {
      state.duelsList = action.payload;
    },
    setExplorationsList: (
      state,
      action: PayloadAction<StellioExploration[]>
    ) => {
      state.explorationsList = action.payload;
    },
    setQuizList: (state, action: PayloadAction<StellioQuiz[]>) => {
      state.quizList = action.payload;
    },
    setQuestionsList: (state, action: PayloadAction<StellioQuestion[]>) => {
      state.questionsList = action.payload;
    },
    setCustomQuestionsList: (
      state,
      action: PayloadAction<StellioCustomQuestion[]>
    ) => {
      state.customQuestionsList = action.payload;
    },
    setEcogesturesList: (state, action: PayloadAction<StellioEcogesture[]>) => {
      state.ecogesturesList = action.payload;
    },
    setUserChallengeList: (state, action: PayloadAction<UserChallenge[]>) => {
      state.userChallengeList = action.payload;
    },
    setUserChallengeProgress: (
      state,
      action: PayloadAction<StellioChallengeProgress>
    ) => {
      state.userChallengeProgress = action.payload;
    },
    setUserEcogestureProgress: (state, action: PayloadAction<Ecogesture>) => {
      if (state.userChallengeProgress === null) return state;
      const ecogestureId = action.payload.id;
      const updatedList = [...state.userChallengeProgress.refEcogesture];
      const findIndex = updatedList.findIndex(
        (relationship) => relationship.object === ecogestureId
      );
      const userEcogesture: UserEcogesture = {
        doing: action.payload.doing,
        viewedInSelection: action.payload.viewedInSelection,
        objective: action.payload.objective,
      };
      updatedList[findIndex].userProgress.json = userEcogesture;

      state.userChallengeProgress.refEcogesture = updatedList;
    },
    reinitAllEcogesturesProgress: (state) => {
      if (state.userChallengeProgress === null) return state;

      const updatedProgress: StellioChallengeProgress = {
        ...state.userChallengeProgress,
        refEcogesture: [...state.userChallengeProgress.refEcogesture].map(
          (relationship) => {
            return {
              ...relationship,
              userProgress: {
                ...relationship.userProgress,
                json: {
                  doing: false,
                  objective: false,
                  viewedInSelection: false,
                },
              },
            };
          }
        ),
      };

      state.userChallengeProgress = updatedProgress;
    },
    setCurrentUserChallenge: (
      state,
      action: PayloadAction<UserChallenge | null>
    ) => {
      state.currentChallenge = action.payload;
    },
    updateUserChallengeList: (state, action: PayloadAction<UserChallenge>) => {
      const id = action.payload.id;
      const currentChallenge =
        action.payload.state === UserChallengeState.ONGOING ||
        action.payload.state === UserChallengeState.DUEL
          ? action.payload
          : null;
      const updatedList = [...state.userChallengeList];
      const findIndex = updatedList.findIndex(
        (challenge) => challenge.id === id
      );
      updatedList[findIndex] = action.payload;

      state.userChallengeList = updatedList;
      state.currentChallenge = currentChallenge || state.currentChallenge;
    },
    unlockNextUserChallenge: (state, action: PayloadAction<UserChallenge>) => {
      const id = action.payload.id;
      const updatedList = [...state.userChallengeList];
      const findIndex = updatedList.findIndex(
        (challenge) => challenge.id === id
      );
      updatedList[findIndex] = action.payload;
      if (
        typeof updatedList[findIndex + 1] !== 'undefined' &&
        updatedList[findIndex + 1].state === UserChallengeState.LOCKED
      ) {
        updatedList[findIndex + 1] = {
          ...updatedList[findIndex + 1],
          state: UserChallengeState.UNLOCKED,
        };
      }

      state.userChallengeList = updatedList;
      state.currentChallenge = null;
    },
    setChallengeConsumption: (state, action: SetChallengeConsumption) => {
      const id = action.payload.userChallenge.id;
      const updatedList = [...state.userChallengeList];
      const findIndex = updatedList.findIndex(
        (challenge) => challenge.id === id
      );
      updatedList[findIndex] = action.payload.userChallenge;

      state.userChallengeList = updatedList;
      state.currentDataload = action.payload.currentDataload;
      if (
        state.currentChallenge &&
        state.currentChallenge.id === action.payload.userChallenge.id
      ) {
        state.currentChallenge = action.payload.userChallenge;
      }
    },
  },
});

export const {
  setChallengesList,
  setDuelsList,
  setExplorationsList,
  setQuizList,
  setQuestionsList,
  setCustomQuestionsList,
  setEcogesturesList,
  setChallengeConsumption,
  setUserChallengeList,
  setUserChallengeProgress,
  setUserEcogestureProgress,
  reinitAllEcogesturesProgress,
  setCurrentUserChallenge,
  unlockNextUserChallenge,
  updateUserChallengeList,
} = challengeSlice.actions;
