import { Button, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from 'assets/icons/ico/close.svg';
import GCUContent from 'components/Options/GCU/GCUContent';
import { useTranslation } from 'react-i18next';
import React from 'react';
import './termsView.scss';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';

interface CGUModalProps {
  open: boolean;
  handleCloseClick: () => void;
}
const CGUModal = ({ open, handleCloseClick }: CGUModalProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleCloseClick}
      aria-labelledby="accessibility-title"
      classes={{
        root: 'modal-root',
        paper: 'modal-paper',
      }}
    >
      <div id="accessibility-title">
        {t('feedback.accessibility.window_title')}
      </div>
      <IconButton
        aria-label={t('feedback.accessibility.button_close')}
        className="modal-paper-close-button"
        onClick={handleCloseClick}
      >
        <StyledIcon icon={CloseIcon} width={16} />
      </IconButton>
      <GCUContent />
      <Button
        aria-label={t('gcu_modal.accessibility.button_accept')}
        onClick={handleCloseClick}
        className="gcu-modal-button btn-profile-next rounded text-16-bold"
      >
        <span>{t('legal.accessibility.button_close')}</span>
      </Button>
    </Dialog>
  );
};

export default CGUModal;
