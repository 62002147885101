import {
  FluidType,
  Season,
  UserActionState,
  UserChallengeUpdateFlag,
} from 'enums';
import { orderBy } from 'lodash';
import { DateTime } from 'luxon';
import { Ecogesture, UserAction, UserChallenge } from 'models';
import { getSeason } from 'utils/utils';
import ChallengeService from './challenge.service';
import EcogestureService from './ecogesture.service';
import AppAxiosService from './appAxios.service';
import {
  StellioChallengeProgress,
  StellioEcogesture,
  StellioExploration,
} from 'models/stellio.model';

export default class ActionService {
  private readonly _client: AppAxiosService;
  private readonly _userSub: string;
  private readonly _ecogestureService: EcogestureService;
  private readonly _challengeService: ChallengeService;
  private readonly _userChallengeProgress: StellioChallengeProgress;
  private readonly _allStellioEcogestures: StellioEcogesture[];
  private readonly _allStellioExplorations: StellioExploration[];

  constructor(
    _client: AppAxiosService,
    userSub: string,
    userChallengeProgress: StellioChallengeProgress,
    allStellioEcogestures: StellioEcogesture[],
    allStellioExplorations: StellioExploration[]
  ) {
    this._client = _client;
    this._userSub = userSub;
    this._userChallengeProgress = userChallengeProgress;
    this._allStellioEcogestures = allStellioEcogestures;
    this._allStellioExplorations = allStellioExplorations;
    this._ecogestureService = new EcogestureService(
      this._client,
      allStellioEcogestures,
      userChallengeProgress
    );
    this._challengeService = new ChallengeService(
      this._client,
      this._userSub,
      userChallengeProgress,
      allStellioEcogestures,
      allStellioExplorations
    );
  }

  /**
   * Get the list of available actions
   * @returns {Promise<Ecogesture[]>}
   */
  public async getAvailableActionList(): Promise<Ecogesture[]> {
    const userChallenges: UserChallenge[] =
      await this._challengeService.getAllUserChallengeEntities();
    const ecogestures: Ecogesture[] =
      await this._ecogestureService.getAllEcolyoGestures();
    const actionsListIds: string[] = ecogestures
      .filter((ecogesture) => ecogesture.action === true)
      .map((action) => action._id);

    const actionsDone: (string | undefined)[] = userChallenges
      .map((challenge) => challenge.action)
      .filter((action) => action.state === UserActionState.DONE)
      .map((action) => action.ecogesture?.id);

    // Remove actions Done from the list
    if (actionsDone.length > 0) {
      actionsListIds.forEach((id) => {
        if (actionsDone.includes(id)) {
          const index = actionsListIds.indexOf(id);
          actionsListIds.splice(index);
        }
      });
    }
    const actionsList: Ecogesture[] =
      await this._ecogestureService.getEcogesturesByIds(actionsListIds);
    return actionsList;
  }

  /**
   * Get default eocgestures
   * @returns {Promise<Ecogesture[]>}
   */
  public async getDefaultActions(): Promise<Ecogesture[]> {
    const actionIds = ['ECOGESTURE0030', 'ECOGESTURE0014', 'ECOGESTURE0010'];
    const defaultActions =
      await this._ecogestureService.getEcogesturesByIds(actionIds);
    return defaultActions;
  }

  /** Complete the list with unfinished default ecogestures
   * @returns {Promise<Ecogesture[]>}
   */
  public async keepListComplete(
    actionList: Ecogesture[]
  ): Promise<Ecogesture[]> {
    const defaultActions: Ecogesture[] = await this.getDefaultActions();
    let i = 0;
    while (actionList.length < 3) {
      actionList.push(defaultActions[i]);
      i++;
    }
    return actionList;
  }

  /**
   *
   * @param {Ecogesture[]} filteredList
   * @param {Ecogesture[]} actionList
   * @returns {Ecogesture[]}
   */
  private completeWithNoSeason(
    filteredList: Ecogesture[],
    actionList: Ecogesture[]
  ): Ecogesture[] {
    if (filteredList.length < 3) {
      const noSeasonList = actionList.filter(
        (action) => action.season === Season.NONE
      );
      let i = 0;
      while (filteredList.length < 3 && i < 3) {
        if (noSeasonList[i]) {
          filteredList.push(noSeasonList[i]);
        }
        i++;
      }
    }
    return filteredList;
  }
  /**
   * filterBySeason - Filter action list depending on current season
   * @param {Ecogesture[]} actionList
   * @returns {Ecogesture[]}
   */
  public filterBySeason(actionList: Ecogesture[]): Ecogesture[] {
    let filteredList: Ecogesture[] = [];
    const currentSeason = getSeason();
    if (currentSeason === Season.SUMMER) {
      filteredList = actionList.filter(
        (action) => action.season === Season.SUMMER
      );
      filteredList = this.completeWithNoSeason(filteredList, actionList).slice(
        0,
        3
      );
    } else if (currentSeason === Season.WINTER) {
      filteredList = actionList.filter(
        (action) => action.season === Season.WINTER
      );
      filteredList = this.completeWithNoSeason(filteredList, actionList);
    } else {
      filteredList = actionList.filter(
        (action) => action.season === Season.NONE
      );
    }
    return filteredList;
  }

  /**
   * getCustomActions - Filter action by fluid and season and sort them by difficulty first and efficiency second
   * @param {FluidType[]} connectedFluids
   * @returns {Promise<Ecogesture[]>}
   */
  public async getCustomActions(
    connectedFluids: FluidType[]
  ): Promise<Ecogesture[]> {
    const availableActions: Ecogesture[] = await this.getAvailableActionList();
    const filteredByFluid: Ecogesture[] = availableActions.filter((action) =>
      action.fluidTypes.some((fluid) => connectedFluids.includes(fluid))
    );

    let filteredBySeason: Ecogesture[] = this.filterBySeason(filteredByFluid);

    if (!filteredBySeason.length) {
      return await this.getDefaultActions();
    }

    if (filteredBySeason.length > 3) {
      filteredBySeason = filteredBySeason.slice(0, 3);
    } else if (filteredBySeason.length < 3) {
      filteredBySeason = await this.keepListComplete(filteredBySeason);
    }

    const sortedByDifficultyAndEfficiency: Ecogesture[] = orderBy(
      filteredBySeason,
      [
        (action) => {
          return action.difficulty;
        },
        (action) => {
          return action.efficiency;
        },
      ],
      ['asc', 'desc']
    );
    return sortedByDifficultyAndEfficiency;
  }

  /**
   * launchAction - parse ecogesture to UserAction and launch it
   * @param {Ecogesture} Ecogesture
   * @returns {UserAction}
   */
  public launchAction(ecogesture: Ecogesture): UserAction {
    const userAction: UserAction = {
      ecogesture: ecogesture,
      startDate: DateTime.local().setZone('utc', {
        keepLocalTime: true,
      }),
      state: UserActionState.ONGOING,
    };
    return userAction;
  }
  /**
   * awaitNotificationAction
   * @param {UserAction} UserAction
   * @returns {UserAction} - updated user action
   */
  public awaitNotificationAction(userAction: UserAction): UserAction {
    const updatedUserAction: UserAction = {
      ...userAction,
      state: UserActionState.NOTIFICATION,
    };
    return updatedUserAction;
  }
  /**
   * endAction
   * @param {UserAction} UserAction
   * @returns {UserAction} - updated user action
   */
  public endAction(userAction: UserAction): UserAction {
    const updatedUserAction: UserAction = {
      ...userAction,
      state: UserActionState.DONE,
    };
    return updatedUserAction;
  }

  /**
   * isActionDone - Set Action state to notification if progress > actionDuration
   * @param {UserChallenge} currentChallenge
   * @returns {Promise<UserChallenge | null>}
   */
  public async isActionDone(
    currentChallenge: UserChallenge
  ): Promise<UserChallenge | null> {
    if (
      !currentChallenge.action.startDate ||
      !currentChallenge.action.ecogesture
    ) {
      return null;
    }

    const startDate: DateTime = currentChallenge.action.startDate;
    const duration = currentChallenge.action.ecogesture.actionDuration;
    const progress = -startDate.startOf('day').diffNow('days').days;

    if (progress >= duration) {
      const challengeService = new ChallengeService(
        this._client,
        this._userSub,
        this._userChallengeProgress,
        this._allStellioEcogestures,
        this._allStellioExplorations
      );

      const userChallenge: UserChallenge =
        await challengeService.updateUserChallenge(
          currentChallenge,
          UserChallengeUpdateFlag.ACTION_NOTIFICATION
        );
      return userChallenge;
    } else return null;
  }
}
