import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const useMatomoTracker = () => {
  const matomoLoaded = useRef(false);

  useEffect(() => {
    if (matomoLoaded.current) return;

    matomoLoaded.current = true;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = `${process.env.REACT_APP_MATOMO_URL}/matomo.js`;
    document.body.appendChild(script);

    window._paq = window._paq || [];
    window._paq.push(['trackPageView']);
    window._paq.push(['enableLinkTracking']);
    window._paq.push([
      'setTrackerUrl',
      `${process.env.REACT_APP_MATOMO_URL}/matomo.php`,
    ]);
    window._paq.push(['setSiteId', process.env.REACT_APP_MATOMO_SITE_ID]);
    window._paq.push(['requireConsent']);
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (!matomoLoaded.current) return;

    window._paq.push(['setCustomUrl', location.pathname]);
    window._paq.push(['trackPageView']);
  }, [location]);
};
