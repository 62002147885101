import { FluidType, TimeStep } from 'enums';
import { DateTime, Interval } from 'luxon';
import { FluidConfig, TimePeriod } from 'models';
import ConfigService from './fluidConfig.service';

export default class DateChartService {
  /**
   * Define a time period for a given slide defined by its index
   * @param referenceDate
   * @param timeStep
   * @param index
   */
  public defineTimePeriod(
    referenceDate: DateTime,
    timeStep: TimeStep,
    index: number
  ): TimePeriod {
    let date: DateTime;
    switch (timeStep) {
      case TimeStep.YEAR:
        date = referenceDate.set({
          month: 1,
          day: 1,
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        return {
          startDate: date.plus({ years: -5 * index - (5 - 1) }),
          endDate: date.plus({ years: -5 * index }),
        };
      case TimeStep.MONTH:
        date = referenceDate.set({
          month: 12,
          day: 1,
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        return {
          startDate: date.plus({
            months: -12 * index - (12 - 1),
          }),
          endDate: date.plus({ months: -12 * index }).endOf('month'),
        };
      case TimeStep.DAY:
        date = referenceDate.set({
          day: 1,
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        return {
          startDate: date.plus({ month: -index }).startOf('day'),
          endDate: date
            .plus({
              month: -index,
            })
            .endOf('month')
            .startOf('day'),
        };
      case TimeStep.WEEK:
        date = referenceDate
          .endOf('week')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        return {
          startDate: date.plus({ days: -7 * index - (7 - 1) }),
          endDate: date.plus({ days: -7 * index }),
        };
      case TimeStep.HALF_AN_HOUR:
        date = referenceDate.set({
          hour: 23,
          minute: 30,
          second: 0,
          millisecond: 0,
        });
        return {
          startDate: date
            .set({ hour: 0, minute: 0 })
            .plus({ days: -1 * index }),
          endDate: date.plus({ days: -1 * index }),
        };
      default:
        throw new Error('TimeStep unknown');
    }
  }

  /**
   * Return first step date
   * @param {DateTime} referenceDate - reference date
   * @param {TimeStep} timeStep - time step
   * @param {number} index - index
   * @returns {DateTime}
   */
  public defineFirstStepDate(
    referenceDate: DateTime,
    timeStep: TimeStep,
    index: number
  ): DateTime {
    const period = this.defineTimePeriod(referenceDate, timeStep, index);
    return period.startDate;
  }

  /**
   * Return last step date
   * @param {DateTime} referenceDate - reference date
   * @param {TimeStep} timeStep - time step
   * @param {number} index - index
   * @returns {DateTime}
   */
  public defineLastStepDate(
    referenceDate: DateTime,
    timeStep: TimeStep,
    index: number
  ): DateTime {
    const period = this.defineTimePeriod(referenceDate, timeStep, index);
    return period.endDate;
  }

  /**
   * Compare two date time in function of a time step
   * @param {TimeStep} timeStep - time step
   * @param {DateTime} date1 - first date to compare
   * @param {DateTime} date2 - second date to compare
   * @returns {boolean} - return true if date are equals for the time step
   */
  public compareStepDate(
    timeStep: TimeStep,
    date1: DateTime,
    date2: DateTime
  ): boolean {
    switch (timeStep) {
      case TimeStep.YEAR:
        return date1.year === date2.year;
      case TimeStep.MONTH:
        return date1.year === date2.year && date1.month === date2.month;
      case TimeStep.DAY:
      case TimeStep.WEEK:
        return (
          date1.year === date2.year &&
          date1.month === date2.month &&
          date1.day === date2.day
        );
      case TimeStep.HALF_AN_HOUR:
        return (
          date1.year === date2.year &&
          date1.month === date2.month &&
          date1.day === date2.day &&
          date1.hour === date2.hour &&
          Math.abs(date1.minute - date2.minute) === 0
        );
      default:
        throw new Error('TimeStep unknown');
    }
  }

  /**
   * Return the number of increment when changing to previous index
   * @param {TimeStep} timeStep - current time step
   * @param {DateTime} selectedDate - current selected date
   * @param {number} index - current index
   * @returns {number} - number of increment
   */
  public defineIncrementForPreviousIndex(
    timeStep: TimeStep,
    selectedDate: DateTime,
    index: number
  ): number {
    switch (timeStep) {
      case TimeStep.YEAR:
        return -5 + (DateTime.local().year + 5 * -index) - selectedDate.year;
      case TimeStep.MONTH:
        return -selectedDate.month;
      case TimeStep.DAY:
        return -selectedDate.day;
      case TimeStep.WEEK:
        return -selectedDate.weekday;
      case TimeStep.HALF_AN_HOUR:
        if (selectedDate.minute === 30) {
          return -selectedDate.hour * 2 - 2;
        } else {
          return -selectedDate.hour * 2 - 1;
        }
      default:
        return 0;
    }
  }

  /**
   * Return the number of increment when changing to previous index
   * @param {TimeStep} timeStep - current time step
   * @param {DateTime} selectedDate - current selected date
   * @param {number} index - current index
   * @returns {number} - number of increment
   */
  public defineIncrementForNextIndex(
    timeStep: TimeStep,
    selectedDate: DateTime,
    index: number
  ): number {
    switch (timeStep) {
      case TimeStep.YEAR:
        return 5 + DateTime.local().year - 5 * index - selectedDate.year;
      case TimeStep.MONTH:
        return 12 + 12 - selectedDate.month;
      case TimeStep.DAY:
        return (
          selectedDate.daysInMonth ??
          30 -
            selectedDate.day +
            (selectedDate.plus({ month: 1 }).daysInMonth ?? 30)
        );
      case TimeStep.WEEK:
        return 7 + 7 - selectedDate.weekday;
      case TimeStep.HALF_AN_HOUR:
        if (selectedDate.minute === 30) {
          return 48 + 48 - selectedDate.hour * 2 - 2;
        } else {
          return 48 + 48 - selectedDate.hour * 2 - 1;
        }
      default:
        return 0;
    }
  }

  /**
   * Return the date incremented in function of the timestep
   * @param {TimeStep} timeStep - current time step
   * @param {DateTime} selectedDate - current selected date
   * @param {number} increment - increment
   * @returns {DateTime} - incremented date
   */
  public incrementDate(
    timeStep: TimeStep,
    selectedDate: DateTime,
    increment: number
  ): DateTime {
    switch (timeStep) {
      case TimeStep.YEAR:
        return selectedDate.plus({ year: increment });
      case TimeStep.MONTH:
        return selectedDate.plus({ month: increment });
      case TimeStep.DAY:
        return selectedDate.plus({ day: increment });
      case TimeStep.WEEK:
        return selectedDate.plus({ day: increment });
      case TimeStep.HALF_AN_HOUR:
        return selectedDate.plus({
          minute: increment * 30,
        });
      default:
        return selectedDate;
    }
  }

  /**
   * Return the chart index in function of the timestep and a given date
   * @param {TimeStep} timeStep - current time step
   * @param {DateTime} selectedDate - current selected date
   * @returns {number} - index
   */
  public defineDateIndex(timeStep: TimeStep, selectedDate: DateTime): number {
    const today = DateTime.local().setZone('utc', {
      keepLocalTime: true,
    });
    switch (timeStep) {
      case TimeStep.YEAR:
        return Math.trunc((today.year - selectedDate.year) / 5);
      case TimeStep.MONTH:
        return today.year - selectedDate.year;
      case TimeStep.DAY:
        return today
          .startOf('month')
          .diff(selectedDate.startOf('month'), 'month')
          .get('month');
      case TimeStep.WEEK:
        return today
          .startOf('week')
          .diff(selectedDate.startOf('week'), 'week')
          .get('week');
      case TimeStep.HALF_AN_HOUR:
        return today
          .startOf('day')
          .diff(selectedDate.startOf('day'), 'day')
          .get('day');
      default:
        return 0;
    }
  }

  /**
   * Checks if the last data date is outdated and returns the number of missing days
   * @param {DateTime | null} lastDataDate
   * @param {FluidType} fluidType
   * @returns {number| null} - The number of missing days
   */
  public isDataOutdated(
    date: DateTime | null,
    fluidType: FluidType
  ): number | null {
    if (date && fluidType !== FluidType.MULTIFLUID) {
      const fluidConfig: Array<FluidConfig> =
        new ConfigService().getFluidConfig();
      const today = DateTime.local().setZone('utc', {
        keepLocalTime: true,
      });
      const inter = Interval.fromDateTimes(date, today).count('days');
      const limitDate = fluidConfig[fluidType].dataDelayOffset + 2;
      if (inter > limitDate) {
        return inter - 2;
      } else return null;
    } else return null;
  }
}
