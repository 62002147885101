export enum UserQuizState {
  UNLOCKED = 0,
  ONGOING = 1,
  DONE = 2,
}

export enum CustomQuestionType {
  DATE = 0,
  MAXDATA = 1,
  AVERAGE = 2,
}
export enum UserQuestionState {
  UNLOCKED = 0,
  CORRECT = 1,
  UNCORRECT = 2,
}
