import Button from '@mui/material/Button';
import starResult from 'assets/icons/visu/quiz/starResult.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import { useTranslation } from 'react-i18next';
import {
  // UsageEventType,
  UserChallengeUpdateFlag,
  UserExplorationState,
} from 'enums';
import { UserChallenge } from 'models';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ChallengeService from 'services/challenge.service';
import { updateUserChallengeList } from 'store/challenge/challenge.slice';
import { toggleChallengeExplorationNotification } from 'store/global/global.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import './explorationFinished.scss';
import AppAxiosService from 'services/appAxios.service';

interface ExplorationFinishedProps {
  userChallenge: UserChallenge;
}

const ExplorationFinished = ({ userChallenge }: ExplorationFinishedProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userChallengeProgress, ecogesturesList, explorationsList } =
    useAppSelector((store) => store.challenge);
  const userSub = useAppSelector((store) => store.profileKeycloakUser.sub);
  const navigate = useNavigate();

  const checkNotificationToEnd = useCallback(async () => {
    if (!userChallengeProgress) {
      return console.warn(
        'userChallengeProgress not set in checkNotificationToEnd'
      );
    }
    if (!userSub) return;

    const client = new AppAxiosService();

    const challengeService = new ChallengeService(
      client,
      userSub,
      userChallengeProgress,
      ecogesturesList,
      explorationsList
    );
    if (userChallenge.exploration.state === UserExplorationState.NOTIFICATION) {
      const updatedChallenge = await challengeService.updateUserChallenge(
        userChallenge,
        UserChallengeUpdateFlag.EXPLORATION_DONE
      );
      // await UsageEventService.addEvent(client, {
      //   type: UsageEventType.EXPLORATION_END_EVENT,
      //   target: userChallenge.exploration.id,
      //   context: userChallenge.id,
      //   startDate: userChallenge.exploration.date,
      // })
      dispatch(toggleChallengeExplorationNotification(false));
      dispatch(updateUserChallengeList(updatedChallenge));
    }
  }, [
    dispatch,
    ecogesturesList,
    explorationsList,
    userChallenge,
    userChallengeProgress,
  ]);

  const goBack = useCallback(async () => {
    await checkNotificationToEnd();
    navigate(-1);
  }, [navigate, checkNotificationToEnd]);

  return (
    <div className="exploration-card">
      <div className="exploration-finish">
        <div className="congratulation">{t('exploration.congratulation')}</div>
        <div className="msg-success">
          {userChallenge.exploration.message_success}
        </div>
        <div className="exploration-earn">{t('exploration.earn')}</div>
        <div className="score-stars">
          5
          <StyledIcon
            className="exploration-icon"
            icon={starResult}
            size={30}
            role="img"
            title="Etoiles"
            ariaHidden={false}
          />
        </div>
        <Button
          aria-label={t('exploration.accessibility.button_confirm')}
          onClick={goBack}
          className="btn-secondary-negative text-16-normal"
        >
          <span>{t('exploration.button_confirm')}</span>
        </Button>
      </div>
    </div>
  );
};

export default ExplorationFinished;
