import { FluidType, TimeStep } from 'enums';
import { Interval } from 'luxon';
import { TimePeriod } from 'models';

export default class ConsumptionValidatorService {
  public ValidateGetGraphData(
    timePeriod: TimePeriod,
    timeStep: TimeStep,
    fluidTypes: FluidType[],
    compareTimePeriod?: TimePeriod,
    isExport?: boolean
  ): boolean {
    if (fluidTypes.length === 0) {
      console.warn('ValidateGetGraphData - 1');
      return false;
    }

    if (!this.ValidateTimePeriod(timePeriod)) {
      console.warn('ValidateGetGraphData - 2');
      return false;
    }

    for (const fluidType of fluidTypes) {
      if (
        timeStep === TimeStep.HALF_AN_HOUR &&
        fluidType !== FluidType.ELECTRICITY
      ) {
        console.warn('ValidateGetGraphData - 2.5');
        return false;
      }
    }

    if (!isExport && !this.ValidateTimePeriodLength(timePeriod, timeStep)) {
      console.warn('ValidateGetGraphData - 3');
      return false;
    }

    if (compareTimePeriod) {
      if (!this.ValidateTimePeriod(compareTimePeriod)) {
        console.warn('ValidateGetGraphData - 4');
        return false;
      }
      if (!this.ValidateTimePeriodLength(compareTimePeriod, timeStep)) {
        console.warn('ValidateGetGraphData - 5');
        return false;
      }
    }

    return true;
  }

  private ValidateTimePeriodLength(
    timePeriod: TimePeriod,
    timeStep: TimeStep
  ): boolean {
    const interval = Interval.fromDateTimes(
      timePeriod.startDate,
      timePeriod.endDate
    );
    if (timeStep == TimeStep.HALF_AN_HOUR && interval.length('hour') > 24)
      return false;
    if (timeStep == TimeStep.WEEK && interval.length('day') > 7) return false;
    if (timeStep == TimeStep.DAY && interval.length('day') > 36) return false;
    if (timeStep == TimeStep.MONTH && interval.length('month') > 12)
      return false;
    if (timeStep == TimeStep.YEAR && interval.length('year') > 10) return false;
    return true;
  }

  private ValidateTimePeriod(timePeriod: TimePeriod): boolean {
    return timePeriod.endDate >= timePeriod.startDate;
  }
}
