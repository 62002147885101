import { DataloadState, FluidType, TimeStep } from 'enums';
import { DateTime, Interval } from 'luxon';
import { Dataload, FluidStatus, TimePeriod } from 'models';
import DateChartService from 'services/dateChart.service';
import { compareDates } from 'utils/date';
import ConfigService from './fluidConfig.service';

export default class ConsumptionFormatterService {
  public formatGraphData(
    data: Dataload[],
    timePeriod: TimePeriod,
    timeStep: TimeStep,
    fluidType: FluidType,
    fluidStatus?: FluidStatus
  ): Dataload[] {
    const inputData = [...data];
    inputData.sort((dataA, dataB) => compareDates(dataA.date, dataB.date));

    // Set status of data and complete missing/empty data
    const filledData: Dataload[] = [];
    let parsingDate = timePeriod.startDate;
    let endDate = timePeriod.endDate;

    const dateChartService = new DateChartService();

    while (parsingDate <= endDate) {
      const filtereddata = inputData.filter((dt) =>
        dateChartService.compareStepDate(timeStep, dt.date, parsingDate)
      );

      const newElement: Dataload = this.defineDataloadState(
        filtereddata[0]
          ? filtereddata[0]
          : {
              date: parsingDate,
              value: -1,
              state: DataloadState.EMPTY,
              valueDetail: null,
            },
        fluidType,
        timeStep,
        fluidStatus
      );

      filledData.push({ ...newElement });

      parsingDate = parsingDate.plus(this.getTimeFromStepTime(timeStep));
    }

    return filledData;
  }

  public defineDataloadState(
    data: Dataload,
    fluidType: FluidType,
    timeStep: TimeStep,
    fluidStatus?: FluidStatus
  ): Dataload {
    const today = DateTime.local().setZone('utc', {
      keepLocalTime: true,
    });
    // Return coming state if data data is >= today
    if (data.date >= today) {
      return { ...data, state: DataloadState.COMING };
    }
    if (!fluidStatus) {
      return data;
    }

    if (data.value !== -1) {
      return { ...data, state: DataloadState.VALID };
    }

    // Define state in function of first and last fluid data date
    if (
      fluidStatus.firstDataDate &&
      timeStep !== TimeStep.MONTH &&
      timeStep !== TimeStep.YEAR &&
      data.date < fluidStatus.firstDataDate
    ) {
      return { ...data, state: DataloadState.EMPTY };
    }
    if (
      fluidStatus.firstDataDate &&
      timeStep === TimeStep.MONTH &&
      data.date < fluidStatus.firstDataDate.startOf('month')
    ) {
      return { ...data, state: DataloadState.EMPTY };
    }
    if (
      fluidStatus.firstDataDate &&
      timeStep === TimeStep.YEAR &&
      data.date < fluidStatus.firstDataDate.startOf('year')
    ) {
      return { ...data, state: DataloadState.EMPTY };
    }
    if (fluidStatus.lastDataDate && data.date > fluidStatus.lastDataDate) {
      const isDataToCome: boolean = this.isDataToCome(data, fluidType);
      return {
        ...data,
        state: isDataToCome ? DataloadState.COMING : DataloadState.MISSING,
      };
    }
    if (
      fluidStatus.firstDataDate &&
      fluidStatus.lastDataDate &&
      data.date >= fluidStatus?.firstDataDate &&
      data.date <= fluidStatus?.lastDataDate
    ) {
      return {
        ...data,
        state: data.value === -1 ? DataloadState.HOLE : DataloadState.VALID,
      };
    }
    return data;
  }

  private isDataToCome(dataload: Dataload, fluidType: FluidType): boolean {
    const configService = new ConfigService();
    const fluidConfig = configService.getFluidConfig();

    const inter = Interval.fromDateTimes(
      dataload.date.startOf('day'),
      DateTime.local()
        .setZone('utc', {
          keepLocalTime: true,
        })
        .startOf('day')
    ).count('days');
    if (
      fluidType === FluidType.ELECTRICITY &&
      inter <= fluidConfig[0].dataDelayOffset + 1
    ) {
      return true;
    }
    if (
      fluidType === FluidType.WATER &&
      inter <= fluidConfig[1].dataDelayOffset + 1
    ) {
      return true;
    }
    if (
      fluidType === FluidType.GAS &&
      inter <= fluidConfig[2].dataDelayOffset + 1
    ) {
      return true;
    } else {
      return false;
    }
  }

  public defineAggregatedDataloadState(
    dataloadStateArray: DataloadState[]
  ): DataloadState {
    if (dataloadStateArray.includes(DataloadState.VALID)) {
      if (
        dataloadStateArray.includes(DataloadState.HOLE) ||
        dataloadStateArray.includes(DataloadState.MISSING)
      ) {
        return DataloadState.AGGREGATED_WITH_HOLE_OR_MISSING;
      }
      if (
        dataloadStateArray.includes(DataloadState.UPCOMING) ||
        dataloadStateArray.includes(DataloadState.COMING)
      ) {
        return DataloadState.AGGREGATED_WITH_COMING;
      }
      if (dataloadStateArray.includes(DataloadState.EMPTY)) {
        return DataloadState.AGGREGATED_WITH_EMPTY;
      }
      return DataloadState.AGGREGATED_VALID;
    }
    // No valid data but at least one hole or missing data
    if (
      dataloadStateArray.includes(DataloadState.HOLE) ||
      dataloadStateArray.includes(DataloadState.MISSING)
    ) {
      return DataloadState.AGGREGATED_HOLE_OR_MISSING;
    }
    // No valid data but at least one upcoming or coming data
    if (
      dataloadStateArray.includes(DataloadState.UPCOMING) ||
      dataloadStateArray.includes(DataloadState.COMING)
    ) {
      return DataloadState.AGGREGATED_COMING;
    }
    // No valid data but at least one empty data
    if (dataloadStateArray.includes(DataloadState.EMPTY)) {
      return DataloadState.AGGREGATED_EMPTY;
    }
    // Default
    return DataloadState.AGGREGATED_VALID;
  }

  private getTimeFromStepTime(timeStep: TimeStep) {
    switch (timeStep) {
      case TimeStep.HALF_AN_HOUR:
        return { minutes: 30 };
      case TimeStep.DAY:
        return { days: 1 };
      case TimeStep.MONTH:
        return { months: 1 };
      case TimeStep.YEAR:
        return { years: 1 };
      default:
        return { days: 1 };
    }
  }
}
