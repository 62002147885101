import Content from 'components/Content/Content';
import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { Ecogesture } from 'models';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import EcogestureSelectionDetail from './EcogestureSelectionDetail/EcogestureSelectionDetail';
import EcogestureSelectionEnd from './EcogestureSelectionEnd/EcogestureSelectionEnd';
import EcogestureSelectionModal from './EcogestureSelectionModal/EcogestureSelectionModal';
import EcogestureSelectionRestart from './EcogestureSelectionRestart/EcogestureSelectionRestart';
import './ecogestureSelectionView.scss';
import EcogestureService from 'services/ecogesture.service';
import AppAxiosService from 'services/appAxios.service';
import { setUserEcogestureProgress } from 'store/challenge/challenge.slice';

const EcogestureSelectionView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profileEcogesture = useAppSelector((state) => state.profileEcogesture);
  const { userChallengeProgress, ecogesturesList } = useAppSelector(
    (state) => state.challenge
  );
  const [isLoading, setIsLoading] = useState(true);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [indexEcogesture, setIndexEcogesture] = useState<number>(0);
  const [ecogestureList, setEcogestureList] = useState<Ecogesture[]>([]);
  const [totalViewed, setTotalViewed] = useState<number>(0);
  const [totalAvailable, setTotalAvailable] = useState<number>(0);
  const [openEcogestureSelectionModal, setOpenEcogestureSelectionModal] =
    useState(false);

  const ecogestureService = useMemo(() => {
    if (!userChallengeProgress) return;
    const client = new AppAxiosService();
    return new EcogestureService(
      client,
      ecogesturesList,
      userChallengeProgress
    );
  }, [ecogesturesList, userChallengeProgress]);

  const getTitle = useCallback((): string => {
    if (
      indexEcogesture <= ecogestureList.length - 1 &&
      ecogestureList[indexEcogesture]
    ) {
      return ecogestureList[indexEcogesture].shortName;
    }
    return t('ecogesture_selection.header');
  }, [ecogestureList, indexEcogesture, t]);

  const validateChoice = useCallback(
    async (objective: boolean, doing: boolean) => {
      setIsLoading(true);
      const updatedEcogesture: Ecogesture = {
        ...ecogestureList[indexEcogesture],
        objective: objective,
        doing: doing,
        viewedInSelection: true,
      };
      const response =
        await ecogestureService?.updateUserEcogesture(updatedEcogesture);
      if (response?.status !== 204) return;

      dispatch(setUserEcogestureProgress(updatedEcogesture));
      const updatedList: Ecogesture[] = ecogestureList;
      updatedList[indexEcogesture] = updatedEcogesture;
      setEcogestureList(updatedList);
      setIndexEcogesture((prev) => prev + 1);
      setIsLoading(false);
    },
    [ecogestureList, ecogestureService, indexEcogesture]
  );

  const restartSelection = useCallback(async () => {
    if (!ecogestureService) return;
    setIsLoading(true);

    const availableList: Ecogesture[] =
      await ecogestureService.getEcogestureListByProfile(profileEcogesture);
    const filteredList: Ecogesture[] = availableList.filter(
      (ecogesture: Ecogesture) => ecogesture.viewedInSelection === false
    );
    const slicedFilteredList = filteredList.slice(0, 10);
    setTotalViewed(availableList.length - filteredList.length);
    setEcogestureList(slicedFilteredList);
    setIndexEcogesture(0);
    setIsLoading(false);
  }, [ecogestureService, profileEcogesture]);

  useEffect(() => {
    let subscribed = true;
    async function getFilteredList() {
      if (!ecogestureService) return;

      const availableList: Ecogesture[] =
        await ecogestureService.getEcogestureListByProfile(profileEcogesture);
      const filteredList: Ecogesture[] = availableList.filter(
        (ecogesture: Ecogesture) => ecogesture.viewedInSelection === false
      );
      const slicedFilteredList = filteredList.slice(0, 10);
      if (subscribed) {
        if (
          availableList.length === filteredList.length &&
          slicedFilteredList.length > 0
        ) {
          setOpenEcogestureSelectionModal(true);
        }
        setTotalAvailable(availableList.length);
        setTotalViewed(availableList.length - filteredList.length);
        setEcogestureList(slicedFilteredList);
        setIndexEcogesture(0);
        setIsLoading(false);
      }
    }
    getFilteredList();
    return () => {
      subscribed = false;
    };
  }, [ecogestureService, profileEcogesture]);

  const renderEcogestureSelection = () => {
    if (indexEcogesture <= ecogestureList.length - 1) {
      return (
        <EcogestureSelectionDetail
          ecogesture={ecogestureList[indexEcogesture]}
          validate={validateChoice}
          title={getTitle()}
        />
      );
    } else if (totalAvailable > totalViewed + ecogestureList.length) {
      return (
        <EcogestureSelectionRestart
          listLength={ecogestureList.length}
          restart={restartSelection}
        />
      );
    } else {
      return <EcogestureSelectionEnd />;
    }
  };

  return (
    <>
      <Header
        setHeaderHeight={setHeaderHeight}
        desktopTitleKey="common.title_ecogestures_choice"
        displayBackArrow={true}
        backFunction={() => navigate('/ecogestures')}
      >
        <div className="eg-selection-header">
          {indexEcogesture <= ecogestureList.length - 1
            ? `${totalViewed + indexEcogesture + 1}/${totalAvailable}`
            : ''}
        </div>
      </Header>
      <Content heightOffset={headerHeight}>
        {isLoading && (
          <div className="loaderContainer">
            <Loader />
          </div>
        )}
        {!isLoading && (
          <>
            {renderEcogestureSelection()}
            {openEcogestureSelectionModal && (
              <EcogestureSelectionModal
                open={openEcogestureSelectionModal}
                handleCloseClick={() => setOpenEcogestureSelectionModal(false)}
              />
            )}
          </>
        )}
      </Content>
    </>
  );
};

export default EcogestureSelectionView;
