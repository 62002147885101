import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import defaultIcon from 'assets/icons/visu/duelResult/default.svg';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { importIconById } from 'utils/utils';
import './duelEmptyValueModal.scss';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';

interface DuelEmptyValueModalProps {
  open: boolean;
  handleCloseClick: () => void;
}

const DuelEmptyValueModal = ({
  open,
  handleCloseClick,
}: DuelEmptyValueModalProps) => {
  const { t } = useTranslation();
  const [emptyIcon, setEmptyIcon] = useState<string>(defaultIcon);
  useEffect(() => {
    async function handleEcogestureIcon() {
      const icon = await importIconById('emptyValue', 'duel');
      setEmptyIcon(icon || defaultIcon);
    }

    handleEcogestureIcon();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleCloseClick}
      aria-labelledby="accessibility-title"
      classes={{
        root: 'modal-root',
        paper: 'modal-paper blue-border',
      }}
    >
      <div id="accessibility-title">
        {t('duel_empty_value_modal.accessibility.window_title')}
      </div>
      <div className="modal-empty-value-root">
        <StyledIcon className="imgResult" icon={emptyIcon} width={208} />
        <div className="text-28-normal-uppercase modal-empty-value-title">
          {t('duel_empty_value_modal.title')}
        </div>
        <div className="text-18-normal">
          {t('duel_empty_value_modal.message')}
        </div>
        <Button
          aria-label={t('duel_empty_value_modal.accessibility.button_validate')}
          onClick={handleCloseClick}
          className="text-16-normal btn-secondary-negative"
        >
          <span> {t('duel_empty_value_modal.button')}</span>
        </Button>
      </div>
    </Dialog>
  );
};

export default DuelEmptyValueModal;
