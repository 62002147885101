export enum KonnectorError {
  LOGIN_FAILED = 'LOGIN_FAILED',
  USER_ACTION_NEEDED = 'USER_ACTION_NEEDED',
  TERMS_VERSION_MISMATCH = 'TERMS_VERSION_MISMATCH',
  CHALLENGE_ASKED = 'CHALLENGE_ASKED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  CRITICAL = 'exit status 1',
  MISSING_SECRET = "Cannot read property 'secret' of null",
}

export enum KonnectorUpdate {
  ERROR_UPDATE = 'error_update',
  ERROR_UPDATE_OAUTH = 'error_update_oauth',
  LOGIN_FAILED = 'login_failed',
  ERROR_CONSENT_FORM_GAS = 'error_consent_form_gas',
}
