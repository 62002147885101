import { ProfileTypeStepForm, SgeStep } from 'enums'
import React from 'react'
import './formProgress.scss'

interface FormProgressProps {
  step: ProfileTypeStepForm | SgeStep
  formType: 'sge' | 'profile'
}

const FormProgress = ({ step, formType }: FormProgressProps) => {
  const getProgress = () => {
    const total: number =
      Object.values(formType === 'sge' ? SgeStep : ProfileTypeStepForm).length /
      2
    const progress: number = Math.round((step / total) * 100) || 1
    return progress
  }
  return (
    <div className="profile-type-progress">
      <div className="profile-type-progress-label">{getProgress()}%</div>
      <div className="profile-type-progress-bar-container">
        <div
          className="profile-type-progress-bar-content"
          style={{ width: `${getProgress()}%` }}
        />
      </div>
    </div>
  )
}

export default FormProgress
