import { Button } from '@mui/material';
import BearIcon from 'assets/icons/visu/duelResult/CHALLENGE0001-0.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import Content from 'components/Content/Content';
import CozyBar from 'components/Header/CozyBar';
import Header from 'components/Header/Header';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import './unSubscribeView.scss';
import { updateStellioUserProfile } from 'store/profileStellioUser/profileStellioUser.slice';

const UnSubscribeView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const unSubscribe = async () => {
    dispatch(
      updateStellioUserProfile({
        sendAnalysisNotification: { type: 'Property', value: false },
      })
    );
    navigate('/consumption');
  };

  return (
    <>
      <CozyBar titleKey="common.title_analysis" />
      <Header
        setHeaderHeight={setHeaderHeight}
        desktopTitleKey="common.title_analysis"
      />
      <Content heightOffset={headerHeight}>
        <div className="unsubscribe-container">
          <StyledIcon className="profile-icon" icon={BearIcon} width={250} />

          <div className="text-20-bold head">{t('unsubscribe.title')}</div>
          <div className="text-16-normal question">
            {t('unsubscribe.content')}
          </div>
          <Button
            aria-label={t('unsubscribe.button_accessibility')}
            onClick={() => unSubscribe()}
            variant="contained"
            className="btn-highlight text-18-bold"
            type="submit"
          >
            <span>{t('unsubscribe.button_text')}</span>
          </Button>
        </div>
      </Content>
    </>
  );
};

export default UnSubscribeView;
