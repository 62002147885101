import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { AnalysisState } from 'models';

const initialState: AnalysisState = {
  period: 'month',
  analysisMonth: DateTime.local().minus({ months: 1 }).startOf('day'),
};

export const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    setPeriod: (state, action: PayloadAction<'month' | 'year'>) => {
      state.period = action.payload;
    },
    setAnalysisMonth: (state, action: PayloadAction<DateTime>) => {
      state.analysisMonth = action.payload;
    },
  },
});

export const { setPeriod, setAnalysisMonth } = analysisSlice.actions;
