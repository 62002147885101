import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import correctAnswer from 'assets/icons/ico/correctAnswer.svg';
import wrongAnswer from 'assets/icons/ico/wrongAnswer.svg';
import { useTranslation } from 'react-i18next';
import { QuestionEntity } from 'models';
import React, { useEffect, useState } from 'react';
import './quizExplanationModal.scss';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';

interface QuizExplanationModalProps {
  open: boolean;
  question: QuestionEntity | undefined;
  answerIndex: number;
  goNext: () => void;
  handleCloseClick: () => void;
}

const QuizExplanationModal = ({
  open,
  answerIndex,
  question,
  goNext,
  handleCloseClick,
}: QuizExplanationModalProps) => {
  const { t } = useTranslation();
  const [validAnswer, setValidAnswer] = useState<number>(0);
  useEffect(() => {
    if (question) {
      const resultIndex: number = question.answers.findIndex(
        (answer) => answer.isTrue
      );
      setValidAnswer(resultIndex);
    }
  }, [question]);
  return (
    <Dialog
      open={open}
      onClose={handleCloseClick}
      aria-labelledby="accessibility-title"
      classes={{
        root: 'modal-root',
        paper: 'modal-paper',
      }}
    >
      <div id="accessibility-title">{t('quiz.accessibility.window_title')}</div>
      <div className="quiz-modal-root">
        {question && (
          <>
            {question.answers[answerIndex].isTrue ? (
              <div>
                <StyledIcon icon={correctAnswer} width={100} />
                <p className="quiz-modal-answer correct">{t('quiz.correct')}</p>
              </div>
            ) : (
              <div>
                <StyledIcon icon={wrongAnswer} width={100} />
                <p className="quiz-modal-answer wrong">{t('quiz.wrong')}</p>
              </div>
            )}
            <div className="text-20-normal answer-label">
              {t('quiz.rightAnswer')} <br />
              <strong>{question.answers[validAnswer].answerLabel}</strong>
            </div>
            <div className="text-16-normal">{question.explanation}</div>
          </>
        )}
        <br />
        <Button
          variant="outlined"
          aria-label={t('quiz.accessibility.button_go_next')}
          onClick={goNext}
        >
          {t('quiz.next')}
        </Button>
      </div>
    </Dialog>
  );
};

export default QuizExplanationModal;
