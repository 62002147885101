import { PerformanceIndicator } from 'models'
import ConverterService from 'services/converter.service'

export default class PerformanceIndicatorService {
  public aggregatePerformanceIndicators(
    performanceIndicators: PerformanceIndicator[]
  ): PerformanceIndicator {
    const converterService = new ConverterService()
    let currentValue = 0
    let compareValue = 0
    if (
      performanceIndicators[0] &&
      performanceIndicators[1] &&
      performanceIndicators[2]
    ) {
      for (const key in performanceIndicators) {
        const performanceIndicator = performanceIndicators[key]
        const fluidType = parseInt(key)
        currentValue += performanceIndicator.value
          ? converterService.LoadToEuro(
              performanceIndicator.value,
              fluidType,
              performanceIndicator?.price || null
            )
          : 0
      }
      if (
        !performanceIndicators[0].compareValue ||
        !performanceIndicators[1].compareValue ||
        !performanceIndicators[2].compareValue
      ) {
        compareValue = 0
      } else {
        for (const key in performanceIndicators) {
          const performanceIndicator = performanceIndicators[key]
          const fluidType = parseInt(key)
          compareValue += performanceIndicator.compareValue
            ? converterService.LoadToEuro(
                performanceIndicator.compareValue,
                fluidType,
                performanceIndicator?.price || null
              )
            : 0
        }
      }
    } else {
      const i = performanceIndicators.length - 1
      if (!performanceIndicators[i]?.value) {
        currentValue = 0
      } else {
        for (const key in performanceIndicators) {
          const performanceIndicator = performanceIndicators[key]
          const fluidType = parseInt(key)
          currentValue += performanceIndicator.value
            ? converterService.LoadToEuro(
                performanceIndicator.value,
                fluidType,
                performanceIndicator?.price || null
              )
            : 0
        }
      }
      if (!performanceIndicators[i]?.compareValue) {
        compareValue = 0
      } else {
        for (const key in performanceIndicators) {
          const performanceIndicator = performanceIndicators[key]
          const fluidType = parseInt(key)
          compareValue += performanceIndicator.compareValue
            ? converterService.LoadToEuro(
                performanceIndicator.compareValue,
                fluidType,
                performanceIndicator?.price || null
              )
            : 0
        }
      }
    }

    const aggregatedPerformanceIndicator: PerformanceIndicator = {
      value: currentValue,
      compareValue: compareValue,
      percentageVariation: currentValue / compareValue - 1,
    }
    return aggregatedPerformanceIndicator
  }
}
