import { QuestionEntity, UserChallenge } from 'models';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QuizService from 'services/quiz.service';
import { useAppSelector } from 'store/hooks';
import QuizQuestionContent from './QuizQuestionContent';
import QuizQuestionContentCustom from './QuizQuestionContentCustom';
import './quizQuestion.scss';
import AppAxiosService from 'services/appAxios.service';

const QuizQuestion = ({ userChallenge }: { userChallenge: UserChallenge }) => {
  const questionsIsLocked: boolean = userChallenge.quiz.questions.some(
    (answer) => answer.result == 0
  );
  const [question, setQuestion] = useState<QuestionEntity>();
  const [isCustomQuest, setIsCustomQuest] =
    useState<boolean>(!questionsIsLocked);
  const [customQuestionLoading, setCustomQuestionLoading] =
    useState<boolean>(false);
  const clientAxios = new AppAxiosService();
  const { fluidTypes } = useAppSelector((state) => state.global);
  const userSub = useAppSelector((store) => store.profileKeycloakUser.sub);
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/challenges');
  };

  useEffect(() => {
    let subscribed = true;
    async function loadCustomQuestion() {
      if (!userSub) return;
      const quizService: QuizService = new QuizService(clientAxios, userSub);
      const customQuestion: QuestionEntity =
        await quizService.getCustomQuestion(
          userChallenge.quiz.customQuestion,
          fluidTypes
        );
      if (subscribed) {
        setQuestion(customQuestion);
        setCustomQuestionLoading(false);
      }
    }
    if (isCustomQuest) {
      setCustomQuestionLoading(true);
      loadCustomQuestion();
    }
    return () => {
      subscribed = false;
    };
  }, [fluidTypes, isCustomQuest, userChallenge.quiz.customQuestion, userSub]);

  return (
    <>
      {isCustomQuest ? (
        <QuizQuestionContentCustom
          userChallenge={userChallenge}
          goBack={goBack}
          question={question}
          isLoading={customQuestionLoading}
        />
      ) : (
        <QuizQuestionContent
          userChallenge={userChallenge}
          setIsCustomQuest={setIsCustomQuest}
          goBack={goBack}
        />
      )}
    </>
  );
};

export default QuizQuestion;
